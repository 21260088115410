import React from 'react';
import { useTranslation } from 'react-i18next';
import { View, StyleSheet } from 'react-native';
import { ProgressBar } from 'react-native-paper';
import rptheme from '~/rptheme';
import ReparkButton from '~/src/components/ReparkButton';
import analytics from '../analytics/analytics';
import { OnboardingCompleteStep } from './OnboardingCompleteStep';
import { OnboardingEmailStep } from './OnboardingEmailStep';
import { OnboardingNameStep } from './OnboardingNameStep';
import { OnboardingWelcomeStep } from './OnboardingWelcomeStep';
import { RootScreens } from '../navigation';
import { AuthStore } from '../authentication/AuthStore';
import { OnboardingFeedbackStep } from './OnboardingFeedbackStep';
import { metaTrackOnboarding } from '../meta-pixel/renderThePixel.web';

export type OnboardingStepProps = {
  onNextButtonPress: () => void;
  buttonType: 'nextStep' | 'nextStep2' | 'toLastStep' | 'letsGo';
};

enum onboardingSteps {
  welcome = 1,
  email = 2,
  name = 3,
  feedback = 4,
  complete = 5,
  //vehicle = 6,
}

export const OnboardingScreen = ({ navigation }: any) => {
  const { t } = useTranslation();
  const [step, setStep] = React.useState(onboardingSteps.welcome);
  const timeoutBeforeCompletion = 2200;
  const [redirectAfterLogin] = AuthStore((store) => [store.redirectAfterLogin]);

  let progress;
  switch (step) {
    case onboardingSteps.welcome:
      progress = 0.5;
      break;
    case onboardingSteps.email:
      progress = 0.65;
      break;
    case onboardingSteps.name:
      progress = 0.8;
      break;
    case onboardingSteps.feedback:
      progress = 0.95;
      break;
    default:
      progress = step / Object.keys(onboardingSteps).length / 2;
  }

  // React.useEffect(() => {
  //   if (
  //     step !== onboardingSteps.complete &&
  //     step !== onboardingSteps.welcome &&
  //     step !== onboardingSteps.feedback
  //   ) {
  //     navigation.setOptions({
  //       headerRight: () => (
  //         <ReparkButton
  //           mode={'text'}
  //           onPress={() => {
  //             analytics.track('onboarding_skip', { currentStep: step });
  //             navigation.replace(RootScreens.MapScreen, {
  //               id: redirectAfterLogin || undefined,
  //             });
  //           }}
  //         >
  //           {t('General.skip')}
  //         </ReparkButton>
  //       ),
  //       headerLeft: () => (
  //         <ReparkButton
  //           onPress={() => {
  //             if (step > 1) {
  //               setStep(step - 1);
  //             }
  //           }}
  //           mode="text"
  //         >
  //           {t('General.back')}
  //         </ReparkButton>
  //       ),
  //     });
  //   } else if (step === onboardingSteps.feedback) {
  //     navigation.setOptions({
  //       headerRight: () => <></>,
  //       headerLeft: () => (
  //         <ReparkButton
  //           onPress={() => {
  //             if (step > 1) {
  //               setStep(step - 1);
  //             }
  //           }}
  //           mode="text"
  //         >
  //           {t('General.back')}
  //         </ReparkButton>
  //       ),
  //     });
  //   } else {
  //     navigation.setOptions({
  //       headerRight: () => <></>,
  //       headerLeft: () => <></>,
  //     });
  //   }
  // }, [step]);

  React.useEffect(() => {
    if (step === onboardingSteps.welcome || step === onboardingSteps.complete) {
      navigation.setOptions({
        headerRight: () => (
          <ReparkButton
            mode={'text'}
            onPress={() => {
              analytics.track('onboarding_skip', { currentStep: step });
              metaTrackOnboarding(step);
              navigation.replace(RootScreens.MapScreen, {
                id: redirectAfterLogin || undefined,
              });
            }}
          >
            {t('General.skip')}
          </ReparkButton>
        ),
        headerLeft: () => <></>,
      });
    } else {
      navigation.setOptions({
        headerLeft: () => (
          <ReparkButton
            onPress={() => {
              if (step > 1) {
                setStep(step - 1);
              }
            }}
            mode="text"
          >
            {t('General.back')}
          </ReparkButton>
        ),

        headerRight: () => (
          <ReparkButton
            mode={'text'}
            onPress={() => {
              analytics.track('onboarding_skip', { currentStep: step });
              metaTrackOnboarding(step);
              navigation.replace(RootScreens.MapScreen, {
                id: redirectAfterLogin || undefined,
              });
            }}
          >
            {t('General.skip')}
          </ReparkButton>
        ),
      });
    }
  }, [step]);

  return (
    <View style={styles.container}>
      {step !== onboardingSteps.welcome && (
        <ProgressBar
          progress={progress}
          indeterminate={step === onboardingSteps.complete}
        />
      )}

      <View style={styles.contentContainer}>
        {step === onboardingSteps.welcome && (
          <OnboardingWelcomeStep
            onNextButtonPress={() => setStep(step + 1)}
            buttonType="nextStep"
          />
        )}

        {step === onboardingSteps.email && (
          <OnboardingEmailStep
            onNextButtonPress={() => setStep(step + 1)}
            buttonType="nextStep"
          />
        )}
        {step === onboardingSteps.name && (
          <OnboardingNameStep
            onNextButtonPress={() => setStep(step + 1)}
            buttonType="toLastStep"
          />
        )}

        {step === onboardingSteps.feedback && (
          <OnboardingFeedbackStep
            buttonType="letsGo"
            onNextButtonPress={() => {
              setStep(onboardingSteps.complete);
              analytics.track('onboarding_complete');
              metaTrackOnboarding(0);
              setTimeout(
                () =>
                  navigation.replace(RootScreens.MapScreen, {
                    id: redirectAfterLogin,
                  }),
                timeoutBeforeCompletion
              );
            }}
          />
        )}
        {/* {step === onboardingSteps.vehicle && (
          <OnboardingVehicleStep
            onNextButtonPress={() => {
              setStep(onboardingSteps.complete);

              analytics.track('onboarding_complete');

              setTimeout(
                () =>
                  navigation.replace(RootScreens.MapScreen, {
                    id: redirectAfterLogin,
                  }),
                timeoutBeforeCompletion
              );
            }}
          />
        )} */}

        {step === onboardingSteps.complete && <OnboardingCompleteStep />}
        {/* <ReparkButton
          style={styles.nextButton}
          onPress={() => setStep(step + 1)}
        >
          Next
        </ReparkButton> */}
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    marginTop: 50,
    flex: 1,
    maxWidth: rptheme.screenSmall,
    width: '100%',
    alignSelf: 'center',
    padding: 25,
    display: 'flex',
    justifyContent: 'space-between',
  },
  contentContainer: { flex: 1, marginTop: 15 },
  list: {
    marginTop: 10,
  },
  text: {
    marginBottom: 10,
    fontSize: 16,
  },
  nextButton: {
    position: 'absolute',
    bottom: 0,
    width: '100%',
  },
});
