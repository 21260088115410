import { feathersClient } from './feathers';

export const getAllParkingSpots = async (query?: any) => {
  const spots = [];
  let toSkip = 0;
  let total, limit;

  // work through pagination
  try {
    do {
      const response = await feathersClient.service('parking-spots').find({
        query: {
          // $limit: 2,
          $skip: toSkip,
          ...query,
        },
      });
      total = response.total;
      limit = response.limit;
      if (response.total > 0) {
        spots.push(...response.data);
        toSkip += limit;
      }
    } while (0 !== total && toSkip - limit < total);
  } catch (e) {
    console.log(e);
  }
  return spots;
};

export const getGarageParkingSpots = async (garageId: string) => {
  const response = await feathersClient.service('parking-spots').find({
    query: { garageId },
  });
  return response.data;
};

export const fetchParkingSpotRequest = async (id: string) => {
  try {
    const response = await feathersClient.service('parking-spots').get(id);
    return response;
  } catch (e: any) {
    console.log(e);
    return { success: false, code: e.response?.status };
  }
};

export const getParkingSpotsToClaim = async (garageId: string) => {
  const query = {
    $and: [
      {
        garageId: garageId,
      },
      { ownerId: null },
    ],
  };

  const spots = await getAllParkingSpots(query);
  return spots;
};
