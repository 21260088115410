import React from 'react';
import { envIsDev } from '../utils/helpers';

const useCountdown = (seconds: number) => {
  const [timer, setTimer] = React.useState(envIsDev() ? 1 : seconds);
  const isTimerRunning = timer > 0;

  React.useEffect(() => {
    const interval = setInterval(() => {
      if (timer <= 0) return;
      setTimer((lastTimer) => {
        lastTimer <= 1 && clearInterval(interval);
        return lastTimer - 1;
      });
    }, 1000); // Decrement every second
    return () => {
      clearInterval(interval);
    };
  }, [isTimerRunning]);

  return { timer };
};

export default useCountdown;
