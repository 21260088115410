import React from 'react';
import { View, Image } from 'react-native';
import SwiperFlatList from 'react-native-swiper-flatlist';
import rptheme from '~/rptheme';

type ImageSwiperProps = {
  images: ImageSwiperImage[];
};

type ImageSwiperImage = {
  title: string;
  path: string;
};

export const ImageSwiper = (props: ImageSwiperProps) => {
  const carouselRef = React.useRef(null);
  const { images } = props;

  if (!images) return <></>;

  return (
    <SwiperFlatList
      ref={carouselRef}
      data={images}
      renderItem={({ item }: { item: ImageSwiperImage }) => (
        <View>
          <Image
            style={{
              height: 200,
              width: 200,
              marginHorizontal: 10,
              borderRadius: rptheme.roundness,
            }}
            testID="image"
            source={{
              uri: item.path,
            }}
          />
        </View>
      )}
    />
  );
};
