import React from 'react';
import { CenteredAppView } from '~/src/components/CenteredAppView';
import { NavProp, RootScreens } from '../navigation';
import { feathersClient } from '~/src/services/feathers';
import { SubscriptionProgressBar } from './components/SubscriptionProgressBar';
import { SubscriptionsStackScreens } from './SubscriptionStackNavigation';
import { WelcomeStep } from './steps/WelcomeStep';
import { GarageInfoStep } from './steps/GarageInfoStep';
import { ChooseParkingSpotStep } from './steps/ChooseParkingSpotStep';
import { ChoosePriceStep } from './steps/ChoosePriceStep';
import { StyleSheet, View } from 'react-native';

type ChooseParkingSpotScreenProps = {
  navigation: NavProp;
  route: any;
};

export const ChooseParkingSpotScreen = (
  props: ChooseParkingSpotScreenProps
) => {
  const { navigation } = props;
  const { garageId } = props.route.params;

  enum chooseParkingSpotSteps {
    welcome = 1,
    garageInfo = 2,
    chooseParkingspot = 3,
    choosePrice = 4,
  }

  const [garage, setGarage] = React.useState<any | null>(null);
  const [loading, setLoading] = React.useState(false);

  const [step, setStep] = React.useState<chooseParkingSpotSteps>(
    chooseParkingSpotSteps.welcome
  );

  const fetchGarage = async () => {
    try {
      setLoading(true);
      return await feathersClient.service('garages/subscribable').get(garageId);
    } catch (e) {
      navigation.navigate(RootScreens.MapScreen);
    } finally {
      setLoading(false);
    }
  };

  React.useEffect(() => {
    fetchGarage().then((res) => {
      console.log('Garage', res);
      setGarage(res);
    });
  }, []);

  const showProgressBar = [
    chooseParkingSpotSteps.garageInfo,
    chooseParkingSpotSteps.chooseParkingspot,
    chooseParkingSpotSteps.choosePrice,
  ].includes(step);

  let progress;
  switch (step) {
    case chooseParkingSpotSteps.garageInfo:
      progress = 0.1;
      break;
    case chooseParkingSpotSteps.choosePrice:
      progress = 0.2;
      break;
    default:
      progress = step / Object.keys(chooseParkingSpotSteps).length / 2;
  }

  return (
    <CenteredAppView>
      <>
        {showProgressBar && (
          <SubscriptionProgressBar
            progress={progress}
            navigation={navigation}
          />
        )}
        <View style={styles.stepsContainer}>
          {step === chooseParkingSpotSteps.welcome && (
            <WelcomeStep
              garage={garage}
              onContinue={() => setStep(chooseParkingSpotSteps.garageInfo)}
              loading={loading}
            />
          )}

          {step === chooseParkingSpotSteps.garageInfo && (
            <GarageInfoStep
              garage={garage}
              onContinue={() =>
                setStep(chooseParkingSpotSteps.chooseParkingspot)
              }
              navigation={navigation}
            />
          )}

          {step === chooseParkingSpotSteps.chooseParkingspot && (
            <ChooseParkingSpotStep
              garage={garage}
              onContinue={() => setStep(chooseParkingSpotSteps.choosePrice)}
              onBack={() => setStep(chooseParkingSpotSteps.garageInfo)}
            />
          )}

          {step === chooseParkingSpotSteps.choosePrice && (
            <ChoosePriceStep
              garage={garage}
              onContinue={() => {
                props.navigation.navigate(SubscriptionsStackScreens.DataInput, {
                  garageId,
                });
              }}
              onBack={() => setStep(chooseParkingSpotSteps.chooseParkingspot)}
            />
          )}
        </View>
      </>
    </CenteredAppView>
  );
};

const styles = StyleSheet.create({
  stepsContainer: {
    paddingTop: 20,
    flex: 1,
  },
});
