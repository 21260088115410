import React from 'react';
import { View, StyleSheet, RotateTransform } from 'react-native';
import { ActivityIndicator, Subheading, Title } from 'react-native-paper';
import { fetchBookingsRequest } from '../booking/bookingService';
import ReparkTextInput from '~/src/components/ReparkTextInput';
import { useTranslation } from 'react-i18next';
import {
  isActiveBooking,
  isPastBooking,
  isUpcomingBooking,
} from '../booking/helpers';
import ReparkListItem from '~/src/components/ReparkListItem';
import dayjs from 'dayjs';
import { ReparkIcon } from '~/src/components/ReparkIcon';
import rptheme from '~/rptheme';

export const CompanyBookingOverview = (props: { companyId: string }) => {
  const { t } = useTranslation();
  const [bookings, setBookings] = React.useState<any>([]);
  const [licencePlate, setLicencePlate] = React.useState<string>('');
  const [refresh, setRefresh] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);

  const [expanded, setExpanded] = React.useState({
    active: false,
    future: false,
    past: false,
  });

  React.useEffect(() => {
    setIsLoading(true);
    const doAsync = async () => {
      const oldest = dayjs().subtract(30, 'days');
      const bookings = await fetchBookingsRequest({
        companyId: props.companyId,
        start: { $gt: oldest },
      });
      setBookings(bookings);
    };
    doAsync().then(() => {
      setIsLoading(false);
    });
  }, [refresh]);

  const bookingsFiltered = bookings.filter((booking: any) =>
    booking.licencePlate.includes(licencePlate)
  );
  //in a perfect world, we would have time and do this properly in the backend and implement a proper
  //lazy loading
  const activeBookings = bookingsFiltered.filter(
    (booking: any) => isActiveBooking(booking) && !booking.canceled
  );

  const futureBookings = bookingsFiltered.filter(
    (booking: any) =>
      isUpcomingBooking(booking) && !booking.isActive && !booking.canceled
  );

  const pastBookings = bookingsFiltered.filter(
    (booking: any) => isPastBooking(booking) && !booking.canceled
  );

  return (
    <View>
      <View>
        <Title>Buchungen</Title>
        <ReparkTextInput
          testID={'licencePlateInput'}
          onChangeText={(text: string) => {
            setLicencePlate(text.replace(/[^a-zA-Z0-9]/g, '').toUpperCase());
          }}
          value={licencePlate}
          maxLength={10}
          secureTextEntry={false}
          autoCorrect={false}
          spellCheck={false}
          autoComplete={false}
          keyboardType={'default'}
          autoFocus={false}
          label={t('Vehicle.licencePlate')}
          zoderror={null}
          icon={'car'}
        />
      </View>
      <View style={styles.controls}>
        <View style={styles.refresh}>
          <ReparkIcon
            name={'spinner'}
            size={15}
            onPress={() => setRefresh(!refresh)}
          />
        </View>
        <View
          style={
            Object.values(expanded).every((key) => key)
              ? styles.chevronAllUp
              : styles.chevronAllDown
          }
        >
          <ReparkIcon
            onPress={() => {
              if (Object.values(expanded).every((key) => key)) {
                setExpanded({
                  active: false,
                  future: false,
                  past: false,
                });
              } else {
                setExpanded({
                  active: true,
                  future: true,
                  past: true,
                });
              }
            }}
            size={16}
            name="chevron-left"
          />
        </View>
      </View>
      {isLoading ? (
        <ActivityIndicator size="large" color={rptheme.colors.primary} />
      ) : (
        <View>
          <View style={expanded.active ? styles.wrapperOpen : {}}>
            <Subheading
              style={
                expanded.active
                  ? styles.subheadingOpen
                  : styles.subheadingClosed
              }
              onPress={() =>
                setExpanded({ ...expanded, active: !expanded.active })
              }
            >
              Aktiv ({activeBookings.length})
              <View
                style={expanded.active ? styles.chevronUp : styles.chevronDown}
              >
                <ReparkIcon name="chevron-left" />
              </View>
            </Subheading>
            {expanded.active &&
              activeBookings.map((booking: any) => (
                <ReparkListItem
                  key={booking.id}
                  title={booking.licencePlate}
                  description={`${dayjs(booking.start).format(
                    'DD.MM.'
                  )} - ${dayjs(booking.start)
                    .add(booking.duration, 'minutes')
                    .subtract(1, 'second')
                    .format('DD.MM.YYYY')}`}
                />
              ))}
          </View>

          <View style={expanded.future ? styles.wrapperOpen : {}}>
            <Subheading
              style={
                expanded.future
                  ? styles.subheadingOpen
                  : styles.subheadingClosed
              }
              onPress={() =>
                setExpanded({ ...expanded, future: !expanded.future })
              }
            >
              Zukünftig ({futureBookings.length})
              <View
                style={expanded.future ? styles.chevronUp : styles.chevronDown}
              >
                <ReparkIcon name="chevron-left" />
              </View>
            </Subheading>
            {expanded.future &&
              futureBookings.map((booking: any) => (
                <ReparkListItem
                  key={booking.id}
                  title={booking.licencePlate}
                  description={`${dayjs(booking.start).format(
                    'DD.MM.'
                  )} - ${dayjs(booking.start)
                    .add(booking.duration, 'minutes')
                    .subtract(1, 'second')
                    .format('DD.MM.YYYY')}`}
                />
              ))}
          </View>

          <View style={expanded.past ? styles.wrapperOpen : {}}>
            <Subheading
              style={
                expanded.past ? styles.subheadingOpen : styles.subheadingClosed
              }
              onPress={() => setExpanded({ ...expanded, past: !expanded.past })}
            >
              Vergangen ({pastBookings.length})
              <View
                style={expanded.past ? styles.chevronUp : styles.chevronDown}
              >
                <ReparkIcon name="chevron-left" />
              </View>
            </Subheading>
            {expanded.past &&
              pastBookings.map((booking: any) => (
                <ReparkListItem
                  key={booking.id}
                  title={booking.licencePlate}
                  description={`${dayjs(booking.start).format(
                    'DD.MM.'
                  )} - ${dayjs(booking.start)
                    .add(booking.duration, 'minutes')
                    .subtract(1, 'second')
                    .format('DD.MM.YYYY')}`}
                />
              ))}
          </View>
        </View>
      )}
    </View>
  );
};

const rotateLeft: RotateTransform = {
  rotate: '-90deg',
};

const rotateRight: RotateTransform = {
  rotate: '90deg',
};

const styles = StyleSheet.create({
  wrapperOpen: {
    borderBottomColor: rptheme.colors.primary,
    borderBottomWidth: 2,
  },
  chevronDown: {
    marginHorizontal: 12,
    transform: [rotateLeft],
  },
  chevronUp: {
    marginHorizontal: 12,
    transform: [rotateRight],
  },
  chevronAllDown: {
    transform: [rotateLeft],
    width: 18,
    height: 18,
    paddingLeft: 4,
  },
  chevronAllUp: {
    transform: [rotateRight],
    width: 18,
    height: 18,
    paddingRight: 4,
  },
  subheadingOpen: {
    borderBottomColor: rptheme.colors.primary,
    borderBottomWidth: 1,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingBottom: 10,
    marginBottom: 10,
  },
  subheadingClosed: {
    borderBottomColor: rptheme.colors.gray,
    borderBottomWidth: 1,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingBottom: 10,
    marginBottom: 10,
  },
  refresh: {
    width: 18,
    height: 18,
    marginTop: 2,
    marginRight: 12,
    alignSelf: 'center',
  },
  controls: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
});
