import { PaymentIntent } from '@stripe/stripe-js';
import dayjs, { Dayjs } from 'dayjs';
import { t } from 'i18next';
import React from 'react';
import { Card, Subheading, Chip } from 'react-native-paper';
import rptheme from '~/rptheme';
import { ListItem } from '~/src/components/ListItem';
import { getOpeningHours } from '~/src/features/garage/garageOpeningHours';
import { GarageType } from '~/src/features/garage/types';
import {
  endToStartDateWithDuration,
  formatCurrency,
} from '~/src/utils/helpers';
import { cardStyles } from './styles';

type BookingDetailsCardProps = {
  garage: GarageType;
  offerStart: Dayjs;
  offerDuration: number;
  paymentIntent: PaymentIntent & { metadata: Record<string, any> };
};

export const BookingDetailsCard = (props: BookingDetailsCardProps) => {
  const { garage, offerStart, offerDuration, paymentIntent } = props;

  const openingHoursOnOfferDay = getOpeningHours({
    opening_hours: garage.opening_hours,
    selectedDate: offerStart,
  });

  return (
    <Card style={cardStyles.card}>
      <Subheading style={cardStyles.heading}>
        {t('Checkout.bookingDetails')}
      </Subheading>
      {garage.name && (
        <ListItem label={t('Checkout.garageNameLabel')} value={garage.name} />
      )}
      <ListItem
        label={t('Checkout.addressLabel')}
        value={garage.addressString}
      />
      <ListItem
        label={t('Checkout.dateLabel')}
        value={
          offerDuration === 1440
            ? openingHoursOnOfferDay
              ? t('Checkout.dateValueWithOpeningHours', {
                  date: dayjs(offerStart).format('DD.MM.YYYY'),
                  start: openingHoursOnOfferDay.start,
                  stop: openingHoursOnOfferDay.stop,
                })
              : t('Checkout.dateValue', {
                  date: dayjs(offerStart).format('DD.MM.YYYY'),
                })
            : `${dayjs(offerStart).format(
                'DD.MM.YYYY'
              )} - ${endToStartDateWithDuration(
                offerStart,
                offerDuration
              ).format('DD.MM.YYYY')}`
        }
      />

      {openingHoursOnOfferDay && (
        <Chip
          icon={'clock'}
          style={{
            backgroundColor: rptheme.colors.secondary,
            marginVertical: 15,
          }}
        >
          {t('Checkout.openingHoursWarning')}
        </Chip>
      )}

      <ListItem
        label={t('Checkout.priceLabel')}
        value={formatCurrency(paymentIntent.amount)}
      />
    </Card>
  );
};
