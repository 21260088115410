import React from 'react';
import { StyleSheet, TouchableOpacity, View } from 'react-native';
import { Divider, Subheading, Title } from 'react-native-paper';
import { t } from 'i18next';

import { AuthStore } from '../authentication/AuthStore';

import UserAvatar from '~/src/features/user-profile/Avatar';

import { NavProp, RootScreens } from '../navigation';
import { ReparkList } from '~/src/components/ReparkList';
import { GarageStore } from '../garage/GarageStore';
import { ProfileStackScreenNames } from './ProfileStackNavigation';
import { ShareWithFriend } from './ShareWithFriend';
import { SubscriptionStore } from '../subscriptions/SubscriptionStore';

type Props = {
  navigation: NavProp;
};

export default function Profile({ navigation }: Props) {
  const user = AuthStore((store) => store.user);
  const myGarages = GarageStore((store) => store.myGarages);

  const [subscriptions] = SubscriptionStore((store) => [store.subscriptions]);

  const Links = () => {
    const listItems = [
      {
        showCondition: true,
        testID: 'bookingsLink',
        onPress: () => {
          navigation.navigate(RootScreens.BookingStack);
        },
        title: t('ProfileScreen.myBookingsLink'),
        icon: 'clock',
      },
      {
        showCondition: subscriptions.length > 0,
        testID: 'subscriptionsLink',
        onPress: () => {
          navigation.navigate(RootScreens.SubscriptionStack);
        },
        title: t('SubscriptionScreen.overview.title'),
        icon: 'logo',
      },
      {
        showCondition: myGarages().length > 0,
        testID: 'garagesLink',
        onPress: () => {
          navigation.replace(RootScreens.GarageOpenerScreen);
        },
        title: t('ProfileScreen.myGaragesLink'),
        icon: 'garage',
      },
      {
        showCondition: true,
        testID: 'personalDataLink',
        onPress: () =>
          navigation.navigate(ProfileStackScreenNames.PersonalDataScreen),
        title: t('PersonalDataScreen.title'),
        icon: 'account',
      },
      {
        showCondition: true,
        testID: 'showVehiclesScreenLink',
        onPress: () =>
          navigation.navigate(ProfileStackScreenNames.ShowVehiclesScreen),
        title: t('ProfileScreen.Vehicles'),
        icon: 'car',
      },
      {
        showCondition: true,
        testID: 'showPaymentMethodsScreen',
        onPress: () =>
          navigation.navigate(ProfileStackScreenNames.ShowPaymentMethodsScreen),
        title: t('ProfileScreen.PaymentMethods'),
        icon: 'payment',
      },
      {
        showCondition: user.companies?.length > 0,
        testID: 'showCompaniesScreen',
        onPress: () =>
          navigation.navigate(ProfileStackScreenNames.CompanyStack),
        title: 'Companies',
        icon: 'logo',
      },
    ];

    return <ReparkList items={listItems} />;
  };

  return (
    <View>
      <TouchableOpacity
        testID={'avatarNameSection'}
        onPress={() =>
          navigation.navigate(ProfileStackScreenNames.PersonalDataScreen)
        }
      >
        <View>
          <View style={styles.avatarContainer}>
            <UserAvatar user={user} testID={'avatar'} />
          </View>
          {(user.firstName || user.lastName) && (
            <Title testID={'userName'} style={{ textAlign: 'center' }}>{`${
              user.firstName || ''
            } ${user.lastName || ''}`}</Title>
          )}
          <Subheading style={{ textAlign: 'center' }} testID={'userCredential'}>
            {user.phoneNumber || user.email}
          </Subheading>
        </View>
      </TouchableOpacity>
      <Divider style={styles.divider} />
      <Links />
      <ShareWithFriend />
    </View>
  );
}

const styles = StyleSheet.create({
  avatarContainer: {
    margin: 20,
  },
  divider: { marginTop: 20, marginBottom: 0 },
  subheading: {
    marginTop: 20,
    marginBottom: 10,
    color: 'grey',
  },
  text: {
    textAlign: 'center',
    marginVertical: 5,
    fontSize: 16,
  },
  cards: {
    marginHorizontal: 25,
  },
});
