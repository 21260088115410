import React from 'react';
import { StyleSheet, View } from 'react-native';
import { CenteredAppView } from '~/src/components/CenteredAppView';
import { NavProp, RootScreens } from '../../navigation';
import { SubscriptionsStackScreens } from '../SubscriptionStackNavigation';
import { InspectionWelcomeStep } from './steps/InspectionWelcomeStep';
import { AuthStore } from '../../authentication/AuthStore';
import { Auth } from '../../authentication/Auth';
import { InspectionDateStep } from './steps/InspectionDateStep';
import { createInspectionAccessRequest } from './inspectionService';
import dayjs, { Dayjs } from 'dayjs';
import { InspectionSuccessStep } from './steps/InspectionSuccessStep';
import { AccessType } from './types';
import { GarageType } from '../../garage/types';
import { feathersClient } from '~/src/services/feathers';
import { SnackbarStore } from '../../snackbar/SnackbarStore';
import LoadingState from '~/src/components/LoadingState';

type InspectionScreenProps = {
  navigation: NavProp;
  route: any;
};

export const InspectionScreen = (props: InspectionScreenProps) => {
  const { navigation, route } = props;
  const { garageId } = route.params;
  const [fireSnack] = SnackbarStore((store) => [store.fireSnack]);

  enum InspectionScreenSteps {
    welcome = 1,
    login = 2,
    chooseDate = 3,
    success = 4,
  }

  const [step, setStep] = React.useState<InspectionScreenSteps>(
    InspectionScreenSteps.welcome
  );

  const [loggedIn] = AuthStore((store) => [store.loggedIn]);

  const [selectedDate, setSelectedDate] = React.useState<Dayjs>(
    dayjs().startOf('day')
  );

  const [user] = AuthStore((store) => [store.user]);

  const [garage, setGarage] = React.useState<GarageType | null>(null);
  const [access, setAccess] = React.useState<AccessType | null>(null);
  const [isLoading, setIsLoading] = React.useState(false);

  const fetchGarage = async () => {
    try {
      return await feathersClient.service('garages').get(garageId);
    } catch (e) {
      navigation.navigate(RootScreens.MapScreen);
    }
  };

  React.useEffect(() => {
    fetchGarage().then((res) => {
      setGarage(res);
    });
  }, []);

  const handleResponseError = () => {
    const title = 'Termin konnte nicht angelegt werden.',
      message =
        'Besichtigungstermin konnte nicht angelegt werden. Eventuell besteht bereits ein Termin.';
    const type = 'error';

    fireSnack({
      title,
      message,
      type,
    });
  };

  const createAccess = async () => {
    try {
      setIsLoading(true);
      const response = await createInspectionAccessRequest({
        userId: user.id,
        garageId: garageId,
        date: selectedDate,
      });

      setAccess(response);
      setStep(InspectionScreenSteps.success);
    } catch (e) {
      console.log(e);
      handleResponseError();
    } finally {
      setTimeout(() => setIsLoading(false), 1000); // It's just too fast otherwise...
    }
  };

  const handleGoBack = () => {
    switch (step) {
      case InspectionScreenSteps.welcome:
        navigation.navigate(SubscriptionsStackScreens.ChooseParkingSpot, {
          garageId: route.params.garageId,
        });

        break;
      case InspectionScreenSteps.login:
        setStep(InspectionScreenSteps.welcome);
        break;
      case InspectionScreenSteps.chooseDate:
        setStep(InspectionScreenSteps.welcome);
        break;
      case InspectionScreenSteps.success:
        setStep(InspectionScreenSteps.chooseDate);
        break;
    }
  };

  const handleNext = () => {
    switch (step) {
      case InspectionScreenSteps.welcome:
        if (!loggedIn) setStep(InspectionScreenSteps.login);
        else setStep(InspectionScreenSteps.chooseDate);
        break;
      case InspectionScreenSteps.login:
        setStep(InspectionScreenSteps.chooseDate);
        break;
      case InspectionScreenSteps.chooseDate:
        setStep(InspectionScreenSteps.success);
        break;
      case InspectionScreenSteps.success:
        navigation.navigate(SubscriptionsStackScreens.ChooseParkingSpot, {
          garageId: route.params.garageId,
        });
        break;
    }
  };

  if (isLoading) return <LoadingState />;

  return (
    <CenteredAppView>
      <View style={styles.container}>
        {step === InspectionScreenSteps.welcome && (
          <InspectionWelcomeStep
            onBack={handleGoBack}
            onContinue={handleNext}
          />
        )}
        {step === InspectionScreenSteps.login && (
          <Auth
            navigation={navigation}
            redirect={false}
            onSuccess={handleNext}
          />
        )}

        {step === InspectionScreenSteps.chooseDate && garage && (
          <InspectionDateStep
            garage={garage}
            onBack={handleGoBack}
            selectedDate={selectedDate}
            setSelectedDate={setSelectedDate}
            onContinue={createAccess}
          />
        )}

        {step === InspectionScreenSteps.success && access && garage && (
          <InspectionSuccessStep
            onContinue={() =>
              navigation.navigate(SubscriptionsStackScreens.ChooseParkingSpot, {
                garageId: route.params.garageId,
              })
            }
            access={access}
            garage={garage}
          />
        )}
      </View>
    </CenteredAppView>
  );
};

const styles = StyleSheet.create({
  container: {
    paddingTop: 40,
    flex: 1,
    margin: 15,
  },
});
