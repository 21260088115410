import create from 'zustand';
import {
  createOfferViewRequest,
  patchOfferViewRequest,
} from './trackingService';

type TrackingStore = {
  currentOfferView: string | null;
  trackOfferView: (user: any, garage: any) => Promise<void>;
  trackOfferViewClose: () => Promise<void>;
  trackOfferViewGoToCheckout: () => Promise<void>;
  stopOfferViewTracking: () => void;
};

export const TrackingStore = create<TrackingStore>((set, get) => ({
  currentOfferView: null,
  trackOfferView: async (user: any, garage: any): Promise<void> => {
    if (!user || Object.keys(user).length === 0 || !garage) {
      console.warn('Could not initiate offer view');
      return;
    }
    const offerViewData = {
      garageId: garage.id,
      garageStatus: garage.status,
      userId: user.id,
      status: 'seen',
    };
    try {
      const result: any = await createOfferViewRequest(offerViewData);
      set({ currentOfferView: result.id });
    } catch (e) {
      console.warn('Could not initiate offer view.');
    }
    return;
  },
  trackOfferViewClose: async (): Promise<void> => {
    try {
      if (get().currentOfferView === null) {
        throw new Error('no current Offer View');
      }
      await patchOfferViewRequest(get().currentOfferView as string, {
        status: 'closed',
      });
    } catch (e) {
      console.warn(`Unable to update offer view ${get().currentOfferView}`);
    }
    return;
  },
  trackOfferViewGoToCheckout: async (): Promise<void> => {
    try {
      if (get().currentOfferView === null) {
        throw new Error('no current Offer View');
      }
      await patchOfferViewRequest(get().currentOfferView as string, {
        status: 'proceed-to-checkout',
      });
    } catch (e) {
      console.warn(`Unable to update offer view ${get().currentOfferView}`);
    }
    return;
  },
  stopOfferViewTracking: (): void => {
    set({ currentOfferView: null });
  },
}));
