import React from 'react';
import { useTranslation } from 'react-i18next';
import { StyleSheet, View } from 'react-native';
import { Avatar, Subheading, Title } from 'react-native-paper';
import { ReparkIcon } from '~/src/components/ReparkIcon';
import { OnboardingStepProps } from './OnboardingScreen';

export const OnboardingCompleteStep = (
  props: Omit<OnboardingStepProps, 'onNextButtonPress' | 'buttonType'>
) => {
  const {} = props;
  const { t } = useTranslation();

  return (
    <>
      <View style={styles.container}>
        <Avatar.Icon
          size={120}
          icon={(props) => <ReparkIcon name="thumbs-up" {...props} />}
        />
        <Title style={styles.title}>
          {t('OnboardingScreen.OnboardingComplete.title')}
        </Title>
        <Subheading style={styles.subtitle}>
          {t('OnboardingScreen.OnboardingComplete.subtitle')}
        </Subheading>
      </View>
    </>
  );
};

const styles = StyleSheet.create({
  container: { height: 300, justifyContent: 'center', alignItems: 'center' },
  title: { marginTop: 50, textAlign: 'center' },
  subtitle: { textAlign: 'center' },
});
