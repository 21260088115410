import create from 'zustand';
import * as Location from 'expo-location';
import { LocationObject, LocationPermissionResponse } from 'expo-location';
import { BottomSheetStore } from '../bottom-sheet/BottomSheetStore';
import RequestLocationPermissionSheet from './RequestLocationPermissionSheet';
import React from 'react';
import haversine from 'haversine-distance';
import { feathersClient } from '~/src/services/feathers';

type LocationSearchParams = {
  id?: string;
  distance?: number;
  refId?: string;
  coords?: string;
  name?: string;
} | null;

type LocationStore = {
  gettingCurrentLocation: boolean;
  hasBeenSearched: boolean;
  locationSearchParams: LocationSearchParams;
  locationPermission: LocationPermissionResponse | null;
  location: Location.LocationObject | null;
  locationSubscription: any;
  minimumDistanceToUpdate: number;
  isGettingLocation: boolean;
  setLocationSearchParams: (data: LocationSearchParams) => void;
  requestPermission: () => Promise<void>;
  setLocationPermissionStatus: (
    status: LocationPermissionResponse | null
  ) => void;
  startLocationUpdates: (interval?: number) => void;
  stopLocationUpdates: () => void;
  userDistanceTo: (obj: {
    coordinates: {
      latitude: number;
      longitude: number;
    };
  }) => Promise<number | false>;
  updateLocationsDataRequest: (
    locationDataId: string,
    userId: string
  ) => Promise<void>;
};

export const initialRegion: LocationObject = {
  coords: {
    latitude: 48.208492, // Koordinaten Stephansdom
    longitude: 16.373127, // Koordinaten Stephansdom
    altitude: 0,
    altitudeAccuracy: 0,
    accuracy: 0,
    heading: 0,
    speed: 0,
  },
  timestamp: 0,
};

export const LocationStore = create<LocationStore>((set, get) => ({
  locationPermission: null,
  hasBeenSearched: false,
  locationSearchParams: null,
  gettingCurrentLocation: false,
  location: null,
  updateIntervalId: undefined,
  locationSubscription: undefined,
  minimumDistanceToUpdate: 0,
  isGettingLocation: false,
  nearbyMode: false,
  setLocationSearchParams: (data: LocationSearchParams) => {
    set({ locationSearchParams: data });
    if (data === null) {
      console.log('Resetting locationSearchParams...');
      set({ hasBeenSearched: false });
    } else {
      console.log('Setting locationSearchParams...');
      set({ hasBeenSearched: true });
    }
  },
  requestPermission: async () => {
    BottomSheetStore.getState().openSheet(
      <RequestLocationPermissionSheet
        setStatus={get().setLocationPermissionStatus}
        getStatus={() => get().locationPermission}
        onPermissionGranted={async () => {
          get().startLocationUpdates();
        }}
      />,
      { adjustToContentHeight: true }
    );
    return;
  },

  startLocationUpdates: async () => {
    set({ isGettingLocation: true });

    if (!get().location) {
      console.log('Getting last known location');
      let knownPosition = await Location.getLastKnownPositionAsync();
      if (!knownPosition)
        knownPosition = await Location.getCurrentPositionAsync();
      set({ location: knownPosition });
    }

    console.log('Starting location updates');

    const locationSubscription = await Location.watchPositionAsync(
      {
        accuracy: Location.Accuracy.High,
        distanceInterval: get().minimumDistanceToUpdate, // in meters
      },
      (location) => {
        set({ location });
      }
    );
    set({ locationSubscription, isGettingLocation: false });
  },

  stopLocationUpdates: async () => {
    console.log('Stopping location updates');
    get().locationSubscription?.remove();
    set({ locationSubscription: undefined });
  },

  setLocationPermissionStatus: (status) => {
    set({ locationPermission: status });
  },

  userDistanceTo: async (obj) => {
    const currentLocation = get().location;
    console.log('currentLocation', currentLocation);
    if (!currentLocation || !obj?.coordinates) return false;
    return haversine(currentLocation.coords, obj.coordinates);
  },
  updateLocationsDataRequest: async (
    locationDataId: string,
    userId: string
  ) => {
    await feathersClient.service('locations').patch(locationDataId, { userId });
  },
}));
