/**
 * An Enum that represents Repark Uuid types bases on their prefix.
 **/
export enum IdType {
  Booking = 0,
  SubscriptionInvoice,
}

/**
 * This type is a lookup for id to IdType mapping. By having it typed, it
 * will help to implement new values of the IdType enum by typechecking them.
 * **/
export type IdPrefixes = {
  BOK: IdType;
  SUI: IdType;
};
