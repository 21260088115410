import React from 'react';
import { useTranslation } from 'react-i18next';
import { View, StyleSheet, Text } from 'react-native';
import { Headline } from 'react-native-paper';
import rptheme from '~/rptheme';
import ReparkIconButton from '~/src/components/ReparkIconButton';
import { CalendarWrapper } from '~/src/components/calendar/CalendarContainer';
import { CalendarOnly } from '~/src/components/calendar/CalendarOnly';
import {
  DAY,
  dateStringToLocalDayjs,
  durationToDays,
  formatCurrency,
  formatDurationInDates,
  getDifferentTimezonesBookingStartAndStop,
} from '~/src/utils/helpers';
import { GarageStore } from '../garage/GarageStore';
import ReparkButton from '~/src/components/ReparkButton';
import { ReparkIcon } from '~/src/components/ReparkIcon';
import { filterGarageOffersForExtendedBooking } from './helpers';
import { NavProp, RootScreens } from '../navigation';
import { PaymentStore } from '../payment/PaymentStore';
import { SnackbarStore } from '../snackbar/SnackbarStore';
import { BottomSheetStore } from '../bottom-sheet/BottomSheetStore';
import { GarageTitle } from '../garage/GarageTitle';
import { PricePer } from '../offer/PricePer';
import { Dayjs } from 'dayjs';
import { BookingStore } from './BookingStore';
import Bugsnag from '@bugsnag/js';

type BookingExtendSheetProps = {
  currentBooking: any;
  navigation: NavProp;
};

const getDayAfterStop = (currentStart: Dayjs, currentStop: Dayjs) => {
  const diff = getDifferentTimezonesBookingStartAndStop(
    currentStart,
    currentStop
  );
  if (diff !== 0) {
    return currentStop.add(1, 'day').add(diff, 'minutes').startOf('day');
  } else {
    return currentStop.add(1, 'day').startOf('day');
  }
};

export const BookingExtendSheet = (props: BookingExtendSheetProps) => {
  const { currentBooking, navigation } = props;
  const { stop } = currentBooking;
  //stop is last day of current booking
  //start is first day of extension equals stop + 1 day
  const start = getDayAfterStop(
    dateStringToLocalDayjs(currentBooking.start),
    dateStringToLocalDayjs(stop)
  );
  const [openCalendar, setOpenCalender] = React.useState(false);
  const [availableUntil, setAvailableUntil] = React.useState<any>();
  const [maxAvailableDays, setMaxAvailableDays] = React.useState<any>();
  const [indicateMaxDays, setIndicateMaxDays] = React.useState<any>(false);
  const [loadingCheckout, setLoadingCheckout] = React.useState<any>(false);
  const [fixedBookingData, setFixedBookingData] = React.useState<any>({
    offerId: undefined,
    parkingSpotId: undefined,
  });

  const [
    maxBookingDaysInFuture,
    calculateRate,
    setOfferSearchParams,
    getGarage,
    selectGarage,
    selectedGarageId,
  ] = GarageStore((store) => [
    store.maxBookingDaysInFuture,
    store.calculateRate,
    store.setOfferSearchParams,
    store.getGarage,
    store.selectGarage,
    store.selectedGarageId,
  ]);

  const [getExtendOffer] = BookingStore((store) => [store.getExtendOffer]);

  const [fetchPaymentIntent] = PaymentStore((store) => [
    store.fetchPaymentIntent,
  ]);
  const fireSnack = SnackbarStore((store) => store.fireSnack);
  const closeSheet = BottomSheetStore((store) => store.closeSheet);
  const [extensionDuration, setExtensionDuration] = React.useState(1440);
  const [t, i18n] = useTranslation();

  const prepareOffers = async () => {
    // const response = await fetchGaragesRequest({
    //   start: dayjs(currentBooking.stop).add(1, 'day').startOf('day'),
    //   duration: extensionDuration,
    // });
    // const fetchedGarage = response.find(
    //   (grg: any) => grg.id === currentBooking.garageId
    // );
    // selectGarage(fetchedGarage.id);
    console.log('PARAMS', currentBooking, start, extensionDuration);
    const { offers, fetchedGarage, assignment } = await getExtendOffer(
      currentBooking,
      start,
      extensionDuration
    );
    console.log('SELECT GARAGE', fetchedGarage);
    selectGarage(fetchedGarage.id);

    // let parkingSpots: any = fetchedGarage.parkingSpots;

    // if (!('companyId' in currentBooking.parkingSpot)) {
    //   const parkingSpot = fetchedGarage.parkingSpots.find(
    //     (parkingSpot: Record<string, any>) => {
    //       return parkingSpot.id === currentBooking.parkingSpot.id;
    //     }
    //   );
    //   console.log('Matched parkingSpot:', parkingSpot);
    //   if (parkingSpot) {
    //     currentBooking.parkingSpot['companyId'] = parkingSpot.companyId;
    //   }
    // }
    // if (
    //   currentBooking.parkingSpot.companyId !== null &&
    //   currentBooking.parkingSpot.companyId !== undefined
    // ) {
    //   parkingSpots = parkingSpots.filter(
    //     (ps: any) => ps.companyId === currentBooking.companyId
    //   );
    // }
    // const parkingSpotIds: string[] = parkingSpots.map(
    //   (parkingSpot: any) => parkingSpot.id
    // );
    // const offers = await getOffersByParkingSpotIds(parkingSpotIds);
    //const assignment = fetchedGarage.parkingSpotAssignment;
    const until = filterGarageOffersForExtendedBooking({
      start,
      offers,
      assignment,
      currentSpotId: currentBooking.parkingSpotId,
      maxBookingDaysInFuture,
    });

    console.log('Found the following offers to extend:', offers);

    if (until) {
      const availUntil = until.availableUntil.subtract(1, 'day');
      setAvailableUntil(availUntil);
      const inDays = availUntil.diff(start, 'day');
      setMaxAvailableDays(inDays + 1);
      if (assignment !== 'fixed') {
        setFixedBookingData({
          offerId: until.offerId,
          parkingSpotId: currentBooking.parkingSpotId,
        });
      } else {
        setFixedBookingData({
          offerId: undefined,
          parkingSpotId: undefined,
        });
      }
    }
  };

  React.useEffect(() => {
    prepareOffers();
    setOfferSearchParams(start);
  }, [currentBooking]);

  React.useEffect(() => {
    setOfferSearchParams(start, extensionDuration);
  }, [extensionDuration]);

  const goToCheckout = async () => {
    try {
      setLoadingCheckout(true);
      //we will fetch here offers the usual way, to make sure
      //the offer we choose is the first possible one, this helps
      //with occupancy
      setOfferSearchParams(start, extensionDuration);
      if (!selectedGarageId) {
        throw new Error('No garage selected');
      }

      if (!fixedBookingData.offerId) {
        throw new Error('No offer found');
      }
      console.log(
        `Fetching payement intent for offer: ${
          fixedBookingData.offerId
        }; start: ${start.toISOString()}; duration: ${extensionDuration} `
      );
      await fetchPaymentIntent({
        offerId: fixedBookingData.offerId,
        start,
        duration: extensionDuration,
        licencePlate: currentBooking.licencePlate,
        bookingType: 'extended',
        extending: currentBooking.id,
      });
      closeSheet();
      navigation.navigate(RootScreens.CheckOutScreen);
    } catch (e: any) {
      console.error(e);
      Bugsnag.notify(e);
      fireSnack({
        title: t('General.error'),
        message: t('Offer.notAvailable'),
        type: 'error',
      });
    }
  };

  const getPlural = () => {
    const isPlural = maxAvailableDays > 1;
    return i18n.language.includes('de')
      ? isPlural
        ? 'Tage'
        : 'Tag'
      : isPlural
      ? 'days'
      : 'day';
  };
  const getLocation = () => {
    const isParkingSpot =
      getGarage(currentBooking.garageId).parkingSpotAssignment === 'fixed';
    return i18n.language.includes('de')
      ? isParkingSpot
        ? 'der Parkplatz'
        : 'die Garage'
      : isParkingSpot
      ? 'the parking spot'
      : 'the garage';
  };

  const removeDay = () => {
    if (extensionDuration > DAY) {
      setExtensionDuration(extensionDuration - DAY);
    }
    setIndicateMaxDays(false);
  };

  const addDay = () => {
    if (extensionDuration / DAY + 1 >= maxAvailableDays) {
      setIndicateMaxDays(true);
      return;
    }
    if (extensionDuration / DAY < maxAvailableDays) {
      setExtensionDuration(extensionDuration + DAY);
    }
  };

  if (!selectedGarageId) return <></>;

  return (
    <View testID="bookingExtensionSheetContentTestID">
      <View style={styles.container}>
        <View style={styles.heading}>
          <GarageTitle
            garage={getGarage(selectedGarageId)}
            align={'flex-start'}
            withSubway={true}
          />

          <PricePer price={getGarage(selectedGarageId).rate} interval="day" />
        </View>
      </View>
      <Headline style={styles.title}>{t('BookingExtend.howManyDays')}</Headline>
      <Text style={styles.duration}>
        {formatDurationInDates(start, extensionDuration)}{' '}
        {maxAvailableDays <= 7 && `(max. ${maxAvailableDays} ${getPlural()})`}
      </Text>
      {indicateMaxDays && (
        <Text style={styles.notice}>
          {t('BookingExtend.noDaysLeft', {
            maxAvailableDays,
            plural: getPlural(),
            location: getLocation(),
          })}
        </Text>
      )}
      <View style={styles.buttonGroup}>
        <ReparkIconButton
          testID="BookingExtendRemoveDayTestId"
          icon={'minus'}
          filled
          color={rptheme.colors.primary}
          onPress={removeDay}
        />
        <Text testID="BookingExtendDayIndicatorTestID" style={styles.days}>
          {durationToDays(extensionDuration, t)}
        </Text>
        <ReparkIconButton
          testID="BookingExtendAddDayTestId"
          icon={'add'}
          filled
          color={rptheme.colors.primary}
          onPress={addDay}
        />
      </View>
      {openCalendar && (
        <CalendarWrapper>
          <CalendarOnly
            onDateSelected={(_date: any, duration = 1440) => {
              setExtensionDuration(duration);
            }}
            maxBookingDaysInFuture={maxBookingDaysInFuture}
            selectedDate={start}
            selectedDuration={extensionDuration}
            mode="range"
            options={{
              lockStartDate: true,
              previousBooking: {
                start: dateStringToLocalDayjs(currentBooking.start),
                duration: currentBooking.duration,
              },
              availableUntil,
            }}
          />
        </CalendarWrapper>
      )}
      <View style={styles.buttonContainer}>
        <ReparkButton
          color={rptheme.colors.accent}
          style={[
            { ...rptheme.shadow, backgroundColor: 'white' },
            styles.denseButtonStyle,
          ]}
          onPress={() => setOpenCalender(!openCalendar)}
        >
          <ReparkIcon name="calendar" color={rptheme.colors.accent} size={35} />
        </ReparkButton>
        <ReparkButton
          style={styles.checkoutButton}
          loading={loadingCheckout}
          disabled={loadingCheckout}
          onPress={goToCheckout}
        >
          <>
            <Text>
              {formatCurrency(
                calculateRate(getGarage(currentBooking.garageId))
              )}
            </Text>
            <Text>{t('Offer.book')}</Text>
          </>
        </ReparkButton>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  top: {},
  title: {
    marginBottom: 15,
    alignSelf: 'center',
    textAlign: 'center',
    fontSize: 20,
    color: rptheme.colors.primary,
    fontWeight: '300',
  },
  duration: {
    fontSize: 22,
    alignSelf: 'center',
    marginBottom: 20,
  },
  notice: {
    fontSize: 18,
    alignSelf: 'center',
    marginBottom: 20,
    textAlign: 'center',
    paddingHorizontal: 15,
    color: rptheme.colors.info,
  },
  buttonGroup: {
    width: '60%',
    flexDirection: 'row',
    alignSelf: 'center',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 20,
  },
  button: {
    alignSelf: 'center',
  },
  days: {
    fontSize: 30,
    alignSelf: 'center',
  },
  buttonContainer: { display: 'flex', flexDirection: 'row', marginBottom: 10 },
  checkoutButton: {
    flexGrow: 3,
    margin: 5,
  },
  denseButtonStyle: {
    flexGrow: 0,
    justifyContent: 'center',
    margin: 5,
    borderColor: rptheme.colors.accent,
    borderWidth: 2,
  },
  container: {
    ...rptheme.shadow,
    marginBottom: 15,
    display: 'flex',
    flexDirection: 'column',
    paddingLeft: 15,
    paddingRight: 15,
    paddingTop: 10,
  },
  heading: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
});
