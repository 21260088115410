import React from 'react';
import { SubscriptionStore } from './SubscriptionStore';
import { NavProp } from '../navigation';
import LoadingState from '~/src/components/LoadingState';
import { SubscriptionInvoice } from './types';
import { SubscriptionInvoiceItem } from './components/SubscriptionInvoiceItem';
import { CenteredAppView } from '~/src/components/CenteredAppView';
import { View, Text, StyleSheet } from 'react-native';
import { sortByDate } from '~/src/utils/helpers';
import { Subheading } from 'react-native-paper';
import rptheme from '~/rptheme';
import { useTranslation } from 'react-i18next';

type SubscriptionInvoicesProps = {
  navigation: NavProp;
  route: any;
};

export function SubscriptionInvoices({
  route,
  navigation,
}: SubscriptionInvoicesProps) {
  const [
    isFetchingSubscriptionInvoices,
    fetchSubscriptionInvoices,
    subscriptionInvoices,
  ] = SubscriptionStore((store) => [
    store.isFetchingSubscriptionInvoices,
    store.fetchSubscriptionInvoices,
    store.subscriptionInvoices,
  ]);

  const [t] = useTranslation();

  const [openInvoices, setOpenInvoices] = React.useState<
    Array<SubscriptionInvoice> | any
  >([]);
  const [paidInvoices, setPaidInvoices] = React.useState<
    Array<SubscriptionInvoice> | any
  >([]);

  React.useEffect(() => {
    const doAsync = async (id: string) => {
      await fetchSubscriptionInvoices(id);
    };
    if (route.params.subscriptionId) {
      doAsync(route.params.subscriptionId).then(() => {});
    }
  }, [route]);

  React.useEffect(() => {
    let paid = [];
    let open = [];
    for (let i = 0; i < subscriptionInvoices.length; i++) {
      const inv = subscriptionInvoices[i];
      if (inv.status === 'paid') {
        paid.push(inv);
      } else {
        open.push(inv);
      }
    }

    paid = paid.sort((a, b) => sortByDate(b.createdAt, a.createdAt));
    open = open.sort((a, b) => sortByDate(b.createdAt, a.createdAt));
    setOpenInvoices(open);
    setPaidInvoices(paid);
  }, [subscriptionInvoices]);

  return (
    <>
      {isFetchingSubscriptionInvoices ? (
        <LoadingState />
      ) : (
        <CenteredAppView>
          <View style={styles.container}>
            <Text
              style={{
                alignSelf: 'center',
                color: 'gray',
                marginBottom: '1em',
              }}
            >
              {t('SubscriptionInvoices.explainDowload')}
            </Text>
            <Text
              style={{
                alignSelf: 'center',
                color: 'gray',
              }}
            >
              {t('SubscriptionInvoices.askAboutWorking')}{' '}
              {t('SubscriptionInvoices.tellAboutBlock')}
            </Text>
            {openInvoices.length > 0 && (
              <View>
                <Subheading style={styles.heading}>
                  {t('SubscriptionInvoices.open')}
                </Subheading>
                {openInvoices.map((sui: SubscriptionInvoice) => (
                  <SubscriptionInvoiceItem
                    invoice={sui}
                    navigation={navigation}
                  />
                ))}
              </View>
            )}
            <View>
              <Subheading style={styles.heading}>
                {t('SubscriptionInvoices.paid')}
              </Subheading>
              {paidInvoices.length > 0 ? (
                paidInvoices.map((sui: SubscriptionInvoice) => (
                  <SubscriptionInvoiceItem
                    invoice={sui}
                    navigation={navigation}
                  />
                ))
              ) : (
                <Text>{t('SubscriptionInvoices.noPaidRecords')}</Text>
              )}
            </View>
          </View>
        </CenteredAppView>
      )}
    </>
  );
}
const styles = StyleSheet.create({
  container: {
    marginHorizontal: 20,
    marginTop: 60,
  },
  heading: {
    color: rptheme.colors.primary,
    marginTop: 30,
    marginBottom: 10,
  },
});
