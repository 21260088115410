import React, { useEffect, useState } from 'react';
import { Paragraph, Snackbar, Title, overlay } from 'react-native-paper';
import rptheme from '~/rptheme';
import { StyleSheet, View } from 'react-native';
import { SnackbarStore } from './SnackbarStore';
import { ReparkIcon } from '~/src/components/ReparkIcon';
import ReparkButton from '~/src/components/ReparkButton';
import ReparkIconButton from '~/src/components/ReparkIconButton';

const SnackBar = () => {
  const { isShown, title, message, type, dismissSnack, icon } = SnackbarStore();
  const [snackStyle, setSnackStyle] = useState({
    backgroundColor: rptheme.colors.primary,
  });

  useEffect(() => {
    switch (type) {
      case 'info':
        setSnackStyle({
          backgroundColor: rptheme.colors.info,
        });
        break;
      case 'error':
        setSnackStyle({
          backgroundColor: rptheme.colors.error,
        });
        break;
      case 'success':
        setSnackStyle({
          backgroundColor: rptheme.colors.success,
        });
        break;
      default:
        setSnackStyle({
          backgroundColor: rptheme.colors.primary,
        });
    }
  }, [isShown, type]);

  return (
    <>
      {isShown && (
        <Snackbar
          testID="snackbar"
          style={{ ...snackStyle }}
          visible
          onDismiss={dismissSnack}
          theme={{ ...rptheme, colors: { accent: 'white' } }} // Nessecary workaround, to make the button white...
        >
          <View style={styles.container}>
            {icon && <ReparkIcon size={24} style={styles.icon} name={icon} />}
            <View style={styles.midSection}>
              <Title
                style={{
                  color: 'white',
                  fontSize: 16,
                  textAlign: message ? 'left' : 'center',
                }}
              >
                {title}
              </Title>
              {message && (
                <Paragraph style={styles.paragraph}>{message}</Paragraph>
              )}
            </View>
            <ReparkIconButton icon="close" size={14} onPress={dismissSnack} />
          </View>
        </Snackbar>
      )}
    </>
  );
};

export default SnackBar;

const styles = StyleSheet.create({
  container: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  midSection: {
    flexDirection: 'column',
    flex: 1,
  },
  button: {
    paddingHorizontal: 4,
  },
  paragraph: {
    color: 'white',
  },
  icon: {
    paddingRight: 12,
    display: 'flex',
    alignItems: 'center',
  },
});
