// ReparkIcons are created as explanied here: https://www.reactnative.guide/12-svg-icons-using-react-native-vector-icons/12.1-creating-custom-iconset.html
// and implemented as explained here: https://docs.expo.dev/guides/icons/

import { createIconSetFromIcoMoon } from '@expo/vector-icons';

export const ReparkIcon = createIconSetFromIcoMoon(
  require('~/assets/fonts/selection.json'),
  'reparkicons',
  'reparkicons.ttf'
);
