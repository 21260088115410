import React from 'react';
import { StyleSheet, View } from 'react-native';
import ConfirmCancelSheet from '~/src/components/ConfirmCancelSheet';
import LoadingState from '~/src/components/LoadingState';
import analytics from '../../analytics/analytics';
import { SnackbarStore } from '../../snackbar/SnackbarStore';
import { cancelBookingRequest, fetchStripeFee } from '../bookingService';
import { formatCurrency } from '~/src/utils/helpers';
import rptheme from '~/rptheme';
import { Paragraph } from 'react-native-paper';
import Bugsnag from '@bugsnag/js';

export default function CancelBookingSheet(props: any) {
  const { id, onCancelSuccess, t } = props;
  const fireSnack = SnackbarStore((store) => store.fireSnack);
  const [stripeFee, setStripeFee] = React.useState<number | null>(null);
  const [canceling, setCanceling] = React.useState(false);

  React.useEffect(() => {
    fetchStripeFee(id).then((fee: number) => {
      setStripeFee(fee);
    });
  }, []);

  const cancelBooking = async () => {
    console.log('Cancelling...', id);

    try {
      const cancelBookingResponse = await cancelBookingRequest(id);

      analytics.track('refund', {
        transaction_id: cancelBookingResponse.id,
        value: cancelBookingResponse.rate / 100,
        currency: 'EUR',
      });

      fireSnack({
        title: t('General.cancelBooking.successTitle'),
        message: t('General.cancelBooking.successMessage'),
        type: 'success',
      });
      onCancelSuccess();
    } catch (e: any) {
      Bugsnag.notify(e);
      console.log(e);
      fireSnack({
        title: t('General.cancelBooking.errorTitle'),
        message: t('General.cancelBooking.errorMessage'),
        type: 'error',
      });
    }
  };

  if (!stripeFee) {
    return (
      <View style={styles.loadingContainer}>
        <LoadingState noLogo={true} />
      </View>
    );
  }
  return (
    <ConfirmCancelSheet
      countdown={5}
      title={t('General.cancelBooking.confirmCancelTitle')}
      message={t('General.cancelBooking.confirmCancelMessage', {
        stripeFee: formatCurrency(stripeFee),
      })}
      confirmLabel={
        canceling
          ? t('General.cancelBooking.canceling')
          : t('General.cancelBooking.confirmLabel')
      }
      onConfirm={async () => {
        setCanceling(true);
        await cancelBooking();
      }}
      confirmMode={'outlined'}
      confirmColor={rptheme.colors.error}
      cancelMode={'contained'}
      confirmLoading={canceling}
      t={t}
    >
      <View style={styles.listItemContainer}>
        <View>
          <Paragraph style={styles.listLabel}>
            {t('Checkout.cancelationPriceLabel')}
          </Paragraph>
        </View>
        <View>
          <Paragraph style={styles.listValue}>
            {formatCurrency(stripeFee)}
          </Paragraph>
        </View>
      </View>
    </ConfirmCancelSheet>
  );
}

const styles = StyleSheet.create({
  loadingContainer: {
    height: 200,
  },
  listItemContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    fontSize: 16,
    marginVertical: 10,
  },
  listLabel: {
    fontSize: 16,
  },
  listValue: {
    color: rptheme.colors.error,
    fontSize: 16,
  },
});
