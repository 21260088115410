import { z } from 'zod';

const LicensePlateRegex = new RegExp(/^[\s\w.ÄäÖöÜü-]+$/);

export function createEmailValidation(t: Function) {
  return z
    .union([
      z.string().email({ message: t('AuthTexts.emailInvalidMessage') }),
      z.literal(''),
    ])
    .transform((value) => (value ? value.trim().toLowerCase() : value));
}

export function createCodeValidation(t: Function) {
  return z
    .string({ required_error: t('AuthTexts.otcRequiredMessage') })
    .length(6, { message: t('AuthTexts.otcInvalidMessage') })
    .regex(new RegExp('^[0-9]*$', 'i'), {
      message: t('AuthTexts.otcInvalidMessage'),
    });
}

export function createFirstNameValidation(t: Function) {
  return z
    .string({ required_error: t('AuthTexts.firstNameRequiredMessage') })
    .regex(
      new RegExp(
        /^$|^[a-zA-ZäöüÄÖÜß]+(([',. -][a-zA-ZäöüÄÖÜß ])?[a-zA-ZäöüÄÖÜß]*)*$/
      ),
      {
        message: t('AuthTexts.firstNameContainsNumbers'),
      }
    );
}

export function createLastNameValidation(t: Function) {
  return (
    z
      .string({ required_error: t('AuthTexts.lastNameRequiredMessage') })
      // .min(2, { message: t('AuthTexts.lastNameTooShortMessage') })
      .regex(
        new RegExp(
          /^$|^[a-zA-ZäöüÄÖÜß]+(([',. -][a-zA-ZäöüÄÖÜß ])?[a-zA-ZäöüÄÖÜß]*)*$/
        ),
        {
          message: t('AuthTexts.lastNameContainsNumbers'),
        }
      )
  );
}

export function createNameValidation(t: Function) {
  return z
    .string({ required_error: t('AuthTexts.nameRequiredMessage') })
    .min(5, { message: t('AuthTexts.nameTooShortMessage') })
    .regex(
      new RegExp("^[-'a-zA-ZÀ-ÖØ-öø-ÿ]{2,} [-'a-zA-ZÀ-ÖØ-öø-ÿ]{2,}$", 'i'),
      {
        message: t('AuthTexts.nameRegexNotMatching'),
      }
    );
}

function createParkingSpotValidation(t: Function) {
  return z.string({
    required_error: t('AuthTexts.parkingSpotRequiredMessage'),
  });
}

function createTermsConsentValidation(t: Function) {
  return z.boolean().superRefine((val, ctx) => {
    if (!val) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: t('AuthTexts.termsConsentRequired'),
      });
    }
  });
}

export function createPhoneNumberValidation(t: Function) {
  return z.string().regex(new RegExp(/^\+(?:[0-9] ?){6,14}[0-9]$/), {
    message: t('AuthTexts.invalidPhoneNumber'),
  });
}

/// Zod Schemas below

export function createSubmitPhoneOTCZodSchema(t: Function) {
  return z.object({
    phoneNumber: createPhoneNumberValidation(t),
    otc: createCodeValidation(t),
  });
}

export function createSubmitEmailOTCZodSchema(t: Function) {
  return z.object({
    email: createEmailValidation(t),
    otc: createCodeValidation(t),
  });
}

export function createLoginWithPhoneNumberValidation(t: Function) {
  return z.object({
    phoneNumber: createPhoneNumberValidation(t),
  });
}

export function createLoginOTCZodSchema(t: Function) {
  return z.object({
    otc: createCodeValidation(t),
  });
}

export function createRegisterOTCZodSchema(t: Function) {
  return z.object({
    firstName: createFirstNameValidation(t).optional(),
    lastName: createLastNameValidation(t).optional(),
    parkingSpotId: createParkingSpotValidation(t).optional(),
    otc: createCodeValidation(t),
    consents: consentsZodSchema(t),
  });
}

export function createPersonalDataZodSchema(t: Function) {
  return z.object({
    firstName: createFirstNameValidation(t).optional(),
    lastName: createLastNameValidation(t).optional(),
    email: createEmailValidation(t).optional(),
    companyId: z.string().optional(),
    userCompanyName: z.string().optional(),
    vatId: z
      .string()
      .regex(new RegExp(/^(ATU.*)?$/, 'i'), {
        message: t('AuthTexts.invalidVATID'),
      })
      .optional(),
  });
}

export function createSubscriptionDataZodSchema(t: Function) {
  return z.object({
    firstName: createFirstNameValidation(t),
    lastName: createLastNameValidation(t),
    email: createEmailValidation(t),
    companyId: z.string().optional(),
    userCompanyName: z.string().optional(),
    vatId: z
      .string()
      .regex(new RegExp(/^(ATU.*)?$/, 'i'), {
        message: t('AuthTexts.invalidVATID'),
      })
      .optional(),
  });
}

export function createEmailZodSchema(t: Function) {
  return z.object({
    email: createEmailValidation(t),
  });
}

function consentsZodSchema(t: Function) {
  return z.object({
    termsConsent: createTermsConsentValidation(t),
    privacyConsent: z.boolean(),
    newsletterConsent: z.boolean(),
    updateConsent: z.boolean(),
  });
}

export function createClaimAccessZodSchema(t: Function) {
  return z.object({
    userId: z.string(),
    name: createNameValidation(t),
    parkingSpotId: createParkingSpotValidation(t),
  });
}

export function createVehicleZodSchema(t: Function) {
  return z.object({
    id: z.string().optional(),
    licencePlate: z
      .string({
        required_error: t('AuthTexts.licencePlateRequiredMessage'),
      })
      .min(3, { message: t('AuthTexts.licencePlateTooShort') })
      .regex(LicensePlateRegex, {
        message: t('AuthTexts.invalidLicencePlate'),
      })
      .transform((licencePlate) => {
        return licencePlate.toUpperCase();
      }),
    countryOfOrigin: z.string().optional(),
  });
}

export function createOtherFeedbackSchema(t: Function) {
  return z
    .string()
    .max(32, {
      message: t('AuthTexts.feedbackOtherTooLong'),
    })
    .nullish();
}

export function createOnboardingZodSchema(t: Function) {
  return {
    firstName: z.object({
      firstName: createFirstNameValidation(t),
    }),
    email: z.object({
      email: createEmailValidation(t).optional(),
    }),
    feedback: z.object({
      feedback: createOtherFeedbackSchema(t),
    }),
    licencePlate: z.object({
      licencePlate: z
        .string({
          required_error: t('AuthTexts.licencePlateRequiredMessage'),
        })
        .min(3, { message: t('AuthTexts.licencePlateTooShort') })
        .regex(LicensePlateRegex, {
          message: t('AuthTexts.invalidLicencePlate'),
        })
        .transform((licencePlate) => {
          return licencePlate.toUpperCase();
        })
        .optional(),
      countryOfOrigin: z.string().optional(),
    }),
  };
}

export function createVoucherZodSchema(t: Function) {
  return z.object({
    voucher: z
      .string({
        required_error: t('CheckoutScreen.voucherCodeRequiredMessage'),
      })
      .min(3, { message: t('CheckoutScreen.voucherCodeTooShort') }),
  });
}

export function createEmailRequiredValidation(t: Function) {
  return z
    .string()
    .email({ message: t('AuthTexts.emailInvalidMessage') })
    .transform((value) => (value ? value.trim().toLowerCase() : value));
}

export function createSubscriptionZodSchema(t: Function) {
  return z.object({
    licencePlate: z
      .string({
        required_error: t('AuthTexts.licencePlateRequiredMessage'),
      })
      .min(3, { message: t('AuthTexts.licencePlateTooShort') })
      .regex(LicensePlateRegex, {
        message: t('AuthTexts.invalidLicencePlate'),
      })
      .transform((licencePlate) => {
        return licencePlate.toUpperCase();
      }),
  });
}
