import { t } from 'i18next';
import React from 'react';
import { View, Text, StyleSheet } from 'react-native';
import ReparkButton from '~/src/components/ReparkButton';
import { cetNow, openLinkInBrowser } from '~/src/utils/helpers';
import { BottomSheetStore } from '../../bottom-sheet/BottomSheetStore';
import { GarageStore } from '../../garage/GarageStore';
import { RootScreens } from '../../navigation';
import { OfferBottomProps } from '../OfferBottom';
import { styles as offerBottomStyles } from '../OfferBottom';
import config from '~/config/config';
import { notAvailableType } from './OfferBottomNotAvailable';

export const OfferBottomLoggedOut = (props: OfferBottomProps) => {
  const { navigation } = props;
  const closeSheet = BottomSheetStore((store) => store.closeSheet);
  const [selectedGarageId, getGarage, setOfferSearchParams] = GarageStore(
    (store) => [
      store.selectedGarageId,
      store.getGarage,
      store.setOfferSearchParams,
    ]
  );

  if (!selectedGarageId) return null;

  React.useEffect(() => {
    setOfferSearchParams(cetNow().startOf('day'), 1440);
  }, []);

  const isComingSoon =
    getGarage(selectedGarageId).status === notAvailableType.comingSoon;

  const garageInfoText = isComingSoon
    ? t('offer.loggedOutComingSoonText')
    : t('offer.loggedOutText');

  return (
    <View testID="loggedOutView" style={offerBottomStyles.container}>
      <>
        <View style={styles.textContainer}>
          <Text style={{ textAlign: 'center' }}>{garageInfoText}</Text>
        </View>
      </>

      <ReparkButton
        style={{ marginTop: 10 }}
        testID="goToLoginButton"
        onPress={() => {
          closeSheet();
          navigation.navigate(RootScreens.ProfileStack);
        }}
      >
        {t('AuthTexts.signInButtonLabel')}
      </ReparkButton>

      <ReparkButton
        mode="text"
        onPress={() => openLinkInBrowser(config().webPages?.howItWorks)}
      >
        {t('offer.howItWorksLinkText')}
      </ReparkButton>
    </View>
  );
};

const styles = StyleSheet.create({
  textContainer: {
    alignItems: 'center',
    marginVertical: 20,
    marginBottom: 10,
  },
});
