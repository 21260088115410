import React from 'react';
import { Image, StyleSheet, View, Text } from 'react-native';
import rptheme from '~/rptheme';
import { useShare } from '../sharing/useShare';
import { TouchableRipple } from 'react-native-paper';
import { t } from 'i18next';
import config from '~/config/config';
import ReparkButton from '~/src/components/ReparkButton';

export const ShareWithFriend = () => {
  const shareImg = rptheme.graphics.digitalShare;
  const shareData = {
    title: t('Share.title'),
    url: config().webPages.shareWithFriend,
    text: t('Share.linkText'),
  };

  const { canShare, share } = useShare(shareData);

  if (!canShare) return <></>;

  return (
    <>
      <View style={styles.container}>
        <TouchableRipple
          style={styles.ripple}
          rippleColor="rgba(0,0,0,.12)"
          onPress={() => {
            share().then();
          }}
        >
          <Image source={shareImg} style={styles.image} />
        </TouchableRipple>
        <Text style={styles.text}>{t('Share.text')}</Text>
        <ReparkButton
          onPress={() => {
            share().then();
          }}
        >
          {t('Share.button')}
        </ReparkButton>
      </View>
    </>
  );
};

const styles = StyleSheet.create({
  container: {
    marginHorizontal: 30,
  },
  ripple: {
    borderRadius: 100,
  },
  text: {
    textAlign: 'center',
    marginVertical: 5,
    fontSize: 14,
  },
  image: {
    marginLeft: 15,
    resizeMode: 'contain',
    height: 200,
  },
});
