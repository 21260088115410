import * as React from 'react';
import { View, Text, StyleSheet } from 'react-native';
import { useForm, Controller } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { Trans, useTranslation } from 'react-i18next';
import ReparkTextInput from '~/src/components/ReparkTextInput';
import ReparkButton from '~/src/components/ReparkButton';
import { Link } from '@react-navigation/native';
import config from '~/config/config';
// import ReparkListItemSwitch from '~/src/components/ReparkListItemSwitch';
import rptheme from '~/rptheme';
import { Paragraph } from 'react-native-paper';
import {
  createLoginOTCZodSchema,
  createRegisterOTCZodSchema,
} from '~/src/rules/inputValidationRules';
import { SnackbarStore } from '../../snackbar/SnackbarStore';
import { AuthStore } from '../AuthStore';
import analytics from '../../analytics/analytics';

export type AuthOTCInputProps = {
  verificationData: {
    phoneNumber: string;
    verificationId: string;
    newUser: boolean;
  };
  onLoginSuccess: (data: any) => void;
};

export default function AuthOTCInput(props: AuthOTCInputProps) {
  const {
    verificationData: { newUser, verificationId },
    onLoginSuccess,
  } = props;
  const fireSnack = SnackbarStore((store) => store.fireSnack);
  const signIn = AuthStore((store) => store.signIn);

  const { t } = useTranslation();
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<{
    otc: string;
    consents: {
      termsConsent?: boolean;
      newsletterConsent?: boolean;
      updateConsent?: boolean;
      privacyConsent?: boolean;
    };
  }>({
    resolver: zodResolver(
      newUser ? createRegisterOTCZodSchema(t) : createLoginOTCZodSchema(t)
    ),
    defaultValues: {
      consents: {
        termsConsent: true,
        newsletterConsent: false,
        updateConsent: true,
        privacyConsent: true,
      },
    },
  });
  const [isLoading, setIsLoading] = React.useState(false);

  const handleResponseError = (response: any) => {
    const { error: errorCode } = response;

    let title = t('General.errors.GenericErrorTitle'),
      message = t('General.errors.GenericErrorMessage');
    const type = 'error';

    if (errorCode === 401) {
      title = t('AuthTexts.checkOTCIsWrongTitle');
      message = t('AuthTexts.checkOTCIsWrongMessage');
    }

    fireSnack({
      title,
      message,
      type,
    });
  };

  const submitOTC = handleSubmit(async (data) => {
    setIsLoading(true);

    let consents;

    if (data.consents) {
      consents = {
        terms: data.consents.termsConsent,
        privacy: data.consents.privacyConsent,
        update: data.consents.updateConsent,
        newsletter: data.consents.newsletterConsent,
      };
    }

    const loginResponse = await signIn({
      otc: data.otc,
      verificationId,
      consents,
    });

    if (loginResponse.success) {
      if (newUser) analytics.track('sign_up');
      onLoginSuccess(loginResponse);
    } else {
      handleResponseError(loginResponse);
      setIsLoading(false);
    }
  });

  return (
    <View style={styles.container}>
      <Controller
        name="otc"
        control={control}
        render={({ field: { onChange } }) => (
          <ReparkTextInput
            testID={'otcInput'}
            onChangeText={(text: string) => onChange(text)}
            maxLength={6}
            secureTextEntry={false}
            textContentType="oneTimeCode"
            autoComplete="otc"
            autoCorrect={false}
            spellCheck={false}
            keyboardType="numeric"
            autoFocus={true}
            label={t('AuthTexts.otcPlaceholder')}
            zoderror={errors.otc}
            icon={'shield'}
          ></ReparkTextInput>
        )}
      ></Controller>
      <View>
        <Text style={styles.wifi}>
          <Trans i18nKey={'AuthScreen.nootchelp'}>
            Keinen Code erhalten?{' '}
            <Link
              to={{
                screen: 'WebView',
                params: { url: config().webPages.wifi_calling },
              }}
              style={{
                textDecorationLine: 'underline',
                color: rptheme.colors.primary,
              }}
            >
              Hier findest du Hilfe.
            </Link>
          </Trans>
        </Text>
      </View>

      {newUser && (
        <Paragraph>
          <Trans>
            Ich erkläre mich mit der Verwendung der{' '}
            <Link
              to={{
                screen: 'WebView',
                params: { url: config().webPages.terms },
              }}
              style={{ textDecorationLine: 'underline' }}
            >
              Nutzungsbedingungen
            </Link>{' '}
            einverstanden.
          </Trans>
        </Paragraph>
        // <Controller
        //   control={control}
        //   name="consents.termsConsent"
        //   render={({ field: { onChange, value } }) => (
        //     <ReparkListItemSwitch
        //       value={value}
        //       testID={'termsConsentSwitch'}
        //       error={errors.consents?.termsConsent}
        //       onValueChange={(value: boolean) => onChange(value)}
        //     >
        //       <Text>
        //         <Trans>
        //           Ich erkläre mich mit der Verwendung der{' '}
        //           <Link
        //             to={{
        //               screen: 'WebView',
        //               params: { url: config().webPages.terms },
        //             }}
        //             style={{ textDecorationLine: 'underline' }}
        //           >
        //             Nutzungsbedingungen
        //           </Link>{' '}
        //           einverstanden.
        //         </Trans>
        //       </Text>
        //     </ReparkListItemSwitch>
        //   )}
        // />
      )}

      <View>
        <ReparkButton
          testID="confirmOtcButton"
          loading={isLoading}
          disabled={isLoading}
          onPress={submitOTC}
          color={rptheme.colors.primary}
        >
          {isLoading
            ? t('AuthTexts.checkOTCNextStepButtonLoadingLabel')
            : newUser
            ? t('AuthTexts.register')
            : t('AuthTexts.login')}
        </ReparkButton>

        {newUser && (
          <Paragraph testID="privacyText">
            <Trans>
              <Text>
                Nähere Informationen zur Verarbeitung Ihrer personenbezogenen
                Daten finden Sie in unserer{' '}
                <Link
                  to={{
                    screen: 'WebView',
                    params: { url: config().webPages.gdpr },
                  }}
                  style={{ textDecorationLine: 'underline' }}
                >
                  Datenschutzerklärung
                </Link>
                .
              </Text>
            </Trans>
          </Paragraph>
        )}
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    marginBottom: 50,
  },
  wifi: {
    marginBottom: 10,
    color: rptheme.colors.gray,
  },
});
