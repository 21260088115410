import * as React from 'react';
import { StyleSheet, Text, View } from 'react-native';
import dayjs from 'dayjs';

import { LocationStore } from '~/src/features/location/LocationStore';
import { Card } from 'react-native-paper';

export const DebugLocationScreen = () => {
  const location = LocationStore((store) => store.location);

  const [refreshingLocation, setRefreshingLocation] = React.useState(false);
  React.useEffect(() => {
    setRefreshingLocation(true);
    const timeoutId = setTimeout(() => {
      setRefreshingLocation(false);
    }, 2000);
    return () => {
      clearTimeout(timeoutId);
    };
  }, [location]);

  return (
    <View style={styles.container}>
      <Card>
        <Card.Content>
          {location ? (
            <>
              <Text>
                {`Last location update: ${dayjs(location.timestamp)
                  .tz('Europe/Vienna')
                  .format('HH:mm:ss')} ${
                  refreshingLocation ? '- refreshing' : ''
                }`}
              </Text>
              <Text>
                {`Lat: ${location.coords.latitude} - Long: ${
                  location.coords.longitude
                } - Acc: ${location.coords.accuracy?.toFixed(2)}`}
              </Text>
            </>
          ) : (
            <Text>No location permission</Text>
          )}
        </Card.Content>
      </Card>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    marginTop: 100,
    marginHorizontal: 25,
  },
});
