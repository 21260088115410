import React from 'react';
import { StyleSheet, View, Text } from 'react-native';
import rptheme from '~/rptheme';
import { ContinueNextButtons } from '../../components/ContinueNextButtons';
import { CalendarOnly } from '~/src/components/calendar/CalendarOnly';
import { Dayjs } from 'dayjs';
import { InfoBanner } from '../../../../components/InfoBanner';
import { GarageCard } from '../components/GarageCard';
import { GarageType } from '~/src/features/garage/types';
import { Title } from 'react-native-paper';

type InspectionWelcomeStepProps = {
  onContinue: () => void;
  onBack: () => void;
  selectedDate: Dayjs;
  setSelectedDate: (date: Dayjs) => void;
  garage: GarageType;
};

export const InspectionDateStep = (props: InspectionWelcomeStepProps) => {
  const { onContinue, onBack, selectedDate, setSelectedDate, garage } = props;

  const createButtonLabel = () => {
    return selectedDate
      ? `Termin für ${selectedDate.format('DD.MM')} buchen`
      : 'Wähle einen Tag';
  };

  return (
    <View>
      <Title>Besichtigungstag auswählen für:</Title>

      <GarageCard garage={garage} />

      <InfoBanner text="Du reservierst keinen Parkplatz, sondern wählst den Besichtigungstermin für die Garage aus" />
      <CalendarOnly
        selectedDate={selectedDate}
        onDateSelected={setSelectedDate}
        selectedDuration={1440}
        mode="day"
        maxBookingDaysInFuture={30}
      />

      <Text style={styles.legalText}>
        Wähle einen Tag für die Besichtigung aus. Du kannst die Garage an diesem
        Tag besichtigen und dir einen Eindruck verschaffen. Du kannst das
        Garagentor am Besichtigungstag mit deinem Handy öffnen. Bitte beachte,
        dass du keinen Parkplatz reservierst, sondern nur die Garage
        besichtigst.
      </Text>

      <ContinueNextButtons
        onBack={onBack}
        onContinue={onContinue}
        nextLabel={createButtonLabel()}
        nextDisabled={!selectedDate}
      />
    </View>
  );
};

const styles = StyleSheet.create({
  image: {
    height: 200,
    resizeMode: 'contain',
    marginVertical: 30,
  },
  legalText: {
    fontSize: 14,
    color: rptheme.colors.gray,
    marginVertical: 25,
  },
});
