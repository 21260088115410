import {
  Elements,
  PaymentElement,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js';
import { Appearance, loadStripe, StripeElementLocale } from '@stripe/stripe-js';
import i18next from 'i18next';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { View } from 'react-native';
import config from '~/config/config';
import LoadingState from '~/src/components/LoadingState';
import ReparkButton from '~/src/components/ReparkButton';
import { SubscriptionPaymentFormProps } from './SubscriptionPaymentForm';
import rptheme from '~/rptheme';

export const appearance: Appearance = {
  theme: 'stripe',
  labels: 'floating',
  variables: {
    borderRadius: `${rptheme.roundness}px`,
    colorDanger: rptheme.colors.error,
    colorPrimary: rptheme.colors.primary,
    colorSuccess: rptheme.colors.success,
    colorBackground: 'white',
    colorTextSecondary: rptheme.colors.gray,
  },
};

export const SubscriptionPaymentForm = (
  props: SubscriptionPaymentFormProps
) => {
  const [stripePromise] = React.useState(() =>
    loadStripe(config().stripe.publicKey)
  );

  const { paymentIntent, subscriptionStatus } = props;
  console.log(paymentIntent);
  if (!paymentIntent) return <LoadingState />;
  const locale: StripeElementLocale = i18next.language as StripeElementLocale;

  return (
    <View>
      <Elements
        key={paymentIntent.id} // We do this to remount, because we fetch a new paymentIntent if an error occurs. Should be refactored in the future...
        stripe={stripePromise}
        options={{
          clientSecret: paymentIntent?.client_secret,
          locale,
          appearance,
        }}
      >
        <CCInput {...props} />
      </Elements>
    </View>
  );
};

const CCInput = ({
  onSuccess,
  onError,
  onIsProcessingPayment,
  subscriptionStatus,
}: any) => {
  const [isLoading, setIsLoading] = React.useState(false);
  const { t } = useTranslation();
  const stripe = useStripe();
  const elements = useElements();

  const handleSubmit = async () => {
    setIsLoading(true);
    onIsProcessingPayment();
    try {
      if (!stripe || !elements) return;
      let response: any;
      if (subscriptionStatus === 'incomplete') {
        response = await stripe.confirmPayment({
          elements,
          redirect: 'if_required',
        });
      } else {
        response = await stripe.confirmSetup({
          elements,
          redirect: 'if_required', // It would be required, e.g. you're paying with SOFORT etc, where a user first has to leave our app and then come back
        });
      }

      await onSuccess(response);
      setIsLoading(false);
    } catch (e: any) {
      e.name = 'StripeError';
      console.log('PaymentForm Web payOfferWeb error', e);
      console.log(JSON.stringify(e, ['message', 'arguments', 'type', 'name']));
      setIsLoading(false);
      onError?.(e);
    }
  };

  return (
    <>
      <PaymentElement />
      <ReparkButton
        loading={isLoading}
        disabled={isLoading}
        onPress={() => handleSubmit()}
      >
        {t('PaymentForm.pay')}
      </ReparkButton>
    </>
  );
};
