import React from 'react';
import { TouchableOpacity, StyleSheet } from 'react-native';
import { Portal } from 'react-native-paper';
import rptheme from '~/rptheme';

type ModalProps = {
  isOpen: boolean;
  onClickOutside: () => void;
  children: Array<JSX.Element> | JSX.Element;
};

export const Modal = (props: ModalProps) => {
  const { children, isOpen, onClickOutside } = props;

  return (
    <>
      {isOpen && (
        <Portal>
          <TouchableOpacity
            style={styles.overlay}
            activeOpacity={1}
            onPress={onClickOutside}
          >
            {children}
          </TouchableOpacity>
        </Portal>
      )}
    </>
  );
};

const styles = StyleSheet.create({
  overlay: {
    position: 'absolute',
    backgroundColor: rptheme.colors.overlay,
    height: '100%',
    width: '100%',
    justifyContent: 'center',
    paddingBottom: rptheme.fab.verticalMargin + 50,
  },
});
