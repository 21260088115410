import * as React from 'react';

import { createStackNavigator } from '@react-navigation/stack';

import HeaderLeft from '~/src/components/HeaderLeft';
import { headerTitleAlign } from '../navigation/helpers';
// import { useTranslation } from 'react-i18next';
import { RootScreens } from '../navigation';
import CompanyOverviewScreen from './CompanyOverviewScreen';
import CompanyDetailScreen from './CompanyDetailScreen';

export const Stack = createStackNavigator();

export enum CompanyStackScreens {
  CompanyOverviewScreen = 'CompanyOverview',
  CompanyDetailScreen = 'CompanyDetail',
}

export default function CompanyStackNavigation({ navigation }: any) {
  const headerLeft = HeaderLeft.bind(navigation);
  // const { t } = useTranslation();

  return (
    <Stack.Navigator
      initialRouteName={CompanyStackScreens.CompanyOverviewScreen}
      screenOptions={{
        headerShown: true,
        headerTransparent: true,
        headerBackTitleVisible: false,
      }}
    >
      <Stack.Screen
        name={CompanyStackScreens.CompanyOverviewScreen}
        component={CompanyOverviewScreen}
        options={{
          title: 'Companies',
          headerTransparent: true,
          headerTitleAlign: headerTitleAlign(),
          headerLeft: (props: any) => {
            props.fallBackScreen = RootScreens.ProfileStack;
            return headerLeft(props);
          },
        }}
      />

      <Stack.Screen
        name={CompanyStackScreens.CompanyDetailScreen}
        component={CompanyDetailScreen}
        options={{
          title: '',
          headerTransparent: true,
          headerTitleAlign: headerTitleAlign(),
          headerLeft: (props: any) => {
            props.fallBackScreen = CompanyStackScreens.CompanyOverviewScreen;
            return headerLeft(props);
          },
        }}
      />
    </Stack.Navigator>
  );
}
