import { t } from 'i18next';
import React from 'react';
import { View, StyleSheet, Text } from 'react-native';
import { Title } from 'react-native-paper';
import rptheme from '~/rptheme';
import OTCInputForm from '~/src/components/OTCInputForm';
import ReparkButton from '~/src/components/ReparkButton';
import sleep from '~/src/utils/sleep';
import { AuthStore } from '../authentication/AuthStore';
import { BottomSheetStore } from '../bottom-sheet/BottomSheetStore';
import { NavProp } from '../navigation';
import { SnackbarStore } from '../snackbar/SnackbarStore';

type DeleteAccountSheetProps = {
  navigation: NavProp;
};

export const DeleteAccountSheet = (props: DeleteAccountSheetProps) => {
  const { navigation } = props;
  const openSheet = BottomSheetStore((store) => store.openSheet);
  const closeSheet = BottomSheetStore((store) => store.closeSheet);
  const fireSnack = SnackbarStore((store) => store.fireSnack);
  const signOut = AuthStore((store) => store.signOut);
  const sendDeleteAccountOtc = AuthStore((store) => store.sendDeleteAccountOtc);
  const deleteAccount = AuthStore((store) => store.deleteAccount);
  const user = AuthStore((store) => store.user);

  const deleteUserAccount = async (props: any) => {
    openSheet(<DeletingAccount />, { adjustToContentHeight: true });
    console.log('OTC is correct');
    console.log('Deleting account');
    try {
      // first sign out to remove auth state and local storage
      // (feathers client will fail to sign out if the user does not exist)
      await sleep(2000);
      await signOut();
      closeSheet();
      navigation.replace('Map');

      // now delete the account using the otc
      const response = await deleteAccount(
        user.id,
        props.otcData.otc,
        props.otcData.phoneNumber
      );
      if (response.data.phoneNumber === props.otcData.phoneNumber) {
        console.log('User account was deleted successfully');
        fireSnack({
          title: t('DeleteAccount.deletedSnackBarTitle'),
          message: t('DeleteAccount.deletedSnackBarText'),
          type: 'info',
        });
      }
    } catch (e) {
      console.log(e);
      fireSnack({
        title: t('DeleteAccount.errorSnackBarTitle'),
        message: t('DeleteAccount.errorSnackBarText'),
        type: 'error',
      });
    }
  };

  const CancelButton = () => (
    <ReparkButton
      onPress={async () => {
        closeSheet();
      }}
    >
      {t('General.cancel')}
    </ReparkButton>
  );

  const DeleteAccountConfirmation = () => {
    return (
      <View style={{ margin: 20 }}>
        <Title style={styles.sheetTitle}>
          {t('DeleteAccount.confirmationSheetTitle')}
        </Title>
        <Text style={styles.text}>
          {t('DeleteAccount.confirmationSheetText')}
        </Text>
        <CancelButton />
        <ReparkButton
          // disabled={envIsDev()} // Why is this disabled, you ask? On dev we do not have real phone numbers, so we would very likely send an SMS to someone who does not want to receive an sms from us. If you need to test it, make sure you have your REAL PHONE NUMBER in your Account!
          testID="deleteAccountConfirmationButton"
          mode={'outlined'}
          color={rptheme.colors.error}
          onPress={async () => {
            openSheet(<DeleteAccountOtcInput />, {
              adjustToContentHeight: true,
            });
            await sendDeleteAccountOtc(user?.id);
          }}
        >
          {t('General.deleteAccount')}
        </ReparkButton>
      </View>
    );
  };

  const DeletingAccount = () => {
    return (
      <View>
        <Title style={styles.deletingAccountTitle}>
          {t('DeleteAccount.deletingAccountTitle')}
        </Title>
        <Text style={styles.deletingAccountText}>
          {t('DeleteAccount.deletingAccountText')}
        </Text>
        <Text style={styles.deletingAccountEmoji}>👋</Text>
      </View>
    );
  };

  const DeleteAccountOtcInput = () => {
    return (
      <View style={{ margin: 20 }}>
        <Title style={styles.sheetTitle}>
          {t('DeleteAccount.otcInputTitle')}
        </Title>
        <OTCInputForm
          phoneNumber={user?.phoneNumber}
          button={{
            title: t('DeleteAccount.otcInputButtonTitle'),
            titleLoading: t('DeleteAccount.otcInputButtonLoadingTitle'),
            color: rptheme.colors.error,
          }}
          onSuccess={deleteUserAccount}
          canPasteFromClipboard={false}
        ></OTCInputForm>

        <CancelButton />
      </View>
    );
  };

  return <DeleteAccountConfirmation />;
};

const styles = StyleSheet.create({
  sheetTitle: {
    textAlign: 'center',
    marginBottom: 20,
  },
  text: {
    textAlign: 'center',
    color: rptheme.colors.error,
    fontSize: rptheme.fontSizeXLarge,
    marginBottom: 20,
  },
  deletingAccountTitle: {
    textAlign: 'center',
    color: rptheme.colors.primary,
  },
  deletingAccountText: {
    textAlign: 'center',
    fontSize: rptheme.fontSizeXLarge,
  },
  deletingAccountEmoji: {
    textAlign: 'center',
    fontSize: 40,
  },
});
