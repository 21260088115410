import dayjs, { Dayjs } from 'dayjs';
import { t } from 'i18next';
import React from 'react';
import { Text, View, StyleSheet, TouchableHighlight } from 'react-native';
import rptheme from '~/rptheme';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import Timezone from 'dayjs/plugin/timezone';
import { ReparkTabs } from '../ReparkTabs';
import ReparkButton from '../ReparkButton';
import { QuickActions } from './QuickActions';
import { CalendarOnly } from './CalendarOnly';
import { endToStartDateWithDuration } from '~/src/utils/helpers';
import { ReparkIcon } from '../ReparkIcon';
require('dayjs/locale/de');
dayjs.extend(Timezone);

export type CalendarProps = {
  onDateSelected: Function;
  selectedDate: Dayjs;
  maxBookingDaysInFuture: number;
  selectedDuration: number;
  testID?: string;
  onClose: () => void;
};

export const Calendar = ({
  onDateSelected,
  selectedDate,
  maxBookingDaysInFuture,
  testID,
  selectedDuration,
  onClose,
}: CalendarProps) => {
  dayjs.extend(isSameOrAfter);
  const [range, setRange] = React.useState<{ start: Dayjs; duration: number }>({
    start: selectedDate,
    duration: selectedDuration,
  });
  const [mode, setMode] = React.useState<'day' | 'range'>('day');

  React.useEffect(() => {
    if (selectedDuration > 1440) {
      setMode('range');
      setRange({ start: selectedDate, duration: selectedDuration });
    }
  }, [selectedDuration]);

  const onSelectDate = (start: Dayjs, duration = 1440, quickAccess = false) => {
    setRange({ start, duration });
    onDateSelected(start, duration, quickAccess);
  };

  const tabs = [
    {
      key: 'day',
      label: t('calendar.tablabelDay'),
      onPress: () => {
        setMode('day');
        setRange({ start: range.start, duration: 1440 });
        onDateSelected(range.start, 1440);
      },
      testID: 'dayTab',
    },
    {
      key: 'range',
      label: t('calendar.tablabelRange'),
      onPress: () => setMode('range'),
      testID: 'rangeTab',
    },
  ];

  return (
    <TouchableHighlight testID={testID} style={styles.container}>
      <>
        <ReparkTabs
          tabs={tabs}
          activeIndex={tabs.indexOf(
            tabs.filter((item) => item.key === mode)[0]
          )}
        />
        <View style={styles.chip}>
          <View style={styles.iconWrapper}>
            <ReparkIcon style={styles.icon} name="info" size={26} />
          </View>
          <Text> {t('General.oneDay')}</Text>
        </View>
        <CalendarOnly
          onDateSelected={onSelectDate}
          selectedDate={selectedDate}
          selectedDuration={selectedDuration}
          maxBookingDaysInFuture={maxBookingDaysInFuture}
          mode={mode}
        />
        {mode === 'day' && (
          <View style={styles.quickActionContainer}>
            <QuickActions
              onDateSelected={(date) => {
                onSelectDate(date, undefined, true);
              }}
            />
          </View>
        )}
        {mode === 'range' && (
          <View>
            <ReparkButton onPress={onClose}>
              {t('calendar.selectRange', {
                start: range.start.format('DD.MM.'),
                stop: endToStartDateWithDuration(
                  range.start,
                  range.duration
                ).format('DD.MM.'),
              })}
            </ReparkButton>
          </View>
        )}
      </>
    </TouchableHighlight>
  );
};

const styles = StyleSheet.create({
  container: {
    backgroundColor: 'white',
    paddingBottom: 10,
    borderRadius: rptheme.roundness,
    marginVertical: 50,
    padding: 20,
    ...rptheme.shadow,
  },
  header: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginVertical: 15,
  },
  weekdaysRow: { flexDirection: 'row', justifyContent: 'space-around' },
  divider: { marginVertical: 10 },
  dates: { minHeight: 200 },
  daysRow: { flexDirection: 'row' },
  dayContainer: {
    margin: 5,
    flex: 1,
    borderRadius: 200,
    height: 35,
    width: 35,
    justifyContent: 'center',
  },
  dayText: {
    textAlign: 'center',
  },
  quickActionContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
  },
  icon: {
    color: '#0000008A',
    marginRight: 12,
  },
  chip: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    fontFamily: rptheme.fonts.regular.fontFamily,
    fontSize: rptheme.fontSizeSmall,
    backgroundColor: rptheme.colors.secondary,
    marginTop: 12,
    borderRadius: 50,
    paddingVertical: 4,
    paddingHorizontal: 14,
  },
  text: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    flexWrap: 'wrap',
  },
  iconWrapper: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
});
