import React from 'react';
import { Card, IconButton, ProgressBar } from 'react-native-paper';
import rptheme from '~/rptheme';
import { View } from 'react-native';
import { SubscriptionAbortModal } from './SubscriptionAbortModal';
import { NavProp } from '../../navigation';

type SubscriptionProgressBarProps = {
  progress: number;
  indeterminate?: boolean;
  navigation: NavProp;
};

export const SubscriptionProgressBar = (
  props: SubscriptionProgressBarProps
) => {
  const { progress, indeterminate = false, navigation } = props;
  const [showAbortModal, setShowAbortModal] = React.useState(false);

  return (
    <>
      <SubscriptionAbortModal
        isOpen={showAbortModal}
        onClose={() => setShowAbortModal(false)}
        navigation={navigation}
      />
      <Card
        style={{
          padding: 10,
          ...rptheme.shadow,
          borderRadius: 0,
          paddingTop: 50,
        }}
      >
        <View
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
          }}
        >
          <View style={{ flex: 1, justifyContent: 'center' }}>
            <ProgressBar progress={progress} indeterminate={indeterminate} />
          </View>
          <View>
            <IconButton
              icon="close"
              size={12}
              onPress={() => setShowAbortModal(true)}
            />
          </View>
        </View>
      </Card>
    </>
  );
};
