import { PaymentMethod } from '@stripe/stripe-js';
import React from 'react';
import { TouchableOpacity } from 'react-native';
import rptheme from '~/rptheme';
import { CardItem } from '../../paymentMethodItems/CardItem';
import { SEPAItem } from '../../paymentMethodItems/SEPAItem';

type PaymentMethodSelectProps = {
  paymentMethods: PaymentMethod[];
  selectedPaymentMethod: PaymentMethod | null | undefined;
  onSelectPaymentMethod: (paymentMethod: PaymentMethod) => void;
};

export const PaymentMethodSelectList = (props: PaymentMethodSelectProps) => {
  const { paymentMethods, selectedPaymentMethod, onSelectPaymentMethod } =
    props;

  return (
    <>
      {paymentMethods.map((paymentMethod: PaymentMethod, index) => {
        const isSelected = selectedPaymentMethod?.id === paymentMethod.id;

        return (
          <TouchableOpacity
            onPress={() => onSelectPaymentMethod(paymentMethod)}
            style={{
              backgroundColor: 'white',
              borderColor: isSelected ? rptheme.colors.primary : 'lightgrey',
              borderWidth: 1,
              borderRadius: rptheme.roundness,
              marginVertical: 5,
            }}
            key={`pm-${index}`}
          >
            <>
              {paymentMethod.type === 'card' && (
                <CardItem card={paymentMethod} />
              )}

              {paymentMethod.type === 'sepa_debit' && (
                <SEPAItem sepa_debit={paymentMethod} />
              )}
            </>
          </TouchableOpacity>
        );
      })}
    </>
  );
};
