import React from 'react';
import { StyleSheet, View, Text } from 'react-native';
import {
  ActivityIndicator,
  Checkbox,
  TouchableRipple,
} from 'react-native-paper';
import rptheme from '~/rptheme';

export type ReparkCheckBoxProps = {
  label: string;
  position?: 'before' | 'after';
  disabled?: boolean;
  width?: number | string;
  checked?: boolean;
  onPress: () => void;
  size?: 'small' | 'medium' | 'large';
  icon?: any;
  isLoading?: boolean;
};

export const ReparkCheckbox = ({
  label,
  position = 'before',
  disabled = false,
  checked,
  onPress,
  width,
  size = 'medium',
  icon,
  isLoading = false,
}: ReparkCheckBoxProps) => {
  const getFontsize = () => {
    switch (size) {
      case 'small':
        return rptheme.fontSizeSmall;
      case 'large':
        return rptheme.fontSizeXLarge;
      default:
        return rptheme.fontSizeLarge;
    }
  };
  const styles = StyleSheet.create({
    container: {
      width: width,
    },
    inner: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: !position
        ? 'space-between'
        : position === 'after'
        ? 'flex-start'
        : 'space-between',
      alignItems: 'center',
    },
    label: {
      fontFamily: rptheme.fonts.regular.fontFamily,
      fontWeight: rptheme.fonts.regular.fontWeight,
      fontSize: getFontsize(),
    },
  });

  const getLabel = () => {
    return <Text style={styles.label}>{label}</Text>;
  };

  return (
    <View style={styles.container}>
      <TouchableRipple onPress={onPress}>
        <View style={styles.inner}>
          {position === 'before' && icon && icon()}
          {position === 'before' && getLabel()}
          {isLoading ? (
            <ActivityIndicator size={'small'} color={rptheme.colors.primary} />
          ) : (
            <Checkbox
              color={rptheme.colors.primary}
              status={
                checked === undefined
                  ? 'indeterminate'
                  : checked
                  ? 'checked'
                  : 'unchecked'
              }
              onPress={onPress}
              disabled={disabled}
            />
          )}
          {position === 'after' && getLabel()}
          {position === 'after' && icon && icon()}
        </View>
      </TouchableRipple>
    </View>
  );
};
