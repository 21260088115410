import React from 'react';
import { useTranslation } from 'react-i18next';
import { View } from 'react-native';
import { Modalize } from 'react-native-modalize';
import config from '~/config/config';
import rptheme from '~/rptheme';
import ReparkButton from '~/src/components/ReparkButton';
import { ReparkIcon } from '~/src/components/ReparkIcon';
import BottomSheetFrame from '../../bottom-sheet/BottomSheetFrame';
import { BottomSheetStore } from '../../bottom-sheet/BottomSheetStore';
import { NavProp, RootScreens } from '../../navigation';

type SuccessSheetProps = {
  success: true;
  message: string;
  openedGates: number;
  totalGates: number;
  navigation: NavProp;
};

export const successSheetOptions: React.ComponentProps<typeof Modalize> = {
  adjustToContentHeight: true,
};

export const SuccessSheet = (props: SuccessSheetProps) => {
  const { t } = useTranslation();
  const { openedGates, totalGates, navigation } = props;
  const hasMultipleGates = totalGates > 1;
  const partialSuccess = hasMultipleGates && openedGates < totalGates;
  const completeSuccess = !partialSuccess;
  const closeSheet = BottomSheetStore((store) => store.closeSheet);

  let title = t('successSheet.successTitle');
  let text = t('successSheet.successText');

  if (hasMultipleGates) {
    title = t('successSheet.completeSuccessTitle');
    text = t('successSheet.completeSuccessText', { openedGates, totalGates });

    if (partialSuccess) {
      title = t('successSheet.partialSuccessTitle', {
        openedGates,
        totalGates,
      });
      text = t('successSheet.partialSuccessText', { openedGates, totalGates });
    }
  }

  const openSupport = () => {
    closeSheet();
    navigation.navigate(RootScreens.WebViewScreen, {
      url: config().webPages.phoneSupport,
    });
  };

  return (
    <BottomSheetFrame title={title} text={text}>
      <>
        {completeSuccess && (
          <View style={{ alignItems: 'center', marginVertical: 20 }}>
            <ReparkIcon
              name="partypopper"
              size={50}
              color={rptheme.colors.primary}
            ></ReparkIcon>
          </View>
        )}
        {partialSuccess && (
          <ReparkButton icon={'phone-support'} onPress={openSupport}>
            {t('general.contactSupport')}
          </ReparkButton>
        )}
      </>
    </BottomSheetFrame>
  );
};
