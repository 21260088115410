import { IdPrefixes, IdType } from './types';

/**
 * Lookup table for enum conversion.
 * **/
const PREFIXES: IdPrefixes = {
  BOK: IdType.Booking,
  SUI: IdType.SubscriptionInvoice,
};

/**
 * Turns an id into an id type.
 * This can obviously fail.
 * @param id - can be any id
 * @returns IdType | null
 * **/
export function idToType(id: string): IdType | null {
  const prefix = id.slice(0, id.indexOf('-'));
  try {
    return PREFIXES[prefix as keyof IdPrefixes];
  } catch (e) {
    console.error(`Unknown/unimplemented prefix: ${prefix}`);
    return null;
  }
}

/**
 * Performs check if id is of enum value.
 * @param id - can be any id
 * @param idType - IdType  to check against
 * @returns boolean - true if id is desired idType
 * */
export function idIs(id: string, idType: IdType): boolean {
  const typed = idToType(id);
  return typed !== null && typed === idType;
}
