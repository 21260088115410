import AppLoading from 'expo-app-loading';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { View, StyleSheet, Text } from 'react-native';
import { Title } from 'react-native-paper';
import { CenteredAppView } from '~/src/components/CenteredAppView';
import ReparkButton from '~/src/components/ReparkButton';
import { verifyEmailRequest } from '../authentication/services/authService';
import { NavProp, RootScreens } from '../navigation';

type VerifyEmailAddressScreenProps = {
  navigation?: NavProp;
  route: {
    params: {
      otc: string;
    };
  };
};

export const VerifyEmailAddressScreen = (
  props: VerifyEmailAddressScreenProps
) => {
  const {
    navigation,
    route: {
      params: { otc },
    },
  } = props;
  const { t } = useTranslation();

  const [loading, setLoading] = React.useState(false);
  const [verified, setVerified] = React.useState(false);

  React.useEffect(() => {
    const doAsync = async () => {
      try {
        setLoading(true);
        await verifyEmailRequest({ otc });
        setVerified(true);
      } catch (e) {
        console.log(e);
      } finally {
        setLoading(false);
      }
    };

    doAsync();
  }, []);

  return (
    <CenteredAppView>
      <View style={styles.container}>
        {loading ? (
          <AppLoading />
        ) : verified ? (
          <>
            <Title>{t('VerfiyEmailAddressScreen.successTitle')}</Title>
            <Text>{t('VerfiyEmailAddressScreen.successText')}</Text>
            <ReparkButton
              onPress={() => navigation?.navigate(RootScreens.MapScreen)}
            >
              {t('VerfiyEmailAddressScreen.successCTALabel')}
            </ReparkButton>
          </>
        ) : (
          <>
            <Title>{t('VerfiyEmailAddressScreen.errorTitle')}</Title>
            <Text>{t('VerfiyEmailAddressScreen.errorText')}</Text>
          </>
        )}
      </View>
    </CenteredAppView>
  );
};

const styles = StyleSheet.create({
  container: {
    marginTop: 70,
    margin: 25,
  },
});
