import { feathersClient } from '~/src/services/feathers';
import { GarageType } from '../../garage/types';
import { Dayjs } from 'dayjs';

export const createInspectionAccessRequest = async (props: {
  userId: string;
  garageId: GarageType['id'];
  date: Dayjs;
}) => {
  const response = await feathersClient.service('accesses').create({
    userId: props.userId,
    garageId: props.garageId,
    reason: 'inspection',
    enabled: true,
  });

  return response;
};
