import * as React from 'react';
import { View, StyleSheet } from 'react-native';
import { Controller, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { useTranslation } from 'react-i18next';
import ReparkButton from '~/src/components/ReparkButton';
import Select from '~/src/components/Select';
import { AuthStore } from '../authentication/AuthStore';
import { GarageStore } from '../garage/GarageStore';
import { createClaimAccessZodSchema } from '~/src/rules/inputValidationRules';
import ReparkTextInput from '~/src/components/ReparkTextInput';
import { SnackbarStore } from '../snackbar/SnackbarStore';
import EditableItem, { Property, Service } from '~/src/components/EditableItem';
import { NavigationProp } from '@react-navigation/native';

export type ClaimAccessFormProps = {
  parkingSpots: any;
  onSuccess: () => void;
  navigation?: NavigationProp<any>;
};

export default function ClaimAccessForm(props: ClaimAccessFormProps) {
  const { parkingSpots, onSuccess } = props;

  const { t } = useTranslation();
  const [userId, firstName, lastName] = AuthStore((store) => [
    store.user.id,
    store.user.firstName,
    store.user.lastName,
  ]);
  const claimAccess = GarageStore((store) => store.claimAccess);
  const fireSnack = SnackbarStore((store) => store.fireSnack);

  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<{
    parkingSpotId: string;
    userId: string;
    name?: string;
  }>({
    resolver: zodResolver(createClaimAccessZodSchema(t)),
    defaultValues: {
      userId: userId,
    },
  });

  React.useEffect(() => {
    if (firstName && lastName) setValue('name', `${firstName} ${lastName}`);
  }, []);

  const [isLoading, setIsLoading] = React.useState(false);

  return (
    <>
      <View style={styles.container}>
        {!firstName || !lastName ? (
          <Controller
            name="name"
            control={control}
            render={({ field: { onChange } }) => (
              <ReparkTextInput
                testID={'nameInput'}
                onChangeText={(text: string) => onChange(text)}
                maxLength={40}
                textContentType="givenName"
                keyboardType="default"
                autoComplete="name"
                autoCorrect={false}
                spellCheck={false}
                autoFocus={true}
                zoderror={errors.name}
                label={t('AuthTexts.namePlaceHolder')}
                icon={'account-outline'}
              />
            )}
          ></Controller>
        ) : (
          <EditableItem
            service={Service.users}
            id={userId}
            property={Property.name}
            isEditable={false}
          />
        )}

        <Controller
          name="parkingSpotId"
          control={control}
          render={({ field: { onChange } }) => (
            <Select
              testID={'parkingSpotSelect'}
              displayKey={'name'}
              placeholder={t('AuthTexts.parkingSpotPlaceHolder')}
              items={parkingSpots}
              onSelectItem={(item: any) => onChange(item?.id)}
              zoderror={errors.parkingSpotId}
            ></Select>
          )}
        ></Controller>

        <ReparkButton
          loading={isLoading}
          disabled={isLoading}
          testID={'claimButton'}
          onPress={handleSubmit(async (data) => {
            setIsLoading(true);

            const response = await claimAccess(
              data.parkingSpotId,
              data.userId,
              data.name
            );

            if (!response.success) {
              fireSnack({
                title: t('General.errors.GenericErrorTitle'),
                message: t('General.errors.GenericErrorMessage'),
                type: 'error',
              });
            }

            fireSnack({
              title: t('ClaimAccessScreen.claimSuccessTitle'),
              message: t('ClaimAccessScreen.claimSuccessMessage'),
              type: 'success',
            });

            onSuccess();
            setIsLoading(false);
          })}
        >
          {isLoading
            ? t('ClaimAccessScreen.claimButtonLoadingLabel')
            : t('ClaimAccessScreen.claimButtonLabel')}
        </ReparkButton>
      </View>
    </>
  );
}

const styles = StyleSheet.create({
  container: {
    marginBottom: 50,
  },
});
