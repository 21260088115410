import * as React from 'react';
import { IconButton } from 'react-native-paper';
import rptheme from '~/rptheme';

export default function HeaderLeft(this: any, props: any) {
  const { fallBackScreen } = props;

  const testID = 'backButton';

  const onPress = () => {
    if (this.replace) {
      this.replace(fallBackScreen);
    } else {
      this.navigate(fallBackScreen);
    }
  };

  return (
    <IconButton
      testID={testID}
      icon={rptheme.backIcon}
      color={rptheme.colors.primary}
      onPress={() => {
        props.canGoBack ? props.onPress() : onPress();
      }}
      disabled={props.disabled}
    ></IconButton>
  );
}
