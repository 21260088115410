export type ReasonOfBookingOptionsType = {
  id: string;
  display: string;
  icon: string;
  size: number;
  margin: number;
};

export type ReasonOfBookingCategory = 'one' | 'more';

const loc_root = 'ReasonOfBooking';
const base_size = 25;

const options: Array<ReasonOfBookingOptionsType> = [
  {
    id: 'family-friends',
    display: `${loc_root}.familyFriends`,
    icon: 'freunde_und_familie',
    size: base_size,
    margin: 20,
  },
  {
    id: 'leisure',
    display: `${loc_root}.leisure`,
    icon: 'bicycle',
    size: base_size + 10,
    margin: 10,
  },
  {
    id: 'event',
    display: `${loc_root}.event`,
    icon: 'microphone',
    size: base_size,
    margin: 20,
  },
  {
    id: 'travel',
    display: `${loc_root}.travel`,
    icon: 'airplane',
    size: base_size,
    margin: 20,
  },
  {
    id: 'work',
    display: `${loc_root}.work`,
    icon: 'arbeit_und_berufliches',
    size: base_size,
    margin: 20,
  },
  {
    id: 'home',
    display: `${loc_root}.home`,
    icon: 'couch',
    size: base_size,
    margin: 20,
  },
];

export const ROB_VALUES = [
  'family-friends',
  'event',
  'leisure',
  'travel',
  'work',
  'home',
];
const ONE_KEYS = ['family-friends', 'event', 'leisure', 'travel', 'work'];
const MORE_KEYS = ['home', 'leisure', 'travel', 'work'];

export const getReasonOfBookingOptions = (
  booking: ReasonOfBookingCategory
): Array<ReasonOfBookingOptionsType> => {
  const keys = booking === 'one' ? ONE_KEYS : MORE_KEYS;
  return options.filter((option) => keys.includes(option.id));
};
