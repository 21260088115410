import { GarageType } from '~/src/features/garage/types';
import { AccessType } from '../types';
import dayjs from 'dayjs';
import React from 'react';
import { View, StyleSheet } from 'react-native';
import { Title, Subheading } from 'react-native-paper';
import rptheme from '~/rptheme';

type GarageCardProps = {
  garage: GarageType;
  access?: AccessType;
};

export const GarageCard = (props: GarageCardProps) => {
  const { garage, access } = props;

  return (
    <View style={styles.garageCard}>
      <Title style={styles.garageName}>{garage.name}</Title>
      <Subheading style={styles.garageAddress}>
        {garage.addressString}
      </Subheading>
      {access && (
        <Subheading style={styles.inspectionDate}>
          {dayjs(access.validFrom).format('DD.MM.YYYY')}
        </Subheading>
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  image: {
    height: 200,
    resizeMode: 'contain',
    marginVertical: 30,
  },
  legalText: {
    fontSize: 14,
    color: rptheme.colors.gray,
    marginVertical: 25,
  },
  heading: {
    textAlign: 'center',
    marginTop: 30,
    marginBottom: 10,
  },
  garageCard: {
    backgroundColor: 'white',
    marginVertical: 20,
    padding: 25,
  },
  garageName: {
    textAlign: 'center',
    fontWeight: 'bold',
  },
  garageAddress: {
    textAlign: 'center',
  },
  inspectionDate: {
    textAlign: 'center',
    color: rptheme.colors.primary,
  },
});
