import { envIsDev } from '~/src/utils/helpers';

export const useShare = (shareData: { title: any; url: any; text: any }) => {
  const canShare = () => {
    if (!envIsDev() && !navigator.share) return false;
    return true;
  };

  const share = async () => {
    if (!navigator.share) {
      console.log(
        `Share button with data: ${shareData.title}, ${shareData.url}, ${shareData.text} pressed. But navigator.share is undefined.`
      );
      return;
    }
    try {
      await navigator.share(shareData);
    } catch (e: any) {}
  };

  return { canShare: canShare(), share };
};
