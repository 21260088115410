import React from 'react';
import { View, Text, StyleSheet } from 'react-native';
import { Title } from 'react-native-paper';
import rptheme from '~/rptheme';
import LoadingState from '~/src/components/LoadingState';

export const PaymentProcessingNote = ({
  title,
  text,
}: {
  title: string;
  text: string;
}) => {
  return (
    <View style={styles.container}>
      <Title style={styles.title}>{title}</Title>
      <Text style={styles.text}>{text}</Text>
      <LoadingState />
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    marginVertical: 40,
  },
  title: {
    alignSelf: 'center',
    textAlign: 'center',
    fontSize: rptheme.fontSizeXLarge,
    fontFamily: rptheme.fonts.regular.fontFamily,
    fontWeight: rptheme.fonts.regular.fontWeight,
    color: rptheme.colors.primary,
  },
  text: {
    alignSelf: 'center',
    textAlign: 'center',
  },
});
