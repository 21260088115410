import React from 'react';
import { PaymentStore } from './PaymentStore';
import { Subheading } from 'react-native-paper';
import { Modal } from '../modal/Modal';
import rptheme from '~/rptheme';
import ReparkButton from '~/src/components/ReparkButton';
import { StyleSheet, TouchableWithoutFeedback, View, Text } from 'react-native';
import { t } from 'i18next';
import { Controller, useForm } from 'react-hook-form';
import ReparkTextInput from '~/src/components/ReparkTextInput';
import { createVoucherZodSchema } from '~/src/rules/inputValidationRules';
import { zodResolver } from '@hookform/resolvers/zod';

// type VoucherModalProps = {};

export const VoucherModal = () => {
  const setVoucher = PaymentStore((store) => store.setVoucher);
  const [modalIsOpen, setModalIsOpen] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);

  const {
    control,
    handleSubmit,
    setError,
    formState: { errors },
  } = useForm<{
    voucher: string;
  }>({
    resolver: zodResolver(createVoucherZodSchema(t)),
  });

  const submitVoucher = () => {
    handleSubmit(async (data) => {
      if (!data.voucher) return;

      setIsLoading(true);

      try {
        await setVoucher(data.voucher);
        setIsLoading(false);
        setModalIsOpen(false);
      } catch (e: any) {
        setError('voucher', { message: t('CheckoutScreen.voucherNotValid') });
        setIsLoading(false);
      }
    })();
  };

  return (
    <>
      <Modal isOpen={modalIsOpen} onClickOutside={() => setModalIsOpen(false)}>
        <TouchableWithoutFeedback onPress={() => {}}>
          <View style={styles.container}>
            <Subheading>{t('CheckoutScreen.voucherModalTitle')}</Subheading>

            <Controller
              name="voucher"
              control={control}
              render={({ field: { onChange } }) => (
                <ReparkTextInput
                  disabled={isLoading}
                  testID={'voucherInput'}
                  onChangeText={(text: string) => onChange(text)}
                  maxLength={40}
                  textContentType="none"
                  keyboardType="default"
                  autoComplete="none"
                  autoCorrect={false}
                  spellCheck={false}
                  zoderror={errors.voucher}
                  label={t('CheckoutScreen.voucherPlaceholder')}
                  icon={'logo'}
                />
              )}
            ></Controller>
            <Text>{t('CheckoutScreen.voucherInfo')}</Text>

            <ReparkButton
              loading={isLoading}
              disabled={isLoading}
              onPress={() => submitVoucher()}
            >
              {t('CheckoutScreen.submitVoucher')}
            </ReparkButton>
          </View>
        </TouchableWithoutFeedback>
      </Modal>

      <ReparkButton
        color={rptheme.colors.primary}
        onPress={() => setModalIsOpen(true)}
        mode="text"
      >
        {t('CheckoutScreen.addVoucherButton')}
      </ReparkButton>
    </>
  );
};

const styles = StyleSheet.create({
  container: {
    justifyContent: 'space-around',
    minWidth: 350,
    maxWidth: rptheme.screenSmall,
    alignSelf: 'center',
    backgroundColor: 'white',
    paddingBottom: 10,
    borderRadius: rptheme.roundness,
    marginVertical: 50,
    padding: 20,
    ...rptheme.shadow,
  },
});
