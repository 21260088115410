import config from '~/config/config';
import { envIsDev } from '~/src/utils/helpers';

const PIXEL_ID_TOKEN = '$$PIXEL_ID$$';

const script_inner_raw = `!function(f,b,e,v,n,t,s) { if(f.fbq)return;n=f.fbq=function() { n.callMethod ? n.callMethod.apply(n,arguments):n.queue.push(arguments)}; if(!f._fbq)f._fbq=n; n.push=n; n.loaded=!0;n.version='2.0'; n.queue=[];t=b.createElement(e);t.async=!0; t.src=v;s=b.getElementsByTagName(e)[0]; s.parentNode.insertBefore(t,s)}(window, document,'script', 'https://connect.facebook.net/en_US/fbevents.js'); fbq('init', '${PIXEL_ID_TOKEN}');`;
const image_src = `https://www.facebook.com/tr?id=${PIXEL_ID_TOKEN}&ev=PageView&noscript=1`;

export const initMetaPixel = () => {
  const test = document.getElementById('meta_pixel');
  if (test || envIsDev()) {
    return;
  }
  const heads = document.getElementsByTagName('head');
  if (heads.length > 0) {
    const head = heads[0];
    const script = document.createElement('script');
    script.id = 'meta_pixel';
    const img: HTMLImageElement = document.createElement('img');
    img.height = 1;
    img.width = 1;
    img.style.display = 'none';
    img.src = insertId(image_src);
    const noscript = document.createElement('noscript');
    noscript.appendChild(img);
    script.innerText = insertId(script_inner_raw);
    head.appendChild(script);
    head.appendChild(noscript);
  }
};

const insertId = (raw: string) => {
  return raw.replace(PIXEL_ID_TOKEN, config().meta.meta_pixel_id);
};

export const metaTrackPurchase = (
  paymentIntentId: string,
  value: number,
  garage: any
) => {
  if (envIsDev()) {
    return;
  }
  //@ts-ignore
  fbq !== undefined &&
    //@ts-ignore
    fbq('track', 'Purchase', {
      currency: 'EUR',
      value: value,
      content_ids: [paymentIntentId],
      content_category: garage.id,
      content_name: garage.name,
    });
};

let LASTCALL = Date.now();

const checkRateLimit = () => {
  const now = Date.now();
  if (now - LASTCALL < 1000) {
    LASTCALL = now;
    return true;
  } else {
    return false;
  }
};

export const metaTrackPageView = () => {
  if (envIsDev()) {
    return;
  }
  if (checkRateLimit()) {
    //@ts-ignore
    fbq !== undefined &&
      //@ts-ignore
      fbq('track', 'PageView');
  }
};
export const metaTrackOnboarding = (step: number) => {
  if (envIsDev()) {
    return;
  }
  const contentName =
    step > 0 ? `onboarding_skip_${step}` : 'onboarding_finished';
  //@ts-ignore
  fbq !== undefined &&
    //@ts-ignore
    fbq('track', 'CompleteRegistration', { contentName });
};
