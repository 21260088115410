import React from 'react';
import { useTranslation } from 'react-i18next';
import { View, StyleSheet } from 'react-native';
import rptheme from '~/rptheme';
import ReparkButton from '~/src/components/ReparkButton';

type ContinueNextButtonsProps = {
  onBack: () => void;
  onContinue: () => void;
  nextDisabled?: boolean;
  nextLabel?: string;
};

export const ContinueNextButtons = (props: ContinueNextButtonsProps) => {
  const { t } = useTranslation();

  const {
    onBack,
    onContinue,
    nextDisabled = false,
    nextLabel = t('General.next'),
  } = props;

  return (
    <View style={styles.buttonContainer}>
      <ReparkButton mode="outlined" style={styles.backButton} onPress={onBack}>
        {t('General.back')}
      </ReparkButton>
      <ReparkButton
        style={styles.nextButton}
        disabled={nextDisabled}
        onPress={onContinue}
      >
        {nextLabel}
      </ReparkButton>
    </View>
  );
};

const styles = StyleSheet.create({
  buttonContainer: {
    display: 'flex',
    flexDirection: 'row',
    marginTop: 5,
  },
  backButton: {
    marginRight: 5,
    justifyContent: 'center',
    borderColor: rptheme.colors.primary,
  },
  nextButton: {
    justifyContent: 'center',
    marginLeft: 5,
    flexGrow: 2,
  },
});
