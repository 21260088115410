import React from 'react';
import { StyleSheet, ScrollView, View } from 'react-native';
import { AuthStore } from '../authentication/AuthStore';
import { BookingStore } from './BookingStore';
import { useTranslation } from 'react-i18next';
import { BookingListItem } from './components/BookingListItem';
import { Auth } from '../authentication/Auth';
import rptheme from '~/rptheme';
import LoadingState from '~/src/components/LoadingState';
import { EmptyState } from '~/src/components/EmptyState';
import { NavProp, RootScreens } from '../navigation';
import { ReparkTabs } from '~/src/components/ReparkTabs';

export type BookingOverviewScreenProps = {
  navigation: NavProp;
};

export const BookingOverviewScreen = (props: BookingOverviewScreenProps) => {
  const { t } = useTranslation();
  const { navigation } = props;
  const loggedIn = AuthStore((store) => store.loggedIn);

  const [pastBookings, upcomingBookings, isFetchingBookings] = BookingStore(
    (store) => [
      store.pastBookings,
      store.upcomingBookings,
      store.isFetchingBookings,
    ]
  );

  const [bookingList, setBookingList] = React.useState<'upcoming' | 'past'>(
    'upcoming'
  );

  if (isFetchingBookings) return <LoadingState />;

  const tabs = [
    {
      label: t('BookingScreen.upcomingTabLabel'),
      onPress: () => setBookingList('upcoming'),
      testID: 'switchToUpcoming',
    },
    {
      label: t('BookingScreen.pastTabLabel'),
      onPress: () => setBookingList('past'),
      testID: 'switchToPast',
    },
  ];

  return (
    <ScrollView style={styles.container}>
      {loggedIn ? (
        <View style={styles.bookingContainer}>
          <View style={{ margin: 20 }}>
            <ReparkTabs tabs={tabs} />
          </View>

          {bookingList === 'upcoming' && (
            <ScrollView
              testID="upcomingBookingsList"
              style={styles.listContainer}
            >
              {upcomingBookings().length === 0 && (
                <EmptyState
                  title={t('BookingScreen.upcomingEmptyText')}
                  image={rptheme.graphics.calendar}
                  CTA={{
                    label: t('BookingScreen.emptyListCTA'),
                    icon: 'map',
                    onPress: () => navigation.navigate(RootScreens.MapScreen),
                  }}
                />
              )}
              {upcomingBookings().map((booking) => {
                return (
                  <BookingListItem
                    key={booking.id}
                    booking={booking}
                    navigation={navigation}
                  />
                );
              })}
            </ScrollView>
          )}

          {bookingList === 'past' && (
            <ScrollView testID="pastBookingsList" style={styles.listContainer}>
              {pastBookings().length === 0 && (
                <EmptyState
                  title={t('BookingScreen.pastEmptyText')}
                  image={rptheme.graphics.calendar}
                  CTA={{
                    label: t('BookingScreen.emptyListCTA'),
                    icon: 'map',
                    onPress: () => navigation.navigate(RootScreens.MapScreen),
                  }}
                />
              )}
              {pastBookings().map((booking) => {
                return (
                  <BookingListItem
                    key={booking.id}
                    booking={booking}
                    navigation={navigation}
                  />
                );
              })}
            </ScrollView>
          )}
        </View>
      ) : (
        <Auth goBackFallBack="Map" navigation={navigation} />
      )}
    </ScrollView>
  );
};

const styles = StyleSheet.create({
  container: {
    paddingTop: 80,
    maxWidth: rptheme.screenSmall,
    width: '100%',
    alignSelf: 'center',
  },
  bookingContainer: {
    maxWidth: 450,
    alignSelf: 'center',
    width: '100%',
  },
  listContainer: { paddingHorizontal: 20, marginBottom: 80 },
  emptyListContainer: {
    marginVertical: 40,
  },
  calendarGraphic: {
    height: 120,
    resizeMode: 'contain',
    marginBottom: 30,
  },
  emptyListText: {
    alignSelf: 'center',
    fontSize: rptheme.fontSizeXLarge,
    fontFamily: rptheme.fonts.regular.fontFamily,
    fontWeight: rptheme.fonts.regular.fontWeight,
    color: rptheme.colors.primary,
  },
  emptyListCTA: {
    marginTop: 30,
  },
});
