import dayjs, { Dayjs } from 'dayjs';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import { BookingStore } from './BookingStore';
import {
  dateStringToLocalDayjs,
  cetNow,
  formatDate,
  endToStartDateWithDuration,
} from '~/src/utils/helpers';
import { rrulestr } from 'rrule';
dayjs.extend(isSameOrAfter);
dayjs.extend(utc);
dayjs.extend(timezone);

type BookingStateProps = {
  start: Dayjs;
  duration: number;
  canceled: boolean;
};

type BookingStateCanceledProps = {
  canceled: boolean;
};

export const isActiveBooking = (
  { start, duration, canceled }: BookingStateProps,
  gracePeriodAfter = 0
) => {
  if (canceled) return false;
  return cetNow().isBetween(
    dayjs(start),
    dayjs(start).add(duration + gracePeriodAfter, 'minutes')
  );
};

export const isUpcomingBooking = ({ start, duration }: BookingStateProps) => {
  return dayjs(start).add(duration, 'minutes') >= cetNow();
};

export const isPastBooking = (
  { start, duration }: BookingStateProps,

  gracePeriodAfter = 0
) => {
  return dayjs(start).add(duration + gracePeriodAfter, 'minutes') < cetNow();
};

export const isCanceledBooking = ({ canceled }: BookingStateCanceledProps) => {
  return canceled;
};

export const isCancelable = (booking: any): boolean => {
  const cancelNewActiveBookingThreshold =
    BookingStore.getState().cancelNewActiveBookingThreshold;

  if (isPastBooking(booking)) return false;
  if (isCanceledBooking(booking)) return false;
  if (
    isActiveBooking(booking) &&
    !cetNow().isBetween(
      dayjs(booking.createdAt),
      dayjs(booking.createdAt).add(cancelNewActiveBookingThreshold, 'minutes')
    )
  ) {
    return false;
  }
  return true;
};

export const wasRefunded = (booking: any): boolean => {
  if (!booking.cancelationDetails || booking.cancelationDetails === null) {
    return false;
  }
  return (
    booking.cancelationDetails.refundedAt &&
    booking.cancelationDetails.refundedAt !== null
  );
};

type ExtendBookingOfferDetails = {
  availableUntil: Dayjs;
  offerId: string;
};

type FilterGarageOffersForExtendedBookingProps = {
  start: Dayjs;
  offers: Array<any>;
  assignment: 'free-choice' | 'restricted-free-choice' | 'on-arrival' | 'fixed';
  currentSpotId: string;
  maxBookingDaysInFuture: number;
};
export const filterGarageOffersForExtendedBooking = ({
  start,
  offers,
  assignment,
  currentSpotId,
  maxBookingDaysInFuture,
}: FilterGarageOffersForExtendedBookingProps): ExtendBookingOfferDetails | null => {
  const max = start.add(maxBookingDaysInFuture, 'days');

  if (assignment === 'fixed') {
    const offer = offers.find((offer) => offer.parkingSpotId === currentSpotId);
    if (!offer) {
      return null;
    }
    const openingHoursRestriction = findRestriction(
      getWeekDaysFromRrule(offer.rrule),
      getOneWeekInWeekdays(start),
      start
    );
    if (offer.exdates.length === 0) {
      if (openingHoursRestriction) {
        return { availableUntil: openingHoursRestriction, offerId: offer.id };
      } else {
        return { availableUntil: max, offerId: offer.id };
      }
    }

    const nearestExdate = findClosestFutureExdate(offer.exdates, start);
    if (nearestExdate !== null) {
      const availableUntil =
        openingHoursRestriction &&
        openingHoursRestriction?.isBefore(nearestExdate)
          ? openingHoursRestriction
          : nearestExdate;
      return {
        availableUntil,
        offerId: offer.id,
      };
    } else {
      if (openingHoursRestriction) {
        return { availableUntil: openingHoursRestriction, offerId: offer.id };
      } else {
        return { availableUntil: max, offerId: offer.id };
      }
    }
  }
  if (assignment === 'free-choice' || assignment === 'restricted-free-choice') {
    let furthest: Dayjs = start;
    let furthestOfferId: string | undefined = undefined;

    for (let i = 0; i < offers.length; i++) {
      const offer = offers[i];
      const offerClosestExdate = findClosestFutureExdate(offer.exdates, start);
      const openingHoursRestriction = findRestriction(
        getWeekDaysFromRrule(offer.rrule),
        getOneWeekInWeekdays(start),
        start
      );
      if (offerClosestExdate === null) {
        //there are no exdates or all exdates are before start, hence we can offer as longs as the bookable days
        if (openingHoursRestriction) {
          if (openingHoursRestriction.isAfter(furthest)) {
            furthest = openingHoursRestriction;
            furthestOfferId = offer.id;
          }
        } else {
          return {
            availableUntil: max,
            offerId: offer.id,
          };
        }
      } else {
        if (openingHoursRestriction) {
          if (openingHoursRestriction.isAfter(offerClosestExdate)) {
            if (offerClosestExdate.isAfter(furthest)) {
              furthest = offerClosestExdate;
              furthestOfferId = offer.id;
            }
          } else {
            if (openingHoursRestriction.isAfter(furthest)) {
              furthest = openingHoursRestriction;
              furthestOfferId = offer.id;
            }
          }
        } else if (offerClosestExdate.isAfter(furthest)) {
          furthest = offerClosestExdate;
          furthestOfferId = offer.id;
        }
      }
    }

    return furthestOfferId
      ? ({
          availableUntil: furthest,
          offerId: furthestOfferId as string,
        } as ExtendBookingOfferDetails)
      : null;
  }
  return null;
};

export const findClosestFutureExdate = (
  exdates: Array<string>,
  start: Dayjs
): Dayjs | null => {
  let closest: Dayjs | null = null;
  for (let i = 0; i < exdates.length; i++) {
    const exdate = dateStringToLocalDayjs(exdates[i]);
    if (exdate.isSameOrAfter(start)) {
      if (closest == null) {
        closest = exdate;
      } else if (exdate.isBefore(closest)) {
        closest = exdate;
      }
    }
  }
  if (closest && closest?.isSame(start)) {
    return null;
  }
  return closest;
};

export const getWeekDaysFromRrule = (rule: string) => {
  const byweekday = rrulestr(rule).options.byweekday;
  for (let i = 0; i < byweekday.length; i++) {
    const weekday = byweekday[i];
    if (weekday === 0) {
      byweekday[i] = 1;
    } else if (weekday === 6) {
      byweekday[i] = 0;
    } else {
      byweekday[i] = weekday + 1;
    }
  }
  return byweekday.sort((a: number, b: number) => a - b);
};

export const getOneWeekInWeekdays = (start: dayjs.Dayjs): number[] => {
  let day = 1;
  const weekdays = [start.day()];
  while (day < 7) {
    weekdays.push(start.add(day, 'day').endOf('day').day());
    day++;
  }
  return weekdays;
};

export const findRestriction = (
  rrule: number[],
  weekdays: number[],
  start: Dayjs
) => {
  if (rrule.length === 7) {
    return null;
  }
  let startWeekday = start.day();
  const first = weekdays[0];
  const firstIdxInRrule = rrule.indexOf(first);
  const sorted = rrule
    .slice(firstIdxInRrule)
    .concat(rrule.slice(0, firstIdxInRrule));
  let lastOpenDay = null;
  for (let i = 0; i < weekdays.length; i++) {
    if (!sorted[i] || sorted[i] !== weekdays[i]) {
      if (i === 0) {
        return start;
      } else {
        lastOpenDay = weekdays[i - 1];
        break;
      }
    }
  }
  if (lastOpenDay !== null) {
    let date = start;
    while (true) {
      if (startWeekday === lastOpenDay) {
        return date.add(1, 'day');
      } else {
        date = date.add(1, 'day');
        startWeekday = startWeekday + 1 === 7 ? 0 : startWeekday + 1;
      }
    }
  }
  return null;
};

export const routerHasName = (router: any) => {
  return (
    router &&
    'name' in router &&
    router.name &&
    router.name != null &&
    router.name != ''
  );
};

export const routerIsDoor = (router: any) => {
  return router && routerHasName(router) && router.name.startsWith('[DOOR]');
};

export const formatBookingDateRange = (booking: any, t: Function) => {
  const startDate = formatDate(
    dayjs(booking.start).tz('Europe/Vienna'),
    t,
    false
  );
  if (booking.duration <= 1440) {
    return startDate;
  }

  return `${startDate} - ${endToStartDateWithDuration(
    dayjs(booking.start),
    booking.duration
  ).format('DD.MM.')}`;
};
