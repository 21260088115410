import React from 'react';
import { StyleSheet, View } from 'react-native';
import { Subheading, Title } from 'react-native-paper';
import { SubwayStationIcon } from '../map/components/SubwayStationIcon';
import { getNearbySubwayStations } from '../map/helpers';

type GarageTitleProps = {
  garage: any;
  align?: 'center' | 'flex-start';
  withSubway?: boolean;
};

export const GarageTitle = (props: GarageTitleProps) => {
  const { garage, align = 'center', withSubway = false } = props;

  const name = garage.name;
  const street = `${garage.street} ${
    garage.status === 'comingSoon' ? '' : garage.streetNumber //Street Number is hidden, if status of garage is `comingSoon`
  }`;
  const postalAndZip = `${garage.postalCode}, ${garage.city}`;

  const title = name || street;
  const subtitle = name ? garage.addressString : postalAndZip;

  const stations = getNearbySubwayStations(garage.coordinates, true).filter(
    (station) => station.distance < 600
  );

  const truncateMaximum = 25;
  const truncatedTitle =
    title.length > truncateMaximum
      ? title.substring(0, truncateMaximum) + '...'
      : title;

  return (
    <View style={styles.container}>
      <View>
        <View style={[{ flexDirection: 'row' }, { alignSelf: align }]}>
          <Title testID="garageTitle" style={[styles.title]} selectable={true}>
            {truncatedTitle}
          </Title>
          {withSubway && (
            <View
              style={{ display: 'flex', flexDirection: 'row', marginLeft: 5 }}
            >
              {stations.map((station, key) => (
                <SubwayStationIcon line={station.line} key={key} />
              ))}
            </View>
          )}
        </View>
      </View>

      <Subheading
        style={[styles.subTitle, { alignSelf: align }]}
        selectable={true}
      >
        {subtitle}
      </Subheading>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {},
  title: {
    alignSelf: 'center',
    fontSize: 20,
    fontWeight: '300',
  },
  subTitle: {
    alignSelf: 'center',
    fontSize: 14,
    marginTop: -5,
  },
});
