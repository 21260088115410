import { trackFirebase, setUserPropertiesFirebase } from './firebaseAnalytics';

//Recommended events can be found here: https://support.google.com/analytics/answer/9267735?hl=en
//GA4 event Docs can be found here: https://developers.google.com/analytics/devguides/collection/ga4/reference/events
export const events = {
  screen_view: 'screen_view', // Prebuilt by Google Analytics - do not rename
  purchase: 'purchase', // Prebuilt by Google Analytics - do not rename
  refund: 'refund', // Prebuilt by Google Analytics - do not rename
  begin_checkout: 'begin_checkout', // Prebuilt by Google Analytics - do not rename
  login: 'login', // Prebuilt by Google Analytics - do not rename
  sign_up: 'sign_up', // Prebuilt by Google Analytics - do not rename
  sign_out: 'sign_out', // Prebuilt by Google Analytics - do not rename
  view_item: 'view_item',
  garage_open: 'garage_open',
  vehicle_add: 'vehicle_add',
  vehicle_edit: 'vehicle_edit',
  vehicle_delete: 'vehicle_delete',
  thumbs_up: 'thumbs_up',
  thumbs_down: 'thumbs_down',
  onboarding_complete: 'onboarding_complete',
  onboarding_skip: 'onboarding_skip',
};

type AnalyticsType = {
  track: (event: AnalyticsEvents, params?: any) => void;
  setUserProperties: (params: any) => void;
};

type AnalyticsEvents = keyof typeof events;

const analyticsEnabled = true; // This could be a User Setting

const analytics: AnalyticsType = {
  track: (event: AnalyticsEvents, params?: any) => {
    if (!analyticsEnabled) return;
    trackFirebase(event, params);
    // In case we want to use more tacking services, we can add them here
  },

  setUserProperties: (params: any) => {
    if (!analyticsEnabled) return;
    setUserPropertiesFirebase(params);
    // In case we want to use more tacking services, we can add them here
  },
};

export default analytics;

export const garageToGAItem = (garage: any) => {
  return {
    item_id: garage.id,
    item_name: garage.addressString,
    affiliation: garage.companyId,
    price: garage.rate / 100,
    currency: 'EUR',
  };
};
