import * as React from 'react';
import { IconButton, Subheading } from 'react-native-paper';
import { StyleSheet, View } from 'react-native';
import rptheme from '~/rptheme';
import { ReparkIcon } from './ReparkIcon';

export default function ReparkIconButton(
  props: React.ComponentProps<typeof IconButton> & {
    label?: string;
    filled?: boolean;
    icon: string;
    styles?: {
      container?: any;
    };
  }
) {
  const { label, filled = false, icon, size = 30 } = props;

  const styles = StyleSheet.create({
    container: props.styles?.container,
    fab: {
      alignSelf: 'center',
      borderRadius: 100,
      marginBottom: 10,
      height: size * 1.3,
      width: size * 1.3,
      backgroundColor: filled ? props.color : 'transparent',
    },
  });

  return (
    <>
      <View style={styles.container}>
        <IconButton
          style={styles.fab}
          {...props}
          icon={() => (
            <ReparkIcon
              name={icon}
              size={size * 0.8}
              color={filled ? 'white' : props.color}
            ></ReparkIcon>
          )}
          color={filled ? 'white' : props.color}
        />
        {label && (
          <Subheading
            style={{
              alignSelf: 'center',
              color: rptheme.colors.primary,
              fontSize: size * 0.4,
            }}
          >
            {label}
          </Subheading>
        )}
      </View>
    </>
  );
}
