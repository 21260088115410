import React from 'react';
import { useTranslation } from 'react-i18next';
import { View } from 'react-native';
import rptheme from '~/rptheme';
import { StyleSheet } from 'react-native';
import { Subheading } from 'react-native-paper';
import ReparkButton from '~/src/components/ReparkButton';
import { Modal } from '~/src/features/modal/Modal';
import { PaymentMethodSelectList } from './PaymentMethodSelectList';
import { PaymentStore } from '../../PaymentStore';
import { ReparkIcon } from '~/src/components/ReparkIcon';
import Icon from '@mdi/react';
import { mdiBank } from '@mdi/js';
import { getPaymentMethodDescription } from '../../paymentHelpers';

// This component is ONLY used for selecting the payment method in PaymentStore. It is not a catch-all picker that returns a selected payment method.
// It will also only display payment methods for selection, that are allowed for the current payment intent, saved in PaymentStore.

export const PaymentMethodPicker = () => {
  const { t } = useTranslation();
  const [modalIsOpen, setModalIsOpen] = React.useState(false);

  const [
    selectPaymentMethod,
    selectedPaymentMethod,
    getForPaymentIntentAllowedPaymentMethods,
  ] = PaymentStore((store) => [
    store.selectPaymentMethod,
    store.selectedPaymentMethod,
    store.getForPaymentIntentAllowedPaymentMethods,
  ]);

  function ButtonIcon() {
    switch (selectedPaymentMethod?.type) {
      case 'card':
        return () => (
          <ReparkIcon
            name={'card'}
            size={16}
            color={rptheme.colors.primary}
          ></ReparkIcon>
        );
      case 'sepa_debit':
        return () => (
          <Icon path={mdiBank} size={1} color={rptheme.colors.primary} />
        );
      default:
        return 'credit-card';
    }
  }

  return (
    <>
      <Modal isOpen={modalIsOpen} onClickOutside={() => setModalIsOpen(false)}>
        <View style={styles.container}>
          <View style={styles.content}>
            <Subheading>Zahlungsart wählen</Subheading>
            <PaymentMethodSelectList
              paymentMethods={getForPaymentIntentAllowedPaymentMethods()}
              onSelectPaymentMethod={(paymentMethod) =>
                selectPaymentMethod(paymentMethod.id)
              }
              selectedPaymentMethod={selectedPaymentMethod}
            />
          </View>
        </View>
      </Modal>
      {selectedPaymentMethod && (
        <ReparkButton
          mode="outlined"
          onPress={() => setModalIsOpen(!modalIsOpen)}
          icon={ButtonIcon()}
        >
          {getPaymentMethodDescription(selectedPaymentMethod, t)}
        </ReparkButton>
      )}
    </>
  );
};

const styles = StyleSheet.create({
  container: {
    maxWidth: rptheme.screenSmall,
    alignSelf: 'center',
  },
  content: {
    backgroundColor: 'white',
    paddingBottom: 30,
    borderRadius: rptheme.roundness,
    marginVertical: 50,
    marginHorizontal: 10,
    padding: 20,
    ...rptheme.shadow,
  },
});
