import React from 'react';
import rptheme from '~/rptheme';
import ReparkIconButton from './ReparkIconButton';

type CancelConfirmProps = {
  iconSize?: number;
  onConfirm: Function;
  onCancel?: Function;
  style?: any;
  showCancel?: boolean;
};

export const CancelConfirm = (props: CancelConfirmProps) => {
  const { iconSize = 25, onCancel, onConfirm, showCancel = true } = props;

  return (
    <>
      <ReparkIconButton
        testID="saveIconButton"
        color={rptheme.colors.success}
        size={iconSize}
        icon={'check'}
        onPress={() => {
          onConfirm();
        }}
        style={props.style}
      />
      {showCancel ? (
        <ReparkIconButton
          size={iconSize}
          testID="closeIconButton"
          color={rptheme.colors.error}
          icon={'close'}
          onPress={() => {
            onCancel?.();
          }}
          style={props.style}
        />
      ) : null}
    </>
  );
};
