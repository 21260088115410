import React from 'react';
import { IconButton } from 'react-native-paper';
import rptheme from '~/rptheme';

type BackButtonProps = {
  onPress: () => void;
};

export const BackButton = ({ onPress }: BackButtonProps) => {
  return (
    <IconButton
      testID={'backButton'}
      icon={rptheme.backIcon}
      color={rptheme.colors.primary}
      onPress={onPress}
    ></IconButton>
  );
};
