import { feathersClient } from '../../../services/feathers';
import { LoginPasswordValuesType } from '~/src/features/authentication/authTypes';

import * as firebaseAuth from 'firebase/auth';
import { PhoneAuthProvider, signInWithCredential } from 'firebase/auth';
import Bugsnag from '@bugsnag/js';

export const authenticateEmailPassword = async (
  signInData: LoginPasswordValuesType
) => {
  console.log('Authenticating Firebase with email/password');
  const auth = firebaseAuth.getAuth();
  let firebaseResponse = null;

  try {
    firebaseResponse = await firebaseAuth.signInWithEmailAndPassword(
      auth,
      signInData.email,
      signInData.password
    );
  } catch (e: any) {
    console.log(e);
    // Error list: https://firebase.google.com/docs/reference/js/auth#autherrorcodes
    if (e.code === 'auth/wrong-password') {
      throw 401;
    } else {
      throw 500;
    }
  }

  const idToken = await firebaseResponse.user.getIdToken();
  console.log('Got Firebase id token', idToken);
  console.log('Got Firebase refresh token', firebaseResponse.user.refreshToken);

  // feathers stores the token by itself
  // (see configuration in ~/services/feathers.tsx)
  const response: any = await authenticateFeathers(idToken);
  console.log('Feathers auth response', response);

  response.idToken = idToken;
  response.refreshToken = firebaseResponse.user.refreshToken;

  return response;
};

export const authenticatePhone = async (signInData: {
  verificationId: string;
  code: string;
}) => {
  console.log('Authenticating Firebase with phone');
  const auth = firebaseAuth.getAuth();
  let firebaseResponse = null;

  const { verificationId, code } = signInData;

  try {
    const credential = PhoneAuthProvider.credential(verificationId, code);
    firebaseResponse = await signInWithCredential(auth, credential);
  } catch (e: any) {
    Bugsnag.notify(e);
    if (e.code === 'auth/invalid-verification-code') {
      throw 401;
    } else if (e.code === 'auth/too-many-requests') {
      throw e;
    } else {
      throw e;
    }
  }

  const idToken = await firebaseResponse?.user.getIdToken();
  console.log('Got Firebase id token', idToken);
  console.log('Got Firebase refresh token', firebaseResponse.user.refreshToken);

  // feathers stores the token by itself
  // (see configuration in ~/services/feathers.tsx)
  const response: any = await authenticateFeathers(idToken as string);
  response.idToken = idToken;
  response.refreshToken = firebaseResponse?.user.refreshToken;

  return response;
};

export const authenticateFeathers = async (idToken: string) => {
  console.log('Authenticating Feathers with Firebase');

  try {
    const response = await feathersClient.authenticate({
      access_token: idToken,
      strategy: 'firebase',
    });
    console.log('Feathers authenticate response', response);

    response.idToken = idToken;
    return response;
  } catch (err: any) {
    Bugsnag.notify(err);
    console.log('feathers-auth error', JSON.stringify(err));
  }
};
