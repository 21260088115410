import { t } from 'i18next';
import React from 'react';
import { Card, Subheading } from 'react-native-paper';
import { NavProp } from '~/src/features/navigation';
import { LicencePlateForm } from '~/src/features/vehicles/LincencePlateInput';
import { VehiclePicker } from '~/src/features/vehicles/VehiclePicker';
import { formatDimensions } from '~/src/utils/helpers';
import { cardStyles } from './styles';
import { StyleSheet, Text } from 'react-native';
import { GarageType } from '~/src/features/garage/types';
import { VehicleStore } from '~/src/features/vehicles/VehicleStore';
import config from '~/config/config';

type VehicleCardProps = {
  navigation: NavProp;
  garage: GarageType;
};

export const VehicleCard = (props: VehicleCardProps) => {
  const { navigation, garage } = props;
  const [selectedVehicle] = VehicleStore((store) => [store.selectedVehicle]);

  const alertUser =
    garage.dimensions?.height <= config().repark.vehicleHeightWarning;

  return (
    <Card style={cardStyles.card}>
      <Subheading style={cardStyles.heading}>
        {t('Checkout.vehicleLabel')}
      </Subheading>

      {!selectedVehicle ? (
        <>
          <LicencePlateForm />
        </>
      ) : (
        <>
          <VehiclePicker navigation={navigation} />
          {garage.dimensions?.height && (
            <Subheading style={styles.vehicleHeightNote}>
              {t('Checkout.maxVehicleHeightInfo')}:{' '}
              <Text
                style={[
                  {
                    color: alertUser ? 'red' : undefined,
                    fontWeight: alertUser ? 'bold' : 'normal',
                  },
                ]}
              >
                {formatDimensions(garage.dimensions.height)}
              </Text>
            </Subheading>
          )}
        </>
      )}
    </Card>
  );
};

const styles = StyleSheet.create({
  vehicleHeightNote: {
    fontSize: 14,
    textAlign: 'center',
  },
});
