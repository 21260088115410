import * as React from 'react';
import { AppState, AppStateStatus, Platform, View } from 'react-native';
import { useForm, Controller } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import {
  SubmitOTCDataValuesType,
  ValidateOTCResponseType,
} from '~/src/features/authentication/authTypes';
import {
  createSubmitEmailOTCZodSchema,
  createSubmitPhoneOTCZodSchema,
  createCodeValidation,
} from '~/src/rules/inputValidationRules';

import { useTranslation } from 'react-i18next';
import ReparkTextInput from '~/src/components/ReparkTextInput';
import ReparkButton from '~/src/components/ReparkButton';

import i18next from 'i18next';
import sleep from '../utils/sleep';
import * as Clipboard from 'expo-clipboard';
import { AuthStore } from '~/src/features/authentication/AuthStore';
import { SnackbarStore } from '~/src/features/snackbar/SnackbarStore';

type Props = {
  email?: string;
  onSuccess: (props: any) => any;
  button?: {
    title: string;
    titleLoading: string;
    color?: string;
  };
  otc?: string;
  canPasteFromClipboard?: boolean;
  phoneNumber?: string;
};

export default function OTCInput({
  phoneNumber,
  email,
  onSuccess,
  button,
  canPasteFromClipboard = true,
}: Props) {
  const { t } = useTranslation();

  const fireSnack = SnackbarStore((store) => store.fireSnack);
  const validateOTC = AuthStore((store) => store.validateOTC);
  const [code, setCode] = React.useState('');

  const resolver = phoneNumber
    ? createSubmitPhoneOTCZodSchema(t)
    : createSubmitEmailOTCZodSchema(t);

  const appState = React.useRef(AppState.currentState);
  const [, setAppStateVisible] = React.useState(appState.current);

  const {
    control,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
  } = useForm<SubmitOTCDataValuesType>({
    resolver: zodResolver(resolver),
    defaultValues: {
      phoneNumber: phoneNumber,
      email: email,
    },
  });
  const [isLoading, setIsLoading] = React.useState(false);
  const [resendTimer, setResendTimer] = React.useState(60);
  const isTimerRunning = resendTimer > 0;

  // Set Resend Timer
  React.useEffect(() => {
    const interval = setInterval(() => {
      if (resendTimer <= 0) return;
      setResendTimer((lastTimer) => {
        lastTimer <= 1 && clearInterval(interval);
        return lastTimer - 1;
      });
    }, 1000); // Decrecemt every second
    return () => {
      clearInterval(interval);
    };
  }, [isTimerRunning]);

  // reset() the form, so a possibly wrong validation error message is not visible :)
  React.useEffect(() => {
    i18next.on('languageChanged', function () {
      reset(control._formValues, { keepValues: true });
    });
    return () => {
      i18next.off('languageChanged');
    };
  }, []);

  const submitOTC = handleSubmit(async (otcData) => {
    setIsLoading(true);

    const response: ValidateOTCResponseType = await validateOTC(otcData);
    if (response.success) {
      await onSuccess({ otcData, response });
    } else {
      handleResponseError(response);
      setIsLoading(false);
    }
  });

  const handleResponseError = (response: ValidateOTCResponseType) => {
    const { name: errorName, code: errorCode, data } = response;

    let title = t('General.errors.GenericErrorTitle'),
      message = t('General.errors.GenericErrorMessage');
    const type = 'error';

    if (errorCode === 404) {
      if (errorName === 'OtcExpired') {
        title = t('AuthTexts.checkOTCExpiredTitle');
        message = t('AuthTexts.checkOTCExpiredMessage');
      }

      if (errorName === 'OtcNotFound') {
        if (data?.tries?.exceeded === false) {
          title = t('AuthTexts.checkOTCTriesLeftTitle');
          message = t(
            data?.tries.triesLeft > 1
              ? 'AuthTexts.checkOTCTriesLeftMessage'
              : 'AuthTexts.checkOTCTriesLeftMessageSingle',
            {
              tries: data?.tries.triesLeft,
            }
          );
        } else if (data?.tries?.exceeded === true) {
          title = t('AuthTexts.checkOTCTriesExceededTitle');
          message = t('AuthTexts.checkOTCTriesExceededMessage');
          setResendTimer(0);
        } else {
          title = t('AuthTexts.checkOTCNotFoundTitle');
          message = t('AuthTexts.checkOTCNotFoundMessage');
        }
      }
    }

    fireSnack({
      title,
      message,
      type,
    });
  };

  // Handle automagically clipboard paste
  React.useEffect(() => {
    if (Platform.OS === 'web') {
      window.addEventListener('focus', handleClipboard);
    } else {
      AppState.addEventListener('change', handleClipboardNative);
    }
    return () => {
      if (Platform.OS === 'web') {
        window.removeEventListener('focus', handleClipboard);
      } else {
        AppState.removeEventListener('change', handleClipboardNative);
      }
    };
  }, []);

  const getClipboard = async () => {
    const tries = 3;
    for (let i = 1; i <= tries; i++) {
      try {
        // TODO: waiting for release of
        //        - https://github.com/expo/expo/blob/afdd261ba30d09d145911bf8cc1fd41a016ae041/packages/expo-clipboard/CHANGELOG.md
        //        - found in https://github.com/expo/expo/issues/15046
        //   to make sure clipboard context can be properly read-out from android device's, too
        const result = await Clipboard.getStringAsync();
        return result;
      } catch (e) {
        sleep(100);
        continue;
      }
    }
  };

  const handleClipboard = async () => {
    if (!canPasteFromClipboard) return;
    let text = (await getClipboard()) || '';

    try {
      text = createCodeValidation(t).parse(text);
    } catch (e) {
      return false;
    }
    // Normally, ReparkButton.onChangeText will be trigged to sync the TextInput value with the control._formValues
    // This is the equivalent way to do it programmatically
    setValue('otc', text, {
      shouldDirty: true,
      shouldTouch: true,
      shouldValidate: true,
    });
    setCode(text);
    submitOTC();
  };

  const handleClipboardNative = async (nextAppState: AppStateStatus) => {
    if (
      appState.current.match(/inactive|background/) &&
      nextAppState === 'active'
    ) {
      await handleClipboard();
    }

    appState.current = nextAppState;
    setAppStateVisible(appState.current);
  };

  return (
    <View>
      <Controller
        name="otc"
        control={control}
        render={({ field: { onChange } }) => (
          <ReparkTextInput
            testID={'otcInput'}
            onChangeText={(text: string) => onChange(text)}
            value={code}
            maxLength={6}
            secureTextEntry={false}
            textContentType="password"
            autoComplete="password"
            autoCorrect={false}
            spellCheck={false}
            keyboardType="numeric"
            autoFocus={true}
            label={t('AuthTexts.otcPlaceholder')}
            zoderror={errors.otc}
            icon={'shield'}
          ></ReparkTextInput>
        )}
      ></Controller>
      <View>
        <ReparkButton
          testID="confirmOtcButton"
          loading={isLoading}
          disabled={isLoading}
          onPress={submitOTC}
          color={button?.color}
        >
          {isLoading
            ? button?.titleLoading ||
              t('AuthTexts.checkOTCNextStepButtonLoadingLabel')
            : button?.title || t('General.nextStep')}
        </ReparkButton>
      </View>
    </View>
  );
}
