import React from 'react';
import { View, StyleSheet, Text } from 'react-native';
import { ReparkIcon } from '~/src/components/ReparkIcon';
import rptheme from '~/rptheme';
import { useTranslation } from 'react-i18next';
import { formatCurrency, formatDate } from '~/src/utils/helpers';
import dayjs from 'dayjs';

type BookingRefundStateProps = {
  cancelationDetails: { [key: string]: any };
};

export const BookingRefundState = (props: BookingRefundStateProps) => {
  const [t] = useTranslation();
  const { refundedAt, refundAmount } = props.cancelationDetails;

  return (
    <View style={styles.container}>
      <ReparkIcon
        style={styles.icon}
        name="success"
        color={rptheme.colors.primary}
      />
      <Text style={styles.text}>
        {t('BookingDetailsScreen.refundIssuedOn', {
          amount: formatCurrency(refundAmount),
          date: formatDate(refundedAt, t, false),
        })}
      </Text>
    </View>
  );
};

const styles = StyleSheet.create({
  icon: {
    alignSelf: 'center',
    marginRight: 5,
  },
  container: {
    fontSize: 16,
    flexDirection: 'row',
    justifyContent: 'center',
    textAlign: 'center',
  },
  text: {
    color: rptheme.colors.primary,
    fontWeight: 'bold',
  },
});
