import React from 'react';
import { View, StyleSheet } from 'react-native';
import rptheme from '~/rptheme';
import ReparkButton from './ReparkButton';

type ReparkTabsProps = {
  tabs: Array<{ label: string; onPress: () => void; testID: string }>;
  activeIndex?: number;
};

export const ReparkTabs = (props: ReparkTabsProps) => {
  const { tabs, activeIndex = 0 } = props;
  const [active, setActive] = React.useState(activeIndex);

  React.useEffect(() => {
    setActive(activeIndex);
  }, [activeIndex]);

  return (
    <View style={styles.tabContainer}>
      {tabs.map((tab, i) => (
        <ReparkButton
          key={i}
          style={styles.tabButton}
          color={rptheme.colors.primary}
          testID={tab.testID}
          onPress={() => {
            setActive(i);
            tab.onPress();
          }}
          mode={active === i ? 'contained' : 'text'}
        >
          {tab.label}
        </ReparkButton>
      ))}
    </View>
  );
};

const styles = StyleSheet.create({
  tabContainer: {
    flexDirection: 'row',
    justifyContent: 'space-around',
    backgroundColor: 'white',
    borderRadius: rptheme.roundness,
    ...rptheme.shadow,
  },
  tabButton: { flex: 1 },
});
