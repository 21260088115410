import Bugsnag from '@bugsnag/js';
import { feathersClient } from '../../services/feathers';

export const fetchGaragesRequest = async (query?: any) => {
  const response = await feathersClient.service('garages').find({
    query: {
      ...query,
      $sort: {
        street: 1,
      },
      $limit: 10000, // @TODO: Why is this necessary?
    },
  });

  return response.data;
};

export const fetchGarageRequest = async (id: string, query?: any) => {
  const response = await feathersClient.service('garages').get(id, {
    query: {
      ...query,
    },
  });
  return response;
};

export const fetchMyAccesses = async (query?: any) => {
  const response = await feathersClient.service('accesses').find({
    query: {
      ...query,
    },
  });

  return response.data;
};

export const openGarageRequest = async (id: string, bookingId?: string) => {
  try {
    const response = await feathersClient.service('open').create({
      garageId: id,
      bookingId,
    });
    return response;
  } catch (e: any) {
    console.log(e);
    Bugsnag.notify(e);
    return { success: false, code: e.response?.status };
  }
};

export const checkCanOpenGarageRequest = async (
  id: string,
  bookingId?: string
) => {
  try {
    const response = await feathersClient.service('open').create(
      {
        garageId: id,
        bookingId,
      },
      {
        query: {
          action: 'check',
        },
      }
    );

    if (response.canOpen === false) {
      throw Error();
    }

    return response;
  } catch (e: any) {
    console.error(e);
    throw e;
  }
};

export const openDeviceRequest = async (id: string, bookingId?: string) => {
  try {
    const response = await feathersClient.service('open').create({
      id,
      bookingId,
    });
    return response;
  } catch (e: any) {
    console.log(e);
    Bugsnag.notify(e);
    return { success: false, code: e.response?.status };
  }
};

export const fetchMyAccessibleGaragesRequest = async () => {
  try {
    const response = await feathersClient.service('garages/accessible').find();
    return response;
  } catch (e: any) {
    console.error(e);
    Bugsnag.notify(e);
    throw e;
  }
};
