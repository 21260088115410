import axios from 'axios';
// import dayjs from 'dayjs';
import { i18n } from 'i18next';
import { AuthStore } from '../authentication/AuthStore';

type changeLanguageProps = {
  i18n: i18n;
  lang: string;
  saveSettings?: boolean;
};

export const changeLanguage = (props: changeLanguageProps) => {
  const { i18n, lang, saveSettings = false } = props;
  const saveUserSettings = AuthStore.getState().saveUserSettings;

  i18n.changeLanguage(lang, () => {
    axios.defaults.headers.common['Accept-Language'] = lang;
    // dayjs.locale(lang); //TODO: this creates a bug in the calendar view, where a day is shifted to right
    if (saveSettings) {
      console.log('Saving language settings to user...');
      saveUserSettings({ language: lang });
    }
  });
};

/**
 *
 * @param localCode A string, like 'de-DE' or 'de-AT'
 * @returns {string} The language code, like 'de'
 */
export const extractLanguageFromLocalCode = (localCode: string) => {
  const language = localCode.split('-')[0];
  return language;
};
