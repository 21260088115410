import React from 'react';
import { View, StyleSheet } from 'react-native';
import { NavProp } from '../navigation';
import { SubscriptionStore } from './SubscriptionStore';
import { CenteredAppView } from '~/src/components/CenteredAppView';
import { Divider, List, Subheading } from 'react-native-paper';
import rptheme from '~/rptheme';
import {
  calculateTotalSubscriptionPrice,
  groupParkingSpotsByGarage,
  isActiveSubscription,
  isCanceledSubscription,
} from './helpers';
import { ListItem } from '~/src/components/ListItem';
import LoadingState from '~/src/components/LoadingState';
import {
  capitalize,
  envIsDev,
  formatCurrency,
  formatFloor,
} from '~/src/utils/helpers';
import { PayForSubscriptionNote } from './components/PayForSubscriptionNote';
import { NewSubscriptionHeader } from './components/NewSubscriptionHeader';
import { GarageDeviceButtons } from '../garage/GarageDeviceButtons';
import ReparkListItem from '~/src/components/ReparkListItem';
import { SubscriptionsStackScreens } from './SubscriptionStackNavigation';
import { BottomSheetStore } from '../bottom-sheet/BottomSheetStore';
import CancelSubscriptionSheet from './components/CancelSubscriptionSheet';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import { FABActions } from '~/src/utils/FABActions';
import { AuthStore } from '../authentication/AuthStore';
import { SubscriptionType } from './types';
import { GarageType } from '../garage/types';
import { feathersClient } from '~/src/services/feathers';

type SubscriptionDetailScreenProps = {
  navigation: NavProp;
  route: any;
};

export const SubscriptionDetailScreen = (
  props: SubscriptionDetailScreenProps
) => {
  const { route, navigation } = props;
  const { id } = route.params;
  const { isNew } = route.params;

  const [subscription, setSubscription] =
    React.useState<SubscriptionType | null>(null);

  const [fetchSubscription, isFetchingSubscriptions] = SubscriptionStore(
    (store) => [store.fetchSubscription, store.isFetchingSubscriptions]
  );
  const [user] = AuthStore((store) => [store.user]);

  const [openSheet] = BottomSheetStore((store) => [store.openSheet]);
  const { t } = useTranslation();

  React.useEffect(() => {
    const doAsync = async () => {
      const subscription = await fetchSubscription(id);
      setSubscription(subscription);
    };
    doAsync();

    feathersClient.service('subscriptions').on('subscription.updated', doAsync);

    return () => {
      feathersClient
        .service('subscriptions')
        .removeListener('subscription.updated');
    };
  }, [id]);

  const { phoneSupportAction } = FABActions({ navigation, user });

  if (isFetchingSubscriptions) {
    return (
      <CenteredAppView>
        <View style={styles.loadingContainer}>
          <LoadingState />
        </View>
      </CenteredAppView>
    );
  }

  const listItems = [
    {
      showCondition: envIsDev(),
      label: 'ID',
      value: subscription?.id,
    },
    {
      showCondition: true,
      label: t('SubscriptionDetailScreen.labels.status'),
      value: t(`SubscriptionDetailScreen.status.${subscription?.status}`),
    },
    {
      showCondition: true,
      label: t('SubscriptionDetailScreen.labels.start'),
      value: dayjs(subscription?.startDate).format('DD.MM.YYYY'),
    },
    {
      showCondition: subscription?.cancelAt,
      label: t('SubscriptionDetailScreen.labels.cancelAt'),
      value: dayjs(subscription?.cancelAt).format('DD.MM.YYYY'),
    },
    {
      showCondition: subscription?.canceledAt,
      label: t('SubscriptionDetailScreen.labels.canceledAt'),
      value: dayjs(subscription?.canceledAt).format('DD.MM.YYYY HH:mm'),
    },
    {
      showCondition: true,
      label: t('SubscriptionDetailScreen.labels.licencePlate'),
      value: subscription?.licencePlate,
    },
    {
      showCondition: true,
      label: t('SubscriptionDetailScreen.labels.price'),
      value:
        subscription &&
        subscription.prices &&
        formatCurrency(calculateTotalSubscriptionPrice(subscription)),
    },
    {
      showCondition: true,
      label: t('SubscriptionDetailScreen.labels.interval'),
      value:
        subscription &&
        subscription?.prices &&
        capitalize(
          t(`subscription.billed.X${subscription.prices[0].interval}ly`, {
            count: subscription.prices[0].interval_count,
          })
        ),
    },
  ];

  const links = [
    {
      showCondition: true,
      testID: 'downloadInvoicesLink',
      onPress: () => {
        navigation.push(SubscriptionsStackScreens.SubscriptionInvoices, {
          subscriptionId: subscription?.id,
        });
      },
      title: t('SubscriptionDetailScreen.invoicesLink'),
      icon: 'invoice',
      disabled: false,
    },

    {
      showCondition: subscription && isActiveSubscription(subscription),
      testID: 'supportLink',
      onPress: phoneSupportAction.onPress,
      title: t('SubscriptionDetailScreen.phoneSupportLink'),
      icon: phoneSupportAction.icon,
      disabled: false,
    },
    {
      showCondition: subscription && !isCanceledSubscription(subscription),
      testID: 'cancelSubscriptionLink',
      onPress: () => {
        openSheet(
          <CancelSubscriptionSheet id={id} t={t} onCancelSuccess={() => {}} />,
          {
            adjustToContentHeight: true,
          }
        );
      },
      title: t('SubscriptionDetailScreen.cancelSubscription'),
      icon: 'cancel',
      iconColor: 'red',
      disabled: false,
    },
  ];

  const garages =
    subscription?.parkingSpots &&
    groupParkingSpotsByGarage(subscription?.parkingSpots);

  const showDeviceButtons =
    subscription?.status && ['active'].includes(subscription?.status);

  return (
    <CenteredAppView>
      <View style={styles.container}>
        {subscription?.status === 'incomplete' ||
          (subscription?.status === 'past_due' && (
            <PayForSubscriptionNote
              subscription={subscription}
              navigation={navigation}
            />
          ))}

        {isNew && <NewSubscriptionHeader navigation={navigation} />}
        <Subheading style={styles.heading}>
          {t('SubscriptionDetailScreen.detailsHeading')}
        </Subheading>

        {listItems
          .filter((item) => item.showCondition)
          .map((item, index) => {
            return (
              <ListItem
                key={index}
                label={item.label}
                value={item.value || ''}
              />
            );
          })}

        <Subheading style={styles.heading}>
          {t('SubscriptionDetailScreen.garageHeading')}
        </Subheading>
        {garages &&
          garages.map((garage) => {
            const showKeys = [
              {
                label: t('SubscriptionDetailScreen.labels.garageName'),
                key: 'name',
              },
              {
                label: t('SubscriptionDetailScreen.labels.garageAddress'),
                key: 'addressString',
              },
            ];

            return (
              <View key={garage.id}>
                {showKeys.map((showKey) => {
                  const key = showKey.key as keyof GarageType;
                  const value = garage[key];

                  return (
                    <ListItem
                      key={showKey.key}
                      label={showKey.label}
                      value={value}
                    />
                  );
                })}

                {garage.parkingSpots.map((parkingSpot: any) => {
                  const showKeys = [
                    {
                      label: t('SubscriptionDetailScreen.labels.parkingSpot'),
                      key: 'name',
                    },
                  ];
                  return (
                    <View key={parkingSpot.id}>
                      {showKeys.map((showKey) => {
                        return (
                          <ListItem
                            key={showKey.key}
                            label={showKey.label}
                            value={parkingSpot[showKey.key]}
                          />
                        );
                      })}
                      <ListItem
                        key={'floor'}
                        label={t('SubscriptionDetailScreen.labels.floor')}
                        value={formatFloor(parkingSpot.floor)?.toString() || ''}
                      />
                    </View>
                  );
                })}

                {showDeviceButtons && (
                  <GarageDeviceButtons
                    garage={garage}
                    navigation={navigation}
                  />
                )}
              </View>
            );
          })}
      </View>
      <Divider style={{ marginTop: 20, marginBottom: 20 }} />
      <Subheading style={{ marginLeft: 25, color: 'grey' }}>
        {t('SubscriptionDetailScreen.linksHeading')}
      </Subheading>
      <List.Section>
        {links
          .filter((link) => link.showCondition)
          .map((item, key) => {
            return (
              <ReparkListItem
                style={{ paddingVertical: 0 }}
                key={key}
                testID={item.testID}
                onPress={item.onPress}
                title={item.title}
                icon={item.icon}
                iconColor={item.iconColor}
                disabled={item.disabled}
              />
            );
          })}
      </List.Section>
    </CenteredAppView>
  );
};

const styles = StyleSheet.create({
  container: {
    marginHorizontal: 20,
    marginTop: 40,
  },
  loadingContainer: {
    height: '100vh',
  },
  heading: {
    color: rptheme.colors.primary,
    marginTop: 30,
    marginBottom: 10,
  },
});
