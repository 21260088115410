import React from 'react';
import { PaymentStore } from '../PaymentStore';

export const useRemovePaymentIntent = () => {
  const [paymentIntent, removePaymentIntent] = PaymentStore((store) => [
    store.paymentIntent,
    store.removePaymentIntent,
  ]);

  const [isRemoving, setIsRemoving] = React.useState(false);

  async function remove() {
    console.log(`Removing payment intent ${paymentIntent.id}`);
    setIsRemoving(true);
    await removePaymentIntent(paymentIntent.id)
      .then(() => {
        setIsRemoving(false);
      })
      .catch(() => {
        console.error(`Failed to remove payment intent ${paymentIntent.id}`);
        setIsRemoving(false);
      })
      .finally(() => {
        setIsRemoving(false);
        console.log('Payment intent removed');
      });
  }

  return {
    isRemoving,
    remove,
  };
};
