import React from 'react';
import { View, StyleSheet, Text } from 'react-native';
import { Title } from 'react-native-paper';
import { GarageType } from '../../garage/types';
import { SubscriptionStore } from '../SubscriptionStore';
import { PriceListItem } from '../components/PriceListItem';
import { ContinueNextButtons } from '../components/ContinueNextButtons';
import rptheme from '~/rptheme';
import { Trans, useTranslation } from 'react-i18next';

type ChoosePriceStepProps = {
  garage: GarageType;
  onContinue: () => void;
  onBack: () => void;
};

export const ChoosePriceStep = (props: ChoosePriceStepProps) => {
  const { garage, onContinue, onBack } = props;
  const { t } = useTranslation();

  const [selectedParkingSpotId, selectedPriceId, selectPriceId] =
    SubscriptionStore((store) => [
      store.selectedParkingSpotId,
      store.selectedPriceId,
      store.selectPriceId,
    ]);

  const availablePrices = garage?.parkingSpots.find(
    (parkingSpot) => parkingSpot.id === selectedParkingSpotId
  )?.products?.[0]?.prices;

  const sortedPrices = availablePrices?.sort(
    (a: any, b: any) => a.unit_amount - b.unit_amount
  );

  React.useEffect(() => {
    if (!selectedPriceId && sortedPrices?.length) {
      selectPriceId(sortedPrices[0].id);
    }
  }, []);

  return (
    <View>
      <View style={styles.container}>
        <Title>{t('Subscription.steps.choosePrice.title')}</Title>

        {sortedPrices?.map((price: any) => (
          <PriceListItem
            key={price.id}
            price={price}
            recommended={price.interval === 'year'}
            selected={selectedPriceId === price.id}
            onPress={() => selectPriceId(price.id)}
          />
        ))}

        <View style={styles.legalTextContainer}>
          <Text style={styles.legalText}>
            <Trans>
              Du zahlst vorab deine Gebühr für dein Abonnement, sobald deine
              Buchung für den Parkplatz startet. In deinen Einstellungen kannst
              du das Abonnement bis 24 Stunden vor Ende der Laufzeit kündigen.
              Danach wird das Abonnement automatisch um die ausgewählte Dauer
              verlängert. Wenn du dein Abonnement nicht rechtzeitig kündigst,
              wird die Gebühr für die nächste Laufzeit von deiner
              Zahlungsmethode abgebucht.
            </Trans>
          </Text>

          <Text style={styles.legalText}>
            <Trans>
              Bitte beachte, dass nach der automatischen Verlängerung die
              Abonnementgebühr für die neue Laufzeit nicht mehr rückerstattet
              werden kann. Es ist daher wichtig, rechtzeitig zu entscheiden und
              gegebenenfalls zu kündigen, wenn du den Dienst in der nächsten
              Laufzeit nicht mehr nutzen möchtest.
            </Trans>
          </Text>
        </View>

        <ContinueNextButtons
          onBack={onBack}
          onContinue={onContinue}
          nextDisabled={!selectedPriceId}
        />
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: { marginHorizontal: 20 },
  legalTextContainer: { marginHorizontal: 10, marginVertical: 20 },
  legalText: {
    fontSize: 14,
    color: rptheme.colors.gray,
    marginBottom: 10,
  },
});
