import React from 'react';
import { Image, StyleSheet, Text, View } from 'react-native';

type GarageImageProps = {
  garage: any;
  style?: any;
};

export const GarageImage = (props: GarageImageProps) => {
  const { garage, style } = props;

  if (!garage.images?.[0].path)
    return (
      <View style={[style, styles.fallback]}>
        <Text style={styles.fallbackText}>
          No Image
          <br /> provided
        </Text>
      </View>
    );

  return (
    <Image
      testID="garageImage"
      style={[styles.image, style]}
      source={{
        uri: garage.images[0].path,
      }}
    />
  );
};

const styles = StyleSheet.create({
  image: {
    flex: 1,
    width: '100%',
  },
  fallback: {
    backgroundColor: 'lightgrey',
    justifyContent: 'center',
  },
  fallbackText: {
    textAlign: 'center',
    color: 'grey',
  },
});
