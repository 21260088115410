import React from 'react';
import { TouchableOpacity, View, Text, StyleSheet } from 'react-native';
import { Chip, Title } from 'react-native-paper';
import rptheme from '~/rptheme';
import { ReparkRadioButton } from '~/src/components/ReparkRadioButton';
import { capitalize, formatCurrency } from '~/src/utils/helpers';
import { PriceType } from '../types';
import { useTranslation } from 'react-i18next';

type PriceListItemProps = {
  selected: boolean;
  price: PriceType;
  onPress: () => void;
  recommended?: boolean;
};

export const PriceListItem = (props: PriceListItemProps) => {
  const { selected, onPress, price, recommended } = props;
  const { t } = useTranslation();

  const createPriceLabel = (
    interval: 'month' | 'year',
    interval_count: number
  ) => {
    if (interval_count === 1 && interval === 'month') {
      return t('priceLabel.monthly');
    } else if (interval_count === 1 && interval === 'year') {
      return t('priceLabel.yearly');
    } else {
      return capitalize(
        t(`subscription.billed.X${price.interval}ly`, {
          count: price.interval_count,
        })
      );
    }
  };

  const calculateRatePerInterval = (price: PriceType) => {
    if (price.interval === 'month') {
      return price.unit_amount / price.interval_count;
    } else if (price.interval === 'year') {
      return price.unit_amount / (price.interval_count * 12);
    } else {
      return price.unit_amount;
    }
  };

  return (
    <TouchableOpacity
      style={[
        { padding: 15, marginVertical: 5, borderWidth: 1 },
        {
          backgroundColor: 'white',
          borderRadius: rptheme.roundness,
          borderColor: selected ? rptheme.colors.primary : 'lightgrey',
        },
      ]}
      onPress={onPress}
    >
      <View style={styles.titleContainer}>
        <Title style={styles.title}>
          {createPriceLabel(price.interval, price.interval_count)}
        </Title>
        <ReparkRadioButton selected={selected} />
      </View>

      {recommended && (
        <View style={styles.recommendedChipContainer}>
          <Chip style={styles.recommendedChip}>
            {t('subcription.recommendedPrice')}
          </Chip>
        </View>
      )}

      {[
        t('subscription.priceBullet.1'),
        t('subscription.priceBullet.2'),
        t('subscription.priceBullet.3'),
      ].map((item, n) => (
        <BulletListItem
          key={`${n}-${item}`}
          text={item}
          style={{ fontSize: 16, marginBottom: 2 }}
        />
      ))}
      <View style={styles.priceContainer}>
        <Text style={styles.priceText}>
          {formatCurrency(price.unit_amount, { maximumSignificantDigits: 2 })}{' '}
          <Text style={styles.priceTextInterval}>
            {price.interval_count === 1
              ? t(`subscription.billed.${price.interval}ly`)
              : t(`subscription.billed.X${price.interval}ly`, {
                  count: price.interval_count,
                })}{' '}
            (
            {formatCurrency(calculateRatePerInterval(price), {
              maximumSignificantDigits: 3,
            })}{' '}
            {t('subscription.billed.perMonth')})
          </Text>
        </Text>
      </View>
    </TouchableOpacity>
  );
};

export const BulletListItem = (
  props: { text: string } & React.ComponentProps<typeof Text>
) => {
  return <Text {...props}>{`\u2022 ${props.text}`}</Text>;
};

const styles = StyleSheet.create({
  titleContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 5,
  },
  title: {
    color: rptheme.colors.primary,
  },
  priceContainer: {
    marginTop: 15,
  },
  priceText: {
    color: rptheme.colors.primary,
    fontSize: 20,
    fontWeight: 'bold',
  },
  priceTextInterval: {
    color: rptheme.colors.text,
    fontSize: 12,
    fontWeight: 'normal',
  },
  recommendedChipContainer: {
    display: 'flex',
    flexDirection: 'row',
    marginBottom: 10,
  },
  recommendedChip: { backgroundColor: rptheme.colors.secondary },
});
