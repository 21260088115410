import React from 'react';
import { t } from 'i18next';
import { View, Text, StyleSheet, TouchableHighlight } from 'react-native';
import rptheme from '~/rptheme';
import ReparkIconButton from '../ReparkIconButton';
import dayjs, { Dayjs } from 'dayjs';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import { DAY, computeDateTranslations, cetNow } from '~/src/utils/helpers';
import { Divider, Title } from 'react-native-paper';
import {
  selectedAfterAvailable,
  selectedDateTooFarInFuture,
} from '~/src/features/offer/helpers';
require('dayjs/locale/de');

export type CalendarOnlyOptions = {
  lockStartDate: boolean; // mode needs to be range
  availableUntil?: Dayjs;
  previousBooking?: { start: Dayjs; duration: number };
};

export type CalendarOnlyProps = {
  testID?: string;
  onDateSelected: Function;
  selectedDate: Dayjs;
  selectedDuration: number;
  maxBookingDaysInFuture: number;
  mode: 'day' | 'range';
  options?: CalendarOnlyOptions;
};

type FormattedDateObject = {
  date: Dayjs;
  day: number;
  month: number;
  year: number;
  isCurrentMonth: boolean;
  isCurrentDay: boolean;
};

export const CalendarOnly = ({
  selectedDate,
  selectedDuration,
  mode,
  onDateSelected,
  maxBookingDaysInFuture,
  options,
}: CalendarOnlyProps) => {
  dayjs.extend(isSameOrAfter);
  const [range, setRange] = React.useState<{ start: Dayjs; duration: number }>({
    start: selectedDate,
    duration: selectedDuration,
  });

  let lockStartDate = false;
  const previousBooking = options?.previousBooking;
  const availableUntil = options?.availableUntil;

  if (options) {
    lockStartDate = options.lockStartDate;
  }
  React.useEffect(() => {
    if (mode === 'range') {
      setRange({ start: selectedDate, duration: selectedDuration });
    }
  }, [selectedDate, selectedDuration]);

  const [currentMonth, setCurrentMonth] = React.useState(
    selectedDate.locale('de')
  );
  const [arrayOfDays, setArrayOfDays] = React.useState<Array<any>>([]);

  const nextMonth = () => {
    setCurrentMonth(currentMonth.add(1, 'month'));
  };

  const prevMonth = () => {
    setCurrentMonth(currentMonth.subtract(1, 'month'));
  };

  const { translatedWeekdays } = computeDateTranslations(t);

  const getBorderProps = (date: FormattedDateObject) => {
    const isCurrentDay = date.isCurrentDay;
    const isSelectedDate =
      range.start.isSame(dayjs(date.date)) ||
      range.start.isSame(dayjs(date.date));
    const isWithinRange = date.date.isBetween(
      dayjs(range.start),
      dayjs(range.start).add(range.duration, 'minutes')
    );

    let isWithinPreviousBooking = false;
    if (previousBooking) {
      isWithinPreviousBooking =
        date.date.isBetween(
          previousBooking.start,
          previousBooking.start.add(previousBooking.duration, 'minutes')
        ) ||
        date.date.isSame(previousBooking.start) ||
        date.date.isBetween(
          previousBooking.start,
          previousBooking.start.add(previousBooking.duration, 'minutes')
        ) ||
        date.date.isSame(previousBooking.start);
    }

    const borderWidth = isCurrentDay ? 1 : undefined;
    const borderColor = isCurrentDay ? rptheme.colors.primary : undefined;
    const backgroundColor = isWithinPreviousBooking
      ? rptheme.colors.accentFade
      : isSelectedDate || isWithinRange
      ? rptheme.colors.primary
      : undefined;

    return {
      borderWidth,
      borderColor,
      backgroundColor,
    };
  };

  const formateDateObject = (date: Dayjs): FormattedDateObject => {
    const clonedObject = { ...date.toObject() };
    const formatedObject = {
      date: date,
      day: clonedObject.date,
      month: clonedObject.months,
      year: clonedObject.years,
      isCurrentMonth: clonedObject.months === currentMonth.month(),
      isCurrentDay: date.isSame(cetNow(), 'day'),
    };

    return formatedObject;
  };

  const getAllDays = () => {
    let currentDate = currentMonth.startOf('month').weekday(0);
    const nextMonth = currentMonth.add(1, 'month').month();
    const allDates = [];
    let weekDates = [];
    let weekCounter = 1;
    while (currentDate.weekday(0).toObject().months !== nextMonth) {
      const formated = formateDateObject(currentDate);
      weekDates.push(formated);
      if (weekCounter === 7) {
        allDates.push({ dates: weekDates });
        weekDates = [];
        weekCounter = 0;
      }
      weekCounter++;
      currentDate = currentDate.add(1, 'day');
    }
    setArrayOfDays(allDates);
  };

  const selectDate = (date: Dayjs) => {
    if (dayjs(date).isBefore(cetNow().startOf('day'))) return;
    if (lockStartDate && dayjs(date).isBefore(range.start.startOf('day')))
      return;
    if (availableUntil && dayjs(date).isAfter(availableUntil.startOf('day')))
      return;

    if (mode === 'day') {
      setRange({ start: date, duration: 1440 });
      onDateSelected(date);
    } else {
      if (lockStartDate) {
        const duration =
          date.endOf('day').diff(range.start.subtract(1, 'day'), 'day') * DAY;
        setRange({ ...range, duration });
        onDateSelected(date, duration);
        return;
      }

      const duration =
        date.endOf('day').diff(range.start.subtract(1, 'day'), 'day') * 1440;
      const setStart = () => {
        setRange({ start: date, duration: 1440 });
        setTimeout(() => onDateSelected(date, 1440), 0);
      };

      if (range.start.isSame(date)) {
        setRange({ start: date, duration });
        setTimeout(() => onDateSelected(date, duration), 0);
      } else if (
        date.isBetween(range.start, range.start.add(range.duration, 'minutes'))
      ) {
        setStart();
      } else if (date.isAfter(range.start)) {
        setRange({ start: range.start, duration });
        setTimeout(() => onDateSelected(range.start, duration), 0);
      } else if (date.isBefore(range.start)) {
        setStart();
      }
    }
  };

  React.useEffect(() => {
    getAllDays();
  }, [currentMonth]);

  const dayTextColor = (date: FormattedDateObject) => {
    const isPastDate =
      !date.isCurrentMonth ||
      dayjs(date.date).isBefore(cetNow().startOf('day'));
    const isSelectedDate =
      range.start.isSame(dayjs(date.date)) ||
      range.start.isSame(dayjs(date.date));
    const isWithinRange =
      date.date.isBetween(
        dayjs(range.start),
        dayjs(range.start).add(range.duration, 'minutes')
      ) ||
      date.date.isBetween(
        dayjs(range.start),
        dayjs(range.start).add(range.duration, 'minutes')
      );

    const isTooFarInFuture = selectedDateTooFarInFuture(
      date.date,
      maxBookingDaysInFuture
    );

    const isNotAvailable = selectedAfterAvailable(date.date, availableUntil);

    const isDisabled =
      (isPastDate || isTooFarInFuture || isNotAvailable) &&
      !(isSelectedDate || isWithinRange);
    if (isDisabled) {
      return 'grey';
    } else if (isSelectedDate || isWithinRange) {
      return 'white';
    } else {
      return undefined;
    }
  };

  const isDisabled = (date: FormattedDateObject) => {
    const isPastDate = dayjs(date.date).isBefore(cetNow().startOf('day'));

    const isTooFarInFuture = selectedDateTooFarInFuture(
      date.date,
      maxBookingDaysInFuture
    );

    const isNotAvailable = selectedAfterAvailable(date.date, availableUntil);

    return isPastDate || isTooFarInFuture || isNotAvailable;
  };

  return (
    <>
      <View style={styles.header}>
        <ReparkIconButton
          testID="prevMonthButton"
          size={20}
          onPress={prevMonth}
          icon={'chevron-left'}
        ></ReparkIconButton>
        <View style={{ alignItems: 'center' }}>
          <Title testID="currentMonthTitle">
            {currentMonth.format('MMMM')}
          </Title>
          <Text testID="currentYear">{currentMonth.year()}</Text>
        </View>
        <ReparkIconButton
          testID="nextMonthButton"
          size={20}
          onPress={nextMonth}
          icon={'chevron-right'}
        ></ReparkIconButton>
      </View>

      <View style={styles.weekdaysRow}>
        {translatedWeekdays.map((day, i) => (
          <View key={i}>
            <Text>{day}</Text>
          </View>
        ))}
      </View>
      <Divider style={styles.divider} />

      <View style={styles.dates}>
        {arrayOfDays.map((week, i) => (
          <View style={styles.daysRow} key={i}>
            {week.dates.map((date: any) => (
              <TouchableHighlight
                key={date.date}
                testID={`date-${date.day}-${date.month}-${date.year}`}
                underlayColor={`${rptheme.colors.accent}20`}
                style={[styles.dayContainer, getBorderProps(date)]}
                disabled={isDisabled(date)}
                onPress={() => {
                  selectDate(date.date);
                }}
              >
                <Text
                  style={[
                    styles.dayText,
                    {
                      color: dayTextColor(date),
                    },
                  ]}
                >
                  {date.day}
                </Text>
              </TouchableHighlight>
            ))}
          </View>
        ))}
      </View>
    </>
  );
};

const styles = StyleSheet.create({
  header: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginVertical: 15,
  },
  weekdaysRow: { flexDirection: 'row', justifyContent: 'space-around' },
  divider: { marginVertical: 10 },
  dates: { minHeight: 200 },
  daysRow: { flexDirection: 'row' },
  dayContainer: {
    margin: 5,
    flex: 1,
    borderRadius: 200,
    height: 35,
    width: 35,
    justifyContent: 'center',
  },
  dayText: {
    textAlign: 'center',
  },
});
