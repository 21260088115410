import React from 'react';
import { Dayjs } from 'dayjs';
import ReparkButton from '../ReparkButton';
import { formatDate, cetNow } from '~/src/utils/helpers';
import { useTranslation } from 'react-i18next';

type QuickActionProps = {
  onDateSelected: (date: Dayjs) => void;
};

export const QuickActions = ({ onDateSelected }: QuickActionProps) => {
  const { t } = useTranslation();

  const actions = [
    {
      date: cetNow().startOf('day'),
    },
    {
      date: cetNow().startOf('day').add(1, 'day'),
    },
    {
      date: cetNow().startOf('day').add(2, 'day'),
    },
  ];

  return (
    <>
      {actions.map((action, index) => (
        <ReparkButton
          style={{ margin: 5 }}
          testID={`quickAction-${index}`}
          key={index}
          onPress={() => {
            onDateSelected(action.date);
          }}
        >
          {formatDate(action.date, t)}
        </ReparkButton>
      ))}
    </>
  );
};
