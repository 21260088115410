import React from 'react';
import { Trans } from 'react-i18next';
import { Image, Text, View, StyleSheet } from 'react-native';
import rptheme from '~/rptheme';

export const ProcessingReparkLogo = () => {
  return (
    <>
      <View style={styles.container}>
        <View style={styles.logo}>
          <Trans i18nKey={'ProcessingReparkLogo'}>
            <Text style={styles.text}>Danke, dass du</Text>
            <Image
              style={styles.image}
              source={require('~/assets/images/repark-logo.png')}
            />
            <Text style={styles.text}>benützt!</Text>
          </Trans>
        </View>
      </View>
    </>
  );
};

const styles = StyleSheet.create({
  container: {
    marginVertical: 40,
  },
  logo: {
    alignSelf: 'center',
    textAlign: 'center',
  },
  image: {
    resizeMode: 'contain',
    height: 50,
  },
  text: {
    alignSelf: 'center',
    textAlign: 'center',
    fontSize: rptheme.fontSizeMedium,
    fontFamily: rptheme.fonts.regular.fontFamily,
    fontWeight: rptheme.fonts.regular.fontWeight,
  },
});
