import React from 'react';
import { Dimensions, StyleSheet, View, TouchableOpacity } from 'react-native';
import { Title, Surface } from 'react-native-paper';
import rptheme from '~/rptheme';
import { ReparkIcon } from '~/src/components/ReparkIcon';
import analytics from '../analytics/analytics';
import { SnackbarStore } from '../snackbar/SnackbarStore';
import { GarageStore } from './GarageStore';
import { useOnlineCheck } from '~/src/hooks/useOnlineCheck';
import { useCheckCanOpen } from './useCheckCanOpen';
import { isActiveBooking } from '../booking/helpers';
import BottomSheetFrame from '../bottom-sheet/BottomSheetFrame';
import dayjs from 'dayjs';
import { BottomSheetStore } from '../bottom-sheet/BottomSheetStore';

export const SLIDER_WIDTH = Dimensions.get('window').width + 80;
export const ITEM_WIDTH = Math.round(SLIDER_WIDTH * 0.7);

interface OpenGarageButtonProps {
  id: string;
  bookingId?: string;
  openSuccess: Function;
  openError?: Function;
  t: Function;
  booking?: any;
}

export default function OpenGarageButton(props: OpenGarageButtonProps) {
  const { id, t, booking } = props;

  const { isOnline } = useOnlineCheck();

  const [getGarage, openGarage] = GarageStore((store) => [
    store.getGarage,
    store.openGarage,
  ]);

  const [fireSnack] = SnackbarStore((store) => [store.fireSnack]);

  const [isLoading, setIsLoading] = React.useState(false);
  const { isCheckingCanOpen, canOpen } = useCheckCanOpen(id);
  const [openSheet] = BottomSheetStore((store) => [store.openSheet]);

  if (!id || !getGarage(id)) return <></>;

  const onOpenPress = async () => {
    if (!isOnline()) {
      fireSnack({
        title: t('General.wentoffline'),
        type: 'error',
        icon: 'wifi-off',
      });
      return;
    }

    if (booking && !isActiveBooking(booking, getGarage(id).gracePeriodPost)) {
      openSheet(
        <BottomSheetFrame
          title={t('BookingDetailScreen.cannotOpenGarageYetTitle')}
          text={t('BookingDetailScreen.cannotOpenGarageYetMessage', {
            openDate: dayjs(booking.start).format('DD.MM.'),
            openTime: dayjs(booking.start).format('HH:mm'),
          })}
        />,
        { adjustToContentHeight: true }
      );

      return;
    }

    setIsLoading(true);
    try {
      console.log(`Opening Garage ${getGarage(id).addressString}`);
      const response = await openGarage(id, booking);
      if (response.success) {
        analytics.track('garage_open', { garageId: id });
        props.openSuccess(response);
      } else {
        props.openError?.(response);
      }
    } catch (e: any) {
      console.log(`Could not open Garage ${getGarage(id).addressString}`);
      throw e;
    } finally {
      setTimeout(() => setIsLoading(false), 500); //Why? To prevent the button from flashing, if the call returns very quick.
    }
  };

  const disabledButton = isLoading || isCheckingCanOpen;
  const greyButton = !canOpen || disabledButton;

  return (
    <View>
      <TouchableOpacity
        disabled={disabledButton}
        onLongPress={onOpenPress}
        onPress={onOpenPress}
        testID={'openGarageButton'}
      >
        <Surface
          style={[
            styles.fab,
            {
              backgroundColor: greyButton ? 'grey' : rptheme.colors.primary,
            },
          ]}
        >
          <ReparkIcon name="garage" size={40} color={'white'}></ReparkIcon>
        </Surface>

        <View style={styles.labelContainer}>
          {isCheckingCanOpen ? (
            <Title
              style={[
                styles.label,
                { color: greyButton ? 'grey' : rptheme.colors.primary },
              ]}
            >
              {t('OpenGarageButton.checkingCanOpen')}
            </Title>
          ) : (
            <Title
              style={[
                styles.label,
                { color: greyButton ? 'grey' : rptheme.colors.primary },
              ]}
            >
              {isLoading
                ? t('MyGaragesScreen.openActionLoading')
                : t('MyGaragesScreen.openAction')}
            </Title>
          )}
        </View>
      </TouchableOpacity>
    </View>
  );
}

const styles = StyleSheet.create({
  fab: {
    backgroundColor: rptheme.colors.primary,
    alignSelf: 'center',
    alignItems: 'center',
    justifyContent: 'center',
    width: 80,
    height: 80,
    borderRadius: 100,
    elevation: 8,
    padding: 0,
    marginBottom: 10,
  },
  labelContainer: { flexDirection: 'row', alignItems: 'center' },
  label: {
    alignSelf: 'center',
    textAlign: 'center',
    justifyContent: 'flex-start',
    color: rptheme.colors.primary,
    fontSize: 16,
  },
});
