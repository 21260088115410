import React from 'react';
import { View, Dimensions, StyleSheet } from 'react-native';
import rptheme from '~/rptheme';

type FABFrameProps = {
  topLeft?: JSX.Element;
  topCenter?: JSX.Element;
  topRight?: JSX.Element;
  bottomLeft?: JSX.Element;
  bottomCenter?: JSX.Element;
  bottomRight?: JSX.Element;
  children?: Array<JSX.Element> | JSX.Element;
};

export default function FABFrame(props: FABFrameProps) {
  const { children } = props;

  return (
    <View style={{ height: Dimensions.get('window').height }}>
      {children}
      {Object.keys(props).map((prop: string, index: number) => {
        if (prop === 'children') return;
        return (
          <View key={index} style={styles[prop]}>
            {props[prop as keyof typeof props]}
          </View>
        );
      })}
    </View>
  );
}

const alignItems = 'center';
const position = 'absolute';
const alignSelf = 'center';
const maxWidth = Dimensions.get('screen').width * 0.5;
const right = rptheme.fab.horizontalMargin;
const left = rptheme.fab.horizontalMargin;
const top = rptheme.fab.verticalMargin;
const bottom = rptheme.fab.bottomMargin;

const styles: any = StyleSheet.create({
  topLeft: {
    position,
    left,
    top,
  },
  topCenter: {
    alignItems,
    position,
    alignSelf,
    top,
    maxWidth,
  },
  topRight: {
    position,
    right,
    top,
  },
  bottomLeft: {
    position,
    left,
    bottom,
  },
  bottomCenter: {
    alignItems,
    position,
    alignSelf,
    bottom,
    maxWidth,
  },
  bottomRight: {
    position,
    right,
    bottom,
  },
});
