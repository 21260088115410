import React from 'react';
import { View, StyleSheet } from 'react-native';

import { useTranslation } from 'react-i18next';
import { Paragraph, Title } from 'react-native-paper';
import ReparkButton from '~/src/components/ReparkButton';
import rptheme from '~/rptheme';
import { RootScreens } from '../navigation';

export default function NotFoundScreen({ navigation }: any) {
  const { t } = useTranslation();

  return (
    <View style={styles.container}>
      <View style={styles.innerContainer}>
        <Title>{t('NotFoundScreen.screenTitle')}</Title>
        <Paragraph>{t('NotFoundScreen.intro')}</Paragraph>
        <ReparkButton
          onPress={() => {
            navigation.navigate(RootScreens.MapScreen);
          }}
        >
          {t('NotFoundScreen.homeButton')}
        </ReparkButton>
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'flex-start',
    alignItems: 'center',
    width: '100%',
  },
  innerContainer: {
    maxWidth: rptheme.screenSmall,
    padding: 25,
  },
});
