import { t } from 'i18next';
import React from 'react';
import { View, StyleSheet, Dimensions, Text } from 'react-native';
import { Subheading } from 'react-native-paper';
import { DatePicker } from '~/src/components/DatePicker';
import { styles as offerBottomStyles } from '../OfferBottom';
import { GarageStore } from '../../garage/GarageStore';
import { NavProp } from '../../navigation';
import ReparkButton from '~/src/components/ReparkButton';
import { BottomSheetStore } from '../../bottom-sheet/BottomSheetStore';
import rptheme from '~/rptheme';

export enum notAvailableType {
  fullyBooked = 'fullyBooked',
  comingSoon = 'comingSoon',
  tooFarInFuture = 'tooFarInFuture',
}

type OfferBottomNotAvailableProps = {
  type?: keyof typeof notAvailableType;
  navigation?: NavProp;
};

export const OfferBottomNotAvailable = (
  props: OfferBottomNotAvailableProps
) => {
  const { type } = props;
  const maxBookingDaysInFuture = GarageStore(
    (store) => store.maxBookingDaysInFuture
  );

  const closeSheet = BottomSheetStore((store) => store.closeSheet);

  let text;
  switch (type) {
    case notAvailableType.fullyBooked:
      text = t('Offer.offerNotAvailable');
      break;
    case notAvailableType.comingSoon:
      text = t('Offer.comingSoonText');
      break;
    case notAvailableType.tooFarInFuture:
      text = t('Offer.tooFarInFutureText', { days: maxBookingDaysInFuture });
      break;
    default:
      text = t('Offer.offerNotAvailable');
  }

  return (
    <View testID={'notAvailableView'} style={offerBottomStyles.container}>
      <View style={styles.buttonContainer}>
        {type !== notAvailableType.comingSoon && (
          <DatePicker
            humanReadable={false}
            mode="outlined"
            offerBottom={true}
          />
        )}
        <View style={{ flex: 1 }}>
          <View style={styles.textContainer}>
            <Subheading style={styles.subtitle}>{text}</Subheading>
          </View>
          {type === notAvailableType.comingSoon && (
            <ReparkButton
              mode="outlined"
              color={rptheme.colors.primary}
              onPress={() => {
                closeSheet();
                props.navigation?.navigate('ProfileStack', {
                  screen: 'PersonalDataScreen',
                });
              }}
            >
              <Text>{t('General.goToMyProfile')}</Text>
            </ReparkButton>
          )}
        </View>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  buttonContainer: { display: 'flex', flexDirection: 'row' },
  textContainer: {
    alignItems: 'center',
    alignSelf: 'center',
    marginVertical: 20,
    marginBottom: 20,
  },
  subtitle: {
    textAlign: 'center',
    maxWidth: Dimensions.get('window').width,
  },
});
