import React from 'react';

export type ReparkCheckBoxStates = { [key: string]: boolean };
export const useCheckboxGroup = (initalValues: ReparkCheckBoxStates) => {
  const [checkBoxStates, setCheckBoxStates] = React.useState(initalValues);
  const [someChecked, setSomeChecked] = React.useState(false);

  const setAll = (states: ReparkCheckBoxStates) => {
    setCheckBoxStates(states);
  };

  const set = (key: string, value: boolean) => {
    if (key in checkBoxStates) {
      setCheckBoxStates({ ...checkBoxStates, [key]: value });
    }
  };
  const get = (key: string): boolean | undefined => {
    if (key in checkBoxStates) {
      return checkBoxStates[key];
    } else {
      return undefined;
    }
  };
  const toggle = (key: string) => {
    const state = get(key);
    if (state !== undefined) {
      set(key, !state);
    }
  };

  const getTrueKeys = () => {
    return Object.entries(checkBoxStates)
      .filter((item) => item[1])
      .map((item) => item[0]);
  };

  const isSomeChecked = (data?: { [key: string]: boolean }) => {
    if (data) {
      setSomeChecked(Object.values(data).some((field) => field));
    } else {
      setSomeChecked(Object.values(checkBoxStates).some((field) => field));
    }
  };

  React.useEffect(() => {
    isSomeChecked();
  }, [checkBoxStates]);

  return {
    set,
    get,
    toggle,
    checkBoxStates,
    getTrueKeys,
    setAll,
    someChecked,
    isSomeChecked,
  };
};
