import create from 'zustand';
import storage from '~/config/storage';

import {
  logout,
  authenticateLocal,
  reauthenticate,
  validateOTCRequest,
  patchUserRequest,
  sendDeleteAccountOtcRequest,
  deleteAccountRequest,
  checkUserExistence,
  resendEmailVerificationRequest,
} from '~/src/features/authentication/services/authService';
import * as authFirebase from '~/src/features/authentication/services/authFirebase';

import axios from 'axios';
import { PhoneAuthProvider } from 'firebase/auth';
import * as firebaseAuth from 'firebase/auth';

import analytics from '~/src/features/analytics/analytics';
import ability from '~/src/services/ability';
import { feathersClient } from '~/src/services/feathers';
import {
  EditProfileType,
  ValidateOTCRequestType,
  ValidateOTCResponseType,
  SignInValuesType,
  UserSettingsType,
  User,
} from './authTypes';
import { BookingStore } from '../booking/BookingStore';
import { PaymentStore } from '../payment/PaymentStore';
import { VehicleStore } from '../vehicles/VehicleStore';
import Bugsnag from '@bugsnag/js';

type AuthStore = {
  loggedIn: boolean;
  isRestoringAuthState: boolean;
  user: User | any;
  settings: UserSettingsType | null;
  redirectAfterLogin: string | null;
  resetStore: () => void;
  startSignInWithPhone: (props: {
    phoneNumber: string;
    applicationVerifier: any;
  }) => Promise<{
    success: boolean;
    verificationId?: string;
    firstName?: string;
    newUser?: boolean;
    errorCode?: string;
  }>;
  signIn: (
    data: SignInValuesType,
    mode?: 'local' | 'firebaseEmailPassword'
  ) => Promise<any>;
  signOut: () => Promise<boolean>;
  restoreAuthState: () => Promise<void>;
  editUser: (editedProfileData: EditProfileType) => any;
  saveUserSettings: (newSettings: UserSettingsType) => any;
  sendDeleteAccountOtc: (userId: string) => any;
  canDeleteAccount: () => boolean;
  deleteAccount: (userId: string, otc: string, phoneNumber: string) => any;
  validateOTC: (
    validateOTCData: ValidateOTCRequestType
  ) => Promise<ValidateOTCResponseType>;
  addConsent: (consent: { type: ConsentType; version: string }) => void;
  addEventlisteners: () => void;
  removeEventlisteners: () => void;
  resendEmailVerification: () => Promise<void>;

  setRedirectAfterLogin: (id: string) => void;
};

enum ConsentType {
  privacy = 'privacy',
  terms = 'terms',
  newsletter = 'newsletter',
  update = 'update',
}

export const AuthStore = create<AuthStore>((set, get) => ({
  loggedIn: false,
  isRestoringAuthState: false,
  user: {},
  redirectAfterLogin: null,
  settings: null,

  resetStore: () => {
    set({
      loggedIn: false,
      user: {},
    });
  },

  signIn: async (data, mode) => {
    console.log(`Signing in user ${data.email}`);
    let response: any;

    try {
      if (mode === 'local') {
        console.log('Using strategy local');
        response = await authenticateLocal(data);
      } else if (mode == 'firebaseEmailPassword') {
        console.log('Using strategy firebaseEmailPassword');
        if (!data.email || !data.password) {
          throw new Error('Please provie email and password');
        }

        const signInData = {
          email: data.email,
          password: data.password,
        };

        response = await authFirebase.authenticateEmailPassword(signInData);
      } else if (data.verificationId && data.otc) {
        console.log('Using strategy firebasePhone');

        response = await authFirebase.authenticatePhone({
          verificationId: data.verificationId,
          code: data.otc,
        });
      } else {
        throw new Error(
          'Provide either password, otc/userId or verificationId/code'
        );
      }

      const authState = {
        accessToken: response.accessToken,
        refreshToken: response.refreshToken,
        idToken: response.idToken,
      };

      if (!response.user.settings.language) {
        response.user.settings.language = 'de';
      }

      set({
        loggedIn: true,
        user: response.user,
        settings: response.user.settings,
      });

      ability.update(response.rules);

      // Set Authorization Header for Axios Requests.
      axios.defaults.headers.common['Authorization'] = response.accessToken;

      // Track Login in Analytics
      analytics.track('login');

      await storage().setItem('authState', JSON.stringify(authState));

      if (data.consents) {
        for (const consent of Object.keys(data.consents)) {
          const type = consent as ConsentType;
          if (!data.consents[consent]) {
            continue;
          }
          get().addConsent({
            type,
            version: '1.0.0', // TODO: should not be hardcoded, current version should possibly come from the backend via 'handshake'
          });
        }
      }

      return { success: true, user: response.user };
    } catch (e: any) {
      Bugsnag.notify(e);
      return { success: false, message: e.message, error: e };
    }
  },

  signOut: async () => {
    console.log('logout called');

    const logoutSuccess = await logout();
    if (logoutSuccess) {
      get().resetStore();

      PaymentStore.getState().resetStore();
      BookingStore.getState().resetStore();
      VehicleStore.getState().resetStore();

      await storage().removeItem('authState');
      delete axios.defaults.headers.common['Authorization'];

      // Track Sign Out in Analytics
      analytics.track('sign_out');

      return true;
    } else {
      return false;
    }
  },

  restoreAuthState: async () => {
    set({ isRestoringAuthState: true });
    const storedAuthState = await storage().getItem('authState');

    if (storedAuthState) {
      const authState = JSON.parse(storedAuthState);
      console.log('Restoring auth state');
      try {
        const newAuthState = await reauthenticate(authState);
        if (newAuthState?.accessToken) {
          if (!newAuthState.user.settings.language) {
            newAuthState.user.settings.language = 'de';
          }

          set({
            loggedIn: true,
            user: newAuthState.user,
            settings: newAuthState.user.settings,
          });

          ability.update(newAuthState.rules);

          await storage().setItem(
            'authState',
            JSON.stringify({
              refreshToken: newAuthState.refreshToken,
              idToken: newAuthState.idToken,
              accessToken: newAuthState.refreshToken,
            })
          );

          // Set Authorization Header for Axios Requests.
          axios.defaults.headers.common['Authorization'] =
            newAuthState?.accessToken;
        }
      } catch (e: any) {
        Bugsnag.notify(e);
        console.log(`Could not reauthenticate with server: ${e.message}`);
      }
    } else {
      console.log('No stored auth state found');
    }
    console.log('Reauthentication done');
    set({ isRestoringAuthState: false });
  },

  editUser: async (editedProfileData: EditProfileType) => {
    try {
      const user = get().user;
      const responsePatchedUser: any = await patchUserRequest(user?.id, {
        ...user,
        ...editedProfileData,
      });
      set({
        user: responsePatchedUser.data,
        settings: responsePatchedUser.data.settings,
      });

      return { success: true };
    } catch (e: any) {
      Bugsnag.notify(e);
      return {
        success: false,
        errorCode: e.response?.status,
        message: e.response?.data.name,
      };
    }
  },

  saveUserSettings: async (newSettings: UserSettingsType) => {
    const currentSettings = get().user?.settings;
    const settings: UserSettingsType = { ...currentSettings, ...newSettings };
    return get().editUser({ settings });
  },

  sendDeleteAccountOtc: async (userId: string) => {
    try {
      const response: any = await sendDeleteAccountOtcRequest(userId);
      return response;
    } catch (e: any) {
      Bugsnag.notify(e);
      console.log(e);
      return {
        success: false,
        errorCode: e.response?.status,
      };
    }
  },

  canDeleteAccount: () => {
    const upcomingBookings = BookingStore.getState().upcomingBookings({
      excludeCanceled: true,
    });

    if (upcomingBookings.length) return false;

    return true;
  },

  deleteAccount: async (userId: string, otc: string, phoneNumber: string) => {
    try {
      const response: any = await deleteAccountRequest(
        userId,
        otc,
        phoneNumber
      );
      return response;
    } catch (e: any) {
      Bugsnag.notify(e);
      console.log(e);
      return {
        success: false,
        errorCode: e.response?.status,
      };
    }
  },

  validateOTC: async (
    validateOTCData: ValidateOTCRequestType
  ): Promise<ValidateOTCResponseType> => {
    try {
      const response: any = await validateOTCRequest(validateOTCData);
      return {
        success: true,
        userId: response.data.userId,
      };
    } catch (e: any) {
      Bugsnag.notify(e);
      return {
        success: false,
        ...e.response?.data,
      };
    }
  },

  startSignInWithPhone: async (props) => {
    const { phoneNumber, applicationVerifier } = props;
    console.log('Starting Sign In With Phone');

    let firstName;
    let newUser = true;

    try {
      // First check if user exists in our database
      const userExists = await checkUserExistence(phoneNumber);

      if (userExists.success) {
        firstName = userExists.firstName;
        newUser = false;
      }

      // Then call Firebase.
      const phoneProvider = new PhoneAuthProvider(firebaseAuth.getAuth());
      const verificationId = await phoneProvider.verifyPhoneNumber(
        phoneNumber,
        applicationVerifier
      );
      return { success: true, verificationId, firstName, newUser };
    } catch (e: any) {
      Bugsnag.notify(e);
      console.log('Sign In With Phone failed', e);
      return { success: false, errorCode: e.code };
    }
  },

  addConsent: async (consent) => {
    const { type, version } = consent;
    try {
      await feathersClient.service('consents').create({
        userId: get().user?.id,
        type,
        version,
      });
    } catch (e) {
      console.log(e);
    }
  },

  resendEmailVerification: async () => {
    try {
      const user = get().user;
      await resendEmailVerificationRequest(user?.id);
    } catch (e: any) {
      Bugsnag.notify(e);
      throw e;
    }
  },

  addEventlisteners: () => {
    console.log('Adding User Eventlisteners');

    feathersClient.service('users').on('patched', (patchedUser: any) => {
      if (patchedUser.id === get().user?.id) {
        set({ user: patchedUser });
      }
    });
  },

  removeEventlisteners: () => {
    console.log('Removing User Eventlisteners');
    feathersClient.service('users').removeAllListeners('patched');
  },

  setRedirectAfterLogin: (redirectAfterLogin: string) => {
    console.log('Setting redirect After login: ', redirectAfterLogin);
    set({ redirectAfterLogin });
  },
}));
