import * as React from 'react';
import { StyleSheet, View } from 'react-native';
import { Avatar } from 'react-native-paper';
import { User } from '../authentication/authTypes';

interface UserAvatarProps {
  user: User;
  size?: number;
  testID?: string;
}

export default function UserAvatar({ user, size, testID }: UserAvatarProps) {
  const avatarSize = size || 80;

  const avatarUrl = user?.avatar?.includes('gravatar')
    ? `${user?.avatar}?s=${avatarSize}`
    : user?.avatar;

  let avatar;
  if (user?.avatar) {
    avatar = (
      <Avatar.Image
        source={{
          uri: avatarUrl,
        }}
        size={avatarSize}
        style={styles.avatar}
      />
    );
  } else if (user?.firstName && user?.lastName) {
    const initials = `${user?.firstName?.[0]}${user?.lastName?.[0]}`;
    avatar = (
      <Avatar.Text label={initials} size={avatarSize} style={styles.avatar} />
    );
  } else {
    avatar = (
      <Avatar.Icon
        icon="account-rounded"
        size={avatarSize}
        style={styles.avatar}
      />
    );
  }

  return (
    <View style={styles.avatarContainer} testID={testID}>
      {avatar}
    </View>
  );
}

const styles = StyleSheet.create({
  avatarContainer: {
    alignItems: 'center',
  },
  avatar: {
    elevation: 12,
    shadowColor: 'black',
    shadowOpacity: 0.2,
    shadowRadius: 6,
    shadowOffset: { width: 0, height: 5 },
  },
});
