import React from 'react';
import { StyleSheet, View } from 'react-native';
import ReparkButton from '~/src/components/ReparkButton';
import { GarageTitle } from '../../garage/GarageTitle';
import { PricePer } from '../../offer/PricePer';
import { FeatureSwiper } from '~/src/components/FeatureSwiper';
import { useTranslation } from 'react-i18next';
import rptheme from '~/rptheme';
import { Chip } from 'react-native-paper';
import { formatDimensions } from '~/src/utils/helpers';
import { ImageSwiper } from '~/src/components/ImageSwiper';
import { GarageDescription } from '../../garage/GarageDescription';
import { NavProp } from '../../navigation';

type GarageInfoStepProps = {
  onContinue: () => void;
  garage: any;
  navigation: NavProp;
};

export const GarageInfoStep = (props: GarageInfoStepProps) => {
  const { onContinue, garage } = props;
  const { t } = useTranslation();

  const hasProducts = garage?.parkingSpots?.[0]?.products?.length > 0;

  // const handleInspectGaragePress = () => {
  //   navigation.navigate(SubscriptionsStackScreens.InspectionScreen, {
  //     garageId: garage.id,
  //   });
  // };

  const createFromPrice = () => {
    if (!hasProducts) return null;
    return garage.parkingSpots?.[0]?.products?.[0].prices.find(
      (price: any) => price.interval === 'month' && price.interval_count === 1
    );
  };

  return (
    <View>
      <View style={styles.heading}>
        <GarageTitle garage={garage} align={'flex-start'} withSubway={false} />
        {hasProducts && (
          <PricePer
            price={createFromPrice().unit_amount}
            interval="month"
            prefix={t('garage.from')}
          />
        )}
      </View>
      <View style={styles.featureSwiperContainer}>
        <FeatureSwiper features={computeGarageFeatures(garage)} />
      </View>
      <ImageSwiper images={garage.images} />

      <View style={styles.descriptionContainer}>
        <GarageDescription garage={garage} />
      </View>

      <View
        style={{
          display: 'flex',
          flexDirection: 'row',
          margin: 15,
        }}
      >
        {/* <ReparkButton
          mode="outlined"
          style={{
            display: 'flex',
            flexDirection: 'row',
            margin: 15,
          }}
        >
          Garage besichtigen
        </ReparkButton> */}
        <ReparkButton style={{ flex: 1, marginLeft: 2 }} onPress={onContinue}>
          {t('Subscription.steps.garageInfo.continue')}
        </ReparkButton>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  heading: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingHorizontal: 15,
    paddingBottom: 15,
  },
  featureSwiperContainer: { ...rptheme.shadow, marginBottom: 15 },
  descriptionContainer: { margin: 15 },
  soldOutText: {
    color: rptheme.colors.error,
    textAlign: 'center',
    margin: 15,
  },
});

export const computeGarageFeatures = (garage: any) => {
  const { t } = useTranslation();

  const garageHasLiftingPlatform = garage?.parkingSpots?.some(
    (spot: any) => spot?.type === 'lifting-platform'
  );

  const styles = StyleSheet.create({
    chip: { backgroundColor: rptheme.colors.secondary },
    chipHighlight: { backgroundColor: rptheme.colors.softHighlight },
    number: {
      fontWeight: '600',
      color: garage?.parkingSpots?.length <= 3 ? 'red' : undefined,
    },
  });

  const features = [
    {
      showCondition: garage?.showAsSpecialOffer,
      component: (
        <Chip icon="percent" style={styles.chipHighlight}>
          {t('General.specialOffer')}
        </Chip>
      ),
    },
    {
      showCondition: garageHasLiftingPlatform,
      component: (
        <Chip icon="car" style={[styles.chip]}>
          {t('garage.liftingPlatform')}
        </Chip>
      ),
    },

    {
      showCondition: !!garage?.dimensions?.height,
      component: (
        <Chip icon="height" style={styles.chip}>
          {formatDimensions(garage?.dimensions?.height)}
        </Chip>
      ),
    },
    {
      showCondition: true,
      component: (
        <Chip icon="cctv" style={styles.chip}>
          {garage.cctv ? t('garage.cctv') : t('garage.noCctv')}
        </Chip>
      ),
    },
    {
      showCondition: true,
      component: (
        <Chip icon="wheelchair" style={styles.chip}>
          {garage.accessibility
            ? t('garage.accessible')
            : t('garage.notAccessible')}
        </Chip>
      ),
    },
  ];

  return features;
};
