import React from 'react';
import { Modal } from 'react-native';
import { PaymentProcessingNote } from './PaymentProcessingNote';

type ProcessingModalProps = {
  visible: boolean;
  isProcessingPayment: boolean;
  isCreatingBooking: boolean;
};

export const ProcessingModal = (props: ProcessingModalProps) => {
  const { visible, isProcessingPayment, isCreatingBooking } = props;

  return (
    <Modal visible={visible} animationType={'fade'} transparent={false}>
      <>
        {isProcessingPayment && (
          <PaymentProcessingNote
            title={'Führe Zahlung durch'}
            text={'Bitte schließe dieses Fenster nicht.'}
          />
        )}

        {isCreatingBooking && (
          <PaymentProcessingNote
            title={'Erstelle Buchung'}
            text={'Bitte schließe dieses Fenster nicht.'}
          />
        )}
      </>
    </Modal>
  );
};
