import { t } from 'i18next';
import React from 'react';
import { StyleSheet, View, Text, Image, ScrollView } from 'react-native';
import { Title } from 'react-native-paper';
import rptheme from '~/rptheme';
import ReparkButton from '~/src/components/ReparkButton';
import ReparkListItem from '~/src/components/ReparkListItem';
import { OnboardingStepProps } from './OnboardingScreen';
import ReparkLogoInline from '~/src/components/ReparkLogoInline';

export const OnboardingWelcomeStep = (props: OnboardingStepProps) => {
  const { onNextButtonPress } = props;
  const imageSource = rptheme.graphics.welcomeOnboarding;

  const list = [
    {
      icon: 'onboarding_icon_pay',
      title: t('OnboardingScreen.WelcomeStep.list.element1'),
    },
    {
      icon: 'onboarding_icon_clock',
      title: t('OnboardingScreen.WelcomeStep.list.element2'),
    },
    {
      icon: 'onboarding_icon_touch',
      title: t('OnboardingScreen.WelcomeStep.list.element3'),
    },
  ];

  return (
    <>
      <ScrollView>
        <View>
          <Title testID="title">
            {t('OnboardingScreen.WelcomeStep.titleNoRepark')}{' '}
            <View style={styles.logo}>
              <ReparkLogoInline fontSize={20} />
            </View>
          </Title>

          <Text style={styles.text}>
            {t('OnboardingScreen.WelcomeStep.list.title')}
          </Text>
          {list.map((item, key) => (
            <ReparkListItem
              key={key}
              title={item.title}
              icon={item.icon}
              iconSize={30}
              dense={true}
              style={{ padding: -5 }}
            />
          ))}
        </View>
        <Image testID="image" source={imageSource} style={styles.image} />
      </ScrollView>
      <ReparkButton
        testID="nextButton"
        onPress={onNextButtonPress}
        style={styles.nextButton}
      >
        {t('General.nextStep')}
      </ReparkButton>
    </>
  );
};

const styles = StyleSheet.create({
  list: {
    marginTop: 10,
  },
  text: {
    marginBottom: 10,
    fontSize: 16,
  },
  image: {
    height: 200,
    resizeMode: 'contain',
    marginVertical: 30,
  },
  nextButton: {
    position: 'absolute',
    bottom: 0,
    width: '100%',
  },
  logo: {
    backgroundColor: 'green',
  },
});
