import React from 'react';
import { ScrollView, View } from 'react-native';
import { StyleSheet } from 'react-native';
import rptheme from '~/rptheme';
import { CompanyStore } from './CompanyStore';
import LoadingState from '~/src/components/LoadingState';
import { CreateBookingAsCompanyForm } from './CreateBookingAsCompanyForm';
import { ReparkTabs } from '~/src/components/ReparkTabs';
import { CompanyBookingOverview } from './CompanyBookingOverview';
import { RootScreens } from '../navigation';

export default function CompanyDetailScreen({ navigation, route }: any) {
  const [fetchCompanies, isFetchingCompanies, getCompany] = CompanyStore(
    (store) => [
      store.fetchCompanies,
      store.isFetchingCompanies,
      store.getCompany,
    ]
  );

  React.useEffect(() => {
    fetchCompanies();
  }, []);

  React.useEffect(() => {
    navigation?.setOptions({
      title: getCompany(route.params?.id)?.name,
    });
  }, [isFetchingCompanies, getCompany(route.params?.id)?.name]);

  if (!getCompany(route.params?.id)) {
    navigation.replace(RootScreens.ProfileStack);
  }

  const [tab, setTab] = React.useState<'newBooking' | 'bookings'>('newBooking');
  const tabs = [
    {
      label: 'Neue Buchung',
      onPress: () => setTab('newBooking'),
      testID: 'switchToNewBooking',
    },
    {
      label: 'Buchungen',
      onPress: () => setTab('bookings'),
      testID: 'switchToAllBookings',
    },
  ];

  if (isFetchingCompanies) return <LoadingState />;

  return (
    <ScrollView
      contentContainerStyle={styles.container}
      keyboardDismissMode="on-drag"
      keyboardShouldPersistTaps="always"
    >
      <View style={{ marginBottom: 20 }}>
        <ReparkTabs tabs={tabs} />
      </View>
      {tab === 'newBooking' && (
        <CreateBookingAsCompanyForm companyId={route.params?.id} />
      )}

      {tab === 'bookings' && (
        <CompanyBookingOverview companyId={route.params?.id} />
      )}
    </ScrollView>
  );
}
const styles = StyleSheet.create({
  container: {
    marginTop: 80,
    marginBottom: 100,
    flex: 1,
    maxWidth: rptheme.screenSmall,
    width: '100%',
    alignSelf: 'center',
    paddingHorizontal: 20,
  },
  bottomHeading: { color: 'grey', marginLeft: 25, marginTop: 30 },
});
