import { zodResolver } from '@hookform/resolvers/zod';
import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation, Trans } from 'react-i18next';
import { Image, StyleSheet, ScrollView } from 'react-native';
import { Title, Text } from 'react-native-paper';
import rptheme from '~/rptheme';
import ReparkButton from '~/src/components/ReparkButton';
import ReparkTextInput from '~/src/components/ReparkTextInput';
import { createOnboardingZodSchema } from '~/src/rules/inputValidationRules';
import { AuthStore } from '../authentication/AuthStore';
import { SnackbarStore } from '../snackbar/SnackbarStore';
import { OnboardingStepProps } from './OnboardingScreen';

export const OnboardingEmailStep = (props: OnboardingStepProps) => {
  const [user, editUser] = AuthStore((store) => [store.user, store.editUser]);
  const { t } = useTranslation();
  const { onNextButtonPress, buttonType } = props;
  const imageSource = rptheme.graphics.mailOnboarding;
  const fireSnack = SnackbarStore((store) => store.fireSnack);
  const [isLoading, setIsLoading] = React.useState(false);

  const {
    control,
    handleSubmit,
    setError,
    formState: { errors, isDirty },
  } = useForm<{
    email: string | undefined;
  }>({
    resolver: zodResolver(createOnboardingZodSchema(t).email),
    defaultValues: {
      email: !user.email || user.email === null ? '' : user.email,
    },
  });

  const submit = handleSubmit(async (data) => {
    setIsLoading(true);
    if (isDirty) {
      if (data.email === '') data.email = undefined;
      const response = await editUser(data);

      if (!response.success) {
        let title, message;

        switch (response.errorCode) {
          case 409:
            title = t('ProfileScreen.editUserFailed.emailAlreadyTakenTitle');
            message = t(
              'ProfileScreen.editUserFailed.emailAlreadyTakenMessage'
            );
            setError('email', {
              message: t('ProfileScreen.editUserFailed.emailAlreadyTakenTitle'),
            });
            break;
          default:
            title = t('General.errors.GenericErrorTitle');
            message = t('General.errors.GenericErrorMessage');
        }

        fireSnack({
          title,
          message,
          type: 'error',
        });
        setIsLoading(false);
        return;
      }
      onNextButtonPress();
    }

    onNextButtonPress();
  });

  return (
    <>
      <ScrollView>
        <Title testID="title">{t('OnboardingScreen.EmailStep.title')}</Title>
        <Text testID="text" style={styles.text}>
          <Trans></Trans>
          {t('OnboardingScreen.EmailStep.text')}
        </Text>
        <Controller
          name="email"
          control={control}
          render={({ field: { onChange } }) => (
            <ReparkTextInput
              testID={'emailInput'}
              onChangeText={(text: string) => onChange(text)}
              value={user.email === null ? '' : user.email}
              maxLength={40}
              textContentType="emailAddress"
              keyboardType="email-address"
              autoComplete="email"
              autoCorrect={false}
              spellCheck={false}
              zoderror={errors.email}
              label={t('AuthTexts.emailPlaceholder')}
              icon={'email'}
            />
          )}
        ></Controller>
        <Text testID="subtextInvoices" style={styles.subtext_top}>
          <Trans i18nKey={'OnboardingScreen.EmailStep.textInvoices'}>
            Hier erhältst du in Zukunft alle wichtigen Informationen, zum
            Beispiel Infos zu deiner <b>Parkplatz-Buchung</b>.
          </Trans>
        </Text>
        <Text testID="subtextVerify" style={styles.subtext_bottom}>
          {t('OnboardingScreen.EmailStep.textVerify')}
        </Text>
        <Image testID="image" source={imageSource} style={styles.image} />
      </ScrollView>
      <ReparkButton
        loading={isLoading}
        disabled={isLoading}
        testID="nextButton"
        onPress={submit}
        style={styles.nextButton}
      >
        {t(`General.${buttonType}`)}
      </ReparkButton>
    </>
  );
};

const styles = StyleSheet.create({
  image: {
    height: 220,
    resizeMode: 'contain',
    marginVertical: 10,
  },
  text: {
    fontSize: rptheme.fontSizeMedium,
  },
  subtext_top: {
    fontSize: rptheme.fontSizeSmall,
    marginBottom: 8,
  },
  subtext_bottom: {
    fontSize: rptheme.fontSizeSmall,
  },
  nextButton: {
    position: 'absolute',
    bottom: 0,
    width: '100%',
  },
});
