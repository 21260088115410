import React from 'react';
import { View, StyleSheet } from 'react-native';
import { Paragraph } from 'react-native-paper';
import rptheme from '~/rptheme';

export const ListItem = (props: { label: string; value: string }) => {
  const { label, value } = props;

  return (
    <View style={styles.listItemContainer}>
      <View>
        <Paragraph style={styles.listLabel}>{label}</Paragraph>
      </View>
      <View>
        <Paragraph>{value}</Paragraph>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  listItemContainer: { flexDirection: 'row', justifyContent: 'space-between' },
  listLabel: {
    color: rptheme.colors.gray,
  },
});
