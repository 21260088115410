import React from 'react';
import ConfirmCancelSheet from '~/src/components/ConfirmCancelSheet';
import { SnackbarStore } from '../../snackbar/SnackbarStore';
import rptheme from '~/rptheme';
import { cancelSubscriptionRequest } from '../subscriptionService';

export default function CancelSubscriptionSheet(props: any) {
  const { id, onCancelSuccess, t } = props;
  const fireSnack = SnackbarStore((store) => store.fireSnack);
  const [canceling, setCanceling] = React.useState(false);

  const cancelSubscription = async () => {
    try {
      await cancelSubscriptionRequest(id);

      fireSnack({
        title: t('General.cancelSubscription.successTitle'),
        message: t('General.cancelSubscription.successMessage'),
        type: 'success',
      });
      onCancelSuccess();
    } catch (e) {
      console.log(e);
      fireSnack({
        title: t('General.cancelSubscription.errorTitle'),
        message: t('General.cancelSubscription.errorMessage'),
        type: 'error',
      });
    }
  };

  return (
    <ConfirmCancelSheet
      countdown={5}
      title={t('General.cancelSubscription.title')}
      message={t('General.cancelSubscription.message')}
      confirmLabel={
        canceling
          ? t('General.cancelSubscription.canceling')
          : t('General.cancelSubscription.confirmLabel')
      }
      onConfirm={async () => {
        setCanceling(true);
        await cancelSubscription();
      }}
      confirmMode={'outlined'}
      confirmColor={rptheme.colors.error}
      cancelMode={'contained'}
      confirmLoading={canceling}
      t={t}
    ></ConfirmCancelSheet>
  );
}
