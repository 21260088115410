import React from 'react';
import { Modal } from '../modal/Modal';
import LanguageSwitcher from './LanguageSwitcher';
import { StyleSheet, View } from 'react-native';
import rptheme from '~/rptheme';

type LanguageSwitcherModalProps = {
  isOpen: boolean;

  onClose: () => void;
};

export const LanguageSwitcherModal = (props: LanguageSwitcherModalProps) => {
  const { isOpen, onClose } = props;
  return (
    <Modal isOpen={isOpen} onClickOutside={onClose}>
      <View style={styles.container}>
        <View style={styles.card}>
          <LanguageSwitcher onLanguageChange={onClose} />
        </View>
      </View>
    </Modal>
  );
};

const styles = StyleSheet.create({
  container: {
    justifyContent: 'space-around',
    maxWidth: rptheme.screenSmall,
    alignSelf: 'center',
  },
  card: {
    minWidth: 300,
    maxWidth: 500,
    backgroundColor: 'white',
    borderRadius: rptheme.roundness,
    ...rptheme.shadow,
  },
});
