import React from 'react';
import { Image, StyleSheet, View } from 'react-native';
import { Title } from 'react-native-paper';
import rptheme from '~/rptheme';
import ReparkButton from '~/src/components/ReparkButton';
import { NavProp, RootScreens } from '../../navigation';
import { useTranslation } from 'react-i18next';

type NewSubscriptionHeaderProps = {
  navigation: NavProp;
};

export const NewSubscriptionHeader = (props: NewSubscriptionHeaderProps) => {
  const { navigation } = props;
  const { t } = useTranslation();

  const goToMap = () => {
    navigation.navigate(RootScreens.MapScreen);
  };

  return (
    <View>
      <Image
        testID="image"
        source={rptheme.graphics.subscriptionSuccess}
        style={styles.image}
      />

      <Title style={styles.title}>{t('subscriptionSuccess.title')}</Title>
      <ReparkButton mode="contained" onPress={goToMap}>
        {t('subscriptionSuccess.goToMapButton')}
      </ReparkButton>
    </View>
  );
};

const styles = StyleSheet.create({
  image: {
    height: 200,
    resizeMode: 'contain',
    marginVertical: 30,
  },
  title: {
    fontSize: 16,
    textAlign: 'center',
    maxWidth: 400,
    alignSelf: 'center',
  },
});
