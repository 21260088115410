import { DAY } from '~/src/utils/helpers';
import { ReasonOfBookingCategory } from './ReasonOfBookingOptions';
import dayjs from 'dayjs';
import { isPastBooking } from '../booking/helpers';

export const getReasonOfBookingCategory = (
  booking: any
): ReasonOfBookingCategory => {
  return booking.duration > DAY ? 'more' : 'one';
};

export const doShowReasonOfBookingModal = (
  user: any,
  booking: any
): boolean => {
  if (!booking || !user) {
    return false;
  }

  if (isPastBooking(booking)) {
    // we can't modify bookings in the past, so that's alright
    return false;
  }

  //check if settings.allowQuestions is set to false
  if (user.settings && user.settings.allowQuestions === false) {
    return false;
  }
  if (booking.type === 'extended') {
    return false;
  }
  if (
    !booking.reasonOfBooking ||
    booking.reasonOfBooking === null ||
    booking.reasonOfBooking === 'closed' ||
    booking.reasonOfBooking === 'clickOutside'
  ) {
    //otherwise let the booking decide
    return true;
  }
  return false;
};

/**
 * Checks for following condition:
 * "wenn User X in Garage Y 2x parkt (min. 1 Mal innerhalb
 * der letzten 90 Tage) und BEIDE male dasselbe antwortet
 * → auto fill diese Antwort bei neuer Buchung von User X
 * in Garage Y"
 * @param {Arra<any>} bookings  - bookings already filtered
 * by userId, garageId and start not in the future and excluding current booking
 * @returns {string | null} - value or null if user needs to still fill out
 */
export const checkForAutofill = (bookings: Array<any>): string | null => {
  //now - 90 days
  const topCountPreFilter = getTopRobCount(bookings);
  if (topCountPreFilter[0] === 'null') {
    //means user has never filled out any reasonOfBooking for this garage
    return null;
  }
  if (topCountPreFilter[1] <= 1) {
    //user only filled out once for this garage
    return null;
  }

  //look at the bookings of the last 90 days and repeat
  const ninety_days = dayjs().startOf('day').subtract(90, 'days');
  const filtered = bookings.filter((book: any) =>
    dayjs(book.start).isAfter(ninety_days)
  );
  const topCountPostFilter = getTopRobCount(filtered);
  if (topCountPostFilter[0] === 'null') {
    return null;
  }
  // if top count is greater than or equal to 1, we can autofill, otherwise
  // the user is asked to do it
  return topCountPostFilter[1] >= 1 ? topCountPostFilter[0] : null;
};

export const getTopRobCount = (bookings: Array<any>): [string, number] => {
  const counts: { [index: string]: number } = { null: 0 };
  for (let i = 0; i < bookings.length; i++) {
    const rob = bookings[i].reasonOfBooking;
    if (rob === null) {
      counts.null += 1;
    } else if (rob in counts) {
      counts[rob] += 1;
    } else {
      counts[rob] = 1;
    }
  }

  const sorted = Object.entries(counts).sort((a, b) => b[1] - a[1]);
  if (sorted.length > 0) {
    const firstKey = sorted[0][0];
    if (firstKey === 'null' && sorted.length > 1) {
      return sorted[1];
    } else {
      return sorted[0];
    }
  }
  return ['null', 0];
};
