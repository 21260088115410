import dayjs, { Dayjs } from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import { RRule } from 'rrule';
import { GarageType } from './types';

dayjs.extend(utc);
dayjs.extend(timezone);

export const computeGarageOrientationInfo = (
  garage: GarageType,
  t: Function
) => {
  return [
    {
      showCondition: !!garage.accessType,
      testID: 'accessInfo',
      title: t(`garage.orientation.carEntry.${garage.accessType}`),
      icon: 'garage',
    },
    {
      showCondition: true,
      testID: 'parkInfo',
      title: t('garage.orientation.park.onyOnMarkedSpots'),
      icon: 'logo',
    },
    {
      showCondition: !!garage.pedestrianExitType,
      testID: 'pedestrianExitTypeInfo',
      title: t(`garage.orientation.pedExit.${garage.pedestrianExitType}`),
      icon: 'exit1',
    },
    {
      showCondition: !!garage.pedestrianEntryType,
      testID: 'pedestrianEntryTypeInfo',
      title: t(`garage.orientation.pedEntry.${garage.pedestrianEntryType}`),
      icon: 'walking',
    },
    {
      showCondition: !!garage.carExitType,
      testID: 'carExitTypeInfo',
      title: t(`garage.orientation.carExit.${garage.carExitType}`),
      icon: 'car',
    },
  ];
};

export const getDatesBetween = (
  rule: RRule,
  start: Dayjs,
  duration: number
) => {
  const timezone = 'Europe/Vienna';

  const startSearch = start.utc().tz(timezone).startOf('day').toDate();
  const endSearch = start.add(duration, 'minutes').toDate();

  const match = rule.between(startSearch, endSearch);

  const dates = match.map((date) => {
    return dayjs(date).utc().tz(timezone).startOf('day');
  });

  return { dates };
};

export const garageContainsLiftingPlatform = (garage: GarageType) => {
  if (!garage || !garage.parkingSpots) return false;
  return garage?.parkingSpots?.some(
    (spot) => spot?.type === 'lifting-platform'
  );
};

export const canSubscribeInGarage = (garage: GarageType): boolean => {
  return garage.subscribable;
};
