import { GarageType, ParkingSpot } from '../garage/types';
import { ParkingSpotType, SubscriptionType } from './types';

export function groupParkingSpotsByGarage(
  parkingSpots: ParkingSpotType[]
): GarageType[] {
  if (!parkingSpots) return [];

  const garages: Record<string, any> = {};

  parkingSpots?.forEach((item) => {
    const garageId: string = item.garage.id;

    if (!garages[garageId]) {
      garages[garageId] = {
        ...item.garage,
        parkingSpots: [],
      };
    }

    garages[garageId].parkingSpots.push(item);
  });

  return Object.values(garages);
}

export function isActiveSubscription(subscription: SubscriptionType) {
  return subscription?.status === 'active';
}

export function isInactiveSubscription(subscription: SubscriptionType) {
  return !isActiveSubscription(subscription);
}

export function isCanceledSubscription(subscription: SubscriptionType) {
  return (
    subscription.status === 'canceled' ||
    subscription.canceled ||
    subscription.cancelAt
  );
}

export function calculateTotalSubscriptionPrice(
  subscription: SubscriptionType
) {
  if (!subscription.prices) return 0;

  return subscription.prices.reduce(
    (acc: number, item) => acc + item.unit_amount,
    0
  );
}

export function formatSubscriptionInterval(interval: string, t: Function) {
  return t(`Subscription.interval.${interval}`);
}

export function findPriceByPriceIdInParkingSpot(
  parkingSpot: ParkingSpot,
  priceId: string
) {
  if (!parkingSpot?.products) return null;
  for (const product of parkingSpot.products) {
    if (!product.prices) continue;
    for (const price of product.prices) {
      if (price.id === priceId) {
        return price;
      }
    }
  }
  return null;
}
