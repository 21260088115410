import React from 'react';
import { useTranslation } from 'react-i18next';
import { StyleSheet, View } from 'react-native';
import { Paragraph, Title } from 'react-native-paper';

type GarageDescriptionProps = {
  garage: any;
};

export const GarageDescription = (props: GarageDescriptionProps) => {
  const { t } = useTranslation();
  const { garage } = props;
  if (!garage.description) return null;

  return (
    <View>
      <Title style={styles.title}>{t('garage.descriptionHeader')}</Title>
      <Paragraph testID="garageDescription">{garage.description}</Paragraph>
    </View>
  );
};

const styles = StyleSheet.create({
  title: {
    fontSize: 16,
  },
});
