//Is https://react-google-maps-api-docs.netlify.app/#marker
import React from 'react';
import { Marker } from '@react-google-maps/api';

import { AuthStore } from '~/src/features/authentication/AuthStore';
import { formatCurrency } from '~/src/utils/helpers';
import rptheme from '~/rptheme';
import { Point } from './helpers';

import { MarkerProps } from './Marker';
import { GarageStore } from '../garage/GarageStore';

export const ReparkMarker = (props: MarkerProps) => {
  const { item, onPress, isActive, rate } = props;
  const loggedIn = AuthStore((store) => store.loggedIn);
  const durationMultiplier = GarageStore((store) => store.offerDuration) / 1440;

  const icon = (): google.maps.Icon => {
    let url: string = rptheme.map.marker.default;
    let labelOrigin = new Point(25, 23);

    const scaledSize = {
      width: 50,
      height: 65,
      equals: () => true,
    };
    const hasOffers = !!item.parkingSpots?.length;
    const isComingSoon = item.status === 'comingSoon';

    if (!loggedIn) {
      if (isComingSoon) {
        url = rptheme.map.marker.comingSoon;
        labelOrigin = new Point(20, 16);
        scaledSize.width = 40;
        scaledSize.height = 44;
        if (isActive) {
          url = rptheme.map.marker.active;
        }
      } else if (isActive) {
        url = rptheme.map.marker.active;
      } else {
        url = rptheme.map.marker.default;
      }

      return { url, labelOrigin, scaledSize };
    }

    if (isComingSoon) {
      url = rptheme.map.marker.comingSoon;
      labelOrigin = new Point(20, 16);
      scaledSize.width = 40;
      scaledSize.height = 44;

      if (isActive) {
        url = rptheme.map.marker.active;
      }
    } else if (hasOffers) {
      url = rptheme.map.marker.default;
      if (isActive) {
        url = rptheme.map.marker.active;
      } else if (item.showAsSpecialOffer) {
        url = rptheme.map.marker.specialOffer;
      }
    } else {
      url = rptheme.map.marker.noOffer;
      if (isActive) {
        url = rptheme.map.marker.activeNoOffer;
      }
    }

    return { url, labelOrigin, scaledSize };
  };

  const label = () => {
    const rateFormatted = `${formatCurrency(rate * durationMultiplier, {
      maximumSignificantDigits: 3,
    })}`;
    const hasOffers = !!item.parkingSpots?.length;
    const isComingSoon = item.status === 'comingSoon';

    if (loggedIn) {
      if (isComingSoon) {
        return {
          text: 'Soon',
          color: rptheme.map.marker.labelColor,
          fontSize: '8px',
          fontFamily: rptheme.fonts.medium.fontFamily,
        };
      } else if (hasOffers) {
        return {
          text: rateFormatted,
          color: rptheme.map.marker.labelColor,
          fontSize: `${rptheme.map.marker.labelFontSize}px`,
          fontFamily: rptheme.fonts.medium.fontFamily,
        };
      } else {
        return {
          text: ' ',
          color: rptheme.map.marker.labelColor,
          fontSize: `${rptheme.map.marker.labelFontSize}px`,
          fontFamily: rptheme.fonts.medium.fontFamily,
        };
      }
    } else {
      if (isComingSoon) {
        return {
          text: 'Soon',
          color: rptheme.map.marker.labelColor,
          fontSize: '8px',
          fontFamily: rptheme.fonts.medium.fontFamily,
        };
      }
      return {
        text: rateFormatted,
        color: rptheme.map.marker.labelColor,
        fontSize: `${rptheme.map.marker.labelFontSize}px`,
        fontFamily: rptheme.fonts.medium.fontFamily,
      };
    }
  };

  const coords = () => {
    return {
      lat: parseFloat(item.coordinates.latitude),
      lng: parseFloat(item.coordinates.longitude),
    };
  };

  const zIndex = () => {
    if (item.status === 'comingSoon') {
      return -1;
    }

    if (isActive) {
      return 100;
    } else {
      return undefined;
    }
  };

  return (
    <Marker
      key={item.id}
      position={coords()}
      onClick={onPress}
      icon={icon()}
      options={{}}
      label={label()}
      zIndex={zIndex()}
    />
  );
};
