import { feathersClient } from '~/src/services/feathers';

export const createOfferViewRequest = async (data: any) => {
  const result = await feathersClient.service('offerViews').create(data);
  return result;
};

export const patchOfferViewRequest = async (id: string, data: any) => {
  return await feathersClient.service('offerViews').patch(id, data);
};
