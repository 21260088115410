import * as React from 'react';
import { createStackNavigator } from '@react-navigation/stack';
import LegalOverviewScreen from '~/src/features/about/AboutScreen';
import { t } from 'i18next';
import WebViewScreen from '~/src/features/webview/WebViewScreen';
import HeaderLeft from '~/src/components/HeaderLeft';
import { headerTitleAlign } from '../navigation/helpers';
import { RootScreens } from '../navigation';

export const Stack = createStackNavigator();

export enum AboutStackScreens {
  AboutScreen = 'About',
  AboutWebViewScreen = 'AboutWebView',
}

export default function AboutStackNavigation({ navigation }: any) {
  const headerLeft = HeaderLeft.bind(navigation);

  return (
    <Stack.Navigator
      initialRouteName={AboutStackScreens.AboutScreen}
      screenOptions={{
        headerShown: true,
        headerTitleAlign: headerTitleAlign(),
        headerTransparent: true,
        headerBackTitleVisible: false,
      }}
    >
      <Stack.Screen
        name={AboutStackScreens.AboutScreen}
        options={{
          title: t('AboutScreen.screenTitle'),
          headerLeft: (props: any) => {
            props.fallBackScreen = RootScreens.MapScreen;
            return headerLeft(props);
          },
        }}
        component={LegalOverviewScreen}
      />

      <Stack.Screen
        name={AboutStackScreens.AboutWebViewScreen}
        component={WebViewScreen}
        options={{
          title: '',
          headerLeft: (props: any) => {
            props.fallBackScreen = AboutStackScreens.AboutScreen;
            return headerLeft(props);
          },
        }}
      />
    </Stack.Navigator>
  );
}
