import { NavigationProp } from '@react-navigation/native';
import React from 'react';
import { View, Text, StyleSheet } from 'react-native';
import { Card } from 'react-native-paper';
import rptheme from '~/rptheme';
import { ReparkIcon } from '~/src/components/ReparkIcon';
import { LinearGradient } from 'expo-linear-gradient';
import {
  calculateTotalSubscriptionPrice,
  isActiveSubscription,
  isInactiveSubscription,
} from '../../subscriptions/helpers';
import { ReparkList } from '~/src/components/ReparkList';
import { formatCurrency } from '~/src/utils/helpers';
import { SubscriptionsStackScreens } from '../../subscriptions/SubscriptionStackNavigation';
import { useTranslation } from 'react-i18next';

type SubscriptionListItemProps = {
  subscription: any;
  navigation: NavigationProp<any>;
};

export const SubscriptionListItem = (props: SubscriptionListItemProps) => {
  const { subscription, navigation } = props;
  const { t } = useTranslation();

  let icon = 'booking-active';
  const leftColumnStyle = [styles.leftColumn];
  let color = rptheme.colors.primary;
  let colorFade = rptheme.colors.primary;
  const cardStyle = [styles.card];

  const createSubscriptionStatusInfo = (subscription: any) => {
    switch (subscription.status) {
      case 'active':
        return {
          title: t('SubscriptionScreen.status.active'),
          icon: 'check',
        };
      case 'incomplete':
        return {
          title: t('SubscriptionScreen.status.incomplete'),
          icon: 'cancel',
        };

      case 'not_started':
        return {
          title: t('SubscriptionScreen.status.notStarted'),
          icon: 'calendar',
        };
      default:
        return {
          title: t('SubscriptionScreen.status.inactive'),
          icon: 'booking-canceled',
        };
    }
  };

  const subscriptionInfos = [
    createSubscriptionStatusInfo(subscription),
    {
      title: `${formatCurrency(
        calculateTotalSubscriptionPrice(subscription)
      )} ${t(`subscription.billed.X${subscription.prices[0].interval}ly`, {
        count: subscription.prices[0].interval_count,
      })}`,
      icon: 'money',
    },
  ];

  if (isActiveSubscription(subscription)) {
    leftColumnStyle.push(styles.leftColumnActive as any);
    color = rptheme.colors.accent;
    colorFade = rptheme.colors.accentFade;
  }

  if (
    isInactiveSubscription(subscription) &&
    subscription.status !== 'incomplete' &&
    subscription.status !== 'not_started'
  ) {
    cardStyle.push(styles.cardCanceled as any);
    leftColumnStyle.push(styles.leftColumnCanceled as any);
    color = rptheme.colors.error;
    colorFade = rptheme.colors.error;
    icon = 'booking-canceled';
  }

  return (
    <Card
      testID="bookingListItem"
      style={cardStyle}
      onPress={() => {
        navigation.navigate(SubscriptionsStackScreens.SubscriptionDetail, {
          id: subscription.id,
        });
      }}
    >
      <View style={styles.container}>
        <LinearGradient
          style={leftColumnStyle}
          colors={[color, colorFade]}
          start={{ x: 1, y: 0 }}
          end={{ x: 0.5, y: 0 }}
        >
          <ReparkIcon name={icon} size={50} color="white" />
          <Text style={styles.leftText}></Text>
        </LinearGradient>
        <View style={styles.rightColumn}>
          <ReparkList
            items={subscriptionInfos}
            iconSize={20}
            iconStyle={styles.iconStyle}
            iconColor={color}
          ></ReparkList>
          <ReparkIcon
            name="chevron-right"
            style={styles.detailsIcon}
            color={color}
          />
        </View>
      </View>
    </Card>
  );
};

const styles = StyleSheet.create({
  card: {
    marginBottom: 15,
  },
  cardCanceled: {
    opacity: 0.5,
  },
  cardPast: {
    opacity: 0.5,
  },
  container: {
    flexDirection: 'row',
  },
  leftColumn: {
    padding: 25,
    width: '30%',
    borderTopLeftRadius: rptheme.roundness,
    borderBottomLeftRadius: rptheme.roundness,
    justifyContent: 'space-around',
    color: 'white',
    alignItems: 'center',
    backgroundColor: rptheme.colors.primary,
  },
  leftColumnActive: {
    backgroundColor: rptheme.colors.accent,
  },
  leftColumnCanceled: {
    backgroundColor: rptheme.colors.error,
  },
  leftText: {
    color: 'white',
    marginTop: 5,
    fontSize: 16,
    textAlign: 'center',
  },
  rightColumn: {
    position: 'relative',
    padding: 5,
    width: '70%',
    borderTopRightRadius: rptheme.roundness,
    borderBottomRightRadius: rptheme.roundness,
  },
  detailsIcon: { position: 'absolute', bottom: 15, right: 15 },
  iconStyle: {
    margin: 0,
    height: 30,
    width: 30,
  },
  canceledText: {
    color: rptheme.colors.error,
    alignSelf: 'center',
    fontSize: rptheme.fontSizeLarge,
    marginTop: 10,
    marginBottom: 0,
  },
});
