import React from 'react';
import { Platform, TouchableOpacity, View } from 'react-native';
import { SubscriptionInvoice } from '../types';
import { Text, Title } from 'react-native-paper';
import Icon from '@mdi/react';
import {
  mdiCheck,
  mdiReceiptClockOutline,
  mdiInvoiceSendOutline,
} from '@mdi/js';
import { fetchSubscriptionInvoice } from '../subscriptionService';
import LoadingState from '~/src/components/LoadingState';
import rptheme from '~/rptheme';
import dayjs, { Dayjs } from 'dayjs';
import { formatCurrency } from '~/src/utils/helpers';
import { SnackbarStore } from '../../snackbar/SnackbarStore';
import { useTranslation } from 'react-i18next';
import { BottomSheetStore } from '../../bottom-sheet/BottomSheetStore';
import { RequestInvoicesSheet } from '../../booking/RequestInvoicesSheet';

type SubscriptionInvoiceItemProps = {
  invoice: SubscriptionInvoice;
  navigation: any;
};

const pdfToUrl = (pdf: Buffer) => {
  const url = URL.createObjectURL(new Blob([pdf], { type: 'application/pdf' }));
  window.open(url, '_blank');
};

const dueIsPast = (dueDate: Dayjs): boolean => {
  return dayjs().isAfter(dueDate);
};

export const SubscriptionInvoiceItem = ({
  invoice,
  navigation,
}: SubscriptionInvoiceItemProps) => {
  const [isLoading, setIsLoading] = React.useState(false);
  const [fireSnack] = SnackbarStore((store) => [store.fireSnack]);
  const { t } = useTranslation();
  const openSheet = BottomSheetStore((store) => store.openSheet);

  return (
    <View>
      {isLoading ? (
        <LoadingState />
      ) : (
        <TouchableOpacity
          style={[
            {
              padding: 15,
              marginVertical: 5,
              borderWidth: 1,
              marginHorizontal: 10,
            },
            {
              backgroundColor: 'white',
              borderRadius: rptheme.roundness,
              borderColor: 'lightgrey',
            },
          ]}
          onPress={async () => {
            setIsLoading(true);
            try {
              const inv = await fetchSubscriptionInvoice(invoice.id, 'pdf');
              pdfToUrl(inv.pdf);
              setIsLoading(false);
            } catch (e) {
              fireSnack({
                title: t('subscriptionInvoices.pdfCreationFailed'),
                message: t('subscriptionInvoices.pdfCreationFailedMessage'),
                type: 'error',
              });

              setIsLoading(false);
            }
          }}
        >
          <View>
            <View
              style={{
                flexDirection: 'row',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <Text style={{ fontSize: 20, color: rptheme.colors.primary }}>
                {invoice.number}
              </Text>
            </View>
            <View
              style={{
                flexDirection: 'row',
                justifyContent: 'space-around',
                alignItems: 'center',
              }}
            >
              <View>
                {invoice.status === 'paid' ? (
                  <View
                    style={{
                      flexDirection: 'row',
                      justifyContent: 'flex-start',
                      alignItems: 'center',
                    }}
                  >
                    <Text>{dayjs(invoice.createdAt).format('DD.MM.YYYY')}</Text>
                    <Icon
                      path={mdiCheck}
                      color={rptheme.colors.accent}
                      size={1}
                      style={{ paddingLeft: 10 }}
                    />
                  </View>
                ) : invoice.status === 'failed' ? (
                  <View
                    style={{
                      flexDirection: 'row',
                      justifyContent: 'flex-start',
                      alignItems: 'center',
                    }}
                  >
                    <View style={{ marginRight: '10px' }}>
                      <Text style={{ color: rptheme.colors.error }}>
                        {t('subscriptionInvoices.due')}:{' '}
                        {dayjs(invoice.dueDate).format('DD.MM.YYYY')}
                      </Text>
                      <Text style={{ color: rptheme.colors.error }}>
                        {t('subscriptionInvoices.paymentFailed')}
                      </Text>
                    </View>
                    <Icon
                      path={mdiReceiptClockOutline}
                      color={rptheme.colors.error}
                      size={1}
                    />
                  </View>
                ) : (
                  <View>
                    <Text
                      style={
                        dueIsPast(dayjs(invoice.dueDate))
                          ? { color: rptheme.colors.error }
                          : {}
                      }
                    >
                      {t('subscriptionInvoices.dueOn')}:{' '}
                      {dayjs(invoice.dueDate).format('DD.MM.YYYY')}
                    </Text>
                    <Icon
                      path={mdiReceiptClockOutline}
                      color={rptheme.colors.primary}
                      size={1}
                    />
                  </View>
                )}
              </View>

              <TouchableOpacity
                onPress={() => {
                  openSheet(
                    <RequestInvoicesSheet
                      contract={invoice}
                      navigation={navigation}
                    />,
                    {
                      adjustToContentHeight: true,
                    }
                  );
                }}
              >
                <Icon
                  path={mdiInvoiceSendOutline}
                  color={rptheme.colors.primary}
                  size={1}
                  style={{ paddingLeft: 10 }}
                />
              </TouchableOpacity>
              <Title>{formatCurrency(invoice.amount || 0)}</Title>
            </View>
          </View>
        </TouchableOpacity>
      )}
    </View>
  );
};

// <ReparkIcon
//   name="invoice"
//   size={20}
//   color={rptheme.colors.primary}
//   style={{ marginRight: 10 }}
// />
