import React from 'react';
import SwiperFlatList from 'react-native-swiper-flatlist';
import { Card, Chip } from 'react-native-paper';
import { openLinkInBrowser } from '../utils/helpers';
import { useTranslation } from 'react-i18next';

type LinkSwiperProps = {
  links: LinkSwiperLink[];
};

export type LinkSwiperLink = {
  url: string;
  type: string;
  title: string;
  description: string;
  image: string;
  showInOffer: boolean;
};

export const LinkSwiper = (props: LinkSwiperProps) => {
  const carouselRef = React.useRef(null);
  const { links } = props;
  const { t } = useTranslation();

  if (!links) return <></>;

  return (
    <SwiperFlatList
      ref={carouselRef}
      data={links.filter((item: LinkSwiperLink) => item.showInOffer)}
      renderItem={({ item }: { item: LinkSwiperLink }) => (
        <Card
          style={{ width: 300, marginHorizontal: 10, marginBottom: 10 }}
          onPress={() =>
            openLinkInBrowser(
              `${item.url}?utm_source=app&utm_medium=offer&utm_campaign=info`
            )
          }
        >
          <Card.Cover
            source={{
              uri: item.image,
            }}
          />
          <Card.Title
            title={item.title}
            subtitle={item.description}
            titleNumberOfLines={2}
            subtitleNumberOfLines={2}
          />
          <Card.Content style={{ display: 'flex', flexDirection: 'row' }}>
            <Chip>{t(`link.${item.type}`)}</Chip>
          </Card.Content>
        </Card>
      )}
    />
  );
};
