import AppLoading from 'expo-app-loading';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { View, StyleSheet, Text } from 'react-native';
import { CenteredAppView } from '~/src/components/CenteredAppView';
import { EmptyState } from '~/src/components/EmptyState';
import { NavProp } from '../navigation';
import { SnackbarStore } from '../snackbar/SnackbarStore';
import { PaymentStore } from './PaymentStore';
import { PaymentMethod } from '@stripe/stripe-js';
import { PaymentMethodsList } from './components/PaymentMethodsList';

type PaymentMethodsScreenProps = {
  navigation: NavProp;
};

export const PaymentMethodsScreen = (props: PaymentMethodsScreenProps) => {
  const { t } = useTranslation();
  const { navigation } = props;
  const [
    isFetchingPaymentMethods,
    paymentMethods,
    fetchPaymentMethods,
    detachPaymentMethod,
  ] = PaymentStore((store) => [
    store.isFetchingPaymentMethods,
    store.paymentMethods,
    store.fetchPaymentMethods,
    store.detachPaymentMethod,
  ]);
  const fireSnack = SnackbarStore((store) => store.fireSnack);

  const onDeletePaymentMethod = async (paymentMethodId: string) => {
    try {
      await detachPaymentMethod(paymentMethodId);
      fetchPaymentMethods();
      fireSnack({
        title: t('PaymentMethodsScreen.delete.successTitle'),
        message: t('PaymentMethodsScreen.delete.successMessage'),
        type: 'success',
      });
    } catch (e) {
      console.log(e);
      fireSnack({
        title: t('General.errors.GenericErrorTitle'),
        message: t('General.errors.GenericErrorMessage'),
        type: 'error',
      });
    }
  };

  if (isFetchingPaymentMethods) return <AppLoading />;

  return (
    <CenteredAppView>
      <View style={styles.container}>
        <PaymentMethodsList
          paymentMethods={paymentMethods}
          onPress={() => {}}
          onDeletePaymentMethod={onDeletePaymentMethod}
        />
        {!paymentMethods.length && (
          <View style={styles.emptyStateContainer}>
            <EmptyState
              title={t('PaymentMethodsScreen.emptyListTitle')}
              text={t('PaymentMethodsScreen.emptyListText')}
              CTA={{
                label: t('PaymentMethodsScreen.emptyListCTALabel'),
                icon: 'map',
                onPress: () => navigation.replace('Map'),
              }}
            />
          </View>
        )}
        {paymentMethods.filter(
          (paymentMethod: PaymentMethod) => paymentMethod.type === 'card'
        ).length > 0 && (
          <Text style={styles.text} testID={'addPaymentMethodInfo'}>
            {t('PaymentMethodsScreen.addPaymentMethodInfo')}
          </Text>
        )}
      </View>
    </CenteredAppView>
  );
};

const styles = StyleSheet.create({
  container: {
    marginTop: 70,
  },
  text: {
    margin: 25,
    textAlign: 'center',
  },
  emptyStateContainer: { padding: 25 },
});
