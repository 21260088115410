import create from 'zustand';
import { AuthStore } from '../authentication/AuthStore';
import {
  detachPaymentMethodRequest,
  fetchPaymentIntentRequest,
  fetchPaymentMethodsRequest,
  patchPaymentIntentRequest,
  removePaymentIntentRequest,
} from './paymentService';
import { Dayjs } from 'dayjs';
import { PaymentIntent, PaymentMethod } from '@stripe/stripe-js';
import { getForPaymentIntentAllowedPaymentMethods } from './paymentHelpers';

type PaymentStore = {
  paymentMethods: PaymentMethod[];
  isFetchingPaymentMethods: Boolean;
  paymentIntent: any;
  selectedPaymentMethod: PaymentMethod | undefined;
  voucher: string | undefined;
  fetchPaymentMethods: () => Promise<void>;
  patchPaymentIntent: (paymentIntentId: string, data: any) => Promise<any>;
  removePaymentIntent: (paymentIntentId: string) => Promise<any>;
  detachPaymentMethod: (paymentMethodId: string) => Promise<any>;
  fetchPaymentIntent: ({
    offerId,
    start,
    duration,
    licencePlate,
    bookingType,
    voucher,
    extending,
  }: {
    offerId: string;
    start: Dayjs;
    duration: number;
    licencePlate: string;
    bookingType?: string;
    voucher?: string;
    extending?: string;
  }) => Promise<PaymentIntent | undefined>;
  selectPaymentMethod: (id: string) => Promise<{ success: boolean }>;
  setVoucher: (voucher: string | undefined) => Promise<void>;
  resetStore: () => void;
  /**
   * Detaches the paymentIntent data from stripe, marks the current paymentIntent as internal and sets the amount to zero.
   * @returns {void}
   */
  makeInternalPaymentIntent: (data?: any) => void;
  /**
   * Get the allowed payment methods for a payment intent, depending on the payment method types set in the payment intent.
   * @returns {PaymentMethod[]} An array of allowed payment methods.
   */
  getForPaymentIntentAllowedPaymentMethods: () => PaymentMethod[];
};

export const PaymentStore = create<PaymentStore>((set, get) => ({
  paymentMethods: [],
  isFetchingPaymentMethods: false,
  paymentIntent: undefined,
  selectedPaymentMethod: undefined,
  voucher: undefined,

  fetchPaymentMethods: async () => {
    set({ isFetchingPaymentMethods: true });
    if (AuthStore.getState().loggedIn) {
      const stripeCustomerId = AuthStore.getState().user?.stripe?.customerId;
      if (stripeCustomerId) {
        const paymentMethods = await fetchPaymentMethodsRequest(
          stripeCustomerId
        );
        if (paymentMethods.data?.length) {
          console.log('Setting payment methods');

          set({
            paymentMethods: paymentMethods.data,
            isFetchingPaymentMethods: false,
          });
          if (!get().selectedPaymentMethod) {
            console.log('Setting default payment method');
            set({ selectedPaymentMethod: paymentMethods.data[0] });
          }
        } else {
          set({
            paymentMethods: [],
            isFetchingPaymentMethods: false,
          });
        }
      }
    }
  },

  detachPaymentMethod: async (paymentMethodId: string) => {
    if (AuthStore.getState().loggedIn) {
      const stripeCustomerId = AuthStore.getState().user?.stripe?.customerId;
      const detachedPaymentMethod = await detachPaymentMethodRequest(
        stripeCustomerId,
        paymentMethodId
      );
      set({ selectedPaymentMethod: undefined });
      await get().fetchPaymentMethods();
      return detachedPaymentMethod;
    }
    return { success: false };
  },

  fetchPaymentIntent: async ({
    offerId,
    start,
    duration,
    licencePlate,
    bookingType,
    extending,
  }) => {
    if (AuthStore.getState().loggedIn) {
      let paymentIntent = await fetchPaymentIntentRequest({
        offerId,
        start,
        duration,
        licencePlate,
        bookingType: bookingType || 'user',
        extending,
      });

      const selectedPaymentMethod = get().selectedPaymentMethod;

      if (
        selectedPaymentMethod &&
        paymentIntent.payment_method_types.includes(
          selectedPaymentMethod.type
        ) &&
        !paymentIntent.payment_method
      ) {
        paymentIntent = await patchPaymentIntentRequest(paymentIntent.id, {
          payment_method: selectedPaymentMethod.id,
        });
      }

      set({ paymentIntent });
      return paymentIntent;
    }
  },

  patchPaymentIntent: async (paymentIntentId: string, data: any) => {
    try {
      const paymentIntent = await patchPaymentIntentRequest(
        paymentIntentId,
        data
      );

      set({ paymentIntent });
      return paymentIntent;
    } catch (e: any) {
      //if a voucher sets the request to a free booking/reservation, we need to remove the paymentIntent from stripe, but keep the data associated
      if (e.name === 'AmountIsZero') {
        const removedPaymentIntent = await get().removePaymentIntent(
          paymentIntentId
        );

        get().makeInternalPaymentIntent(e.data);
        return removedPaymentIntent;
      }
      //otherwise just propagate the error
      throw new Error(e);
    }
  },

  removePaymentIntent: async (paymentIntentId: string) => {
    console.log(`Removing payment intent ${paymentIntentId}`);
    const removedPaymentIntent = await removePaymentIntentRequest(
      paymentIntentId
    );
    return removedPaymentIntent;
  },

  makeInternalPaymentIntent: (data?: any) => {
    const paymentIntent = data.paymentIntent;
    paymentIntent['internal'] = true;
    console.log(paymentIntent);
    set({ paymentIntent });
  },

  selectPaymentMethod: async (id: string) => {
    const currentPaymentMethod = get().selectedPaymentMethod;
    const newPaymentMethod = get().paymentMethods.find((method: any) => {
      if (method.id === id) return true;
    });

    set({ selectedPaymentMethod: newPaymentMethod });
    if (get().paymentIntent) {
      try {
        console.log('Patching payment intent with new payment method');
        const paymentIntent = await patchPaymentIntentRequest(
          get().paymentIntent.id,
          {
            payment_method: newPaymentMethod?.id,
          }
        );

        set({ paymentIntent });
      } catch (e) {
        set({ selectedPaymentMethod: currentPaymentMethod });

        return { success: false };
      }
    }

    return { success: true };
  },

  /**
   * Get the allowed payment methods for a payment intent, depending on the payment method types set in the payment intent.
   * @param paymentIntent The payment intent to get the allowed payment methods for.
   * @returns {PaymentMethod[]} An array of allowed payment methods.
   */
  getForPaymentIntentAllowedPaymentMethods: () =>
    getForPaymentIntentAllowedPaymentMethods(
      get().paymentIntent,
      get().paymentMethods
    ),

  setVoucher: async (voucher: string | undefined) => {
    set({ voucher });
    const paymentIntent = await get().patchPaymentIntent(
      get().paymentIntent.id,
      {
        voucher,
      }
    );
    return paymentIntent;
  },

  resetStore: () => {
    set({
      paymentMethods: [],
      isFetchingPaymentMethods: false,
      paymentIntent: undefined,
      selectedPaymentMethod: undefined,
      voucher: undefined,
    });
  },
}));
