import React from 'react';
import { StyleSheet, View } from 'react-native';
import { NavProp } from '../../navigation';
import { PaymentFormV2 } from './PaymentFormV2';
import { createSetupIntentRequest } from '../paymentService';
import { AuthStore } from '../../authentication/AuthStore';
import { PaymentStore } from '../PaymentStore';
import { BottomSheetStore } from '../../bottom-sheet/BottomSheetStore';
import { StripeError } from '@stripe/stripe-js';
import { StripeIntentFailedModal } from '../components/paymentFailed/StripeIntentFailedModal';

export const AddPaymentMethodSheet = ({
  navigation,
}: {
  navigation: NavProp;
}) => {
  const [setupIntent, setSetupIntent] = React.useState<any | null>(null);
  const [user] = AuthStore((store) => [store.user]);
  const [fetchPaymentMethods] = PaymentStore((store) => [
    store.fetchPaymentMethods,
  ]);
  const [closeSheet] = BottomSheetStore((store) => [store.closeSheet]);
  const [stripeError, setStripeError] = React.useState<StripeError | null>(
    null
  );

  React.useEffect(() => {
    createSetupIntentRequest(user.stripe.customerId).then((res) => {
      console.log(res);
      setSetupIntent(res);
    });
  }, []);

  const handleSuccess = () => {
    fetchPaymentMethods();
    closeSheet();
  };

  const handleError = (e: any) => {
    setStripeError(e);
  };

  return (
    <View style={styles.container}>
      <StripeIntentFailedModal
        intent="setup"
        error={stripeError}
        onClose={() => setStripeError(null)}
      />

      <PaymentFormV2
        intent={setupIntent}
        onSuccess={handleSuccess}
        onError={handleError}
      />
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    padding: 15,
  },
});
