import * as React from 'react';

import { createStackNavigator } from '@react-navigation/stack';
import HeaderLeft from '~/src/components/HeaderLeft';
import { headerTitleAlign } from '../navigation/helpers';
import { DebugLocationScreen } from './DebugLocationScreen';
import { DebugOverview } from './DebugOverview';
import { DebugSubscriptions } from './DebugSubscriptions';

export const Stack = createStackNavigator();

export const DebugStackNavigation = ({ navigation }: any) => {
  const headerLeft = HeaderLeft.bind(navigation);

  return (
    <Stack.Navigator
      initialRouteName="DebugOverview"
      screenOptions={{
        headerShown: true,
        headerTransparent: true,
        headerBackTitleVisible: false,
      }}
    >
      <Stack.Screen
        name="DebugOverview"
        component={DebugOverview}
        options={{
          title: 'Debug Overview',
          headerTransparent: true,
          headerTitleAlign: headerTitleAlign(),
          headerLeft: (props: any) => {
            props.fallBackScreen = 'Map';
            return headerLeft(props);
          },
        }}
      />
      <Stack.Screen
        name="DebugLocationScreen"
        component={DebugLocationScreen}
        options={{
          title: 'Location',
          headerTransparent: true,
          headerTitleAlign: headerTitleAlign(),
          headerLeft: (props: any) => {
            props.fallBackScreen = 'DebugOverview';
            return headerLeft(props);
          },
        }}
      />
      <Stack.Screen
        name="DebugSubscriptions"
        component={DebugSubscriptions}
        options={{
          title: 'Subs',
          headerTransparent: true,
          headerTitleAlign: headerTitleAlign(),
          headerLeft: (props: any) => {
            props.fallBackScreen = 'DebugOverview';
            return headerLeft(props);
          },
        }}
      />
    </Stack.Navigator>
  );
};
