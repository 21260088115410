import axios from 'axios';
import config from '~/config/config';

export const get = async (service: string, id: string) => {
  try {
    const entry: any = (await axios.get(`${config().server}/${service}/${id}`))
      .data;
    return entry;
  } catch (e: any) {
    if (e.response?.data) throw e.response.data;
    throw e;
  }
};

export const patch = async (service: string, id: string, data: any) => {
  try {
    const result: any = await axios.patch(
      `${config().server}/${service}/${id}`,
      data
    );
    return result;
  } catch (e: any) {
    if (e.response?.data) throw e.response.data;
    throw e;
  }
};
