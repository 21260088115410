import { getAnalytics, logEvent, setUserProperties } from 'firebase/analytics';
import { app } from '~/App';

const getAnalyticsModule = async () => {
  return getAnalytics(await app);
};

export const trackFirebase = async (event: string, params?: any) => {
  // Download and enable Google Analytics Debugger Chrome Extension to... debug: https://chrome.google.com/webstore/detail/google-analytics-debugger/jnkmfdileelhofjcijamephohjechhna
  const analyticsModule = await getAnalyticsModule();
  logEvent(analyticsModule, event, params);
};

export const setUserPropertiesFirebase = async (params: any) => {
  const analyticsModule = await getAnalyticsModule();
  setUserProperties(analyticsModule, params);
};
