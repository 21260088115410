import React from 'react';
import { Card } from 'react-native-paper';
import { StyleSheet, Text } from 'react-native';
import Select from '~/src/components/Select';
import ReparkButton from '~/src/components/ReparkButton';
import { useTranslation } from 'react-i18next';
import rptheme from '~/rptheme';
import { AuthStore } from '../AuthStore';
import { envIsDev } from '~/src/utils/helpers';
import { SnackbarStore } from '../../snackbar/SnackbarStore';
import AuthPhoneInput from './AuthPhoneInput';
import AuthOTCInput from './AuthOTCInput';

type AuthDevShortcutProps = {
  onLoginSuccess: (data: any) => void;
};

export const AuthDevShortcut = ({ onLoginSuccess }: AuthDevShortcutProps) => {
  const { t } = useTranslation();

  const signIn = AuthStore((store) => store.signIn);
  const fireSnack = SnackbarStore((store) => store.fireSnack);
  const [devUser, setDevUser] = React.useState<any>({});
  const [isLoading, setIsLoading] = React.useState(false);

  const [verificationData, setVerificationData] = React.useState();

  let users: any = [];
  if (envIsDev()) {
    try {
      users = require('~/config/users.json');
    } catch {
      // do nothing
    }
  }

  const login = async () => {
    setIsLoading(true);
    const response = await signIn(devUser, 'firebaseEmailPassword');
    if (response.success) {
      onLoginSuccess(response);
    } else {
      console.log('Login failed', response.message);
      fireSnack({
        title: t('AuthTexts.LoginFailedTitle', {
          name: devUser.firstName,
        }),
        message: t('AuthTexts.LoginFailedText'),
        type: 'error',
      });
      setIsLoading(false);
    }
  };

  if (!envIsDev()) return <></>;
  if (!users) return <></>;

  return (
    <Card style={styles.container}>
      <Card.Title title="Dev Login"></Card.Title>
      <Card.Content>
        <Text>Choose your Character:</Text>
        <Select
          items={users.map((user: any) => ({ ...user, id: user?.email }))} // But why? Because `Select` uses property `id` as key...
          displayKey={'firstName'}
          onSelectItem={(user: any) => setDevUser(user)}
        />
        <ReparkButton
          disabled={!devUser || isLoading}
          loading={isLoading}
          onPress={login}
        >
          Login
        </ReparkButton>
      </Card.Content>
      <Card.Content>
        <Text>Or debug User login:</Text>
        <AuthPhoneInput
          onVerificationReceived={async (data) => {
            setVerificationData(data);
          }}
        />
        {verificationData && (
          <AuthOTCInput
            verificationData={verificationData}
            onLoginSuccess={(data: any) => {
              onLoginSuccess(data);
            }}
          />
        )}
      </Card.Content>
    </Card>
  );
};

const styles = StyleSheet.create({
  container: {
    // marginVertical: 50,
    shadowColor: rptheme.colors.primary,
  },
  errorText: {
    color: rptheme.colors.error,
  },
});
