import React from 'react';
import i18next from '../../i18n';
import {
  SnackbarProps,
  SnackbarStore,
} from '../features/snackbar/SnackbarStore';

export const useOnlineCheck = () => {
  const [fireSnack] = SnackbarStore((store) => [store.fireSnack]);

  const getSnackData = (mode: 'online' | 'offline'): SnackbarProps => {
    return {
      title: i18next.t(`General.went${mode}`),
      type: mode === 'online' ? 'success' : 'error',
      duration: mode === 'offline' ? 10000 : 5000,
      icon: mode === 'offline' ? 'wifi-off' : 'wifi',
    };
  };

  const wentOnline = () => {
    console.log('Client went online.');
    fireSnack(getSnackData('online'));
  };
  const wentOffline = () => {
    console.log('Client went offline.');
    fireSnack(getSnackData('offline'));
  };

  React.useEffect(() => {
    console.log('Registered Online Listeners');
    if (typeof window.addEventListener === 'function') {
      window.addEventListener('online', wentOnline);
      window.addEventListener('offline', wentOffline);
    }
    return () => {
      console.log('DEregistered Online Listeners');
      if (typeof window.removeEventListener === 'function') {
        window.removeEventListener('online', wentOnline);
        window.removeEventListener('offline', wentOffline);
      }
    };
  }, []);

  const isOnline = (show = true): boolean => {
    if (!window) {
      return true;
    }
    const online = window.navigator.onLine;
    if (show && !online) {
      wentOffline();
    }

    return online;
  };
  return { isOnline };
};
