import { zodResolver } from '@hookform/resolvers/zod';
import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { StyleSheet, Image, ScrollView } from 'react-native';
import { Title, Text } from 'react-native-paper';
import rptheme from '~/rptheme';
import ReparkButton from '~/src/components/ReparkButton';
import ReparkTextInput from '~/src/components/ReparkTextInput';
import { createOnboardingZodSchema } from '~/src/rules/inputValidationRules';
import { AuthStore } from '../authentication/AuthStore';
import { OnboardingStepProps } from './OnboardingScreen';

export const OnboardingNameStep = (props: OnboardingStepProps) => {
  const [user, editUser] = AuthStore((store) => [store.user, store.editUser]);
  const { t } = useTranslation();
  const { onNextButtonPress, buttonType } = props;
  const imageSource = rptheme.graphics.nameOnboarding;
  const [isLoading, setIsLoading] = React.useState(false);

  const {
    control,
    handleSubmit,
    formState: { errors, isDirty },
  } = useForm<{
    firstName: string | undefined;
  }>({
    resolver: zodResolver(createOnboardingZodSchema(t).firstName),
    defaultValues: {
      firstName:
        !user.firstName || user.firstName === null ? '' : user.firstName,
    },
  });

  const submit = handleSubmit(async (data) => {
    if (isDirty) {
      setIsLoading(true);
      try {
        if (data.firstName === '') data.firstName = undefined;
        await editUser(data);
        onNextButtonPress();
      } catch (e) {
        console.log(e);
      } finally {
        setIsLoading(false);
      }
    }

    onNextButtonPress();
  });

  return (
    <>
      <ScrollView>
        <Title testID="title">{t('OnboardingScreen.NameStep.title')}</Title>
        <Text testID="text" style={styles.text}>
          {t('OnboardingScreen.NameStep.text')}
        </Text>

        <Controller
          name="firstName"
          control={control}
          render={({ field: { onChange } }) => (
            <ReparkTextInput
              testID={'firstNameInput'}
              onChangeText={(text: string) => onChange(text)}
              value={user.firstName === null ? '' : user.firstName}
              maxLength={40}
              textContentType="givenName"
              keyboardType="default"
              autoComplete="given-name"
              autoCorrect={false}
              spellCheck={false}
              zoderror={errors.firstName}
              label={t('AuthTexts.firstNamePlaceHolder')}
              icon={'account-outline'}
            />
          )}
        ></Controller>
        <Image testID="image" source={imageSource} style={styles.image} />
      </ScrollView>
      <ReparkButton
        loading={isLoading}
        disabled={isLoading}
        testID="nextButton"
        onPress={submit}
        style={styles.nextButton}
      >
        {t(`General.${buttonType}`)}
      </ReparkButton>
    </>
  );
};

const styles = StyleSheet.create({
  image: {
    height: 220,
    resizeMode: 'contain',
    marginVertical: 30,
  },
  text: {
    fontSize: 16,
  },
  nextButton: {
    position: 'absolute',
    bottom: 0,
    width: '100%',
  },
});
