import { PaymentIntent, PaymentMethod } from '@stripe/stripe-js';
import dayjs from 'dayjs';
import { capitalize } from '~/src/utils/helpers';

export const checkCreditCard = (paymentMethod: PaymentMethod) => {
  const { card } = paymentMethod;
  if (!card) return { isExpired: false, expiresOn: dayjs() };

  const expMonth = card?.exp_month;
  const expYear = card?.exp_year;

  const expiresOn = dayjs()
    .month(expMonth - 1)
    .year(expYear)
    .endOf('month');

  const isExpired = dayjs().isAfter(expiresOn);

  return {
    expiresOn,
    isExpired,
  };
};

export const createCreditCardTitle = (paymentMethod: PaymentMethod, t: any) => {
  if (!paymentMethod.card) return '';

  if (checkCreditCard(paymentMethod).isExpired) {
    return t('General.cardExpired');
  }
  return capitalize(paymentMethod.card.brand);
};

export const createCreditCardDescription = (
  paymentMethod: PaymentMethod,
  t: any
) => {
  return `···· ···· ···· ${paymentMethod.card?.last4} ${t(
    'General.validUntil'
  )} ${paymentMethod.card?.exp_month}/${paymentMethod.card?.exp_year}`;
};

export const createSEPADescription = (paymentMethod: PaymentMethod) => {
  return `${paymentMethod.sepa_debit?.country} ···· ···· ···· ${paymentMethod.sepa_debit?.last4}`;
};

export const getPaymentMethodDescription = (
  paymentMethod: PaymentMethod,
  t: any
) => {
  if (paymentMethod.type === 'card') {
    return createCreditCardDescription(paymentMethod, t);
  } else if (paymentMethod.type === 'sepa_debit') {
    return createSEPADescription(paymentMethod);
  }
};

/**
 * Get the allowed payment methods for a payment intent, depending on the payment method types set in the payment intent.
 * @param paymentIntent The payment intent to get the allowed payment methods for.
 * @param paymentMethods The available payment methods to filter from.
 * @returns {PaymentMethod[]} An array of allowed payment methods.
 */
export const getForPaymentIntentAllowedPaymentMethods = (
  paymentIntent: PaymentIntent,
  paymentMethods: PaymentMethod[]
): PaymentMethod[] => {
  const paymentIntentPaymentMethods = paymentIntent.payment_method_types;

  const allowedPaymentMethods = paymentMethods.filter((method) =>
    paymentIntentPaymentMethods.includes(method.type)
  );

  return allowedPaymentMethods;
};
