import { Dayjs } from 'dayjs';
import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { StyleSheet, View, Text } from 'react-native';
import { Title, Paragraph, Chip } from 'react-native-paper';
import SwiperFlatList from 'react-native-swiper-flatlist';
import rptheme from '~/rptheme';
import { getOpeningHours } from '../features/garage/garageOpeningHours';
import { formatDimensions } from '../utils/helpers';
import { AuthStore } from '../features/authentication/AuthStore';
import { garageContainsLiftingPlatform } from '../features/garage/helpers';

type FeatureSwiperProps = {
  features: Feature[];
};

type Feature = {
  label?: string;
  text?: string;
  component?: JSX.Element;
  showCondition?: boolean;
};

export const FeatureSwiper = (props: FeatureSwiperProps) => {
  const carouselRef = React.useRef(null);
  const { features } = props;

  if (!features) return <></>;

  return (
    <View style={styles.swiperContainer}>
      <SwiperFlatList
        style={styles.swiper}
        ref={carouselRef}
        data={features}
        renderItem={({ item }: { item: Feature }) => {
          return <FeatureSwiperItem feature={item} />;
        }}
      />
    </View>
  );
};

export const FeatureSwiperItem = (props: { feature: Feature }) => {
  const { feature } = props;

  if (feature.showCondition === false) return <></>;

  return (
    <View style={styles.itemContainer}>
      {feature.label && <Title style={styles.itemLabel}>{feature.label}</Title>}
      {feature.text ? <Paragraph>{feature.text}</Paragraph> : feature.component}
    </View>
  );
};

const styles = StyleSheet.create({
  swiperContainer: {
    // marginBottom: 25,
  },
  swiper: {
    paddingVertical: 15,
    // ...rptheme.shadow,
  },
  itemContainer: {
    marginHorizontal: 5,
    justifyContent: 'center',
    borderRadius: rptheme.roundness,
  },
  itemLabel: { fontSize: 10, lineHeight: 10 },
});

export const computeGarageFeatures = (garage: any, date: Dayjs) => {
  const { t } = useTranslation();
  const { loggedIn } = AuthStore();

  const openingHoursOnThatDay = getOpeningHours({
    opening_hours: garage.opening_hours,
    selectedDate: date,
  });

  const styles = StyleSheet.create({
    chip: { backgroundColor: rptheme.colors.secondary },
    chipHighlight: { backgroundColor: rptheme.colors.softHighlight },
    number: {
      fontWeight: '600',
      color: garage?.parkingSpots?.length <= 3 ? 'red' : undefined,
    },
  });

  const features = [
    {
      showCondition: garage?.showAsSpecialOffer,
      component: (
        <Chip icon="percent" style={styles.chipHighlight}>
          {t('General.specialOffer')}
        </Chip>
      ),
    },
    {
      showCondition: garageContainsLiftingPlatform(garage),
      component: (
        <Chip icon="car" style={[styles.chip]}>
          {t('garage.liftingPlatform')}
        </Chip>
      ),
    },
    {
      component: (
        <Chip icon="clock" style={styles.chip}>
          {openingHoursOnThatDay
            ? `${openingHoursOnThatDay.start} - ${openingHoursOnThatDay.stop}`
            : '0-24h'}
        </Chip>
      ),
    },
    {
      showCondition:
        loggedIn &&
        garage.parkingSpots?.length > 0 &&
        garage.status === 'enabled',
      component: (
        <Chip icon="logo" style={styles.chip}>
          <Trans
            i18nKey={'garage.availableParkingSpots'}
            count={garage.parkingSpots?.length.toString()}
          >
            noch{' '}
            <Text style={styles.number}>
              {garage.parkingSpots?.length.toString()}
            </Text>{' '}
            frei
          </Trans>
        </Chip>
      ),
    },
    {
      showCondition: !!garage?.dimensions?.width,
      component: (
        <Chip icon="width" style={styles.chip}>
          {formatDimensions(garage?.dimensions?.width)}
        </Chip>
      ),
    },
    {
      showCondition: !!garage?.dimensions?.height,
      component: (
        <Chip icon="height" style={styles.chip}>
          {formatDimensions(garage?.dimensions?.height)}
        </Chip>
      ),
    },
    {
      showCondition: true,
      component: (
        <Chip icon="cctv" style={styles.chip}>
          {garage.cctv ? t('garage.cctv') : t('garage.noCctv')}
        </Chip>
      ),
    },
    {
      showCondition: true,
      component: (
        <Chip icon="wheelchair" style={styles.chip}>
          {garage.accessibility
            ? t('garage.accessible')
            : t('garage.notAccessible')}
        </Chip>
      ),
    },
  ];

  return features;
};
