import React from 'react';
import { List } from 'react-native-paper';
import ReparkListItem, { ReparkListItemProps } from './ReparkListItem';

type ReparkListProps = {
  items: Array<ReparkListItemProps>;
  titleStyle?: any;
  descriptionStyle?: any;
  iconSize?: number;
  iconStyle?: any;
  iconColor?: any;
  iconRotation?: number;
  testId?: string;
  dense?: boolean;
};

export const ReparkList = (props: ReparkListProps) => {
  const {
    items,
    titleStyle,
    descriptionStyle,
    iconSize,
    iconColor,
    iconStyle,
    testId,
    dense = false,
  } = props;

  return (
    <List.Section style={{ margin: 0, padding: 0 }} testID={testId}>
      {items.map((item, key) => {
        if (item.showCondition === false) return;
        return (
          <ReparkListItem
            style={{ paddingVertical: item.icon || dense ? 0 : undefined }}
            key={key}
            testID={item.testID}
            onPress={item.onPress}
            title={item.title}
            description={item.description}
            icon={item.icon}
            iconRotation={item.iconRotation}
            iconSize={iconSize}
            iconStyle={iconStyle}
            iconColor={iconColor}
            titleStyle={titleStyle}
            descriptionStyle={descriptionStyle}
            right={item.right}
            dense={dense}
          />
        );
      })}
    </List.Section>
  );
};
