import { t } from 'i18next';
import React from 'react';
import { View, StyleSheet, Text, Platform } from 'react-native';
import { DatePicker } from '~/src/components/DatePicker';
import ReparkButton from '~/src/components/ReparkButton';
import { formatCurrency } from '~/src/utils/helpers';
import { AuthStore } from '../authentication/AuthStore';
import { BottomSheetStore } from '../bottom-sheet/BottomSheetStore';
import { NavProp, RootScreens } from '../navigation';
import { SnackbarStore } from '../snackbar/SnackbarStore';
import { PaymentStore } from '../payment/PaymentStore';
import { GarageStore } from '../garage/GarageStore';
import rptheme from '~/rptheme';
import { OfferBottomLoggedOut } from './OfferBottom/OfferBottomLoggedOut';
import { OfferBottomNotAvailable } from './OfferBottom/OfferBottomNotAvailable';
import { selectedDateTooFarInFuture } from './helpers';
import { VehicleStore } from '../vehicles/VehicleStore';
import { TrackingStore } from '../tracking/trackingStore';

export type OfferBottomProps = {
  navigation: NavProp;
};

export const OfferBottom = (props: OfferBottomProps) => {
  const { navigation } = props;

  const [loggedIn] = AuthStore((store) => [store.loggedIn]);
  const [fetchPaymentIntent] = PaymentStore((store) => [
    store.fetchPaymentIntent,
  ]);
  const fireSnack = SnackbarStore((store) => store.fireSnack);
  const [
    selectedGarageId,
    fetchGarages,
    calculateRate,
    offerDuration,
    offerStart,
    maxBookingDaysInFuture,
    getGarage,
    isFetchingGarages,
  ] = GarageStore((store) => [
    store.selectedGarageId,
    store.fetchGarages,
    store.calculateRate,
    store.offerDuration,
    store.offerStart,
    store.maxBookingDaysInFuture,
    store.getGarage,
    store.isFetchingGarages,
  ]);

  const closeSheet = BottomSheetStore((store) => store.closeSheet);
  const [loadingCheckout, setLoadingCheckout] = React.useState(false);
  const [selectedVehicle] = VehicleStore((store) => [store.selectedVehicle]);
  const [trackOfferViewGoToCheckout, stopOfferViewTracking] = TrackingStore(
    (store) => [store.trackOfferViewGoToCheckout, store.stopOfferViewTracking]
  );

  if (!selectedGarageId) return null;

  const goToCheckout = async () => {
    try {
      setLoadingCheckout(true);
      trackOfferViewGoToCheckout().then(() => {
        stopOfferViewTracking();
      });
      await fetchGarages();
      const offer = getGarage(selectedGarageId).parkingSpots[0].offers[0];
      await fetchPaymentIntent({
        offerId: offer.id,
        start: offerStart,
        duration: offerDuration,
        licencePlate: selectedVehicle?.licencePlate,
        bookingType: 'user',
      });

      closeSheet();
      navigation.navigate(RootScreens.CheckOutScreen);
    } catch (e) {
      setLoadingCheckout(false);
      console.log(e);
      fireSnack({
        title: t('General.error'),
        message: t('Offer.notAvailable'),
        type: 'error',
      });
    }
  };

  if (!loggedIn) {
    return <OfferBottomLoggedOut navigation={navigation} />;
  }

  if (getGarage(selectedGarageId)?.status === 'comingSoon') {
    return (
      <OfferBottomNotAvailable type="comingSoon" navigation={navigation} />
    );
  }

  if (selectedDateTooFarInFuture(offerStart, maxBookingDaysInFuture)) {
    return <OfferBottomNotAvailable type="tooFarInFuture" />;
  }

  if (!getGarage(selectedGarageId)?.parkingSpots?.length) {
    return <OfferBottomNotAvailable type="fullyBooked" />;
  }

  return (
    <View testID="loggedInView" style={styles.container}>
      <View style={styles.buttonContainer}>
        <DatePicker
          humanReadable={false}
          mode="outlined"
          offerBottom={true}
          isLoading={isFetchingGarages}
        />

        <ReparkButton
          testID="checkoutButton"
          loading={loadingCheckout || isFetchingGarages}
          disabled={loadingCheckout || isFetchingGarages}
          style={styles.checkoutButton}
          onPress={goToCheckout}
        >
          <>
            <Text>
              {formatCurrency(calculateRate(getGarage(selectedGarageId)))}
            </Text>
            <Text>{t('Offer.book')}</Text>
          </>
        </ReparkButton>
      </View>
    </View>
  );
};

export const sheetPosition = Platform.OS === 'web' ? 'fixed' : 'absolute';

export const styles = StyleSheet.create({
  container: {
    backgroundColor: 'white',
    padding: 5,
    bottom: 0,
    //@ts-ignore-next-line
    position: sheetPosition,
    width: '100%',
    ...{ ...rptheme.shadow, ...{ shadowRadius: 10 } },
  },
  buttonContainer: { display: 'flex', flexDirection: 'row' },
  checkoutButton: {
    flexGrow: 3,
    margin: 5,
  },
  rate: {
    color: rptheme.colors.primary,
  },
});
