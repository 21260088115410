import create from 'zustand';
import { feathersClient } from '~/src/services/feathers';
import { fetchBookingsRequest, patchBookingRequest } from './bookingService';
import {
  isActiveBooking,
  isUpcomingBooking,
  isPastBooking,
  isCanceledBooking,
} from './helpers';
import { AuthStore } from '../authentication/AuthStore';
import dayjs, { Dayjs } from 'dayjs';
import duration from 'dayjs/plugin/duration';
import { checkForAutofill } from '../feedback/ReasonOfBookingHelper';
import { fetchGaragesRequest } from '../garage/garageService';
import { getGarageParkingSpots } from '~/src/services/parkingSpotService';
import { fetchOffersRequest } from '../offer/offerService';

dayjs.extend(duration);

type UpcomingBookingProps = {
  excludeActive?: boolean;
  excludeCanceled?: boolean;
};

type FindDuplicateBookingProps = {
  offerStart: dayjs.Dayjs;
  offerDuration: number;
  garageId: string;
};

type BookingStore = {
  isFetchingBookings: boolean;
  bookings: Array<any>;
  cancelNewActiveBookingThreshold: number; // After a new booking on the same day was created, you have this number in minutes time to cancel the new active booking.
  canExtendOption: (id: string) => Promise<boolean>;
  /**
   * @param booking
   * @return true if was autofilled
   */
  getExtendOffer: (
    booking: any,
    start: Dayjs,
    duration: number
  ) => Promise<any>;
  rawParkingSpots: Array<any>;
  autofillRoB: (booking: any) => Promise<boolean>;
  hasActiveBooking: () => boolean;
  pastBookings: () => Array<any>;
  upcomingBookings: (props?: UpcomingBookingProps) => Array<any>;
  activeBookings: () => Array<any>;
  fetchBookings: (query?: any) => Promise<void>;
  patchBooking: (data: any) => Promise<any>;
  getBooking: (id: string) => any;
  findDuplicateBooking: (props: FindDuplicateBookingProps) => any | undefined;
  insertBooking: (booking: any) => void;
  addEventlisteners: () => void;
  removeEventlisteners: () => void;
  resetStore: () => void;
};

export const BookingStore = create<BookingStore>((set, get) => ({
  isFetchingBookings: false,
  bookings: [],
  rawParkingSpots: [],
  cancelNewActiveBookingThreshold: 5,
  canExtendOption: async (id: string): Promise<boolean> => {
    console.log('CHECKING if offer is extendable');
    if (!id) {
      return false;
    }
    let booking = get().getBooking(id);
    if (!booking) {
      get().fetchBookings();
      booking = get().getBooking(id);
      if (!booking) {
        return false;
      }
    }
    if (isPastBooking(booking) || isCanceledBooking(booking)) {
      return false;
    }
    const start = dayjs(booking.stop).add(1, 'day').startOf('day');
    const offerDuration = dayjs.duration(1, 'day').asMinutes();

    const response = await fetchGaragesRequest({
      start,
      duration: offerDuration,
    });
    const rawParkingSpots = await getGarageParkingSpots(booking.garageId);

    const fetchedGarage = response.find(
      (grg: any) => grg.id === booking.garageId
    );
    if (!fetchedGarage) {
      return false;
    }
    const parkingAssign = fetchedGarage.parkingSpotAssignment;
    const isFreeChoice = ['free-choice', 'restricted-free-choice'].includes(
      parkingAssign
    );

    const originalParkingSpotId = booking.parkingSpot.id;
    const originalParkingSpot = rawParkingSpots.find(
      (ps: any) => ps.id === originalParkingSpotId
    );

    let possibleParkingSpots = fetchedGarage.parkingSpots;
    if (originalParkingSpot.companyId !== null) {
      console.log('Original Parking Spot is not null');
      const compId = originalParkingSpot.companyId;
      possibleParkingSpots = possibleParkingSpots.filter(
        (ps: any) => ps.companyId === compId
      );
    }

    console.log(
      'Offers for extension: possibleParkingSpots',
      possibleParkingSpots
    );

    set({ rawParkingSpots });

    if (
      fetchedGarage &&
      possibleParkingSpots.length > 0 &&
      possibleParkingSpots[0].offers.length > 0
    ) {
      if (isFreeChoice) {
        return true;
      } else if (parkingAssign === 'on-arrival') {
        return false;
      } else if (parkingAssign === 'fixed') {
        return possibleParkingSpots
          .map((spot: any) => spot.id)
          .includes(originalParkingSpotId);
      }
    } else {
      return false;
    }
    return false;
  },
  getExtendOffer: async (booking: any, start: Dayjs, duration: number) => {
    console.log(booking, start, duration);
    const response = await fetchGaragesRequest({
      start,
      duration,
    });
    const fetchedGarage = response.find(
      (grg: any) => grg.id === booking.garageId
    );
    if (!fetchedGarage) {
      return false;
    }
    const raw = get().rawParkingSpots;
    const assignment = fetchedGarage.parkingSpotAssignment;

    console.log('GARAGE:', fetchedGarage);

    const originalParkingSpotId = booking.parkingSpot.id;
    const originalParkingSpot = raw.find(
      (ps: any) => ps.id === originalParkingSpotId
    );

    let possibleParkingSpots = fetchedGarage.parkingSpots;
    if (originalParkingSpot.companyId !== null) {
      console.log('Original Parking Spot is not null');
      const compId = originalParkingSpot.companyId;
      possibleParkingSpots = possibleParkingSpots.filter(
        (ps: any) => ps.companyId === compId
      );
    }

    const parkingSpotIds = possibleParkingSpots.map((ps: any) => ps.id);
    const offersData = await fetchOffersRequest({
      parkingSpotId: {
        $in: parkingSpotIds,
      },
    });
    return { offers: offersData.data, fetchedGarage, assignment };
  },
  hasActiveBooking: () => {
    return (
      get().bookings.filter((booking) => isActiveBooking(booking)).length > 0
    );
  },

  pastBookings: () => {
    return get().bookings.filter((booking) => isPastBooking(booking));
  },

  upcomingBookings: (
    props = { excludeActive: false, excludeCanceled: false }
  ) => {
    const { excludeActive, excludeCanceled } = props;

    return get().bookings.filter((booking) => {
      if (excludeCanceled && booking.canceled) {
        return false;
      }

      if (excludeActive && isActiveBooking(booking)) {
        return false;
      }

      if (isUpcomingBooking(booking)) {
        return true;
      }
      return false;
    });
  },

  activeBookings: () => {
    return get().bookings.filter((booking) => isActiveBooking(booking));
  },

  fetchBookings: async (query) => {
    const now = Date.now();
    set({ isFetchingBookings: true });
    try {
      const response = await fetchBookingsRequest(query);
      set({ bookings: await response, isFetchingBookings: false });
    } catch (e) {
      set({ isFetchingBookings: false });
      throw e;
    }
  },

  patchBooking: async (data: any) => {
    const response = await patchBookingRequest(data);
    return response?.data;
  },

  getBooking: (id: string) => {
    return get().bookings?.find((booking: any) => booking.id === id);
  },

  findDuplicateBooking: async (props: FindDuplicateBookingProps) => {
    const { offerStart, offerDuration, garageId } = props;
    console.log('Looking for duplicate booking.');
    try {
      const bookings = await fetchBookingsRequest({
        userId: AuthStore.getState().user?.id,
        garageId,
        start: offerStart,
        duration: offerDuration,
        canceled: false,
      });
      return bookings?.[0];
    } catch (e) {
      throw e;
    }
  },

  insertBooking: (booking: any) => {
    const bookings = get().bookings;
    bookings.push(booking);
    set({ bookings });
  },

  autofillRoB: async (booking: any): Promise<boolean> => {
    const { id, userId, parkingSpot, garageId } = booking;
    const garage = garageId || parkingSpot.garage.id || undefined;
    if (!garage) {
      return false;
    }

    const bookings = await fetchBookingsRequest({
      userId,
      garageId,
      start: { $lt: new Date(Date.now()) },
      id: { $ne: id },
    });

    if (bookings.length <= 1) {
      //does not meet qualification for autofill
      return false;
    }

    let reasonOfBooking = checkForAutofill(bookings);
    if (reasonOfBooking == null) {
      return false;
    }

    //case when we autofill
    // let's mark it as autofilled as well
    if (!reasonOfBooking.endsWith('[autofilled]')) {
      reasonOfBooking += '[autofilled]';
    }
    try {
      await get().patchBooking({ id, reasonOfBooking });
    } catch (e) {
      return false;
    }
    return true;
  },

  addEventlisteners: () => {
    console.log('Adding Bookings Eventlisteners');

    feathersClient.service('bookings').on('created', (booking: any) => {
      get().fetchBookings({ userId: AuthStore.getState().user?.id });
    });

    feathersClient.service('bookings').on('removed', () => {
      get().fetchBookings({ userId: AuthStore.getState().user?.id });
    });

    feathersClient.service('bookings').on('patched', () => {
      get().fetchBookings({ userId: AuthStore.getState().user?.id });
    });
  },

  removeEventlisteners: () => {
    console.log('Removing Bookings Eventlisteners');

    feathersClient.service('bookings').removeAllListeners('created');
    feathersClient.service('bookings').removeAllListeners('removed');
    feathersClient.service('bookings').removeAllListeners('patched');
  },

  resetStore: () => {
    set({
      isFetchingBookings: false,
      bookings: [],
    });
  },
}));
