import React from 'react';
import { StyleSheet, View, Linking, Platform } from 'react-native';
import rptheme from '~/rptheme';
import { Paragraph, Title } from 'react-native-paper';
import ReparkButton from '~/src/components/ReparkButton';
import * as Location from 'expo-location';
import { LocationPermissionResponse } from 'expo-location';
import { BottomSheetStore } from '../bottom-sheet/BottomSheetStore';
import { useTranslation } from 'react-i18next';

type RequestPermissionSheetProps = {
  onPermissionGranted: () => any;
  setStatus: (status: LocationPermissionResponse | null) => void;
  getStatus: () => Location.LocationPermissionResponse | null;
};

export default function RequestLocationPermissionSheet(
  props: RequestPermissionSheetProps
) {
  const closeSheet = BottomSheetStore((store) => store.closeSheet);
  const { t } = useTranslation();

  const getPermission = async () => {
    const status = await Location.requestForegroundPermissionsAsync();
    props.setStatus(status);
    if (status.granted) {
      props.onPermissionGranted();
    }
    closeSheet();
  };

  const goToSettings = () => {
    props.setStatus(null);
    closeSheet();
    Linking.openSettings();
  };

  return (
    <View style={styles.container}>
      {props.getStatus()?.status !== null &&
      props.getStatus()?.status !== 'denied' ? (
        <>
          <Title style={styles.title} testID={'requestTitle'}>
            {t('Location.RequestLocationTitle')}
          </Title>
          <Paragraph style={styles.text} testID={'requestText'}>
            {t('Location.RequestLocationText')}
          </Paragraph>
          <ReparkButton onPress={getPermission} testID={'requestButton'}>
            {t('Location.RequestLocationButtonTitle')}
          </ReparkButton>
        </>
      ) : (
        <>
          <Title style={styles.title} testID={'deniedTitle'}>
            {t('Location.RequestLocationDeniedTitle')}
          </Title>
          <Paragraph style={styles.text} testID={'deniedText'}>
            {t('Location.RequestLocationDeniedText')}
          </Paragraph>
          {Platform.OS !== 'web' && (
            <ReparkButton onPress={goToSettings} testID={'deniedButton'}>
              {t('Location.RequestLocationToSettingsButton')}
            </ReparkButton>
          )}
        </>
      )}
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'center',
    margin: 25,
  },
  title: {
    textAlign: 'center',
    color: rptheme.colors.primary,
  },
  text: {
    textAlign: 'center',
    marginVertical: 5,
    fontSize: 16,
  },
});
