import { feathersClient } from '~/src/services/feathers';

export const fetchCompaniesRequest = async (query: any) => {
  const response = await feathersClient.service('companies').find({
    query: {
      ...query,
    },
  });
  return response.data;
};
