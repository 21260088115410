import * as React from 'react';
import { Button } from 'react-native-paper';
import { View } from 'react-native';
import rptheme from '~/rptheme';

export default function ReparkButton(
  props: React.ComponentProps<typeof Button> & {
    fontSize?: number;
    outlineThickness?: number;
    marginVertical?: number;
    backgroundColor?: string;
  }
) {
  const outlinedStyle =
    props.mode === 'outlined'
      ? {
          borderColor: props.color || rptheme.colors.primary,
          borderWidth: props.outlineThickness ? props.outlineThickness : 1,
        }
      : undefined;

  return (
    <Button
      style={[
        {
          marginVertical: props.marginVertical ? props.marginVertical : 10,
          elevation: 0,
        },
        outlinedStyle,
      ]}
      uppercase={false}
      mode="contained"
      {...props}
    >
      <View style={{ display: 'flex' }}>
        <>{props.children}</>
      </View>
    </Button>
  );
}
