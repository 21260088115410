import React from 'react';
import { useTranslation } from 'react-i18next';
import { StyleSheet, View } from 'react-native';
import { formatDimensions } from '~/src/utils/helpers';
import { ReparkList } from '~/src/components/ReparkList';
import { Subheading } from 'react-native-paper';
import { computeGarageOrientationInfo } from './helpers';

type GarageDetailListProps = {
  garage: any;
  parkingSpot?: {
    type: 'flat' | 'lifting-platform';
  };
  options?: {
    hideSize: boolean;
  };
};

export const GarageDetailList = ({
  garage,
  parkingSpot,
  options,
}: GarageDetailListProps) => {
  const { t } = useTranslation();
  const hideSize = options?.hideSize || false;

  const garageOrientationInfo = computeGarageOrientationInfo(garage, t);

  const garageSize = [
    {
      showCondition: !!garage?.dimensions?.height,
      testID: 'heightInfo',
      title: formatDimensions(garage?.dimensions?.height),
      icon: 'height',
    },
    {
      showCondition: !!garage?.dimensions?.width,
      testID: 'widthInfo',
      title: formatDimensions(garage?.dimensions?.width),
      icon: 'height',
      iconRotation: 90,
    },
  ];

  const garageInfo = [
    {
      testID: 'accessibilityInfo',
      title: garage.accessibility
        ? t('garage.accessible')
        : t('garage.notAccessible'),
      icon: 'wheelchair',
    },
    {
      testID: 'cctvInfo',
      title: garage.cctv ? t('garage.cctv') : t('garage.noCctv'),
      icon: 'cctv',
    },
    {
      showCondition: parkingSpot?.type === 'lifting-platform',
      testID: 'parkingSpotTypeInfo',
      title: t('garage.liftingPlatform'),
      icon: 'parkingspot',
    },
    {
      showCondition: garage?.accessType === 'repark-app',
      testID: 'accessTypeInfo',
      title: t('garage.accessWithReparkApp'),
      icon: 'logo',
    },
  ];

  return (
    <View>
      <Subheading style={styles.title}>
        {t('garage.orientation.title')}
      </Subheading>
      <ReparkList items={garageOrientationInfo} />
      {hideSize ? null : (
        <>
          {garage.dimensions && (
            <>
              <Subheading style={styles.title}>
                {t('garage.entrySizeTitle')}
              </Subheading>
              <ReparkList items={garageSize} />
            </>
          )}
        </>
      )}
      <Subheading style={styles.title}>
        {t('garage.parkingSpotDetailsTitle')}
      </Subheading>
      <ReparkList items={garageInfo} />
    </View>
  );
};

const styles = StyleSheet.create({
  title: {
    margin: 20,
    marginTop: 30,
  },
});
