import * as Location from 'expo-location';
import React from 'react';

import { LocationStore } from '~/src/features/location/LocationStore';

export default function LocationInitializer() {
  const [
    setLocationPermissionStatus,
    startLocationUpdates,
    stopLocationUpdates,
  ] = LocationStore((store) => {
    return [
      store.setLocationPermissionStatus,
      store.startLocationUpdates,
      store.stopLocationUpdates,
    ];
  });

  React.useEffect(() => {
    const initializeLocation = async () => {
      console.log('Initializing web location');

      // On web we use `navigator.permissions.query` because `Location.useForegroundPermissions`
      // triggers the system menu, which queries the user to share her location

      if (navigator.permissions?.query) {
        // This function will only be added in Safari 16 (rolling out Fall 2022)
        const response = await navigator.permissions.query({
          name: 'geolocation',
        });
        const status: Location.PermissionStatus =
          response.state === 'granted'
            ? Location.PermissionStatus.GRANTED
            : response.state === 'denied'
            ? Location.PermissionStatus.DENIED
            : Location.PermissionStatus.UNDETERMINED;

        const locationPermission: Location.LocationPermissionResponse = {
          status,
          expires: 0,
          granted: status === Location.PermissionStatus.GRANTED,
          canAskAgain: true,
        };

        console.log('Got location permission status', locationPermission);
        setLocationPermissionStatus(locationPermission);

        if (locationPermission?.granted) {
          startLocationUpdates();
        }
      }
    };

    initializeLocation();
    return () => {
      stopLocationUpdates();
    };
  }, []);

  return <></>;
}
