import React from 'react';
import { Text, ScrollView, StyleSheet, Image } from 'react-native';
import { NavProp, RootScreens } from '../navigation';
import { GarageStore } from '../garage/GarageStore';
import { LocationStore } from '../location/LocationStore';
import ReparkButton from '~/src/components/ReparkButton';
import { Title } from 'react-native-paper';
import rptheme from '~/rptheme';
import { useTranslation } from 'react-i18next';
import * as Location from 'expo-location';

type NearbyScreenProps = {
  navigation: NavProp;
};

export const NearbyScreen = (props: NearbyScreenProps) => {
  const { navigation } = props;
  const { t } = useTranslation();
  const [garages] = GarageStore((store) => [store.garages]);
  const [userDistanceTo, startLocationUpdates, locationPermission, location] =
    LocationStore((store) => [
      store.userDistanceTo,
      store.startLocationUpdates,
      store.locationPermission,
      store.location,
      store.isGettingLocation,
    ]);

  const [isLoading, setIsLoading] = React.useState(false);
  const [canGetLoaction, setCanGetLocation] = React.useState(
    locationPermission?.status !== 'denied'
  );

  const imageSource = rptheme.graphics.nearby;

  React.useEffect(() => {
    const updateGaragesWithDistance = async () => {
      const garagesWithDistance = await Promise.all(
        garages.map(async (garage) => {
          const distance = await userDistanceTo(garage);
          return {
            ...garage,
            distance: distance && distance.toFixed(2),
          };
        })
      );

      garagesWithDistance.sort((a, b) => a.distance - b.distance);

      navigation.replace(RootScreens.MapScreen, {
        id: garagesWithDistance[0].id,
      });
    };

    if (location) updateGaragesWithDistance();
  }, [locationPermission, location]);

  const getPermission = async () => {
    setIsLoading(true);
    const status = await Location.requestForegroundPermissionsAsync();
    if (status.granted) {
      startLocationUpdates();
    } else {
      setCanGetLocation(false);
    }
    setIsLoading(false);
  };

  return (
    <ScrollView style={styles.container}>
      {!location && (
        <>
          <Title>{t('Nearby.title')}</Title>
          <Text style={styles.text}>{t('Nearby.text')}</Text>
          <Image testID="image" source={imageSource} style={styles.image} />

          {canGetLoaction ? (
            <ReparkButton
              loading={isLoading}
              disabled={isLoading}
              onPress={getPermission}
            >
              {isLoading
                ? t('Nearby.gettingLocation')
                : t('Nearby.requestPremission')}
            </ReparkButton>
          ) : (
            <Text style={styles.text}>
              {t('Nearby.noLocationPermissionText')}
            </Text>
          )}

          <ReparkButton
            onPress={() => navigation.replace(RootScreens.MapScreen)}
            mode="text"
          >
            {t('General.skip')}
          </ReparkButton>
        </>
      )}
    </ScrollView>
  );
};

const styles = StyleSheet.create({
  container: {
    margin: 30,
    padding: 10,
    flex: 1,
    maxWidth: rptheme.screenSmall,
    width: '100%',
    alignSelf: 'center',
  },
  image: {
    height: 220,
    resizeMode: 'contain',
    marginVertical: 30,
  },
  text: {
    fontSize: 16,
  },
});
