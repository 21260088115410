import React from 'react';
import { View, Text, StyleSheet } from 'react-native';
import { computeSubwayColor } from '../helpers';

type SubwayStationIconProps = {
  line: number;
};

export const SubwayStationIcon = (props: SubwayStationIconProps) => {
  const { line } = props;
  return (
    <View style={{ justifyContent: 'center' }}>
      <View
        style={[
          styles.lineContainer,
          { backgroundColor: computeSubwayColor(line) },
        ]}
      >
        <Text style={styles.lineText}>U{line}</Text>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  lineContainer: {
    aspectRatio: 1,
    justifyContent: 'center',
    width: 17,
    alignContent: 'center',
    marginHorizontal: 2,
    padding: 3,
  },
  lineText: { textAlign: 'center', color: 'white', fontSize: 7 },
});
