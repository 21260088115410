import * as React from 'react';
import { List } from 'react-native-paper';
import { GestureResponderEvent, StyleSheet } from 'react-native';
import rptheme from '~/rptheme';
import { ReparkIcon } from './ReparkIcon';

export type ReparkListItemProps = {
  testID?: string;
  title: string | React.ReactNode;
  description?: string;
  icon?: string;
  iconSize?: number;
  iconStyle?: any;
  iconColor?: any;
  iconRotation?: number;
  onPress?:
    | (((event: GestureResponderEvent) => void) &
        ((e: GestureResponderEvent) => void) &
        (() => void))
    | undefined;
  style?: any;
  showCondition?: boolean;
  titleStyle?: any;
  descriptionStyle?: any;
  right?: any;
  dense?: boolean;
};

export default function ReparkListItem(
  props: ReparkListItemProps & React.ComponentProps<typeof List.Item>
) {
  const { icon, iconSize, iconStyle, iconColor, iconRotation, disabled } =
    props;

  let dynamicIconStyle: any = styles.iconStyle;
  if (iconStyle) {
    dynamicIconStyle = StyleSheet.compose(styles.iconStyle, iconStyle);
  }
  if (iconRotation) {
    const newStyles: any = StyleSheet.create({
      iconStyle: {
        transform: [{ rotate: `${iconRotation}deg` }],
      },
    });
    dynamicIconStyle = StyleSheet.compose(
      styles.iconStyle,
      newStyles.iconStyle
    );
  }

  return (
    <List.Item
      titleNumberOfLines={2}
      style={{
        opacity: disabled ? 0.5 : undefined,
      }}
      left={(props) =>
        icon && (
          <List.Icon
            {...props}
            icon={() => (
              <ReparkIcon
                name={icon}
                size={iconSize || 16}
                color={
                  disabled ? 'lightgrey' : iconColor || rptheme.colors.primary
                }
              ></ReparkIcon>
            )}
            style={dynamicIconStyle}
          />
        )
      }
      {...props}
    />
  );
}

const styles = StyleSheet.create({
  iconStyle: {
    alignSelf: 'center',
  },
});
