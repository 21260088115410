import { PaymentMethod } from '@stripe/stripe-js';
import React from 'react';
import { CardItem } from '../paymentMethodItems/CardItem';
import { SEPAItem } from '../paymentMethodItems/SEPAItem';
import { View } from 'react-native';

type PaymentMethodsListProps = {
  paymentMethods: PaymentMethod[];
  onPress: (id: string) => void;
  onDeletePaymentMethod?: (paymentMethodId: string) => void;
};

export const PaymentMethodsList = ({
  onPress,
  paymentMethods,
  onDeletePaymentMethod,
}: PaymentMethodsListProps) => {
  return (
    <View testID={'paymentMethodList'}>
      {paymentMethods.map((paymentMethod) => {
        return (
          <div key={paymentMethod.id} onClick={() => onPress(paymentMethod.id)}>
            <>
              {paymentMethod.type === 'card' && (
                <CardItem
                  card={paymentMethod}
                  onDeletePaymentMethod={onDeletePaymentMethod}
                />
              )}

              {paymentMethod.type === 'sepa_debit' && (
                <SEPAItem
                  sepa_debit={paymentMethod}
                  onDeletePaymentMethod={onDeletePaymentMethod}
                />
              )}
            </>
          </div>
        );
      })}
    </View>
  );
};
