import React from 'react';
import { StyleSheet, Text, View } from 'react-native';
import rptheme from '~/rptheme';

export type BookingDetailItemFrameProps = {
  children: any;
  title: string;
  titleSize?: number;
};

export const BookingDetailItemFrame = (props: BookingDetailItemFrameProps) => {
  const { children, title, titleSize = 13 } = props;
  return (
    <View style={styles.container}>
      <Text
        style={[styles.title, { fontSize: titleSize }]}
        testID="bookingDetailTitle"
      >
        {title}
      </Text>
      <View style={styles.children}>{children}</View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    marginVertical: 10,
    maxWidth: '80%',
    alignSelf: 'center',
  },
  title: {
    textAlign: 'center',
    marginBottom: 5,
    color: rptheme.colors.primary,
  },
  children: {
    flexDirection: 'row',
    justifyContent: 'center',
    textAlign: 'center',
  },
});
