import { Polyline } from '@react-google-maps/api';
import React from 'react';
import { computeSubwayColor, isSubwayFinalDestination } from '../helpers';
import viennaSubwayData from './viennasubwaydata.json';
import { SubwayViennaProps } from './SubwayVienna';
import {
  SubwayViennaFinalStopMarker,
  SubwayViennaStopMarker,
} from './SubwayViennaMarker';

export const SubwayVienna = (props: SubwayViennaProps) => {
  return (
    <>
      {viennaSubwayData.features.map((feature) => {
        if (feature.geometry.type === 'Point' && props.zoom < 13) {
          return null;
        }
        if (feature.geometry.type === 'LineString' && props.zoom < 11) {
          return null;
        }

        if (feature.geometry.type === 'Point') {
          if (isSubwayFinalDestination(feature.properties.OBJECTID)) {
            return (
              <SubwayViennaFinalStopMarker key={feature.id} station={feature} />
            );
          }

          return <SubwayViennaStopMarker key={feature.id} station={feature} />;
        }

        return (
          <Polyline
            key={feature.id}
            options={{
              strokeColor: computeSubwayColor(feature.properties.LINFO),
              strokeWeight: 2,
            }}
            path={feature.geometry.coordinates.map((entry: any) => {
              return { lng: entry[0], lat: entry[1] };
            })}
          />
        );
      })}
    </>
  );
};
