import * as React from 'react';
import { Paragraph, Subheading, TextInput } from 'react-native-paper';
import { Platform, StyleSheet, View } from 'react-native';
import rptheme from '~/rptheme';
import CountryPicker, { CountryCode } from 'react-native-country-picker-modal';
import parsePhoneNumber from 'libphonenumber-js';
import min from 'libphonenumber-js/metadata.min.json';

export default function ReparkPhoneInput(
  props: Omit<React.ComponentProps<typeof TextInput>, 'autoComplete'> & {
    topLabel?: string;
    onChangeText: Function;
    zoderror: any;
    showError: boolean;
  } & Partial<React.ComponentProps<typeof CountryPicker>>
) {
  const { topLabel, zoderror, showError } = props;

  const [countryCode, setCountryCode] = React.useState('AT' as CountryCode);
  const [country, setCountry] = React.useState({ callingCode: '43' });
  const [phoneNumber, setPhoneNumber] = React.useState(props.value || '');

  const onSelect = (country: any) => {
    setCountry(country);
    setCountryCode(country.cca2);
  };

  const cleanup = (number: string) => {
    if (number.includes(`+${country.callingCode}`)) {
      return number.replace(`+${country.callingCode}`, '').trim();
    }
    if (number.startsWith('0')) {
      return number.substring(1);
    }
    return number;
  };

  React.useEffect(() => {
    if (phoneNumber !== '') {
      const formattedPhoneNumber = parsePhoneNumber(phoneNumber);
      if (formattedPhoneNumber) {
        if (formattedPhoneNumber.country) {
          setCountryCode(formattedPhoneNumber.country as CountryCode);
        } else {
          //found calling found with more than one country, so show the first found country
          const getCountriesOfCallingCode =
            min.country_calling_codes[formattedPhoneNumber.countryCallingCode];
          if (getCountriesOfCallingCode) {
            setCountryCode(getCountriesOfCallingCode[0] as CountryCode);
          } else {
            console.error('CallingCode Not Found...');
          }
        }
        setCountry({ callingCode: formattedPhoneNumber.countryCallingCode });
      }
    }
    setPhoneNumber(cleanup(phoneNumber));
    props.onChangeText(`+${country.callingCode} ${phoneNumber}`);
  }, [phoneNumber, country]);

  return (
    <View style={styles.container}>
      {topLabel && (
        <Subheading style={styles.subheading}>{topLabel}</Subheading>
      )}

      <TextInput
        theme={{ roundness: rptheme.roundness }}
        style={styles.outerTextInput}
        mode="outlined"
        autoComplete="tel"
        dense
        outlineColor={zoderror ? rptheme.colors.error : rptheme.colors.primary}
        activeOutlineColor={
          zoderror ? rptheme.colors.error : rptheme.colors.primary
        }
        {...props}
        render={(props: any) => (
          <View style={{ display: 'flex', flexDirection: 'row' }}>
            <CountryPicker
              {...{
                countryCode,
                withFlag: true,
                withCallingCode: true,
                preferredCountries: ['AT', 'DE'],
                withAlphaFilter: false,
                withCallingCodeButton: true,
                withModal: true,
                onSelect,
              }}
              containerButtonStyle={styles.countryPicker}
            />
            <TextInput
              {...props}
              mode="flat"
              dense
              outlineColor="transparent"
              underlineColor="transparent"
              activeUnderlineColor="transparent"
              activeOutlineColor="transparent"
              textContentType="telephoneNumber"
              keyboardType="phone-pad"
              autoComplete="tel"
              value={phoneNumber}
              style={styles.innerTextInput}
              onChangeText={(text) => setPhoneNumber(text)}
            />
          </View>
        )}
      />
      {showError && zoderror && (
        <Paragraph style={styles.errorText}>{zoderror.message}</Paragraph>
      )}
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    marginVertical: 10,
  },
  subheading: {
    color: rptheme.colors.primary,
  },
  errorText: {
    color: rptheme.colors.error,
    marginTop: 5,
    textAlign: 'center',
  },
  countryPicker: Platform.OS === 'android' ? { marginTop: 3 } : {},
  outerTextInputTheme: {},
  outerTextInput: {
    paddingHorizontal: 10,
  },
  innerTextInput: {
    backgroundColor: 'transparent',
    width: '80%',
    marginTop: Platform.OS === 'web' ? 0 : -1,
  },
});
