import React from 'react';
import { View, Text, TouchableOpacity } from 'react-native';
import rptheme from '~/rptheme';
import { PricePer } from '../offer/PricePer';
import { Chip } from 'react-native-paper';
import { formatDimensions, formatFloor } from '~/src/utils/helpers';

type ParkingSpotListItemProps = {
  parkingSpot: {
    name: string;
    id: string;
    products: {
      prices: {
        unit_amount: number;
        interval_count: number;
        id: string;
        interval: 'month' | 'year';
      }[];
    }[];
    dimensions: {
      height: number;
      width: number;
    };
    floor: number;
  };
  highlighted?: boolean;
  onPress: () => void;
};

export const ParkingSpotListItem = (props: ParkingSpotListItemProps) => {
  const { parkingSpot, highlighted, onPress } = props;

  const price = parkingSpot.products[0].prices.find(
    (price) => price.interval === 'month' && price.interval_count === 1
  );

  const chips = [
    {
      icon: 'height',
      label: parkingSpot.dimensions?.height
        ? formatDimensions(parkingSpot.dimensions?.height)
        : 'N/A',
    },
    {
      icon: 'width',
      label: parkingSpot.dimensions?.width
        ? formatDimensions(parkingSpot.dimensions?.width)
        : 'N/A',
    },
    {
      icon: 'marker',
      label: formatFloor(parkingSpot.floor) || 'N/A',
    },
  ];

  return (
    <TouchableOpacity
      style={[
        { padding: 15, marginVertical: 5, borderWidth: 1 },
        {
          backgroundColor: 'white',
          borderRadius: rptheme.roundness,
          borderColor: highlighted ? rptheme.colors.primary : 'lightgrey',
        },
      ]}
      key={parkingSpot.id}
      onPress={onPress}
    >
      <View
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
        }}
      >
        <View
          style={{
            backgroundColor: 'lightgrey',
            justifyContent: 'center',
            width: 75,
            height: 75,
            alignItems: 'center',
          }}
        >
          <Text style={{ borderRadius: rptheme.roundness }}>
            {parkingSpot.name}
          </Text>
        </View>
        <View
          style={{
            flex: 1,
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
          }}
        >
          <View
            style={{
              display: 'flex',
              flex: 1,
              flexDirection: 'row',
              flexWrap: 'wrap-reverse',
              alignContent: 'center',
              paddingLeft: 5,
              maxWidth: '180px',
            }}
          >
            {chips.map((chip) => (
              <View key={chip.icon} style={{ marginRight: 5, marginBottom: 5 }}>
                <Chip
                  style={{ backgroundColor: rptheme.colors.secondary }}
                  icon={chip.icon}
                  onPress={onPress}
                >
                  {chip.label}
                </Chip>
              </View>
            ))}
          </View>
          {price?.unit_amount && (
            <View style={{ alignSelf: 'center' }}>
              <PricePer price={price?.unit_amount} interval="month" />
            </View>
          )}
        </View>
      </View>
    </TouchableOpacity>
  );
};
