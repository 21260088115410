import * as React from 'react';
import { StyleSheet, View, Linking, Image } from 'react-native';
import { Trans, useTranslation } from 'react-i18next';
import { Paragraph, Title } from 'react-native-paper';
import ReparkButton from '~/src/components/ReparkButton';
import { ScrollView } from 'react-native-gesture-handler';

export default function ClaimAccessFallback() {
  const { t } = useTranslation();

  return (
    <ScrollView style={styles.container}>
      <Title testID="title">{t('ClaimAccessScreen.FallbackTitle')}</Title>
      <Paragraph testID="intro">
        <Trans i18nKey={'ClaimAccessScreen.FallbackIntro'}>
          Wenn deine Garage bereits digitalisiert ist, findest du so einen
          QR-Code in deinem Stiegenhaus, Postkasten oder E-Mail-Postfach.
          {'\n\n'}
          Scanne diesen und fordere den Zugriff auf deinen Parkplatz an.
        </Trans>
      </Paragraph>
      <View style={styles.imageContainer}>
        <Image
          style={styles.image}
          source={{
            uri: 'https://www.repark.at/wp-content/uploads/2022/05/qr-code-repark-at.png',
          }}
        />
      </View>
      <Paragraph testID="outro">
        <Trans i18nKey={'ClaimAccessScreen.FallbackOutro'}>
          Du kannst deinen QR-Code nicht finden oder möchtest, dass wir deine
          Garage digitalisieren?{'\n\n'}
          Kontaktiere uns!
        </Trans>
      </Paragraph>
      <ReparkButton
        testID="contactButton"
        style={styles.button}
        onPress={() => {
          Linking.openURL('mailto:contact@repark.at');
        }}
      >
        {t('General.contactViaMailButton')}
      </ReparkButton>
    </ScrollView>
  );
}

const styles = StyleSheet.create({
  container: {
    padding: 25,
  },
  imageContainer: { alignItems: 'center', marginVertical: 30 },
  image: { height: 200, width: 200 },
  button: {
    marginTop: 25,
    marginBottom: 50,
  },
});
