import React from 'react';
import { View, StyleSheet } from 'react-native';
import { Divider, Title } from 'react-native-paper';
import { ParkingSpotListItem } from '../ParkingSpotListItem';
import { GarageType } from '../../garage/types';
import { SubscriptionStore } from '../SubscriptionStore';
import { ContinueNextButtons } from '../components/ContinueNextButtons';
import ReparkButton from '~/src/components/ReparkButton';
import rptheme from '~/rptheme';
import { openLinkInBrowser } from '~/src/utils/helpers';
import { BottomSheetStore } from '../../bottom-sheet/BottomSheetStore';
import { FloorPlansSheet } from '../../garage/sheets/FloorPlansSheet';
import { useTranslation } from 'react-i18next';

type ChooseParkingSpotStepProps = {
  garage: GarageType;
  onContinue: () => void;
  onBack: () => void;
};

export const ChooseParkingSpotStep = (props: ChooseParkingSpotStepProps) => {
  const { garage, onContinue, onBack } = props;
  const [openSheet] = BottomSheetStore((store) => [store.openSheet]);
  const { t } = useTranslation();

  const availableParkingSpots = garage?.parkingSpots.filter(
    (parkingSpot: any) => parkingSpot?.products?.length > 0
  );

  const [selectedParkingSpotId, selectParkingSpotId] = SubscriptionStore(
    (store) => [store.selectedParkingSpotId, store.selectParkingSpotId]
  );

  const handleOpenFloorPlan = () => {
    if (!garage.floorPlans || garage.floorPlans.length === 0) return;

    if (garage.floorPlans.length === 1)
      return openLinkInBrowser(garage.floorPlans[0].path);

    openSheet(<FloorPlansSheet floorPlans={garage.floorPlans} />, {
      adjustToContentHeight: true,
    });
  };

  return (
    <View>
      {garage.floorPlans && garage.floorPlans.length > 0 && (
        <>
          <View style={styles.container}>
            <Title>
              {t('Subscription.steps.chooseParkingSpot.floorPlanTitle')}
            </Title>
            <ReparkButton
              mode="outlined"
              color={rptheme.colors.accent}
              onPress={handleOpenFloorPlan}
            >
              {t('Subscription.steps.chooseParkingSpot.floorPlanAction')}
            </ReparkButton>
          </View>
          <Divider style={{ marginVertical: 25 }} />
        </>
      )}
      <View style={styles.container}>
        <Title>{t('Subscription.steps.chooseParkingSpot.title')}</Title>
        {availableParkingSpots?.map((parkingSpot: any) => (
          <ParkingSpotListItem
            key={parkingSpot.id}
            parkingSpot={parkingSpot}
            highlighted={selectedParkingSpotId === parkingSpot.id}
            onPress={() => selectParkingSpotId(parkingSpot.id)}
          />
        ))}

        <ContinueNextButtons
          onBack={onBack}
          onContinue={onContinue}
          nextDisabled={!selectedParkingSpotId}
        />
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: { marginHorizontal: 20 },
  buttonContainer: { display: 'flex', flexDirection: 'row' },
  backButton: {
    margin: 5,
    justifyContent: 'center',
  },
  nextButton: {
    justifyContent: 'center',
    margin: 5,
    flexGrow: 2,
  },
});
