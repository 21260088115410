import * as React from 'react';
import { Paragraph, Subheading, TextInput } from 'react-native-paper';
import { StyleSheet, View } from 'react-native';
import rptheme from '~/rptheme';
import { ReparkIcon } from './ReparkIcon';

export default function ReparkTextInput(
  props: React.ComponentProps<typeof TextInput> & {
    topLabel?: string;
    icon?: string;
    zoderror: any;
    showError?: boolean;
    containerStyle?: any;
  }
) {
  const { topLabel, zoderror, showError = true, icon } = props;

  if (props.containerStyle) {
    styles.container = { ...styles.container, ...props.containerStyle };
  }

  return (
    <View style={styles.container}>
      {topLabel && (
        <Subheading
          style={{
            color: rptheme.colors.primary,
          }}
        >
          {topLabel}
        </Subheading>
      )}
      <TextInput
        theme={{ roundness: 15 }}
        style={{ padding: 0 }}
        mode="outlined"
        dense
        outlineColor={zoderror ? rptheme.colors.error : rptheme.colors.primary}
        activeOutlineColor={
          zoderror ? rptheme.colors.error : rptheme.colors.primary
        }
        value={''}
        left={
          <TextInput.Icon
            disabled
            style={{ opacity: 1 }} // TextInput is disabled, so tabIndex is set to '-1'. We need to set opacity separately back to 1.
            name={() => (
              <ReparkIcon
                name={icon}
                size={16}
                color={zoderror ? rptheme.colors.error : rptheme.colors.primary}
              ></ReparkIcon>
            )}
          />
        }
        {...props}
      />
      {showError && zoderror && (
        <Paragraph testID="inputErrorMessage" style={styles.errorText}>
          {zoderror.message}
        </Paragraph>
      )}
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    marginVertical: 10,
  },
  errorText: {
    color: rptheme.colors.error,
    marginTop: 5,
    textAlign: 'center',
  },
});
