import React from 'react';
import { StyleSheet, View, Text } from 'react-native';
import { GarageStore } from '../garage/GarageStore';
import { GarageTitle } from '../garage/GarageTitle';
import { PricePer } from './PricePer';
import { LocationStore } from '../location/LocationStore';
import { doAsync, formatDistance } from '~/src/utils/helpers';

import { t } from 'i18next';
import rptheme from '~/rptheme';

export const OfferTop = () => {
  const [selectedGarageId, getGarage] = GarageStore((store) => [
    store.selectedGarageId,
    store.getGarage,
  ]);

  if (!selectedGarageId) return null;

  const [
    locationPermission,
    startLocationUpdates,
    stopLocationUpdates,
    userDistanceTo,
    location,
    hasBeenSearched,
    locationSearchParams,
  ] = LocationStore((store) => [
    store.locationPermission,
    store.startLocationUpdates,
    store.stopLocationUpdates,
    store.userDistanceTo,
    store.location,
    store.hasBeenSearched,
    store.locationSearchParams,
  ]);
  const [distance, setDistance] = React.useState('');

  const getDistance = () => {
    if (hasBeenSearched && locationSearchParams?.distance) {
      setDistance(formatDistance(locationSearchParams.distance));
      return;
    }
    doAsync(async () => {
      if (
        location &&
        !(
          locationPermission?.status === 'denied' ||
          locationPermission?.status === 'undetermined'
        )
      ) {
        if (!location) startLocationUpdates();
        const garage = getGarage(selectedGarageId);
        const distanceTo = await userDistanceTo(garage);
        if (distanceTo) {
          setDistance(formatDistance(distanceTo));
        } else {
          setDistance('');
        }
      }
    });
  };

  React.useEffect(() => {
    getDistance();
    return () => {
      stopLocationUpdates();
    };
  }, [selectedGarageId, location]);

  return (
    <View style={styles.container}>
      <View style={styles.heading}>
        <GarageTitle
          garage={getGarage(selectedGarageId)}
          align={'flex-start'}
          withSubway={true}
        />

        <PricePer price={getGarage(selectedGarageId).rate} interval="day" />
        {/* <ShareButton
        shareData={{
          title: getGarage(selectedGarageId).name,
          url: `${window.location.href}?id=${getGarage(selectedGarageId).id}`,
          text: '',
        }}
      /> */}
      </View>
      {distance !== '' && hasBeenSearched ? (
        <View style={styles.searchDistanceWrapper}>
          <Text style={styles.distance}>
            {distance} {t('General.from')}{' '}
          </Text>
          <Text style={styles.distanceBold}>
            {locationSearchParams?.name || t('Nearby.searchedAddress')}{' '}
          </Text>
          <Text style={styles.distance}>{t('General.away')}</Text>
        </View>
      ) : (
        <Text style={styles.distance}>
          {distance} {t('Nearby.distanceToGarage')}
        </Text>
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    display: 'flex',
    flexDirection: 'column',
    margin: 25,
    marginBottom: 10,
    marginLeft: 15,
    marginRight: 15,
  },
  heading: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  distance: {
    color: rptheme.colors.gray,
  },
  distanceBold: {
    color: rptheme.colors.gray,
    fontWeight: 'bold',
  },
  searchDistanceWrapper: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
  },
});
