import React from 'react';
import { BookingStore } from '../../booking/BookingStore';
import { Dayjs } from 'dayjs';

export const useDuplicateBookingAlertHook = ({
  offerStart,
  offerDuration,
  selectedGarageId,
}: {
  offerStart: Dayjs;
  offerDuration: number;
  selectedGarageId: string | undefined;
}) => {
  const [findDuplicateBooking] = BookingStore((store) => [
    store.findDuplicateBooking,
  ]);

  const [duplicateBooking, setDuplicateBooking] = React.useState<
    undefined | Record<any, any>
  >();

  React.useEffect(() => {
    //check for duplicate booking
    if (selectedGarageId) {
      const doAsync = async () => {
        try {
          const duplicate = await findDuplicateBooking({
            offerStart,
            offerDuration,
            garageId: selectedGarageId,
          });
          setDuplicateBooking(duplicate);
        } catch (e) {
          console.log(e);
        }
      };
      doAsync();
    }
  }, [offerStart, offerDuration, selectedGarageId]);

  return {
    setDuplicateBooking,
    duplicateBooking,
  };
};
