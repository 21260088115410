import { zodResolver } from '@hookform/resolvers/zod';
import React from 'react';
import { useForm, Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { View, StyleSheet } from 'react-native';
import rptheme from '~/rptheme';
import ReparkButton from '~/src/components/ReparkButton';
import ReparkTextInput from '~/src/components/ReparkTextInput';
import { createPersonalDataZodSchema } from '~/src/rules/inputValidationRules';
import { AuthStore } from '../../authentication/AuthStore';
import { NavProp } from '../../navigation';
import { SnackbarStore } from '../../snackbar/SnackbarStore';
import { Text, TextInput } from 'react-native-paper';

type PersonalDataFormProps = {
  navigation?: NavProp;
};

export const PersonalDataForm = (props: PersonalDataFormProps) => {
  const { navigation } = props;
  const { t } = useTranslation();
  const [user, editUser, resendEmailVerification] = AuthStore((store) => [
    store.user,
    store.editUser,
    store.resendEmailVerification,
  ]);
  const fireSnack = SnackbarStore((store) => store.fireSnack);
  const [isLoading, setIsLoading] = React.useState(false);

  const {
    control,
    handleSubmit,
    setError,
    formState: { errors, isDirty },
  } = useForm<{
    firstName: string;
    lastName: string;
    email: string;
    phoneNumber: string;
    userCompanyName: string;
    vatId: string;
  }>({
    resolver: zodResolver(createPersonalDataZodSchema(t)),
    defaultValues: {
      firstName: user.firstName ? user.firstName : undefined,
      lastName: user.lastName ? user.lastName : undefined,
      email: user.email ? user.email : undefined,
      phoneNumber: user.phoneNumber,
      userCompanyName: user.userCompanyName ? user.userCompanyName : undefined,
      vatId: user.vatId ? user.vatId : undefined,
    },
  });

  const goBack = () => {
    if (navigation?.canGoBack()) {
      navigation.pop();
    }
    navigation?.replace('Profile');
  };

  React.useEffect(() => {
    if (isDirty) {
      navigation?.setOptions({
        headerLeft: () => {
          return (
            <ReparkButton
              mode="text"
              disabled={isLoading}
              testID={'cancelButton'}
              color={rptheme.colors.primary}
              onPress={goBack}
            >
              {t('General.cancel')}
            </ReparkButton>
          );
        },
        headerRight: () => {
          return (
            <ReparkButton
              loading={isLoading}
              disabled={isLoading}
              mode="text"
              testID={'saveButton'}
              color={rptheme.colors.primary}
              onPress={handleSubmit(async (data) => {
                setIsLoading(true);
                const response = await editUser(data);

                if (!response.success) {
                  let title, message;

                  switch (response.errorCode) {
                    case 409:
                      title = t(
                        'ProfileScreen.editUserFailed.emailAlreadyTakenTitle'
                      );
                      message = t(
                        'ProfileScreen.editUserFailed.emailAlreadyTakenMessage'
                      );
                      setError('email', {
                        message: t(
                          'ProfileScreen.editUserFailed.emailAlreadyTakenTitle'
                        ),
                      });
                      break;
                    default:
                      title = t('General.errors.GenericErrorTitle');
                      message = t('General.errors.GenericErrorMessage');
                  }

                  fireSnack({
                    title,
                    message,
                    type: 'error',
                  });

                  setIsLoading(false);
                  return;
                }

                fireSnack({
                  title: t('Personaldata.edit.successTitle'),
                  message: t('Personaldata.edit.successMessage'),
                  type: 'success',
                });

                setIsLoading(false);
                goBack();
              })}
            >
              {t('General.save')}
            </ReparkButton>
          );
        },
      });
    }
  }, [isDirty, isLoading]);

  const resendVerification = async () => {
    try {
      await resendEmailVerification();
      fireSnack({
        title: t('EmailVerification.successTitle'),
        message: t('EmailVerification.successMessage'),
        type: 'success',
      });
    } catch (e: any) {
      let title, message, type;

      if (e.response.status === 429) {
        title = t('EmailVerification.tooManyRequestsTitle');
        message = t('EmailVerification.tooManyRequestsMessage');
        type = 'info' as any;
      } else {
        title = t('General.errors.GenericErrorTitle');
        message = t('General.errors.GenericErrorMessage');
        type = 'error';
      }

      fireSnack({
        title,
        message,
        type,
      });
    }
  };

  return (
    <View style={styles.container}>
      <Controller
        name="firstName"
        control={control}
        render={({ field: { onChange } }) => (
          <ReparkTextInput
            testID={'firstNameInput'}
            disabled={isLoading}
            value={user.firstName || ''}
            onChangeText={(text: string) => onChange(text)}
            maxLength={40}
            textContentType="givenName"
            keyboardType="default"
            autoComplete="given-name"
            autoCorrect={false}
            spellCheck={false}
            autoFocus={true}
            zoderror={errors.firstName}
            label={t('AuthTexts.firstNamePlaceHolder')}
            icon={'account-outline'}
          />
        )}
      />

      <Controller
        name="lastName"
        control={control}
        render={({ field: { onChange } }) => (
          <ReparkTextInput
            testID={'lastNameInput'}
            disabled={isLoading}
            value={user.lastName || ''}
            onChangeText={(text: string) => onChange(text)}
            maxLength={40}
            textContentType="familyName"
            keyboardType="default"
            autoComplete="family-name"
            autoCorrect={false}
            spellCheck={false}
            autoFocus={true}
            zoderror={errors.lastName}
            label={t('AuthTexts.lastNamePlaceHolder')}
            icon={'account-outline'}
          />
        )}
      />

      <Controller
        name="email"
        control={control}
        render={({ field: { onChange } }) => (
          <ReparkTextInput
            testID={'emailInput'}
            disabled={isLoading}
            value={user.email || ''}
            onChangeText={(text: string) => onChange(text)}
            maxLength={40}
            textContentType="emailAddress"
            keyboardType="email-address"
            autoComplete="email"
            autoCorrect={false}
            spellCheck={false}
            autoFocus={true}
            zoderror={errors.email}
            label={t('AuthTexts.emailPlaceholder')}
            icon={'email'}
            right={
              user.emailVerified && (
                <TextInput.Icon
                  name={'check'}
                  disabled
                  size={10}
                  testID={'emailVerifiedCheck'}
                />
              )
            }
          />
        )}
      />
      {user.email && !user.emailVerified && (
        <Text testID={'emailNotVerifiedInfo'} style={styles.helperText}>
          {t('EmailVerification.notVerifiedInfo')}{' '}
          <Text
            style={styles.link}
            onPress={resendVerification}
            testID={'resendEmailVerification'}
          >
            {t('EmailVerification.resend')}
          </Text>
        </Text>
      )}

      <ReparkTextInput
        testID={'phoneNumber'}
        disabled
        value={user.phoneNumber || '+43 123 12345678'}
        maxLength={40}
        textContentType="givenName"
        keyboardType="default"
        autoComplete="name"
        autoCorrect={false}
        spellCheck={false}
        autoFocus={true}
        zoderror={errors.phoneNumber}
        label={t('AuthTexts.phonenumberTopLabel')}
        icon={'phone'}
        right={<TextInput.Icon name={'check'} disabled size={10} />}
      />
      <Text style={styles.helperText}>
        {t('PersonalDataScreen.setTelNumber')}
      </Text>
      <Text style={styles.companyData}>
        {t('PersonalDataScreen.companyData')}
      </Text>
      <Controller
        name="userCompanyName"
        control={control}
        render={({ field: { onChange } }) => (
          <ReparkTextInput
            testID={'companyNameTestID'}
            disabled={isLoading}
            value={user.userCompanyName || ''}
            onChangeText={(text: string) => onChange(text)}
            maxLength={60}
            textContentType="none"
            keyboardType="default"
            autoComplete="off"
            autoCorrect={false}
            spellCheck={false}
            autoFocus={true}
            zoderror={errors.userCompanyName}
            label={t('AuthTexts.companyName')}
          />
        )}
      />
      <Controller
        name="vatId"
        control={control}
        render={({ field: { onChange } }) => (
          <ReparkTextInput
            testID={'vatInput'}
            disabled={isLoading}
            value={user.vatId || ''}
            onChangeText={(text: string) => onChange(text)}
            maxLength={40}
            textContentType="none"
            keyboardType="default"
            autoComplete="off"
            autoCorrect={false}
            spellCheck={false}
            autoFocus={true}
            zoderror={errors.vatId}
            label={t('AuthTexts.vatIdPlaceholder')}
          />
        )}
      />
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    marginTop: 70,
    margin: 25,
  },
  link: {
    color: rptheme.colors.primary,
  },
  helperText: {
    marginBottom: 15,
  },
  companyData: {
    marginTop: 20,
    marginBottom: 5,
    alignSelf: 'center',
    fontSize: 16,
  },
});
