import {
  mdiNumeric1BoxOutline,
  mdiNumeric2BoxOutline,
  mdiNumeric3BoxOutline,
  mdiNumeric4BoxOutline,
} from '@mdi/js';
import { ListItemCard } from '../../components/ListItemCard';
import React from 'react';
import { StyleSheet, View, Image } from 'react-native';
import rptheme from '~/rptheme';
import { ContinueNextButtons } from '../../components/ContinueNextButtons';

type InspectionWelcomeStepProps = {
  onContinue: () => void;
  onBack: () => void;
};

export const InspectionWelcomeStep = (props: InspectionWelcomeStepProps) => {
  const { onContinue, onBack } = props;
  const imageSource = rptheme.graphics.welcomeOnboarding;

  const tutorialItems = [
    {
      title: 'Melde dich an oder registriere dich',
      icon: mdiNumeric1BoxOutline,
    },
    {
      title: 'Wähle einen Tag',
      icon: mdiNumeric2BoxOutline,
    },
    {
      title: 'Besichtige die Garage',
      icon: mdiNumeric3BoxOutline,
    },
    {
      title: ' Buche deinen Parkplatz',
      icon: mdiNumeric4BoxOutline,
    },
  ];

  return (
    <View>
      <ListItemCard
        items={tutorialItems}
        mdiIcons={true}
        title="So besichtigst du die Garage:"
      />
      <Image testID="image" source={imageSource} style={styles.image} />

      <ContinueNextButtons onBack={onBack} onContinue={onContinue} />
    </View>
  );
};

const styles = StyleSheet.create({
  image: {
    height: 200,
    resizeMode: 'contain',
    marginVertical: 30,
  },
});
