import { NavigationProp } from '@react-navigation/native';
import React from 'react';
import { View, Text, StyleSheet } from 'react-native';
import { Card } from 'react-native-paper';
import rptheme from '~/rptheme';
import { ReparkIcon } from '~/src/components/ReparkIcon';
import {
  endToStartDateWithDuration,
  formatCurrency,
  formatDate,
} from '~/src/utils/helpers';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import { ReparkList } from '~/src/components/ReparkList';
import { LinearGradient } from 'expo-linear-gradient';
import { isActiveBooking, isCanceledBooking, isPastBooking } from '../helpers';
import { BookingStackScreens } from '../BookingStackNavigation';

type BookingListItemProps = {
  booking: any;
  navigation: NavigationProp<any>;
};

export const BookingListItem = (props: BookingListItemProps) => {
  const { booking, navigation } = props;
  const { t } = useTranslation();

  let icon = 'booking-active';
  const leftColumnStyle = [styles.leftColumn];
  let color = rptheme.colors.primary;
  let colorFade = rptheme.colors.primary;
  const cardStyle = [styles.card];
  const bookingInfos = [
    {
      title: `${booking?.parkingSpot?.garage?.street} ${booking?.parkingSpot?.garage?.streetNumber}`,
      description: `${booking?.parkingSpot?.garage?.postalCode} ${booking?.parkingSpot?.garage?.city}`,
      icon: 'marker',
    },
    {
      title: formatCurrency(booking?.rate),
      icon: 'money',
    },
  ];

  if (isActiveBooking(booking)) {
    leftColumnStyle.push(styles.leftColumnActive as any);
    color = rptheme.colors.accent;
    colorFade = rptheme.colors.accentFade;
  }

  if (isPastBooking(booking)) {
    cardStyle.push(styles.cardPast as any);
    icon = 'booking-done';
  }

  if (isCanceledBooking(booking)) {
    cardStyle.push(styles.cardCanceled as any);
    leftColumnStyle.push(styles.leftColumnCanceled as any);
    color = rptheme.colors.error;
    colorFade = rptheme.colors.error;
    icon = 'booking-canceled';
  } else {
    bookingInfos.push({
      title: booking.licencePlate,
      icon: 'car',
    });
  }

  return (
    <Card
      testID="bookingListItem"
      style={cardStyle}
      onPress={() => {
        navigation.navigate(BookingStackScreens.BookingDetailScreen, {
          id: booking.id,
        });
      }}
    >
      <View style={styles.container}>
        <LinearGradient
          style={leftColumnStyle}
          colors={[color, colorFade]}
          start={{ x: 1, y: 0 }}
          end={{ x: 0.5, y: 0 }}
        >
          <ReparkIcon name={icon} size={50} color="white" />
          <Text style={styles.leftText}>
            {booking.duration <= 1440
              ? formatDate(dayjs(booking.start).tz('Europe/Vienna'), t, false)
              : `${formatDate(
                  dayjs(booking.start).tz('Europe/Vienna'),
                  t,
                  false
                )} bis ${endToStartDateWithDuration(
                  dayjs(booking.start),
                  booking.duration
                ).format('DD.MM.')}`}
          </Text>
        </LinearGradient>
        <View style={styles.rightColumn}>
          {booking.canceled ? (
            <Text style={styles.canceledText}>Storniert</Text>
          ) : null}
          <ReparkList
            items={bookingInfos}
            iconSize={20}
            iconStyle={styles.iconStyle}
            iconColor={color}
          ></ReparkList>
          <ReparkIcon
            name="chevron-right"
            style={styles.detailsIcon}
            color={color}
          />
        </View>
      </View>
    </Card>
  );
};

const styles = StyleSheet.create({
  card: {
    marginBottom: 15,
  },
  cardCanceled: {
    opacity: 0.5,
  },
  cardPast: {
    opacity: 0.5,
  },
  container: {
    flexDirection: 'row',
  },
  leftColumn: {
    padding: 25,
    width: '30%',
    borderTopLeftRadius: rptheme.roundness,
    borderBottomLeftRadius: rptheme.roundness,
    justifyContent: 'space-around',
    color: 'white',
    alignItems: 'center',
    backgroundColor: rptheme.colors.primary,
  },
  leftColumnActive: {
    backgroundColor: rptheme.colors.accent,
  },
  leftColumnCanceled: {
    backgroundColor: rptheme.colors.error,
  },
  leftText: {
    color: 'white',
    marginTop: 5,
    fontSize: 16,
    textAlign: 'center',
  },
  rightColumn: {
    position: 'relative',
    padding: 5,
    width: '70%',
    borderTopRightRadius: rptheme.roundness,
    borderBottomRightRadius: rptheme.roundness,
  },
  detailsIcon: { position: 'absolute', bottom: 15, right: 15 },
  iconStyle: {
    margin: 0,
    height: 30,
    width: 30,
  },
  canceledText: {
    color: rptheme.colors.error,
    alignSelf: 'center',
    fontSize: rptheme.fontSizeLarge,
    marginTop: 10,
    marginBottom: 0,
  },
});
