import dayjs, { Dayjs } from 'dayjs';
import { FABActions } from '~/src/utils/FABActions';
import { NavProp, RootScreens } from '../navigation';
import { useShare } from '../sharing/useShare';
import { BottomSheetStore } from '../bottom-sheet/BottomSheetStore';
import { useTranslation } from 'react-i18next';
import { canSubscribeInGarage } from '../garage/helpers';
import { SubscriptionsStackScreens } from '../subscriptions/SubscriptionStackNavigation';

export const selectedDateTooFarInFuture = (date: Dayjs, maxFuture: number) => {
  const today = dayjs();
  const diff = date.diff(today, 'day');
  return diff >= maxFuture;
};

export const selectedAfterAvailable = (
  date: Dayjs,
  availableUntil: Dayjs | undefined
): boolean => {
  if (!availableUntil) {
    return false;
  }
  return date.isAfter(availableUntil.startOf('day'));
};

export const createOfferLinks = ({
  navigation,
  user,
  garage,
}: {
  navigation: NavProp;
  user: any;
  garage: any;
}) => {
  const { feedbackAction, FAQAction } = FABActions({
    navigation,
    user,
  });

  const { t } = useTranslation();
  const [closeSheet] = BottomSheetStore((store) => [store.closeSheet]);

  const { canShare, share } = useShare({
    title: garage.name,
    url: `${window.location.href}?id=${garage.id}&utm_source=app&utm_medium=referral&utm_campaign=share`,
    text: '',
  });

  const links = [
    {
      showCondition: canSubscribeInGarage(garage),
      title: t('garage.subscribe'),
      onPress: () => {
        closeSheet();
        navigation.navigate(RootScreens.SubscriptionStack, {
          screen: SubscriptionsStackScreens.ChooseParkingSpot,
          params: {
            garageId: garage.id,
          },
        });
      },
    },
    {
      showCondition: true,
      title: FAQAction.label,
      onPress: () => {
        closeSheet();
        FAQAction.onPress();
      },
    },
    {
      showCondition: true,
      title: feedbackAction.label,
      onPress: () => {
        closeSheet();
        feedbackAction.onPress();
      },
    },
    {
      showCondition: canShare,
      title: t('garage.shareGarage'),
      onPress: share,
    },
  ];

  return links.filter((link) => link.showCondition);
};
