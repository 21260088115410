import { NavigationProp } from '@react-navigation/native';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { StyleSheet } from 'react-native';
import rptheme from '~/rptheme';
import ReparkButton from '~/src/components/ReparkButton';
import { RootScreens } from '../../navigation';
import { BookingStackScreens } from '../BookingStackNavigation';
import { BookingStore } from '../BookingStore';
import { isActiveBooking } from '../helpers';

export type BookingButtonProps = {
  navigation: NavigationProp<any>;
};

export const BookingButton = (props: BookingButtonProps) => {
  const { t } = useTranslation();
  const { navigation } = props;
  const bookings = BookingStore((store) => store.bookings);
  const activeBookings = bookings.filter((booking) => isActiveBooking(booking));

  const goToBooking = () => {
    if (activeBookings.length > 1) {
      navigation.navigate(RootScreens.BookingStack, {
        screen: BookingStackScreens.BookingOverviewScreen,
      });
    } else {
      navigation.navigate(RootScreens.BookingStack, {
        screen: BookingStackScreens.BookingDetailScreen,
        params: {
          id: activeBookings[0].id,
        },
      });
    }
  };

  const bookingButton = (
    <ReparkButton
      testID="bookingButton"
      onPress={() => {
        navigation.navigate(RootScreens.BookingStack);
      }}
      icon="clock"
      color="white"
      style={styles.bookingsButton}
      labelStyle={{
        color: rptheme.colors.primary,
      }}
    >
      {t('BookingScreen.screenTitle')}
    </ReparkButton>
  );

  const activeBookingButton = (
    <ReparkButton
      testID="activeBookingBadge"
      onPress={goToBooking}
      icon={'clock'}
      color={rptheme.colors.accent}
      style={styles.bookingsButton}
    >
      {t('General.activeBookingBadge')}
    </ReparkButton>
  );

  if (activeBookings.length) return activeBookingButton;

  return bookingButton;
};

const styles = StyleSheet.create({
  bookingsButton: { ...rptheme.shadow, marginBottom: 6 },
});
