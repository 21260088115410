import { initializeApp, getApp } from 'firebase/app';
import { initializeAuth, getAuth, connectAuthEmulator } from 'firebase/auth';
import { getReactNativePersistence } from 'firebase/auth/react-native';
import AsyncStorage from '@react-native-async-storage/async-storage';
import config from '~/config/config';
import { envIsDev } from '~/src/utils/helpers';

import { initializeAnalytics, isSupported } from 'firebase/analytics';

export const initializeFirebase = async () => {
  // initialize firebase
  const app = initializeApp(config().firebase);

  if (await isSupported())
    initializeAnalytics(app, { config: { send_page_view: false } }); // Analytics would automatically track a `page_view`in initialisation. For consistency, we're tracking `screen_views` cross-platform.

  try {
    console.log('Initializing Firebase');
    // initialize firebase

    initializeAuth(getApp(), {
      persistence: getReactNativePersistence(AsyncStorage),
    });

    if (envIsDev() && config().firebaseDev?.useEmulator) {
      console.log('Using Firebase Emulator');

      const authEmulator = config().firebaseDev?.authEmulator;
      console.log('Using firebase auth emulator', authEmulator);
      try {
        const auth = getAuth();
        connectAuthEmulator(auth, authEmulator, { disableWarnings: true });
      } catch (e) {
        console.log(e);
      }
    } else {
      console.log('Using Firebase Live');
    }
    console.log('Firebase initialized');
  } catch (e: any) {
    // ignore already initialized warning
    if (e.message?.includes('auth/already-initialized')) {
    } else {
      console.log(e.message);
    }
  }
  return app;
};
