import Icon from '@mdi/react';
import React from 'react';
import { StyleSheet, View } from 'react-native';
import { Card, Headline } from 'react-native-paper';
import rptheme from '~/rptheme';
import ReparkListItem from '~/src/components/ReparkListItem';

type ListItemCardProps = {
  title: string;
  items: {
    title: string;
    icon: string;
  }[];
  mdiIcons?: boolean;
};

export const ListItemCard = (props: ListItemCardProps) => {
  const { title, items, mdiIcons = false } = props;

  return (
    <Card style={styles.card}>
      <Headline style={styles.cardTitle}>{title}</Headline>

      {/* THIS SUCKS. But I dont see another way without refactoring all of it.. */}
      {items.map((item) => {
        return (
          <View key={item.title}>
            {mdiIcons ? (
              <ReparkListItem
                key={item.title}
                style={styles.listItem}
                titleStyle={styles.listItemTitle}
                title={item.title}
                left={() => (
                  <Icon
                    style={{
                      alignSelf: 'center',
                      margin: 15,
                    }}
                    path={item.icon}
                    size={1}
                    color={rptheme.colors.primary}
                  />
                )}
              />
            ) : (
              <ReparkListItem
                key={item.title}
                style={styles.listItem}
                titleStyle={styles.listItemTitle}
                title={item.title}
                icon={item.icon}
                iconSize={25}
              />
            )}
          </View>
        );
      })}
    </Card>
  );
};

const styles = StyleSheet.create({
  card: {
    marginVertical: 15,
  },
  cardTitle: {
    fontSize: 16,
    marginTop: 15,
    marginLeft: 15,
    fontWeight: 'bold',
  },
  listItem: { padding: 0 },
  listItemTitle: { fontSize: 14 },
});
