import dayjs, { Dayjs } from 'dayjs';
import { AuthStore } from '../authentication/AuthStore';

export type OpeningHoursType = {
  day_of_week: number;
  start: string;
  stop: string;
};

type GarageOpeningHoursListItemProps = {
  opening_hours: OpeningHoursType[] | null;
  selectedDate: Dayjs;
  t: Function;
};

export const getOpeningHours = (
  props: Omit<GarageOpeningHoursListItemProps, 't'>
) => {
  const { opening_hours, selectedDate } = props;

  const dayOfSelectedDate = dayjs(selectedDate).day() - 1;
  const openingHoursOnThatDay = opening_hours?.find(
    (opening_hour) => opening_hour.day_of_week == dayOfSelectedDate
  );

  return openingHoursOnThatDay;
};

export const GarageOpeningHoursListItem = (
  props: GarageOpeningHoursListItemProps
) => {
  const loggedIn = AuthStore.getState().loggedIn;
  const { opening_hours, selectedDate, t } = props;

  const openingHoursOnThatDay = getOpeningHours({
    opening_hours,
    selectedDate,
  });

  let title = t('Garage.open24h');

  if (openingHoursOnThatDay) {
    title = t('Garage.openingHourSpecific', {
      start: openingHoursOnThatDay.start,
      stop: openingHoursOnThatDay.stop,
    });
  }

  return {
    showCondition: loggedIn,
    testID: 'openingHoursInfo',
    title,
    icon: 'clock1',
    iconRotation: undefined,
  };
};
