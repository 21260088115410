import React from 'react';
import { StyleSheet, View } from 'react-native';
import { Paragraph, Title } from 'react-native-paper';
import rptheme from '~/rptheme';

type BottomSheetProps = {
  title?: string;
  text?: string;
  children?: JSX.Element;
};

export default function BottomSheetFrame(props: BottomSheetProps) {
  const { title, text, children } = props;
  return (
    <View style={styles.container}>
      <Title style={styles.title}>{title}</Title>
      {text && <Paragraph style={styles.text}>{text}</Paragraph>}
      {children}
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    margin: 25,
  },
  title: { textAlign: 'center', color: rptheme.colors.primary },
  text: {
    textAlign: 'center',
    marginVertical: 5,
    fontSize: 16,
  },
});
