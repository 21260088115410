import { mdiAlertCircleOutline } from '@mdi/js';
import Icon from '@mdi/react';
import { StripeError } from '@stripe/stripe-js';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { View, Text, StyleSheet } from 'react-native';
import { Paragraph } from 'react-native-paper';
import rptheme from '~/rptheme';
import ReparkButton from '~/src/components/ReparkButton';
import { Modal } from '~/src/features/modal/Modal';
import { getNextSteps, StripeErrorCodes } from './stripeErrorHelper';

type PaymentFailedModalProps = {
  error: (StripeError & { shouldRetry?: boolean }) | undefined | null;
  onClose: () => void;
  intent?: 'payment' | 'setup';
};

export const StripeIntentFailedModal = (props: PaymentFailedModalProps) => {
  const { error, onClose, intent = 'payment' } = props;
  const { t } = useTranslation();

  const [isOpen, setIsOpen] = React.useState(false);

  React.useEffect(() => {
    if (error) setIsOpen(true);
    else setIsOpen(false);
  }, [error]);

  function closeModal() {
    setIsOpen(false);
    onClose();
  }

  const title =
    intent === 'payment'
      ? 'PaymentFailedModal.title'
      : 'SetupFailedModal.title';
  const text =
    intent === 'payment' ? 'PaymentFailedModal.text' : 'SetupFailedModal.text';

  return (
    <Modal isOpen={isOpen} onClickOutside={closeModal}>
      <View style={styles.container}>
        <View style={styles.content}>
          <Icon
            style={{ alignSelf: 'center' }}
            path={mdiAlertCircleOutline}
            size={2.5}
            color={rptheme.colors.error}
          />
          <Text style={styles.title}>{t(title)}</Text>
          <Paragraph>{t(text)}</Paragraph>

          <Paragraph style={styles.reason}>
            {error?.message || t('PaymentFailedModal.unknownReason')}
          </Paragraph>

          <Paragraph style={styles.nextSteps}>
            {getNextSteps(
              (error?.decline_code as StripeErrorCodes | undefined) ||
                (error?.code as StripeErrorCodes | undefined),
              t
            )}
          </Paragraph>

          <ReparkButton onPress={closeModal}>Ok</ReparkButton>
        </View>
      </View>
    </Modal>
  );
};

const styles = StyleSheet.create({
  container: {
    alignSelf: 'center',
    maxWidth: 500,
    minWidth: rptheme.screenSmall - 50,
  },
  content: {
    margin: 20,
    backgroundColor: 'white',
    paddingBottom: 20,
    borderRadius: rptheme.roundness,
    padding: 15,
    ...rptheme.shadow,
    position: 'relative',
  },
  title: {
    fontSize: 20,
    fontWeight: 'bold',
    marginBottom: 10,
    marginTop: 10,
    textAlign: 'center',
  },
  reason: {
    marginTop: 10,
    borderWidth: 1,
    padding: 15,
    borderRadius: rptheme.roundness,
    borderColor: 'lightgrey',
  },
  nextSteps: {
    marginTop: 10,
    marginBottom: 10,
  },
});
