import { useEffect } from 'react';
import config from '~/config/config';
import storage from '~/config/storage';
import i18n from '~/i18n';

export const useLocizeLanguages = () => {
  const defaultLanguage = config().locize?.defaultLanguage;

  useEffect(() => {
    const getLocizeLanguages = async () => {
      await i18n.services.backendConnector.backend.backends[0].getLanguages(
        (err: any, ret: any) => {
          let result = ret;
          if (err) {
            result = defaultLanguage;
          }
          storage().setItem('locizeLanguages', JSON.stringify(result));
        }
      );
    };

    getLocizeLanguages();
  }, []);
};
