import { TFunction } from 'react-i18next';

export type StripeErrorCodes =
  | 'authentication_required'
  | 'approve_with_id'
  | 'call_issuer'
  | 'card_not_supported'
  | 'card_velocity_exceeded'
  | 'currency_not_supported'
  | 'do_not_honor'
  | 'do_not_try_again'
  | 'duplicate_transaction'
  | 'expired_card'
  | 'fraudulent'
  | 'generic_decline'
  | 'incorrect_number'
  | 'incorrect_cvc'
  | 'incorrect_pin'
  | 'incorrect_zip'
  | 'insufficient_funds'
  | 'invalid_account'
  | 'invalid_amount'
  | 'invalid_cvc'
  | 'invalid_expiry_month'
  | 'invalid_expiry_year'
  | 'invalid_number'
  | 'invalid_pin'
  | 'issuer_not_available'
  | 'lost_card'
  | 'merchant_blacklist'
  | 'new_account_information_available'
  | 'no_action_taken'
  | 'not_permitted'
  | 'offline_pin_required'
  | 'online_or_offline_pin_required'
  | 'pickup_card'
  | 'pin_try_exceeded'
  | 'processing_error'
  | 'reenter_transaction'
  | 'restricted_card'
  | 'revocation_of_all_authorizations'
  | 'revocation_of_authorization'
  | 'security_violation'
  | 'service_not_allowed'
  | 'stolen_card'
  | 'stop_payment_order'
  | 'testmode_decline'
  | 'transaction_not_allowed'
  | 'try_again_later'
  | 'withdrawal_count_limit_exceeded';

export function getNextSteps(
  declineCode: StripeErrorCodes | undefined,
  t: TFunction<'translation', undefined>
): string {
  switch (declineCode) {
    case 'authentication_required':
      return t('stripeDeclineAction.tryAgainAndAuthenticate');
    case 'approve_with_id':
    case 'call_issuer':
    case 'card_not_supported':
    case 'card_velocity_exceeded':
    case 'currency_not_supported':
    case 'do_not_honor':
    case 'do_not_try_again':
    case 'invalid_account':
    case 'invalid_amount':
    case 'issuer_not_available':
    case 'lost_card':
    case 'new_account_information_available':
    case 'no_action_taken':
    case 'not_permitted':
    case 'pickup_card':
    case 'restricted_card':
    case 'revocation_of_all_authorizations':
    case 'revocation_of_authorization':
    case 'security_violation':
    case 'service_not_allowed':
    case 'stolen_card':
    case 'stop_payment_order':
    case 'transaction_not_allowed':
    case 'try_again_later':
    case 'generic_decline':
      return t('stripeDeclineAction.contactBank');
    case 'duplicate_transaction':
      return t('stripeDeclineAction.checkForDuplicate');
    case 'expired_card':
    case 'insufficient_funds':
    case 'pin_try_exceeded':
      return t('stripeDeclineAction.tryDifferentPaymentMethod');
    case 'fraudulent':
    case 'merchant_blacklist': // Das soll nicht an den Kunden weitergegeben werden
      return t('stripeDeclineAction.contactBank');
    case 'incorrect_number':
    case 'invalid_number':
      return t('stripeDeclineAction.checkCardNumber');
    case 'incorrect_cvc':
    case 'invalid_cvc':
      return t('stripeDeclineAction.checkCVC');
    case 'incorrect_pin':
    case 'invalid_pin':
    case 'offline_pin_required':
    case 'online_or_offline_pin_required':
      return t('stripeDeclineAction.checkPIN');
    case 'incorrect_zip':
      return t('stripeDeclineAction.checkZIP');
    case 'processing_error':
    case 'reenter_transaction':
      return t('stripeDeclineAction.tryAgain');
    case 'testmode_decline':
      return t('stripeDeclineAction.doNotUseTestCard');
    default:
      return t('stripeDeclineAction.unknownContactSupport');
  }
}
