import React from 'react';
import { StyleSheet, Text, View } from 'react-native';
import rptheme from '~/rptheme';
import { ReparkIcon } from '~/src/components/ReparkIcon';
import { formatFloor } from '~/src/utils/helpers';
import { BookingDetailItemFrame } from './BookingDetailItemFrame';

export type ParkingSpotInfoProps = {
  parkingSpot: any;
  t: Function;
};

export const ParkingSpotInfo = (props: ParkingSpotInfoProps) => {
  const { parkingSpot, t } = props;

  const alternativeParkingSpotInfo = () => {
    let title, info;

    switch (parkingSpot.garage.parkingSpotAssignment) {
      case 'free-choice':
        title = t('bookingDetailScreen.freeChoiceTitle');
        info = t('bookingDetailScreen.freeChoiceText');
        break;

      case 'restricted-free-choice':
        title = t('bookingDetailScreen.restrictedFreeChoiceTitle');
        info = t('bookingDetailScreen.restrictedFreeChoiceText');
        break;

      case 'on-arrival':
        title = t('bookingDetailScreen.onArrivalTitle');
        info = t('bookingDetailScreen.onArrivalText');
        break;

      default:
        title = '';
        info = '';
    }

    return { title, info };
  };

  return (
    <>
      {!parkingSpot.garage.parkingSpotAssignment ||
      parkingSpot.garage.parkingSpotAssignment === 'fixed' ? (
        <BookingDetailItemFrame
          title={t('bookingDetailScreen.parkingSpotTitle')}
          titleSize={16}
        >
          <View testID="parkingSpotInfo" style={styles.parkingSpotContainer}>
            <ReparkIcon
              style={styles.icon}
              name={'marker'}
              size={30}
              color={rptheme.colors.primary}
            />
            <Text
              style={[styles.text, styles.parkingSpotName]}
              testID={'parkingSpotName'}
            >
              {parkingSpot.name}
            </Text>
            <Text style={[styles.text, styles.dot]}> · </Text>
            <Text style={[styles.text]} testID={'parkingSpotFloor'}>
              {formatFloor(parkingSpot.floor)}
            </Text>
          </View>
        </BookingDetailItemFrame>
      ) : (
        <>
          <BookingDetailItemFrame
            title={alternativeParkingSpotInfo().title}
            titleSize={16}
          >
            <Text testID="alternativeParkingSpotInfoText">
              {alternativeParkingSpotInfo().info}
            </Text>
          </BookingDetailItemFrame>
        </>
      )}
    </>
  );
};

const styles = StyleSheet.create({
  title: {
    textAlign: 'center',
    marginBottom: 5,
  },
  parkingSpotContainer: {
    flexDirection: 'row',
    justifyContent: 'center',
  },
  text: {
    color: rptheme.colors.primary,
    fontSize: 25,
  },
  icon: {
    marginRight: 20,
  },
  parkingSpotName: {
    fontWeight: '700',
  },
  dot: {
    fontWeight: '700',
  },
});
