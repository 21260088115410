import create from 'zustand';
import { feathersClient } from '~/src/services/feathers';
import analytics from '../analytics/analytics';
import { AuthStore } from '../authentication/AuthStore';
import {
  createVehicleRequest,
  deleteVehicleRequest,
  fetchVehiclesRequest,
  patchVehicleRequest,
} from './vehicleService';
import { PaymentStore } from '../payment/PaymentStore';
import { VehicleType } from './vehicleTypes';

type VehicleStore = {
  vehicles: VehicleType[];
  maxVehicles: number;
  selectedVehicle: any;
  isFetchingVehicles: boolean;
  fetchVehicles: (params?: any) => void;
  getVehicle: (id: string) => any;
  createVehicle: (data: any, set_vehicle?: boolean) => Promise<any>;
  patchVehicle: (data: any, set_vehicle?: boolean) => Promise<any>;
  deleteVehicle: (vehicleId: string) => Promise<any>;
  selectVehicle: (vehicleId?: string) => void;
  addEventlisteners: () => void;
  removeEventlisteners: () => void;
  resetStore: () => void;
};

export const VehicleStore = create<VehicleStore>((set, get) => ({
  vehicles: [],
  maxVehicles: 3,
  selectedVehicle: undefined,
  isFetchingVehicles: false,
  fetchVehicles: async (params = {}) => {
    set({ isFetchingVehicles: true });
    const userId = AuthStore.getState().user?.id;
    params.userId = userId;
    try {
      const response = await fetchVehiclesRequest(params);
      console.log('Setting vehicles');

      set({ vehicles: response, isFetchingVehicles: false });
      get().selectVehicle();
    } catch (e) {
      set({ isFetchingVehicles: false });
      throw e;
    }
  },

  getVehicle: (id: string) => {
    if (get().vehicles.length) {
      return get().vehicles?.find((vehicle: any) => vehicle.id === id);
    }
  },

  createVehicle: async (data: any, setVehicle = false) => {
    const userId = AuthStore.getState().user?.id;
    if (!userId) return false;
    const response = await createVehicleRequest(userId, data);
    analytics.track('vehicle_add');
    const newVehicle = response.data;
    if (setVehicle) {
      get().selectVehicle(newVehicle.id);
    } else if (!get().selectedVehicle) get().selectVehicle(newVehicle.id);
    return newVehicle;
  },

  patchVehicle: async (data: any, setVehicle = false) => {
    const response = await patchVehicleRequest(data);
    analytics.track('vehicle_edit');
    if (setVehicle && response.data?.id) {
      get().selectVehicle(response.data?.id);
    }
    return response?.data;
  },

  deleteVehicle: async (vehicleId: string) => {
    const response = await deleteVehicleRequest(vehicleId);
    analytics.track('vehicle_delete');
    return response?.data;
  },

  selectVehicle: (vehicleId?: any) => {
    vehicleId = vehicleId || get().selectedVehicle?.id;
    const newSelectedVehicle =
      get().vehicles.find((current) => current.id === vehicleId) ||
      get().vehicles[0] ||
      undefined;
    set({ selectedVehicle: newSelectedVehicle });

    if (PaymentStore.getState().paymentIntent) {
      PaymentStore.getState().patchPaymentIntent(
        PaymentStore.getState().paymentIntent.id,
        {
          metadata: {
            licencePlate: newSelectedVehicle?.licencePlate,
          },
        }
      );
    }

    console.log('Setting selected vehicle', newSelectedVehicle?.licencePlate);
  },

  addEventlisteners: () => {
    console.log('Adding Vehicle Eventlisteners');

    feathersClient.service('vehicles').on('created', (newVehicle: any) => {
      const vehicles = get().vehicles.concat([newVehicle]);
      set({ vehicles });
      if (!get().selectedVehicle) get().selectVehicle();
    });

    feathersClient.service('vehicles').on('removed', (removedVehicle: any) => {
      const vehicles = get().vehicles.filter(
        (vehicle: any) => vehicle.id !== removedVehicle.id
      );
      set({ vehicles });
      if (removedVehicle.id === get().selectedVehicle?.id) {
        get().selectVehicle();
      }
    });

    feathersClient.service('vehicles').on('patched', (patchedVehicle: any) => {
      const vehicles = get().vehicles.map((vehicle: any) => {
        if (vehicle.id === patchedVehicle.id) {
          return patchedVehicle;
        }
        return vehicle;
      });
      set({ vehicles });
      get().selectVehicle(get().selectedVehicle?.id);
    });
  },

  removeEventlisteners: () => {
    console.log('Removing Vehicle Eventlisteners');

    feathersClient.service('vehicles').removeAllListeners('created');
    feathersClient.service('vehicles').removeAllListeners('removed');
    feathersClient.service('vehicles').removeAllListeners('patched');
  },

  resetStore: () => {
    set({
      vehicles: [],
      selectedVehicle: undefined,
      isFetchingVehicles: false,
    });
  },
}));
