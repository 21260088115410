import React from 'react';
import { View, Text } from 'react-native';
import { NavProp } from '../navigation';
import { SubscriptionStore } from './SubscriptionStore';
import { CenteredAppView } from '~/src/components/CenteredAppView';
import { SubscriptionPaymentForm } from './SubscriptionPaymentForm';
import { feathersClient } from '~/src/services/feathers';
import { SubscriptionsStackScreens } from './SubscriptionStackNavigation';

type SubscriptionPayScreenProps = {
  navigation: NavProp;
  route: any;
};

export const SubscriptionPayScreen = (props: SubscriptionPayScreenProps) => {
  const { route, navigation } = props;
  const { id } = route.params;

  const [subscription, setSubscription] = React.useState<any | null>(null);

  const [fetchSubscription, isFetchingSubscriptions] = SubscriptionStore(
    (store) => [store.fetchSubscription, store.isFetchingSubscriptions]
  );

  React.useEffect(() => {
    const doAsync = async () => {
      const subscription = await fetchSubscription(id);
      console.log(subscription);

      setSubscription(subscription);
    };

    doAsync();
  }, [id]);

  React.useEffect(() => {
    feathersClient
      .service('subscriptions')
      .on('subscription.updated', (subscription: any) => {
        navigation.replace('SubscriptionDetail', { id: subscription.id });
      });

    return () => {
      feathersClient
        .service('subscriptions')
        .removeListener('subscription.updated');
    };
  }, []);

  if (isFetchingSubscriptions) {
    return (
      <CenteredAppView>
        <View style={{ marginTop: 80 }}>
          <Text>Loading...</Text>
        </View>
      </CenteredAppView>
    );
  }

  if (!subscription) {
    return (
      <CenteredAppView>
        <View style={{ marginTop: 80 }}>
          <Text>Subscription not found</Text>
        </View>
      </CenteredAppView>
    );
  }

  return (
    <CenteredAppView>
      <View style={{ marginTop: 80 }}>
        <View>
          {subscription.id && (
            <SubscriptionPaymentForm
              paymentIntent={
                subscription?.stripeSubscription.latest_invoice.payment_intent
              }
              onIsProcessingPayment={() => {}}
              onSuccess={() =>
                navigation.replace(
                  SubscriptionsStackScreens.SubscriptionOverview
                )
              }
              subscriptionId={subscription?.id}
              subscriptionStatus={subscription?.status || ''}
            />
          )}
        </View>
      </View>
    </CenteredAppView>
  );
};
