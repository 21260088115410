import { t } from 'i18next';
import React from 'react';
import BottomSheetFrame from '../bottom-sheet/BottomSheetFrame';
import { GarageDeviceButtons } from '../garage/GarageDeviceButtons';
import { NavProp } from '../navigation';

type GarageDevicesSheetProps = {
  garage: any;
  booking: any;
  navigation: NavProp;
};

export const GarageDevicesSheet = (props: GarageDevicesSheetProps) => {
  const { garage } = props;

  return (
    <BottomSheetFrame
      title={t('GarageDevicesSheet.title')}
      text={t('GarageDevicesSheet.text')}
    >
      <GarageDeviceButtons garage={garage} navigation={props.navigation} />
    </BottomSheetFrame>
  );
};
