import React from 'react';
import { VehicleType } from '../../vehicles/vehicleTypes';

export const useVehiclesForList = ({
  booking,
  vehicles,
}: {
  booking: any;
  vehicles: VehicleType[];
}) => {
  const [vehiclesForList, setVehiclesForList] = React.useState<
    VehicleType[] | Pick<VehicleType, 'licencePlate'>[]
  >([]);

  React.useEffect(() => {
    setVehiclesForList([
      {
        licencePlate: booking?.licencePlate,
      },
      ...vehicles.filter(
        (vehicle) => vehicle.licencePlate !== booking?.licencePlate
      ),
    ]);
  }, [booking, vehicles]);

  return {
    vehiclesForList,
  };
};
