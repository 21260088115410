import React from 'react';
import { Paragraph, TextInput } from 'react-native-paper';
import { StyleSheet, View } from 'react-native';
import rptheme from '~/rptheme';

type ReparkTextInputSimpleProps = React.ComponentProps<typeof TextInput> & {
  zoderror: any;
  showError?: boolean;
};

export const ReparkTextInputSimple = React.forwardRef(
  (props: ReparkTextInputSimpleProps, ref: any) => {
    const { zoderror, showError } = props;
    return (
      <View style={styles.container}>
        <TextInput
          ref={ref}
          theme={{ roundness: 3 }}
          value=""
          mode="flat"
          style={{ padding: 0 }}
          dense
          outlineColor={
            zoderror ? rptheme.colors.error : rptheme.colors.primary
          }
          activeOutlineColor={
            zoderror ? rptheme.colors.error : rptheme.colors.primary
          }
          {...props}
        />

        {showError && zoderror && (
          <Paragraph testID="inputErrorMessage" style={styles.errorText}>
            {zoderror.message}
          </Paragraph>
        )}
      </View>
    );
  }
);

const styles = StyleSheet.create({
  container: {
    marginVertical: 10,
  },
  errorText: {
    color: rptheme.colors.error,
    marginTop: 5,
    textAlign: 'center',
  },
});
