import * as React from 'react';
import { StyleSheet, Image } from 'react-native';

type ReparkLogoProps = {
  height?: number;
  width?: number;
};

export default function ReparkLogo({
  height = 60,
  width = 100,
}: ReparkLogoProps) {
  const styles = StyleSheet.create({
    logo: {
      height,
      width,
      resizeMode: 'contain',
    },
  });

  return (
    <Image
      source={require('~/assets/images/repark-logo.png')}
      style={styles.logo}
    ></Image>
  );
}
