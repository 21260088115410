import { Dimensions, Linking, Platform } from 'react-native';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';
import rptheme from '~/rptheme';
import Constants from 'expo-constants';
import config from '~/config/config';
import dayjs, { Dayjs } from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import duration from 'dayjs/plugin/duration';
import i18next from 'i18next';
dayjs.extend(isBetween);
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(duration);

export const DAY = dayjs.duration(1, 'day').asMinutes();
export const HOUR_IN_MIN = dayjs.duration(1, 'hour').asMinutes();

export const toBoolean = (input: string | number | undefined) => {
  if (input === undefined || !input) {
    return false;
  } else if (typeof input === 'number') {
    return true;
  } else {
    return /^\s*(true|1|on)\s*$/i.test(input);
  }
};

export const createMetaTitle = (text: string) => {
  return Platform.select({ web: ` ${text} | repark` });
};

export const isMobile = () => {
  return (
    Dimensions.get('window').width < rptheme.screenLarge ||
    Platform.OS !== 'web'
  );
};

export const isAdmin = (role: string) => {
  return ['superadmin', 'admin'].includes(role);
};

export const mayCreateStripeUserAccount = (role: string) => {
  return isAdmin(role) || ['landlord'].includes(role);
};

export const getEnv = () => {
  return Constants.manifest?.extra?.mode || config().mode;
};

export const envIsDev = () => {
  const mode = getEnv();

  return ['development', 'testing'].includes(mode);
};

export const stripeIsMocked = () => {
  return toBoolean(config().stripe.isMocked);
};

export const onlyNumbers = (str: string) => {
  return /^[0-9]+$/.test(str);
};

export const splitName = (name: string) => {
  name = name.trim();
  const names = name.split(' ');
  if (names.length !== 2) throw new Error('Name format is invalid');

  return {
    firstName: names[0],
    lastName: names[1],
  };
};

export const formatDimensions = (size: number) => {
  return (size / 100).toFixed(1) + ' m';
};

export const formatDistance = (distance: number) => {
  if (distance >= 1000 && distance < 2000) {
    return (distance / 1000.0).toFixed(1) + ' km';
  } else if (distance >= 2000) {
    return Math.round(distance / 1000.0) + ' km';
  } else if (distance >= 100) {
    return Math.round(distance) + ' m';
  }
  if (!distance) return '? m';

  return distance.toFixed(1) + ' m';
};

export const formatDate = (date: Dayjs, t: Function, humanReadable = true) => {
  if (cetNow().isSame(dayjs(date), 'day') && humanReadable)
    return t('General.today');
  if (cetNow().add(1, 'day').isSame(dayjs(date), 'day') && humanReadable)
    return t('General.tomorrow');
  return dayjs(date).format('DD.MM.');
};

/**
 * Takes two arguments: start date and duration. Returns corresponding date duration string.
 */
export const formatDurationInDates = (
  date: Dayjs | string,
  duration: number
) => {
  if (typeof date === 'string') {
    date = dayjs(date).tz('Europe/Vienna');
  }
  const end = date.add(duration / DAY, 'day').subtract(1, 'minute');
  return `${formatDate(date, () => {}, false)} - ${formatDate(
    end,
    () => {},
    false
  )}`;
};

export const formatFloor = (floor: number) => {
  if (floor < 0) {
    return `${floor * -1}. UG`;
  } else if (floor === 0) {
    return 'EG';
  } else if (floor > 0) {
    return `${floor}. OG`;
  }
};

export const dateStringToLocalDayjs = (datetime: string): Dayjs => {
  if (!datetime || typeof datetime !== 'string') {
    return dayjs().tz('Europe/Vienna');
  }

  if (datetime.endsWith('Z')) {
    return dayjs.utc(datetime).tz('Europe/Vienna');
  } else {
    return dayjs(datetime).tz('Europe/Vienna');
  }
};

export const computeDateTranslations = (t: Function) => {
  const weekdays = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];
  const months = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sept',
    'Nov',
    'Dec',
  ];

  const translatedWeekdays = weekdays.map((day) => t(`Calendar.${day}`));
  const translatedMonths = months.map((month) => t(`Calendar.${month}`));
  return { translatedWeekdays, translatedMonths };
};

export const replaceBrowserBackAction = (callback: any) => {
  if (Platform.OS === 'web') {
    window.history?.pushState(null, '', window.location.href);
    window.onpopstate = () => {
      callback();
    };
  }
};

export const resetBrowserBack = () => {
  if (Platform.OS === 'web') {
    window.onpopstate = null;
  }
};

type FormatCurrencyOptions = {
  currency?: string;
  maximumSignificantDigits?: number;
};

export const formatCurrency = (
  num: number,
  options?: FormatCurrencyOptions
) => {
  const defaultOptions = { currency: '€' };
  options = { ...defaultOptions, ...options };

  return (
    options.currency +
    ' ' +
    Intl.NumberFormat(i18next.language, {
      style: 'decimal',
      maximumSignificantDigits: options.maximumSignificantDigits,
    }).format(num / 100)
  );
};

export const capitalize = (str: string) => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};

export const openLinkInBrowser = (link: string) => {
  Linking.openURL(link);
};

export const nowBetween = (
  start: Dayjs | string,
  end: Dayjs | number | string
) => {
  const now = dayjs().utc().tz('Europe/Vienna');
  if (typeof start === 'string') {
    start = dayjs(start);
  }
  if (typeof end === 'string') {
    end = dayjs(end);
  }
  if (typeof end === 'number') {
    end = start.add(end, 'minutes').endOf('day');
  }
  return now.isBetween(start, end);
};

/**
 * Takes any container 'object'type (object, array, string) and
 * checks if it is empty or not. Return null if it wasn't able to check the type
 * @param {Array<any> | string | {} | any} obj
 * @returns {boolean | null}
 */
export const isEmpty = (
  obj: Array<any> | string | {} | any
): boolean | null => {
  try {
    if (typeof obj === 'object') {
      obj = Object.keys(obj);
    }
    if (typeof obj === 'number') {
      return null;
    }
    return obj.length === 0;
  } catch (e) {
    return null;
  }
};

export const durationToDays = (
  duration: number,
  t?: Function
): number | string => {
  const inDays = Math.floor(duration / DAY);
  if (t) {
    return `${duration / DAY} ${
      inDays > 1 ? t('General.daysNoun') : t('General.dayNoun')
    }`;
  } else {
    return duration / DAY;
  }
};

type DebugResolverProbs = {
  schema: z.ZodSchema;
  data: any;
  context: any;
  options: any;
  devOnly?: boolean;
};
export const debugResolverForm = async ({
  schema,
  data,
  context,
  options,
  devOnly = true,
}: DebugResolverProbs): Promise<void> => {
  const result = await zodResolver(schema)(data, context, options);
  if (!isEmpty(result.errors)) {
    if (devOnly && envIsDev()) {
      console.error(result);
    } else {
      console.error(result);
    }
  }
};

export const doAsync = async (cb: Function): Promise<void> => {
  await cb();
};

/**
 * Handles daylight saving time offsets, needed for end of day or start of day calculations
 * dayjs dosn't seem to handle it correctly if you add minutes, days suit it better.
 * @param {Dayjs} start - start date
 * @param {number} duration - duration in minutes
 * @param {boolean} endOfDay - set the date to end of day of the last day
 * @return {Dayjs}
 */
export const endToStartDateWithDuration = (
  start: Dayjs,
  duration: number,
  endOfDay = false
): Dayjs => {
  const stop = start.subtract(1, 'day').add(duration / DAY, 'days');
  return endOfDay ? stop.endOf('day') : stop;
};

/**
 * Sets to start of day, if it hasn't already happened
 * @param {Dayjs} day
 * @returns {Dayjs}
 */
export const startOfDayIfNotAlready = (day: Dayjs) => {
  if (day.hour() + day.minute() + day.second() + day.millisecond() === 0) {
    return day;
  } else {
    return day.startOf('day');
  }
};

const matchers = [23, 59, 59, 999];
/**
 * Sets to end of day, if it hasn't already happened
 * @param {Dayjs} day
 * @returns {Dayjs}
 */
export const endOfDayIfNotAlready = (day: Dayjs) => {
  return [day.hour(), day.minute(), day.second(), day.millisecond()].every(
    (x: number, i: number) => matchers[i] === x
  )
    ? day
    : day.endOf('day');
};

/**
 * Checks whether string offset is the same as $offset in dazjs timeobject and adjusts
 * $offset accordinglz. This can happen on DST changes, until the month of DST is over.
 * @param {Dayjs} date
 * @return {Dayjs}
 */
export const syncTimeZoneOffsets = (date: Dayjs): Dayjs => {
  const d: Date | null = new Date(Date.parse(date.toISOString()));
  const offsetDayJs = date.utcOffset();
  //js new Date parses it the opposite way, hence the sign change
  const offsetJs = d.getTimezoneOffset() * -1;
  if (offsetDayJs !== offsetJs) {
    (date as any)['$offset'] = offsetJs;
  }
  return date;
};
/**
 * Checks if timezones are the same. If they are, zero is returned, otherwise the defference
 * calculates start - stop
 * @param {Dayjs} start
 * @param {Dayjs} stop
 * @returns {number}
 */
export const getDifferentTimezonesBookingStartAndStop = (
  start: Dayjs,
  stop: Dayjs
): number => {
  return start.utcOffset() - stop.utcOffset();
};

export const cetNow = (): Dayjs => {
  return dayjs().tz('Europe/Vienna');
};

export const sortByDate = (a: Date, b: Date): number => {
  return dayjs(a).unix() - dayjs(b).unix();
};
