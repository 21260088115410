import 'react-native-gesture-handler'; // Needed by Stack Navigator, as stated here: https://reactnavigation.org/docs/stack-navigator
import 'setimmediate';
import React, { Suspense } from 'react';
import { SafeAreaProvider } from 'react-native-safe-area-context';
import { Provider as PaperProvider } from 'react-native-paper';
import {
  useFonts,
  Poppins_400Regular,
  Poppins_600SemiBold,
} from '@expo-google-fonts/poppins';
import i18n from '~/i18n';
import Navigation from '~/src/features/navigation';
import axios from 'axios';
import rptheme from '~/rptheme';
import config from '~/config/config';
import SnackBar from './src/features/snackbar/Snackbar';
import LoadingState from './src/components/LoadingState';
import { AuthStore } from './src/features/authentication/AuthStore';
import { GarageStore } from './src/features/garage/GarageStore';
import { BottomSheet } from './src/features/bottom-sheet/BottomSheet';
import { initializeFirebase } from './firebase';
import LocationInitializer from '~/src/features/location/LocationInitializer';
import { BookingStore } from './src/features/booking/BookingStore';
import { Portal } from 'react-native-paper';
import { VehicleStore } from './src/features/vehicles/VehicleStore';
import { PaymentStore } from './src/features/payment/PaymentStore';
import { ReparkIcon } from './src/components/ReparkIcon';
import './platformSpeficImports';
import { changeLanguage } from './src/features/language-switcher/helpers';
import { stripeIsMocked } from './src/utils/helpers';
import { useOnlineCheck } from './src/hooks/useOnlineCheck';
import { initMetaPixel } from './src/features/meta-pixel/renderThePixel.web';
import { SubscriptionStore } from './src/features/subscriptions/SubscriptionStore';
import { initializeBugSnag } from './src/features/error-monitoring/BugSnag.web';
import { initializeDayjs } from './initializeDayjs';
import { useLocizeLanguages } from './src/hooks/useLocizeLanguages';

initializeDayjs();

// load firebase
console.log(`Starting on ${config().server} in mode ${config().mode}`);
export const app = initializeFirebase();

export const { Bugsnag, ErrorBoundary } = initializeBugSnag();

if (stripeIsMocked()) {
  console.log('Using stripe mock');
} else {
  console.log('Using stripe live');
}

export default function App() {
  const [fontsLoaded] = useFonts({
    Poppins_400Regular,
    Poppins_600SemiBold,
    reparkicons: require('~/assets/fonts/reparkicons.ttf'),
  });
  axios.defaults.headers.common['Accept-Language'] = i18n.language;

  initMetaPixel();
  useOnlineCheck();
  useLocizeLanguages();

  const [
    loggedIn,
    user,
    isRestoringAuthState,
    restoreAuthState,
    addUserEventlisteners,
    removeUserEventlisteners,
  ] = AuthStore((store) => [
    store.loggedIn,
    store.user,
    store.isRestoringAuthState,
    store.restoreAuthState,
    store.addEventlisteners,
    store.removeEventlisteners,
  ]);

  const [
    fetchGarages,
    addGarageEventlisteners,
    removeGarageEventlisteners,
    fetchMyGarages,
  ] = GarageStore((store) => [
    store.fetchGarages,
    store.addEventlisteners,
    store.removeEventlisteners,
    store.fetchMyGarages,
  ]);

  const [fetchBookings, addBookingEventlisteners, removeBookingEventlisteners] =
    BookingStore((store) => [
      store.fetchBookings,
      store.addEventlisteners,
      store.removeEventlisteners,
    ]);

  const [fetchVehicles, addVehicleEventlisteners, removeVehicleEventlisteners] =
    VehicleStore((store) => [
      store.fetchVehicles,
      store.addEventlisteners,
      store.removeEventlisteners,
    ]);

  const [fetchPaymentMethods] = PaymentStore((store) => [
    store.fetchPaymentMethods,
  ]);

  const [
    fetchSubscriptions,
    addSubscriptionEventListeners,
    removeSubscriptionEventListeners,
  ] = SubscriptionStore((store) => [
    store.fetchSubscriptions,
    store.addEventlisteners,
    store.removeEventlisteners,
  ]);

  React.useEffect(() => {
    restoreAuthState();
    fetchGarages();
    addGarageEventlisteners();
    addBookingEventlisteners();
    addVehicleEventlisteners();
    addSubscriptionEventListeners();

    return () => {
      removeGarageEventlisteners();
      removeBookingEventlisteners();
      removeVehicleEventlisteners();
      removeSubscriptionEventListeners();
    };
  }, []);

  React.useEffect(() => {
    if (loggedIn) {
      fetchBookings({ userId: user?.id });
      fetchGarages();
      fetchMyGarages();
      fetchVehicles();
      fetchPaymentMethods();
      addUserEventlisteners();
      changeLanguage({ i18n, lang: user?.settings?.language });
      fetchSubscriptions();

      Bugsnag.setUser(user?.id);
    } else {
      BookingStore.setState({ bookings: [] });
    }

    return () => {
      if (loggedIn) removeUserEventlisteners();
    };
  }, [loggedIn]);

  return (
    <>
      {/* @ts-ignore Sorry, I just don't know better... */}
      <ErrorBoundary>
        <LocationInitializer />
        <SafeAreaProvider>
          <PaperProvider
            theme={rptheme}
            settings={{ icon: (props) => <ReparkIcon {...props} /> }}
          >
            <Suspense fallback={<LoadingState />}>
              {!isRestoringAuthState && fontsLoaded ? (
                <Portal.Host>
                  <Navigation>
                    <BottomSheet />
                  </Navigation>
                </Portal.Host>
              ) : (
                <LoadingState />
              )}
              <SnackBar />
            </Suspense>
          </PaperProvider>
        </SafeAreaProvider>
      </ErrorBoundary>
    </>
  );
}
