import { ReCAPTCHAProps } from 'react-google-recaptcha';

export default class FirebaseRecaptchaVerifier {
  token;
  constructor(token: string | null) {
    this.token = token;
  }
  get type() {
    return 'recaptcha';
  }
  async verify() {
    return this.token;
  }
  async _reset() {}
}

export type RecaptchaProps = Partial<ReCAPTCHAProps> & {
  verify?: boolean;
  onSuccess: (token: string) => void;
};
