import React from 'react';
import { FAB } from 'react-native-paper';
import rptheme from '~/rptheme';
import { BottomSheetStore } from '../../bottom-sheet/BottomSheetStore';
import CancelBookingSheet from './CancelBookingSheet';

export type CancelBookingButtonProps = {
  id: string;
  onCancelSuccess: () => void;
  t: Function;
};

export const CancelBookingButton = (props: CancelBookingButtonProps) => {
  const openSheet = BottomSheetStore((store) => store.openSheet);
  const { id, t, onCancelSuccess } = props;

  return (
    <FAB
      icon={'booking-canceled'}
      color={rptheme.colors.error}
      style={{ backgroundColor: 'white' }}
      testID="cancelBookingButton"
      onPress={() =>
        openSheet(
          <CancelBookingSheet
            id={id}
            t={t}
            onCancelSuccess={onCancelSuccess}
          />,
          {
            adjustToContentHeight: true,
          }
        )
      }
    >
      {t('General.cancelBooking.ButtonLabel')}
    </FAB>
  );
};
