import create from 'zustand';
import { feathersClient } from '~/src/services/feathers';
import { fetchCompaniesRequest } from './companyService';

type CompanyStore = {
  isFetchingCompanies: boolean;
  companies: Array<any>;
  fetchCompanies: (query?: any) => Promise<void>;
  // patchCompany: (data: any) => Promise<any>;
  getCompany: (id: string) => any;
  addEventlisteners: () => void;
  removeEventlisteners: () => void;
};

export const CompanyStore = create<CompanyStore>((set, get) => ({
  isFetchingCompanies: false,
  companies: [],

  fetchCompanies: async (query) => {
    set({ isFetchingCompanies: true });
    try {
      const response = await fetchCompaniesRequest(query);
      console.log(response);
      set({ companies: await response, isFetchingCompanies: false });
    } catch (e) {
      set({ isFetchingCompanies: false });
      throw e;
    }
  },

  // patchCompany: async (data: any) => {
  //   // const response = await patchCompanyRequest(data);
  //   // return response?.data;
  // },

  getCompany: (id: string) => {
    return get().companies?.find((company: any) => company.id === id);
  },

  addEventlisteners: () => {
    console.log('Adding Companiess Eventlisteners');

    feathersClient.service('companies').on('created', () => {
      get().fetchCompanies();
    });

    feathersClient.service('companies').on('removed', () => {
      get().fetchCompanies();
    });

    feathersClient.service('companies').on('patched', () => {
      get().fetchCompanies();
    });
  },

  removeEventlisteners: () => {
    console.log('Removing Companies Eventlisteners');

    feathersClient.service('companies').removeAllListeners('created');
    feathersClient.service('companies').removeAllListeners('removed');
    feathersClient.service('companies').removeAllListeners('patched');
  },
}));
