import React from 'react';
import { GarageStore } from './GarageStore';

export const useCheckCanOpen = (id: string) => {
  const [isCheckingCanOpen, setIsCheckingCanOpen] = React.useState(false);
  const [canOpenGarage] = GarageStore((store) => [store.canOpenGarage]);
  const [canOpen, setCanOpen] = React.useState(false);

  const checkIfEnabled = async () => {
    console.log('Checking if garage can be opened', id);
    if (!id) return;
    setIsCheckingCanOpen(true);
    try {
      await canOpenGarage(id);
      setCanOpen(true);
    } catch (e: any) {
      setCanOpen(false);
      console.log('Error checking if garage can be opened', e);
    } finally {
      setTimeout(() => setIsCheckingCanOpen(false), 500); //Why? To prevent the button from flashing, if the call returns very quick.
    }
  };

  React.useEffect(() => {
    checkIfEnabled();

    window.addEventListener?.('focus', checkIfEnabled);
    return () => {
      window.removeEventListener?.('focus', checkIfEnabled);
    };
  }, []);

  return {
    isCheckingCanOpen,
    canOpen,
  };
};
