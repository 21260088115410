import React from 'react';
import { StyleSheet, View, Image } from 'react-native';
import rptheme from '~/rptheme';
import { InfoBanner } from '../../../../components/InfoBanner';
import { AccessType } from '../types';
import { GarageType } from '~/src/features/garage/types';
import ReparkButton from '~/src/components/ReparkButton';
import { Title } from 'react-native-paper';
import { GarageCard } from '../components/GarageCard';

type InspectionSuccessStepProps = {
  onContinue: () => void;
  access: AccessType;
  garage: GarageType;
};

export const InspectionSuccessStep = (props: InspectionSuccessStepProps) => {
  const { onContinue, access, garage } = props;
  const imageSource = rptheme.graphics.welcomeOnboarding;

  return (
    <View>
      <Image testID="image" source={imageSource} style={styles.image} />
      <Title style={styles.heading}>
        Glückwunsch, deine Buchung zur Garagenbesichtigung hat geklappt!
      </Title>

      <GarageCard garage={garage} access={access} />

      <InfoBanner text="Wir senden dir am Vortag eine E-Mail mit allen freien Parkplätzen und der Erklärung zum Zugang zu." />
      <InfoBanner
        backgroundColor="transparent"
        textColor={rptheme.colors.text}
        text="Du hast Fragen oder brauchst Hilfe? Dann melde dich bei uns unter info@repark.at"
      />
      <ReparkButton onPress={onContinue}>Fertig</ReparkButton>
    </View>
  );
};

const styles = StyleSheet.create({
  image: {
    height: 200,
    resizeMode: 'contain',
    marginVertical: 30,
  },
  legalText: {
    fontSize: 14,
    color: rptheme.colors.gray,
    marginVertical: 25,
  },
  heading: {
    textAlign: 'center',
    marginTop: 30,
    marginBottom: 10,
  },
});
