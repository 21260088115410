import React from 'react';
import { View, TouchableHighlight, StyleSheet } from 'react-native';
import rptheme from '~/rptheme';

export type CalendarWrapperProps = {
  children: any;
  testID?: string;
};

export const CalendarWrapper = (props: CalendarWrapperProps) => {
  return (
    <View style={styles.wrapper}>
      <TouchableHighlight testID={props.testID} style={styles.container}>
        {props.children}
      </TouchableHighlight>
    </View>
  );
};

const styles = StyleSheet.create({
  wrapper: {
    justifyContent: 'space-around',
    maxWidth: rptheme.screenSmall,
    alignSelf: 'center',
  },
  container: {
    backgroundColor: 'white',
    paddingBottom: 10,
    borderRadius: rptheme.roundness,
    marginVertical: 10,
    padding: 20,
    ...rptheme.shadow,
  },
});
