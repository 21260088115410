import React from 'react';
import { StyleSheet } from 'react-native';
import { FAB } from 'react-native-paper';
import rptheme from '~/rptheme';
import { envIsDev, isAdmin } from '~/src/utils/helpers';
import { AuthStore } from '../authentication/AuthStore';
import { NavProp, RootScreens } from '../navigation';

type DebugFABProps = {
  navigation: NavProp;
};

export const DebugFAB = (props: DebugFABProps) => {
  const { navigation } = props;
  const user = AuthStore((store) => store.user);

  const currentDomain = window?.location?.hostname;

  if (
    currentDomain !== 'app.preview.repark.ninja' &&
    !envIsDev() &&
    !isAdmin(user?.role)
  )
    return <></>;

  return (
    <FAB
      icon={'logo'}
      onPress={() => navigation.navigate(RootScreens.DebugStack)}
      color={rptheme.colors.primary}
      style={styles.fab}
    />
  );
};

const styles = StyleSheet.create({
  fab: {
    backgroundColor: rptheme.colors.secondary,
  },
});
