import React from 'react';
import { useTranslation } from 'react-i18next';
import { KeyboardAvoidingView, Platform, View, StyleSheet } from 'react-native';
import { Title, Paragraph, IconButton } from 'react-native-paper';
import config from '~/config/config';
import rptheme from '~/rptheme';
import { envIsDev } from '~/src/utils/helpers';
import { NavProp, RootScreens } from '../navigation';
import { AuthStore } from './AuthStore';
import { AuthDevShortcut } from './components/AuthDevShortcut';
import AuthOTCInput from './components/AuthOTCInput';
import AuthPhoneInput from './components/AuthPhoneInput';

type AuthProps = {
  navigation: NavProp;
  texts?: IntroTexts;
  goBackFallBack?: string;
  redirect?: boolean;
  onSuccess?: () => void;
};

type IntroTexts = {
  EnterPhoneNumberStep?: {
    title?: string;
    intro?: string;
  };
  EnterOTCStep?: {
    title?: string;
    intro?: string;
  };
};

export const Auth = (props: AuthProps) => {
  const { t } = useTranslation();

  const defaultTexts = {
    EnterPhoneNumberStep: {
      title: t('Auth.EnterPhoneNumberStep.title'),
      intro: t('Auth.EnterPhoneNumberStep.intro'),
    },
    EnterOTCStep: {
      title: t('Auth.EnterOTCStep.title'),
      intro: t('Auth.EnterOTCStep.intro'),
    },
  };

  const {
    navigation,
    texts = defaultTexts,
    goBackFallBack = 'Map',
    redirect = true,
    onSuccess,
  } = props;
  const [loggedIn, redirectAfterLogin] = AuthStore((store) => [
    store.loggedIn,
    store.redirectAfterLogin,
  ]);
  const [verificationData, setVerificationData] = React.useState<any>();

  React.useLayoutEffect(() => {
    // If Auth-Component is initialized on a page, it takes over control of the back button.
    navigation.setOptions({
      headerLeft: () => (
        <IconButton
          testID={'backToPhoneInput'}
          icon={rptheme.backIcon}
          color={rptheme.colors.primary}
          onPress={() => {
            if (verificationData && !loggedIn) {
              setVerificationData(null);
            } else if (navigation.canGoBack()) {
              navigation.goBack();
            } else {
              navigation.navigate(goBackFallBack);
            }
          }}
        ></IconButton>
      ),
    });
  }, [verificationData, loggedIn]);

  const onLoginSuccess = (verifyData?: any) => {
    onSuccess?.();
    if (!redirect) return;

    if (envIsDev() && verifyData) {
      if (verifyData.newUser) {
        console.log(
          'In env! Would be redirected to onboarding after otc step',
          verifyData
        );
        navigation.navigate(RootScreens.OnboardingScreen);
      } else {
        navigation.replace(RootScreens.MapScreen, {
          id: redirectAfterLogin || undefined,
        });
      }
    }
    if (verificationData?.newUser) {
      navigation.navigate(RootScreens.OnboardingScreen);
    } else {
      navigation.replace(RootScreens.MapScreen, {
        id: redirectAfterLogin || undefined,
      });
    }
  };

  return (
    <>
      <KeyboardAvoidingView
        style={styles.container}
        behavior={Platform.OS === 'ios' ? 'padding' : undefined}
      >
        {!verificationData ? (
          <>
            {config().firebaseDev.useEmulator && envIsDev() ? (
              <AuthDevShortcut onLoginSuccess={onLoginSuccess} />
            ) : (
              <>
                <View style={styles.textContainer}>
                  {texts.EnterPhoneNumberStep?.title && (
                    <Title>{texts.EnterPhoneNumberStep.title}</Title>
                  )}
                  {texts.EnterPhoneNumberStep?.intro && (
                    <Paragraph>{texts.EnterPhoneNumberStep.intro}</Paragraph>
                  )}
                </View>
                <AuthPhoneInput
                  onVerificationReceived={async (data) =>
                    setVerificationData(data)
                  }
                />
              </>
            )}
          </>
        ) : (
          <>
            <View style={styles.textContainer}>
              {texts.EnterOTCStep?.title && (
                <Title>{texts.EnterOTCStep.title}</Title>
              )}
              {texts.EnterOTCStep?.intro && (
                <Paragraph>{texts.EnterOTCStep.intro}</Paragraph>
              )}
            </View>
            <AuthOTCInput
              verificationData={verificationData}
              onLoginSuccess={async () => onLoginSuccess()}
            />
          </>
        )}
      </KeyboardAvoidingView>
    </>
  );
};

const styles = StyleSheet.create({
  container: {
    margin: 20,
  },
  textContainer: {
    marginBottom: 10,
  },
});
