import { feathersClient } from '../../services/feathers';

export const fetchOffersRequest = async (query?: any) => {
  const response = await feathersClient.service('offers').find({
    query: {
      ...query,
    },
  });
  return response;
};

export const fetchSingleOfferRequest = async (id: string) => {
  const response = await feathersClient.service('offers').get(id);
  return response;
};
