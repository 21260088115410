import React from 'react';
import { Dimensions, Keyboard, Platform } from 'react-native';
import { Modalize } from 'react-native-modalize';
import { BottomSheetStore } from './BottomSheetStore';

export const BottomSheet = () => {
  const modalizeRef = React.useRef<Modalize>(null);
  const show = BottomSheetStore((store) => store.show);
  const closeSheet = BottomSheetStore((store) => store.closeSheet);
  const sheetContent = BottomSheetStore((store) => store.sheetContent);
  const sheetProps = BottomSheetStore((store) => store.sheetProps);
  const setSheetPosition = BottomSheetStore((store) => store.setSheetPosition);
  const setSheetProps = BottomSheetStore((store) => store.setSheetProps);
  const [isOpen, setIsOpen] = React.useState(false);

  const [keyBoardOffset, setKeyboardOffset] = React.useState(0);

  React.useEffect(() => {
    if (show && !isOpen) {
      openSheetWithRef();
    } else if (!show && isOpen) {
      closeSheetWithRef();
    }
  }, [show]);

  const openSheetWithRef = () => {
    modalizeRef.current?.open();
  };

  const closeSheetWithRef = () => {
    modalizeRef.current?.close();
  };

  React.useEffect(() => {
    const keyboardVisibleListener = Keyboard.addListener(
      Platform.OS === 'ios' ? 'keyboardWillShow' : 'keyboardDidShow',
      handleKeyboardVisible
    );
    const keyboardHiddenListener = Keyboard.addListener(
      Platform.OS === 'ios' ? 'keyboardWillHide' : 'keyboardDidHide',
      handleKeyboardHidden
    );

    return () => {
      keyboardHiddenListener.remove();
      keyboardVisibleListener.remove();
    };
  }, []);

  const handleKeyboardVisible = () => {
    setKeyboardOffset(240);
  };

  const handleKeyboardHidden = () => {
    setKeyboardOffset(0);
  };

  return (
    <Modalize
      //Position fixed would throw a TS Error, because it's not a valid option in native. However, in web it words as intended.
      // @ts-ignore:next-line
      rootStyle={{
        marginBottom: keyBoardOffset,
        overflow: Dimensions.get('window').width > 640 ? 'hidden' : undefined,
        position: Platform.OS === 'web' ? 'fixed' : undefined,
      }}
      keyboardAvoidingBehavior={'padding'}
      keyboardAvoidingOffset={-200}
      ref={modalizeRef}
      {...sheetProps}
      onPositionChange={(position) => setSheetPosition(position)}
      onClose={() => {
        setIsOpen(false);
        setSheetProps({
          ...sheetProps,
          FloatingComponent: undefined,
        });
      }}
      onClosed={() => {
        if (show) closeSheet();
      }}
      onOpen={() => {
        setIsOpen(true);
      }}
      scrollViewProps={{ alwaysBounceVertical: false }}
    >
      {sheetContent}
    </Modalize>
  );
};
