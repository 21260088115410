import React from 'react';
import { View } from 'react-native';
import rptheme from '~/rptheme';

type RadioButtonProps = {
  selected: boolean;
  style?: any;
};

export const ReparkRadioButton = (props: RadioButtonProps) => {
  return (
    <View
      style={[
        {
          height: 24,
          width: 24,
          borderRadius: 12,
          borderWidth: 2,
          borderColor: rptheme.colors.primary,
          alignItems: 'center',
          justifyContent: 'center',
        },
        props.style,
      ]}
    >
      {props.selected ? (
        <View
          style={{
            height: 12,
            width: 12,
            borderRadius: 6,
            backgroundColor: rptheme.colors.primary,
          }}
        />
      ) : null}
    </View>
  );
};
