// https://github.com/onmotion/react-native-autocomplete-dropdown

import * as React from 'react';
import { View, Platform, StyleSheet } from 'react-native';
import { AutocompleteDropdown } from 'react-native-autocomplete-dropdown';
import rptheme from '~/rptheme';
import { Subheading, Paragraph } from 'react-native-paper';
import ReparkButton from '~/src/components/ReparkButton';

const isWeb = Platform.OS === 'web';

type SelectProps = {
  topLabel?: string;
  placeholder?: string;
  displayKey: string;
  zoderror?: any;
  testID?: string;
  items: Array<any>;
  onSelectItem: (item: any) => any;
};

export default function Select(props: SelectProps) {
  const [dataSet, setDataSet] = React.useState<any>([]);
  const { topLabel, placeholder, displayKey, zoderror, testID, items } = props;

  const dynamicStyles = getDynamicStyles(zoderror, dataSet);

  React.useEffect(() => {
    setDataSet(
      items.map((item: any) => {
        item.title = item[displayKey];
        return item;
      })
    );
  }, [items]);

  return (
    <View style={styles.view}>
      {topLabel && (
        <Subheading
          style={{
            color: rptheme.colors.primary,
          }}
        >
          {topLabel}
        </Subheading>
      )}
      <AutocompleteDropdown
        testID={testID}
        textInputProps={{
          placeholder: placeholder,
          style: dynamicStyles.textInput,
        }}
        containerStyle={dynamicStyles.selectContainer}
        rightButtonsContainerStyle={dynamicStyles.rightButtonContainer as any}
        suggestionsListContainerStyle={
          dynamicStyles.suggestionsListContainer as any
        }
        clearOnFocus={false}
        closeOnBlur={false}
        closeOnSubmit={false}
        dataSet={dataSet}
        {...props}
      />

      {/* In test mode, the select will be extended with a list of buttons, due to the dropdown not being properly rendered. */}
      {process.env.NODE_ENV === 'test' &&
        Object.keys(dataSet).map((key: any) => {
          return (
            <View key={key}>
              <ReparkButton
                testID={'selectWorkaround'}
                onPress={() => props.onSelectItem(dataSet[key])}
              >
                {dataSet[key].title}
              </ReparkButton>
            </View>
          );
        })}

      {zoderror && (
        <Paragraph style={styles.errorText}>{zoderror.message}</Paragraph>
      )}
    </View>
  );
}

const getDynamicStyles = (error: any, dataSet: any) => {
  const inputHeight = 42;
  return {
    textInput: {
      backgroundColor: 'whitesmoke',
      height: inputHeight,
      paddingHorizontal: 10,
      borderRadius: rptheme.roundness,
      borderWidth: 1,
      borderColor: error ? rptheme.colors.error : rptheme.colors.primary,
      fontFamily: 'Poppins_400Regular',
    },
    rightButtonContainer: isWeb
      ? {
          backgroundColor: 'whitesmoke',
          borderRadius: 40,
          marginRight: 10,
          marginTop: 4,
          height: '85%',
          position: 'absolute',
          alignSelf: 'flex-end',
          justifyContent: 'center',
          alignItems: 'center',
          flex: 1,
          flexDirection: 'row',
        }
      : {
          backgroundColor: 'whitesmoke',
          borderRadius: 25,
          marginRight: 3,
          marginTop: 1,
          height: '95%',
        },
    selectContainer: {},
    suggestionsListContainer: isWeb
      ? {
          backgroundColor: 'white',
          position: 'absolute',
          top: inputHeight + 5,
          width: '100%',
          shadowColor: '#000',
          shadowOffset: {
            width: 0,
            height: 2,
          },
          shadowOpacity: 0.25,
          shadowRadius: 3.84,
          elevation: 5,
          minHeight:
            dataSet.length < 4 ? dataSet.length * inputHeight : 4 * inputHeight,
        }
      : {},
  };
};

const styles = StyleSheet.create({
  view: {
    marginVertical: 10,
    // On Android setting zIndex makes the items untabable
    zIndex: isWeb ? 10 : Platform.OS === 'ios' ? 10 : undefined,
  },
  errorText: {
    color: rptheme.colors.error,
    marginTop: 5,
    textAlign: 'center',
  },
});
