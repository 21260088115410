import * as React from 'react';
import { StyleSheet, View } from 'react-native';
import { useTranslation } from 'react-i18next';
import { List, Paragraph, Subheading } from 'react-native-paper';
import config from '~/config/config';
import ReparkListItem from '~/src/components/ReparkListItem';
import ReparkLogo from '~/src/components/ReparkLogo';
import dayjs from 'dayjs';
import { version } from '~/package.json';
import { CenteredAppView } from '~/src/components/CenteredAppView';
import { AboutStackScreens } from './AboutStackNavigation';
import { openLinkInBrowser } from '~/src/utils/helpers';

export default function AboutScreen({ navigation }: any) {
  const { t } = useTranslation();
  const openInWebView = (item: any) => {
    navigation.navigate(AboutStackScreens.AboutWebViewScreen, {
      url: item.url,
    });
  };

  const companyItems = [
    {
      testID: 'reparkHomePage',
      url: config().webPages?.homepage,
      title: 'repark.at',
      icon: 'reparklogo',
    },
    {
      testID: 'reparkJobs',
      url: config().webPages?.jobs,
      title: t('AboutScreen.linkToJobs'),
      icon: 'agree',
    },
  ];

  const socialItems = [
    {
      testID: 'reparkLinkedIn',
      url: config().webPages?.linkedin,
      title: 'LinkedIn',
      icon: 'linkedin',
    },
    {
      testID: 'reparkFacebook',
      url: config().webPages?.facebook,
      title: 'Facebook',
      icon: 'facebook',
    },
    {
      testID: 'reparkInstagram',
      url: config().webPages?.instagram,
      title: 'Instagram',
      icon: 'instagram',
    },
  ];

  const legalItems = [
    {
      testID: 'gdprScreenLink',
      url: config().webPages?.gdpr,
      title: t('LegalScreen.privacyPolicy'),
      icon: 'gdpr',
    },
    {
      testID: 'termsScreenLink',
      url: config().webPages?.terms,
      title: t('LegalScreen.terms'),
      icon: 'policy',
    },
    {
      testID: 'imprintScreenLink',
      url: config().webPages?.imprint,
      title: t('LegalScreen.imprint'),
      icon: 'imprint',
    },
  ];

  return (
    <CenteredAppView>
      <View style={styles.logoContainer}>
        <ReparkLogo width={300} />
        <View style={styles.textContainer}>
          <Paragraph style={styles.text}>
            © {dayjs().year()} Repark GmbH
          </Paragraph>
          <Paragraph style={styles.text}>App v{version}</Paragraph>
        </View>
      </View>

      <List.Section>
        {companyItems.map((item, key) => {
          return (
            <ReparkListItem
              style={{ paddingVertical: 0 }}
              key={key}
              testID={item.testID}
              onPress={() => openLinkInBrowser(item.url)}
              title={item.title}
              icon={item.icon}
            />
          );
        })}
      </List.Section>

      <List.Section>
        {socialItems.map((item, key) => {
          return (
            <ReparkListItem
              style={{ paddingVertical: 0 }}
              key={key}
              testID={item.testID}
              onPress={() => openLinkInBrowser(item.url)}
              title={item.title}
              icon={item.icon}
            />
          );
        })}
      </List.Section>

      <Subheading style={styles.sectionTitle}>
        {t('LegalScreen.screenTitle')}
      </Subheading>
      <List.Section>
        {legalItems.map((item, key) => {
          return (
            <ReparkListItem
              style={{ paddingVertical: 0 }}
              key={key}
              testID={item.testID}
              onPress={() => openInWebView(item)}
              title={item.title}
              icon={item.icon}
            />
          );
        })}
      </List.Section>
    </CenteredAppView>
  );
}

const styles = StyleSheet.create({
  sectionTitle: { color: 'grey', marginLeft: 25, marginTop: 30 },
  logoContainer: { alignItems: 'center', marginVertical: 10, marginTop: 70 },
  textContainer: { marginVertical: 10, alignItems: 'center' },
  text: { marginVertical: 5 },
});
