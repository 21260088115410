import { t } from 'i18next';
import React from 'react';
import rptheme from '~/rptheme';
import { routerIsDoor, routerHasName } from '../booking/helpers';
import { openDeviceRequest } from './garageService';
import { GarageType } from './types';
import ReparkButton from '~/src/components/ReparkButton';
import { BottomSheetStore } from '../bottom-sheet/BottomSheetStore';
import { ErrorSheet, errorSheetOptions } from './sheets/ErrorSheet';
import { NavProp } from '../navigation';

type GarageDeviceButtonsProps = {
  garage: GarageType;
  navigation: NavProp;
};

export const GarageDeviceButtons = ({
  garage,
  navigation,
}: GarageDeviceButtonsProps) => {
  const [isLoading, setIsLoading] = React.useState(() => {
    const routerStates = garage.routers.map((router: any) => ({
      [router.id]: false,
    }));
    const deviceStates = garage.ewelinkDevices.map((device: any) => ({
      [device.id]: false,
    }));

    return Object.assign({}, ...routerStates, ...deviceStates);
  });

  const [openResponse, setOpenResponse] = React.useState(() => {
    const routerStates = garage.routers.map((router: any) => ({
      [router.id]: null,
    }));
    const deviceStates = garage.ewelinkDevices.map((device: any) => ({
      [device.id]: null,
    }));

    return Object.assign({}, ...routerStates, ...deviceStates);
  });

  const handleOpenSuccess = (id: string) => {
    setOpenResponse((prevState: any) => ({
      ...prevState,
      [id]: 'success',
    }));

    setTimeout(() => {
      setOpenResponse((prevState: any) => ({
        ...prevState,
        [id]: null,
      }));
    }, 3000);
  };
  const [openSheet] = BottomSheetStore((store) => [store.openSheet]);

  const handleOpenError = (id: string, response?: any) => {
    openSheet(
      <ErrorSheet {...response} navigation={navigation} />,
      errorSheetOptions
    );

    setOpenResponse((prevState: any) => ({
      ...prevState,
      [id]: 'error',
    }));

    setTimeout(() => {
      setOpenResponse((prevState: any) => ({
        ...prevState,
        [id]: null,
      }));
    }, 3000);
  };

  const handleOpen = async (id: string) => {
    setIsLoading((prevState: any) => ({
      ...prevState,
      [id]: true,
    }));
    try {
      const response = await openDeviceRequest(id);
      if (response.success) {
        handleOpenSuccess(id);
      } else {
        handleOpenError(id);
      }
    } catch (e) {
      console.log(e);
      handleOpenError(id);
    }

    setIsLoading((prevState: any) => ({
      ...prevState,
      [id]: false,
    }));
  };

  const computeButtonColor = (id: string) => {
    if (openResponse[id] === 'success') {
      return rptheme.colors.accent;
    } else if (openResponse[id] === 'error') {
      return rptheme.colors.error;
    }

    return rptheme.colors.primary;
  };

  const computeButtonLabel = (device: any) => {
    const isRouter = device.id.startsWith('RTR');
    const isEweLinkDevice = device.id.startsWith('ELD') || routerIsDoor(device);

    const deviceName =
      (routerHasName(device) && device.name.replace('[DOOR]', '')) ||
      (isRouter
        ? 'Garagentor'
        : isEweLinkDevice || routerIsDoor(device)
        ? 'Tür'
        : 'Gerät');

    if (openResponse[device.id] === 'success') {
      return t('GarageDevicesSheet.openDeviceButtonLabelSuccess', {
        device: deviceName,
      });
    } else if (openResponse[device.id] === 'error') {
      return t('GarageDevicesSheet.openDeviceButtonLabelError', {
        device: deviceName,
      });
    }
    return t('GarageDevicesSheet.openDeviceButtonLabel', {
      device: deviceName,
    });
  };

  return (
    <>
      {garage.routers.map((router: any) => {
        //dirty hack for now
        return !routerIsDoor(router) ? (
          <ReparkButton
            key={router.id}
            onPress={async () => handleOpen(router.id)}
            icon={'garage'}
            loading={isLoading[router.id]}
            disabled={isLoading[router.id]}
            color={computeButtonColor(router.id)}
          >
            {computeButtonLabel(router)}
          </ReparkButton>
        ) : (
          <ReparkButton
            key={router.id}
            onPress={async () => handleOpen(router.id)}
            icon={'walking'}
            loading={isLoading[router.id]}
            disabled={isLoading[router.id]}
            color={computeButtonColor(router.id)}
          >
            {computeButtonLabel(router)}
          </ReparkButton>
        );
      })}
      {garage.ewelinkDevices.map((device: any) => {
        return (
          <ReparkButton
            key={device.id}
            onPress={async () => handleOpen(device.id)}
            icon={'walking'}
            loading={isLoading[device.id]}
            disabled={isLoading[device.id]}
            color={computeButtonColor(device.id)}
          >
            {computeButtonLabel(device)}
          </ReparkButton>
        );
      })}
    </>
  );
};
