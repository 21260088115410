import React from 'react';
import { CenteredAppView } from '~/src/components/CenteredAppView';
import { NavProp } from '../../navigation';
import { PersonalDataForm } from './PersonalDataForm';

type PersonalDataScreenProps = {
  navigation?: NavProp;
};

export const PersonalDataScreen = (props: PersonalDataScreenProps) => {
  return (
    <CenteredAppView>
      <PersonalDataForm navigation={props.navigation} />
    </CenteredAppView>
  );
};
