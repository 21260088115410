import * as React from 'react';
import { StyleSheet, Image } from 'react-native';
import { View } from 'react-native';

type ReparkLogoInlineProps = {
  fontSize?: number;
};

const ORIGINAL_HEIGHT = 1218;
const ORIGINAL_WIDTH = 4025;
const ORIGINAL_DESCENDER = 274;
const ASPECT_RATIO = ORIGINAL_WIDTH / ORIGINAL_HEIGHT;

const FACTOR_DESCENDER = ORIGINAL_DESCENDER / ORIGINAL_HEIGHT;
const VISUAL_DESCENDER_OFFSET = 0.5;

export default function ReparkLogo({ fontSize = 16 }: ReparkLogoInlineProps) {
  const calculateSizes = () => {
    const height = fontSize;
    const width = height * ASPECT_RATIO;
    const bottom = Math.floor(
      (height + VISUAL_DESCENDER_OFFSET) * FACTOR_DESCENDER * -1
    );
    return { width, height, bottom };
  };

  const { width, height, bottom } = calculateSizes();

  const styles = StyleSheet.create({
    logo: {
      height,
      width,
      resizeMode: 'contain',
      position: 'absolute',
      bottom,
    },
  });

  return (
    <View style={{ position: 'relative' }}>
      <Image
        source={require('~/assets/images/repark-logo.png')}
        style={styles.logo}
      ></Image>
    </View>
  );
}
