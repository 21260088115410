import React from 'react';
import { View } from 'react-native';
import { ReparkCheckbox, ReparkCheckBoxProps } from './ReparkCheckbox';
import { ReparkCheckBoxStates } from '../hooks/useCheckboxGroup';

export type ReparkCheckboxItem = ReparkCheckBoxProps & {
  key: string;
};
type ReparkCheckboxGroupProps = {
  items: Array<ReparkCheckboxItem>;
  width?: number;
  states: ReparkCheckBoxStates;
  position?: 'before' | 'after';
  size?: 'small' | 'medium' | 'large';
};

export const ReparkCheckboxGroup = ({
  items,
  states,
  position,
  width,
  size,
}: ReparkCheckboxGroupProps) => {
  return (
    <View>
      {items.map((item: ReparkCheckboxItem) => (
        <View key={item.key}>
          <ReparkCheckbox
            position={position}
            width={width}
            label={item.label}
            checked={states[item.key]}
            onPress={item.onPress}
            disabled={false}
            size={size}
          />
        </View>
      ))}
    </View>
  );
};
