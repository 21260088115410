import { NavigationProp } from '@react-navigation/native';
import * as React from 'react';
import ReparkWebView from '~/src/features/webview/ReparkWebView';

type WebViewScreenProps = {
  navigation: NavigationProp<any>;
  route?: {
    params: {
      url: string;
    };
  };
};

export default function WebViewScreen(props: WebViewScreenProps) {
  const {
    route: { params: { url } } = {
      params: { url: 'https://www.repark.at' },
    },
  } = props;

  return (
    <>
      <ReparkWebView url={url}></ReparkWebView>
    </>
  );
}
