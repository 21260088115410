import haversine from 'haversine-distance';
import { Platform } from 'react-native';
import { Modalize } from 'react-native-modalize';
import rptheme from '~/rptheme';
import viennaSubwayData from './components/viennasubwaydata.json';

type Station = {
  name: string;
  line: number;
  distance: number;
};

export const getNearbySubwayStations = (coords: any, unique = false) => {
  const stations: Array<Station> = [];

  viennaSubwayData.features.map((feature) => {
    if (feature.geometry.type !== 'Point') return;

    stations.push({
      name: feature.properties.HTXT as string,
      line: feature.properties.LINFO as number,
      distance: haversine(coords, {
        lat: parseFloat(feature.geometry.coordinates[1] as any),
        lng: parseFloat(feature.geometry.coordinates[0] as any),
      }),
    });
  });

  stations.sort((a, b) => a.distance - b.distance);

  if (unique) {
    const lines: Array<number | undefined> = [];
    const uniqueStations: Station[] = [];
    stations.map((station) => {
      if (!lines.includes(station.line)) {
        lines.push(station.line);
        uniqueStations.push(station);
        return station;
      }
    });

    return uniqueStations;
  }

  return stations;
};

export const computeSubwayColor = (line: number) => {
  switch (line) {
    case 1:
      return '#D22E26';
    case 2:
      return '#9F669F';
    case 3:
      return '#E08330';
    case 4:
      return '#429548';
    case 6:
      return '#966C3B';
    default:
      return rptheme.colors.primary;
  }
};

export const createOpenInMapsLink = (
  coordinates: {
    latitude: string;
    longitude: string;
  },
  label?: string
) => {
  const scheme = Platform.select({ ios: 'maps:0,0?q=', android: 'geo:0,0?q=' });
  const lat = coordinates?.latitude;
  const lng = coordinates?.longitude;
  const latLng = `${lat},${lng}`;
  const url = Platform.select({
    ios: `${scheme}${label}@${latLng}`,
    android: `${scheme}${latLng}(${label})`,
    web: `https://www.google.com/maps/place/${lat},${lng}`,
  });

  return url;
};

export class Point {
  x = 0;
  y = 0;

  constructor(x: number, y: number) {
    (this.x = x), (this.y = y);
  }

  equals(other: Point) {
    if (other.x === this.x && other.y === this.y) return true;
    return false;
  }

  toString(): string {
    return `${this.x}, ${this.y}`;
  }
}

export const isSubwayFinalDestination = (OBJECTID: number) => {
  const finalDestinationsVienna = [
    147080, // Simmering
    147115, // Oberlaa
    147085, // Siebenhirten
    147075, // Hütteldorf
    147112, // Ottakring
    147088, // Floridsdorf
    147083, // Heiligenstadt
    147101, // Leopoldau
    147089, // Seestadt
    147069, // Schottentor
  ];

  return finalDestinationsVienna.includes(OBJECTID);
};

export const latitudeForBottomSheet = ({
  latitude,
  sheetProps,
  region,
}: {
  latitude: number;
  sheetProps: React.ComponentProps<typeof Modalize>;
  region: {
    longitude: number;
    latitude: number;
    longitudeDelta: number;
    latitudeDelta: number;
    altitude: number;
    zoom: number;
  };
}) => {
  const metersPerPx =
    (156543.03392 * Math.cos((latitude * Math.PI) / 180)) /
    Math.pow(2, region.zoom);

  const markerHeight = 57;
  const sheetHeight = sheetProps.snapPoint
    ? sheetProps.snapPoint - markerHeight
    : 0;
  const mapOffset = (sheetHeight / 2) * metersPerPx;
  const metersPerDegreeLatitude = 111319;
  const mapOffsetInDegree = mapOffset / metersPerDegreeLatitude;

  if (latitude >= 0) {
    return latitude - mapOffsetInDegree;
  } else {
    return latitude + mapOffsetInDegree;
  }
};
