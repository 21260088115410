import React from 'react';
import LoadingState from '~/src/components/LoadingState';

type ReparkWebViewProps = {
  url: string;
};

export default function ReparkWebView(props: ReparkWebViewProps) {
  const { url } = props;
  const [isLoading, setIsLoading] = React.useState(true);
  const [iframeHeight, setiframeHeight] = React.useState('0%');

  const loadingFinished = () => {
    setIsLoading(false);
    setiframeHeight('100%');
  };

  return (
    <>
      {isLoading && <LoadingState />}
      <iframe
        id="webViewiFrame"
        width="100%"
        height={iframeHeight}
        style={{
          borderWidth: 0,
        }}
        src={url}
        allow="autoplay; encrypted-media"
        onLoad={() => loadingFinished()}
      ></iframe>
    </>
  );
}
