import React from 'react';
import { useTranslation } from 'react-i18next';
import { StyleSheet, Text, View } from 'react-native';
import { Trans } from 'react-i18next';
import rptheme from '~/rptheme';
import {
  ReasonOfBookingOptionsType,
  getReasonOfBookingOptions,
} from './ReasonOfBookingOptions';
import ReparkIconButton from '~/src/components/ReparkIconButton';
import { getReasonOfBookingCategory } from './ReasonOfBookingHelper';
import ReparkButton from '~/src/components/ReparkButton';
import { ReparkIcon } from '~/src/components/ReparkIcon';

export type ReasonOfBookingModalProps = {
  onSelection: (selection: string) => void;
  onClosePress: () => void;
  handleDeactivate: () => void;
  booking: any;
};

export const ReasonOfBookingModal = ({
  onSelection,
  onClosePress,
  booking,
  handleDeactivate,
}: ReasonOfBookingModalProps) => {
  const { t } = useTranslation();
  const [reasonOfBookingOptions, setReasonOfBookingOptions] =
    React.useState<any>([]);

  React.useEffect(() => {
    const category = getReasonOfBookingCategory(booking);
    setReasonOfBookingOptions(getReasonOfBookingOptions(category));
  }, [booking]);
  return (
    <>
      <View style={styles.container}>
        <View style={styles.content}>
          <View style={styles.closeButton}>
            <ReparkIconButton icon={'close'} size={20} onPress={onClosePress} />
          </View>
          <Text style={styles.heading}>
            <Trans i18nKey={'ReasonOfBooking.title'}>
              Was ist <b>der Grund </b>für diese Parkplatzbuchung?
            </Trans>
          </Text>
          <Text style={styles.explanation}>{t('ReasonOfBooking.explain')}</Text>
          <View>
            {reasonOfBookingOptions.map(
              (option: ReasonOfBookingOptionsType) => (
                <View key={option.id}>
                  <ReparkButton
                    marginVertical={5}
                    labelStyle={{ justifyContent: 'flex-start', width: '100%' }}
                    outlineThickness={2}
                    fontSize={16}
                    mode="outlined"
                    onPress={() => {
                      onSelection(option.id);
                    }}
                  >
                    <View style={styles.optionButton}>
                      <ReparkIcon name={option.icon} size={option.size} />
                      <Text style={{ marginLeft: option.margin }}>
                        {t(option.display)}
                      </Text>
                    </View>
                  </ReparkButton>
                </View>
              )
            )}
            <ReparkButton
              mode="text"
              onPress={() => {
                onSelection('no-comment');
              }}
            >
              <Text style={{ fontSize: 18 }}>
                {t('ReasonOfBooking.noComment')}
              </Text>
            </ReparkButton>
            <Text style={styles.explanation}>
              <Trans i18nKey={'ReasonOfBooking.deactivate'}>
                Nervt dich diese Frage? Du kannst diese Frage dauerhaft
                <Text
                  onPress={handleDeactivate}
                  style={{
                    fontWeight: 'bold',
                    textDecorationLine: 'underline',
                  }}
                >
                  deaktivieren
                </Text>
              </Trans>
            </Text>
          </View>
        </View>
      </View>
    </>
  );
};

const styles = StyleSheet.create({
  container: {
    alignSelf: 'center',
  },
  content: {
    margin: 20,
    backgroundColor: 'white',
    paddingBottom: 10,
    borderRadius: rptheme.roundness,
    padding: 10,
    ...rptheme.shadow,
    position: 'relative',
  },
  closeButton: {
    position: 'absolute',
    top: 0,
    right: 0,
    padding: 4,
  },
  heading: {
    paddingRight: 10,
    marginTop: 5,
    marginBottom: 5,
    fontSize: rptheme.fontSizeXXLarge,
  },
  explanation: {
    fontSize: rptheme.fontSizeMedium,
    color: rptheme.colors.gray,
    marginBottom: 10,
  },
  optionButton: {
    fontSize: rptheme.fontSizeLarge,
    flexDirection: 'row',
    alignItems: 'center',
    height: 35,
  },
});
