import React from 'react';
import { StyleSheet, View, Text } from 'react-native';
import { Paragraph, Title } from 'react-native-paper';
import rptheme from '~/rptheme';
import { BottomSheetStore } from '../features/bottom-sheet/BottomSheetStore';
import useCountdown from '../hooks/useCountdown';
import LoadingState from './LoadingState';
import ReparkButton from './ReparkButton';

export type ConfirmCancelSheetProps = {
  title: string;
  message?: string;
  confirmLabel?: string;
  cancelLabel?: string;
  onConfirm: Function;
  onCancel?: Function;
  confirmMode?: 'outlined' | 'text' | 'contained';
  cancelMode?: 'outlined' | 'text' | 'contained';
  confirmColor?: string;
  cancelColor?: string;
  loading?: boolean;
  confirmLoading?: boolean;
  t: Function;
  countdown?: number;
  children?: any;
};

export default function ConfirmCancelSheet(props: ConfirmCancelSheetProps) {
  const closeSheet = BottomSheetStore((store) => store.closeSheet);
  const { t } = props;
  const {
    title,
    message,
    confirmLabel = t('General.confirm'),
    confirmMode = 'contained',
    cancelMode = 'outlined',
    cancelLabel = t('General.cancel'),
    confirmColor = rptheme.colors.primary,
    cancelColor = rptheme.colors.primary,
    onConfirm,
    onCancel = closeSheet,
    loading = false,
    confirmLoading = false,
    countdown = 0,
    children,
  } = props;

  const { timer } = useCountdown(countdown);

  return (
    <View style={styles.container}>
      {loading ? (
        <LoadingState noLogo={true} />
      ) : (
        <>
          <Title testID="title" style={styles.title}>
            {title}
          </Title>
          <Paragraph testID="message" style={styles.text}>
            {message}
          </Paragraph>
          {children}
          <ReparkButton
            testID="confirmButton"
            disabled={timer > 0 || confirmLoading}
            mode={confirmMode}
            color={confirmColor}
            loading={confirmLoading}
            onPress={async () => {
              await onConfirm();
              closeSheet();
            }}
          >
            {confirmLabel}{' '}
            {timer ? <Text style={{ fontSize: 12 }}>({timer})</Text> : ''}
          </ReparkButton>
          <ReparkButton
            testID="cancelButton"
            mode={cancelMode}
            color={cancelColor}
            onPress={async () => {
              await onCancel();
              closeSheet();
            }}
          >
            {cancelLabel}
          </ReparkButton>
        </>
      )}
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    margin: 25,
  },
  title: { textAlign: 'center', color: rptheme.colors.primary },
  text: {
    textAlign: 'center',
    marginVertical: 5,
    fontSize: 16,
  },
});
