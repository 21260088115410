// Calendar Picker from here: https://github.com/stephy/CalendarPicker
// It's the only one I found, that works cross-platform.

import React from 'react';
import ReparkButton from './ReparkButton';
import dayjs, { Dayjs } from 'dayjs';
import {
  formatDate,
  cetNow,
  endToStartDateWithDuration,
} from '../utils/helpers';
import { t } from 'i18next';
import { StyleSheet, View } from 'react-native';
import rptheme from '~/rptheme';
import { Calendar } from './calendar/Calendar';
import { GarageStore } from '../features/garage/GarageStore';
import { Modal } from '../features/modal/Modal';
import { ReparkIcon } from './ReparkIcon';
import Timezone from 'dayjs/plugin/timezone';
dayjs.extend(Timezone);

export type DatePickerProps = {
  isLoading?: boolean;
  mode?: 'outlined' | 'contained';
  humanReadable?: boolean;
  offerBottom?: boolean;
};

export const DatePicker = (props: DatePickerProps) => {
  const {
    isLoading = false,
    mode = 'contained',
    humanReadable = true,
    offerBottom = false,
  } = props;
  const [
    offerStart,
    maxBookingDaysInFuture,
    offerDuration,
    setOfferSearchParams,
  ] = GarageStore((store) => [
    store.offerStart,
    store.maxBookingDaysInFuture,
    store.offerDuration,
    store.setOfferSearchParams,
  ]);
  const [modalIsOpen, setModalIsOpen] = React.useState(false);
  const [selectedDate, setSelectedDate] = React.useState<Dayjs>(cetNow());
  const [selectedDuration, setSelectedDuration] = React.useState<number>(1440);

  return (
    <>
      <Modal
        isOpen={modalIsOpen}
        onClickOutside={() => {
          setModalIsOpen(false);
          setOfferSearchParams(selectedDate, selectedDuration);
        }}
      >
        <View style={styles.calendarContainer}>
          <Calendar
            testID="calendar"
            selectedDate={offerStart}
            maxBookingDaysInFuture={maxBookingDaysInFuture}
            selectedDuration={offerDuration}
            onDateSelected={(
              date: Dayjs,
              duration = 1440,
              quickAccess = false
            ) => {
              setSelectedDate(date);
              setSelectedDuration(duration);
              if (quickAccess) {
                setModalIsOpen(false);
                setOfferSearchParams(date, duration);
              }
            }}
            onClose={() => {
              setModalIsOpen(false);
              setOfferSearchParams(selectedDate, selectedDuration);
            }}
          />
        </View>
      </Modal>

      <ReparkButton
        color={rptheme.colors.accent}
        style={[
          { ...rptheme.shadow, backgroundColor: 'white' },
          styles.denseButtonStyle,
        ]}
        icon={offerBottom ? undefined : 'calendar'}
        testID="showDatePicker"
        onPress={() => setModalIsOpen(true)}
        loading={isLoading}
        mode={mode}
      >
        {offerBottom && (
          <ReparkIcon style={{ marginBottom: 5 }} name="calendar"></ReparkIcon>
        )}

        {offerDuration <= 1440
          ? formatDate(offerStart, t, humanReadable)
          : `${formatDate(offerStart, t, false)} - ${endToStartDateWithDuration(
              offerStart,
              offerDuration
            ).format('DD.MM.')}`}
      </ReparkButton>
    </>
  );
};

const styles = StyleSheet.create({
  calendarContainer: {
    justifyContent: 'space-around',
    maxWidth: rptheme.screenSmall,
    alignSelf: 'center',
  },
  quickActionContainer: {
    flexDirection: 'row',
    justifyContent: 'space-around',
    flexWrap: 'wrap',
  },
  denseButtonStyle: {
    flexGrow: 0,
    justifyContent: 'center',
    margin: 5,
    borderColor: rptheme.colors.accent,
    borderWidth: 2,
  },
});
