import { LinearGradient } from 'expo-linear-gradient';
import * as React from 'react';

export default function ReparkGradient() {
  return (
    <LinearGradient
      colors={['#DDFBDD', '#D1E7FB']}
      style={{ flex: 1 }}
      start={{ x: 0, y: 1 }}
      end={{ x: 1, y: 1 }}
    />
  );
}
