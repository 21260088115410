import React from 'react';
import { StyleSheet, ScrollView, View } from 'react-native';
import { AuthStore } from '../authentication/AuthStore';
import { useTranslation } from 'react-i18next';
import { Auth } from '../authentication/Auth';
import rptheme from '~/rptheme';
import LoadingState from '~/src/components/LoadingState';
import { NavProp } from '../navigation';
import { SubscriptionStore } from './SubscriptionStore';
import { SubscriptionListItem } from '../booking/components/SubscriptionListItem';
import { ReparkTabs } from '~/src/components/ReparkTabs';
import { EmptyState } from '~/src/components/EmptyState';

export type SubscriptionOverviewScreenProps = {
  navigation: NavProp;
};

export const SubscriptionsOverviewScreen = (
  props: SubscriptionOverviewScreenProps
) => {
  const { t } = useTranslation();
  const { navigation } = props;
  const loggedIn = AuthStore((store) => store.loggedIn);

  const [isFetchingSubscriptions, activeSubscriptions, inactiveSubscriptions] =
    SubscriptionStore((store) => [
      store.isFetchingSubscriptions,
      store.activeSubscriptions,
      store.inactiveSubscriptions,
    ]);

  const [subscriptionList, setSubscriptionList] = React.useState<
    'active' | 'inactive'
  >('active');

  const tabs = [
    {
      label: t('SubscriptionScreen.overview.activeTabLabel'),
      onPress: () => setSubscriptionList('active'),
      testID: 'switchToActive',
    },
    {
      label: t('SubscriptionScreen.overview.inactiveTabLabel'),
      onPress: () => setSubscriptionList('inactive'),
      testID: 'switchToInactive',
    },
  ];

  if (isFetchingSubscriptions) return <LoadingState />;
  return (
    <ScrollView style={styles.container}>
      {loggedIn ? (
        <View style={styles.bookingContainer}>
          <View style={{ margin: 20 }}>
            <ReparkTabs tabs={tabs} />
          </View>

          {subscriptionList === 'active' && (
            <ScrollView
              testID="upcomingBookingsList"
              style={styles.listContainer}
            >
              {activeSubscriptions().length === 0 && (
                <EmptyState
                  title={t('SubscriptionScreen.overview.emptyListText')}
                />
              )}
              {activeSubscriptions().map((subscription: any) => (
                <SubscriptionListItem
                  key={subscription.id}
                  subscription={subscription}
                  navigation={navigation}
                />
              ))}
            </ScrollView>
          )}

          {subscriptionList === 'inactive' && (
            <ScrollView
              testID="upcomingBookingsList"
              style={styles.listContainer}
            >
              {inactiveSubscriptions().map((subscription: any) => (
                <SubscriptionListItem
                  key={subscription.id}
                  subscription={subscription}
                  navigation={navigation}
                />
              ))}
            </ScrollView>
          )}
        </View>
      ) : (
        <Auth goBackFallBack="Map" navigation={navigation} />
      )}
    </ScrollView>
  );
};

const styles = StyleSheet.create({
  container: {
    paddingTop: 80,
    maxWidth: rptheme.screenSmall,
    width: '100%',
    alignSelf: 'center',
  },
  bookingContainer: {
    maxWidth: 450,
    alignSelf: 'center',
    width: '100%',
  },
  listContainer: { paddingHorizontal: 20, marginBottom: 80 },
  emptyListContainer: {
    marginVertical: 40,
  },
  calendarGraphic: {
    height: 120,
    resizeMode: 'contain',
    marginBottom: 30,
  },
  emptyListText: {
    alignSelf: 'center',
    fontSize: rptheme.fontSizeXLarge,
    fontFamily: rptheme.fonts.regular.fontFamily,
    fontWeight: rptheme.fonts.regular.fontWeight,
    color: rptheme.colors.primary,
  },
  emptyListCTA: {
    marginTop: 30,
  },
});
