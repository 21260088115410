import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';

// ChainedBackend has to be imported before LocizeBackend! No idea why...
import ChainedBackend from 'i18next-chained-backend';
import LocizeBackend from 'i18next-locize-backend';
import resourcesToBackend from 'i18next-resources-to-backend';
import { toBoolean } from '~/src/utils/helpers';
import config from '~/config/config';
import { languageDetector } from './src/features/language-switcher/languageDetector';

if (process.env.NODE_ENV === 'test') {
  i18next.use(initReactI18next).init({
    lng: 'de',
    fallbackLng: 'de',

    // have a common namespace used around the full app
    ns: ['default'],
    defaultNS: 'default',

    debug: false,

    interpolation: {
      escapeValue: false, // not needed for react!!
    },

    resources: { de: { default: require('~/assets/i18n/german') } },
  });
} else {
  i18next
    // detect user language
    // learn more: https://github.com/i18next/i18next-browser-languageDetector
    .use(languageDetector)
    // pass the i18n instance to react-i18next.
    .use(initReactI18next)
    // Use Chained Backend, so we are able to use a fallback
    // More Information here: https://docs.locize.com/more/backend-fallback
    .use(ChainedBackend)
    // init i18next and fallback
    // for all options read: https://www.i18next.com/overview/configuration-options
    .init({
      fallbackLng: process.env.NODE_ENV === 'development' ? 'de' : 'en',
      compatibilityJSON: 'v3',
      debug:
        process.env.NODE_ENV === 'development' && toBoolean(process.env.DEBUG),
      partialBundledLanguages: true,
      // have a initial namespace used and defined in locize.app
      ns: ['default'],
      defaultNS: 'default',
      saveMissing: process.env.NODE_ENV === 'development' ? true : false,

      backend: {
        backends: [
          LocizeBackend,
          resourcesToBackend({
            de: { default: require('~/assets/i18n/german') },
          }),
        ],
        backendOptions: [
          {
            projectId: config().locize.projectId,
            apiKey: config().locize.apiKey,
            referenceLng: 'de',
          },
        ],
      },
    });
}

/**
 * Accepted WARNING:
 *  - init: i18next is already initialized
 *          happens when you hot reload this file
 */

export default i18next;
