import React from 'react';
import { Card, Title, Paragraph } from 'react-native-paper';
import ReparkButton from '~/src/components/ReparkButton';
import { NavProp } from '../../navigation';
import { useTranslation } from 'react-i18next';

type PayForSubscriptionNoteProps = {
  subscription: any;
  navigation: NavProp;
};

export const PayForSubscriptionNote = (props: PayForSubscriptionNoteProps) => {
  const { subscription, navigation } = props;
  const { t } = useTranslation();

  return (
    <Card style={{ marginTop: 30, paddingHorizontal: 10 }}>
      <Title style={{ marginTop: 10 }}>
        {t('SubscriptionScreen.payForSubscriptionNote.title')}
      </Title>
      <Paragraph>
        {t('SubscriptionScreen.payForSubscriptionNote.text')}
      </Paragraph>
      <ReparkButton
        onPress={() => {
          navigation.navigate('SubscriptionPay', {
            id: subscription.id,
          });
        }}
      >
        {t('SubscriptionScreen.payForSubscriptionNote.buttonLabel')}
      </ReparkButton>
    </Card>
  );
};
