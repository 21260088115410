import React from 'react';
import { useTranslation } from 'react-i18next';
import { View } from 'react-native';
import { Modalize } from 'react-native-modalize';
import config from '~/config/config';
import rptheme from '~/rptheme';
import ReparkButton from '~/src/components/ReparkButton';
import { ReparkIcon } from '~/src/components/ReparkIcon';
import BottomSheetFrame from '../../bottom-sheet/BottomSheetFrame';
import { BottomSheetStore } from '../../bottom-sheet/BottomSheetStore';
import { NavProp, RootScreens } from '../../navigation';

type ErrorSheetProps = {
  success: false;
  message: string;
  openedGates: 0;
  totalGates: number;
  navigation: NavProp;
};

export const errorSheetOptions: React.ComponentProps<typeof Modalize> = {
  adjustToContentHeight: true,
};

export const ErrorSheet = (props: ErrorSheetProps) => {
  const { t } = useTranslation();
  const { navigation } = props;
  const closeSheet = BottomSheetStore((store) => store.closeSheet);

  const title = t('errorSheet.errorTitle');
  const text = t('errorSheet.errorText');

  const openSupport = () => {
    closeSheet();
    navigation.navigate(RootScreens.WebViewScreen, {
      url: config().webPages.phoneSupport,
    });
  };

  return (
    <BottomSheetFrame title={title} text={text}>
      <>
        <View style={{ alignItems: 'center', marginVertical: 20 }}>
          <ReparkIcon
            name="close"
            size={50}
            color={rptheme.colors.primary}
          ></ReparkIcon>
        </View>
        <ReparkButton icon={'phone-support'} onPress={openSupport}>
          {t('general.contactSupport')}
        </ReparkButton>
      </>
    </BottomSheetFrame>
  );
};
