import React from 'react';
import { View, Text, StyleSheet, Linking } from 'react-native';
import { BookingStore } from './BookingStore';
import {
  formatBookingDateRange,
  isCancelable,
  routerIsDoor,
  wasRefunded,
} from './helpers';
import { CancelBookingButton } from './components/CancelBookingButton';
import { useTranslation } from 'react-i18next';
import rptheme from '~/rptheme';
import FABFrame from '~/src/components/FABFrame';
import FABGroup from '~/src/components/FABGroup';
import { AuthStore } from '../authentication/AuthStore';
import { NavProp, RootScreens } from '../navigation';
import LoadingState from '~/src/components/LoadingState';
import { GarageImage } from '../garage/GarageImage';
import { GarageTitle } from '../garage/GarageTitle';
import { ParkingSpotInfo } from './components/ParkingSpotInfo';
import { FABActions } from '~/src/utils/FABActions';
import { createOpenInMapsLink } from '../map/helpers';
import { Card, FAB } from 'react-native-paper';
import { Modal } from '../modal/Modal';
import { BookingDetailItemFrame } from './components/BookingDetailItemFrame';
import dayjs from 'dayjs';
import { BottomSheetStore } from '../bottom-sheet/BottomSheetStore';
import { GarageDetailList } from '../garage/GarageDetailList';
import { ReparkIcon } from '~/src/components/ReparkIcon';
import { VehicleStore } from '../vehicles/VehicleStore';
import { SnackbarStore } from '../snackbar/SnackbarStore';
import { ReparkPicker } from '~/src/components/ReparkPicker';
import { CenteredAppView } from '~/src/components/CenteredAppView';
import { getOpeningHours } from '../garage/garageOpeningHours';
import { BookingStackScreens } from './BookingStackNavigation';
import { GarageDevicesSheet } from './GarageDevicesSheet';
import { BookingRefundState } from './BookingRefundState';
import { BookingExtendSheet } from './BookingExtendSheet';
import { ReasonOfBookingModal } from '../feedback/ReasonOfBookingModal';
import { ProfileStackScreenNames } from '../user-profile/ProfileStackNavigation';
import { ROB_VALUES } from '../feedback/ReasonOfBookingOptions';
import { GarageStore } from '../garage/GarageStore';
import { RequestInvoicesSheet } from './RequestInvoicesSheet';
import { BottomCenter } from './components/BookingDetailBottom';
import { useCheckCanOpen } from '../garage/useCheckCanOpen';
import { useReasonOfBooking } from './hooks/useReasonOfBooking';
import { useVehiclesForList } from './hooks/useVehiclesForList';

export type BookingDetailScreenProps = {
  navigation: NavProp;
  route: any;
};

export const BookingDetailScreen = (props: BookingDetailScreenProps) => {
  const { t } = useTranslation();
  const {
    navigation,
    route: {
      params: { id },
    },
  } = props;

  const [getBooking, bookings, patchBooking, getExtendOption] = BookingStore(
    (store) => [
      store.getBooking,
      store.bookings,
      store.patchBooking,
      store.canExtendOption,
    ]
  );

  const [fetchGarage] = GarageStore((store) => [store.fetchGarage]);

  const [vehicles] = VehicleStore((store) => [store.vehicles]);
  const [fireSnack] = SnackbarStore((store) => [store.fireSnack]);
  const user = AuthStore((store) => store.user);
  const openSheet = BottomSheetStore((store) => store.openSheet);

  const [booking, setBooking] = React.useState<any>();
  const [canExtend, setCanExtend] = React.useState<any>();

  const { emailSupportAction, feedbackAction, phoneSupportAction, FAQAction } =
    FABActions({
      user,
      navigation,
    });

  const doExtend = async (id: string) => {
    const result = await getExtendOption(id);
    setCanExtend(result);
  };

  React.useEffect(() => {
    setBooking(getBooking(id));
    doExtend(id);
  }, [id, bookings, user]);

  const {
    openReasonOfBookingModal,
    setOpenReasonOfBookingModal,
    setKeepReasonOfBookingModalHidden,
  } = useReasonOfBooking({ booking });

  const { vehiclesForList } = useVehiclesForList({ booking, vehicles });

  const { canOpen } = useCheckCanOpen(getBooking(id)?.parkingSpot.garageId);

  if (!booking) return <LoadingState navigation={navigation} />;

  const {
    parkingSpot,
    parkingSpot: { garage },
  } = booking;

  console.log(garage);

  const openingHoursOnBookingDay = getOpeningHours({
    opening_hours: garage.opening_hours,
    selectedDate: booking.start,
  });

  if (!garage.gracePeriodPost) {
    fetchGarage(garage.id).then((result) => {
      garage['gracePeriodPost'] = result.gracePeriodPost;
    });
  }

  const showGarageDeviceSheet =
    garage.ewelinkDevices?.length > 0 ||
    garage.routers?.find((router: any) => routerIsDoor(router));

  const openExtendBookingSheet = () => {
    openSheet(<BookingExtendSheet {...props} currentBooking={booking} />, {
      adjustToContentHeight: true,
    });
  };

  const handleReasonOfBooking = async (value: string) => {
    await patchBooking({ id, reasonOfBooking: value });
    if (ROB_VALUES.includes(value)) {
      fireSnack({
        title: t('Feedback.thankYou'),
        message: t('Feedback.message'),
        type: 'success',
      });
    }
    setOpenReasonOfBookingModal(false);
  };

  const RequestInvoicesAction = {
    icon: 'invoice',
    label: t('fabaction.requestInvoices'),
    color: rptheme.colors.primary,
    onPress: () => {
      openSheet(
        <RequestInvoicesSheet contract={booking} navigation={navigation} />,
        {
          adjustToContentHeight: true,
        }
      );
    },
  };

  return (
    <CenteredAppView>
      <Modal
        isOpen={openReasonOfBookingModal}
        onClickOutside={() => {
          handleReasonOfBooking('clickOutside');
          setKeepReasonOfBookingModalHidden(true);
          setOpenReasonOfBookingModal(false);
        }}
      >
        {
          <ReasonOfBookingModal
            handleDeactivate={() => {
              setKeepReasonOfBookingModalHidden(true);
              setOpenReasonOfBookingModal(false);
              navigation.replace(RootScreens.ProfileStack, {
                screen: ProfileStackScreenNames.SettingsScreen,
              });
            }}
            onClosePress={() => {
              setKeepReasonOfBookingModalHidden(true);
              handleReasonOfBooking('closed');
              setOpenReasonOfBookingModal(false);
            }}
            onSelection={handleReasonOfBooking}
            booking={getBooking(id)}
          />
        }
      </Modal>
      <FABFrame
        bottomCenter={
          <BottomCenter
            garage={garage}
            booking={booking}
            navigation={navigation}
          />
        }
        bottomRight={
          isCancelable(getBooking(id)) ? (
            <CancelBookingButton
              id={id}
              onCancelSuccess={() => navigation.pop()}
              t={t}
            />
          ) : showGarageDeviceSheet ? (
            <FAB
              disabled={!canOpen}
              onPress={() => {
                openSheet(
                  <GarageDevicesSheet
                    garage={garage}
                    booking={booking}
                    navigation={navigation}
                  />,
                  {
                    adjustToContentHeight: true,
                  }
                );
              }}
              icon={'key'}
              color={!canOpen ? 'white' : rptheme.colors.primary}
              style={[
                styles.garageOpenFAB,
                { backgroundColor: !canOpen ? 'grey' : 'white' },
              ]}
            />
          ) : (
            <></>
          )
        }
        bottomLeft={
          <FABGroup
            icon={'help'}
            actions={[
              RequestInvoicesAction,
              feedbackAction,
              emailSupportAction,
              phoneSupportAction,
              FAQAction,
            ]}
          />
        }
        topRight={(() => {
          const link = createOpenInMapsLink(
            garage.coordinates,
            garage.addressString
          );
          if (link && !booking.canceled)
            return (
              <FAB
                onPress={() => {
                  Linking.openURL(link);
                }}
                icon="directions"
                color={rptheme.colors.primary}
                style={styles.FAB}
              >
                Nav
              </FAB>
            );
        })()}
        topLeft={
          <FAB
            onPress={() => {
              if (navigation.canGoBack()) {
                navigation.goBack();
              } else {
                navigation.replace(BookingStackScreens.BookingOverviewScreen);
              }
            }}
            icon={rptheme.backIcon}
            color={rptheme.colors.primary}
            style={styles.FAB}
          >
            Nav
          </FAB>
        }
      >
        <Card style={{ margin: 25 }}>
          <GarageImage garage={garage} style={styles.image} />
          <Card.Content>
            {wasRefunded(booking) && (
              <BookingRefundState
                cancelationDetails={booking.cancelationDetails}
              />
            )}
            <GarageTitle garage={garage} />

            {garage.entranceAddress && (
              <Text
                style={{
                  textAlign: 'center',
                  borderColor: rptheme.colors.primary,
                  padding: 5,
                  borderWidth: 1,
                  borderRadius: 5,
                }}
              >
                <span style={{ fontWeight: 'bold' }}>
                  {t('bookingDetailScreen.entranceAddressLabel')}:
                </span>{' '}
                {garage.entranceAddress}
              </Text>
            )}

            <BookingDetailItemFrame
              title={
                openingHoursOnBookingDay
                  ? t('bookingDetailScreen.dateTitleWithOpeningHours', {
                      start: openingHoursOnBookingDay.start,
                      stop: openingHoursOnBookingDay.stop,
                    })
                  : t('bookingDetailScreen.dateTitle')
              }
            >
              <ReparkIcon
                style={styles.icons}
                name="calendar"
                color={rptheme.colors.primary}
              />
              <Text>{formatBookingDateRange(booking, t)}</Text>
            </BookingDetailItemFrame>
            {booking && canExtend && (
              <Text
                testID="canExtendBookingButtonInlineTestID"
                style={styles.extendBookingInline}
                onPress={openExtendBookingSheet}
              >
                {t('BookingExtend.wannaExtend')}
              </Text>
            )}
            <BookingDetailItemFrame
              title={t('bookingDetailScreen.licencePlateTitle')}
            >
              <ReparkIcon
                style={styles.icons}
                name="car"
                color={rptheme.colors.primary}
              />

              {isCancelable(booking) && vehiclesForList.length > 1 ? (
                <ReparkPicker
                  items={vehiclesForList}
                  labelKey={'licencePlate'}
                  valueKey={'licencePlate'}
                  selectedValue={booking?.licencePlate}
                  onValueChange={async (licencePlate: any) => {
                    try {
                      if (!isCancelable(booking)) {
                        fireSnack({
                          title: t(
                            'BookingDetailsScreen.editBookingFailedTitle'
                          ),
                          message: t(
                            'BookingDetailsScreen.editBookingFailedMessage'
                          ),
                          type: 'error',
                        });
                        return;
                      }

                      await patchBooking({
                        id: booking.id,
                        licencePlate,
                      });
                      fireSnack({
                        title: t(
                          'BookingDetailsScreen.changeVehicleSuccessTitle'
                        ),
                        message: t(
                          'BookingDetailsScreen.changeVehicleSuccessMessage',
                          { licencePlate }
                        ),
                        type: 'success',
                      });
                    } catch (e) {
                      console.log(e);

                      fireSnack({
                        title: t('General.errors.GenericErrorTitle'),
                        message: t('General.errors.GenericErrorMessage'),
                        type: 'error',
                      });
                      throw e;
                    }
                  }}
                />
              ) : (
                <Text>{booking.licencePlate}</Text>
              )}
            </BookingDetailItemFrame>
            <ParkingSpotInfo parkingSpot={parkingSpot} t={t} />
          </Card.Content>
          <FAB
            onPress={() => {
              openSheet(
                <GarageDetailList
                  garage={garage}
                  parkingSpot={parkingSpot}
                ></GarageDetailList>,
                { adjustToContentHeight: true }
              );
            }}
            icon={'info-cursive'}
            color={rptheme.colors.primary}
            style={styles.garageInfoFAB}
          >
            Info
          </FAB>
          {booking && canExtend && (
            <FAB
              testID="canExtendBookingButtonTestID"
              onPress={openExtendBookingSheet}
              icon={'add'}
              color={rptheme.colors.primary}
              style={styles.extendBookingFAB}
            >
              Extend
            </FAB>
          )}
        </Card>
        {booking.canceled && (
          <View
            testID="bookingCanceledInfo"
            style={styles.canceledBookingInfoContainer}
          >
            <Text style={styles.canceledBookingInfoText}>
              {t('BookingDetail.canceledInformation', {
                date: dayjs(booking.updatedAt).format('DD.MM.YYYY'),
              })}
            </Text>
          </View>
        )}
      </FABFrame>
    </CenteredAppView>
  );
};

const styles = StyleSheet.create({
  image: {
    height: 150,
    borderTopLeftRadius: rptheme.roundness,
    borderTopRightRadius: rptheme.roundness,
  },
  canceledBookingInfoContainer: {
    position: 'absolute',
    padding: 40,
    top: 0,
    backgroundColor: rptheme.colors.error,
    width: '100%',
  },
  canceledBookingInfoText: {
    color: 'white',
    textAlign: 'center',
    justifyContent: 'center',
    marginTop: 25,
  },
  FAB: { backgroundColor: 'white' },
  garageInfoFAB: {
    position: 'absolute',
    backgroundColor: 'white',
    bottom: -20,
    right: -5,
    textAlign: 'center',
  },
  extendBookingFAB: {
    position: 'absolute',
    backgroundColor: 'white',
    bottom: -20,
    left: -5,
    textAlign: 'center',
  },
  extendBookingInline: {
    alignSelf: 'center',
    color: rptheme.colors.primary,
    fontWeight: 'bold',
    textDecorationLine: 'underline',
  },
  garageOpenFAB: {
    textAlign: 'center',
    backgroundColor: 'white',
  },
  icons: {
    alignSelf: 'center',
    marginRight: 5,
  },
});
