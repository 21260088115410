import * as React from 'react';

import { createStackNavigator } from '@react-navigation/stack';

import HeaderLeft from '~/src/components/HeaderLeft';
import { headerTitleAlign } from '../navigation/helpers';
import { BookingOverviewScreen } from './BookingOverviewScreen';
import { BookingDetailScreen } from './BookingDetailScreen';
import { useTranslation } from 'react-i18next';
import { RootScreens } from '../navigation';

export const Stack = createStackNavigator();

export enum BookingStackScreens {
  BookingOverviewScreen = 'BookingOverview',
  BookingDetailScreen = 'BookingDetail',
}

export default function BookingStackNavigation({ navigation }: any) {
  const headerLeft = HeaderLeft.bind(navigation);
  const { t } = useTranslation();

  return (
    <Stack.Navigator
      initialRouteName={BookingStackScreens.BookingOverviewScreen}
      screenOptions={{
        headerShown: true,
        headerTransparent: true,
        headerBackTitleVisible: false,
      }}
    >
      <Stack.Screen
        name={BookingStackScreens.BookingOverviewScreen}
        component={BookingOverviewScreen}
        options={{
          title: t('BookingScreen.screenTitle'),
          headerTransparent: true,
          headerTitleAlign: headerTitleAlign(),
          headerLeft: (props: any) => {
            props.fallBackScreen = RootScreens.MapScreen;
            return headerLeft(props);
          },
        }}
      />

      <Stack.Screen
        name={BookingStackScreens.BookingDetailScreen}
        component={BookingDetailScreen}
        options={{
          title: '',
          headerTransparent: true,
          headerLeft: () => <></>,
        }}
      />
    </Stack.Navigator>
  );
}
