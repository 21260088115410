import * as React from 'react';
import { TouchableOpacity, View, StyleSheet } from 'react-native';
import { FAB, Portal } from 'react-native-paper';
import rptheme from '~/rptheme';
import ReparkButton from './ReparkButton';

export type FABGroupPROPS = {
  icon: string;
  color?: string;
  actions: Array<FABAction>;
};

type FABAction = {
  icon: string;
  label: string;
  color?: string;
  onPress: () => void;
};

export default function FABGroup(props: FABGroupPROPS) {
  const { actions, icon, color } = props;
  const [open, setOpen] = React.useState(false);

  const buttonPress = (action: Function) => {
    action();
    setOpen(!open);
  };

  return (
    <>
      {open && (
        <Portal>
          <TouchableOpacity
            style={styles.overlay}
            onPress={() => setOpen(!open)}
          >
            <View style={styles.secondaryButtonsSheet}>
              {actions.map((action: FABAction, i: number) => (
                <View key={i} style={styles.buttonContainer}>
                  <ReparkButton
                    testID={`button-${i}`}
                    color={action.color}
                    icon={action.icon}
                    onPress={() => buttonPress(action.onPress)}
                    style={styles.button}
                  >
                    {action.label}
                  </ReparkButton>
                </View>
              ))}
            </View>
          </TouchableOpacity>
        </Portal>
      )}
      <FAB
        testID="openCloseButton"
        icon={open ? 'close' : icon}
        onPress={() => setOpen(!open)}
        color={rptheme.colors.primary}
        style={{
          ...styles.openCloseButton,
          ...{ backgroundColor: color || rptheme.colors.background },
        }}
      />
    </>
  );
}

const styles = StyleSheet.create({
  openCloseButton: {},
  secondaryButtonsSheet: {
    padding: 10,
  },
  buttonContainer: {
    flexDirection: 'row',
    marginBottom: 5,
  },
  button: {
    marginVertical: 5,
    ...rptheme.shadow,
  },
  overlay: {
    position: 'absolute',
    backgroundColor: rptheme.colors.overlay,
    height: '100%',
    width: '100%',
    justifyContent: 'flex-end',
    paddingBottom: rptheme.fab.verticalMargin + 50,
    paddingLeft: 10,
  },
  iconButton: { margin: 0 },
});
