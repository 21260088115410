import * as React from 'react';

import { createStackNavigator } from '@react-navigation/stack';
import HeaderLeft from '~/src/components/HeaderLeft';
import { headerTitleAlign } from '../navigation/helpers';
import { ChooseParkingSpotScreen } from './ChooseParkingSpotScreen';
import { DataInputScreen } from './DataInputScreen';
import { SubscriptionsOverviewScreen } from './SubscriptionsOverviewScreen';
import { SubscriptionDetailScreen } from './SubscriptionDetailScreen';
import { SubscriptionPayScreen } from './SubscriptionPayScreen';
import { useTranslation } from 'react-i18next';
import { DataConfirmScreen } from './DataConfirmScreen';
import { SubscriptionInvoices } from './SubscriptionInvoices';
import { InspectionScreen } from './inspection/InspectionScreen';
import { BackButton } from '../navigation/BackButton';
import { RootScreens } from '../navigation';

export const Stack = createStackNavigator();

export const SubscriptionsStackScreens = {
  ChooseParkingSpot: 'ChooseParkingSpot',
  DataInput: 'DataInput',
  DataConfirm: 'DataConfirm',
  SubscriptionOverview: 'SubscriptionOverview',
  SubscriptionDetail: 'SubscriptionDetail',
  SubscriptionPay: 'SubscriptionPay',
  SubscriptionInvoices: 'SubscriptionInvoices',
  InspectionScreen: 'InspectionScreen',
  SubscriptionInvoicePdf: 'SubscriptionInvoicePdf',
} as const;

export const SubscriptionStackNavigation = ({ navigation }: any) => {
  const headerLeft = HeaderLeft.bind(navigation);

  const { t } = useTranslation();

  return (
    <Stack.Navigator
      initialRouteName={SubscriptionsStackScreens.SubscriptionOverview}
      screenOptions={{
        headerShown: true,
        headerTransparent: true,
        headerBackTitleVisible: false,
        animationEnabled: false,
      }}
    >
      <Stack.Screen
        name={SubscriptionsStackScreens.ChooseParkingSpot}
        component={ChooseParkingSpotScreen}
        options={{
          title: '',
          headerShown: false,
        }}
      />

      <Stack.Screen
        name={SubscriptionsStackScreens.DataInput}
        component={DataInputScreen}
        options={{
          title: '',
          headerShown: false,
        }}
      />
      <Stack.Screen
        name={SubscriptionsStackScreens.DataConfirm}
        component={DataConfirmScreen}
        options={{
          headerShown: false,
        }}
      />

      <Stack.Screen
        name={SubscriptionsStackScreens.SubscriptionOverview}
        component={SubscriptionsOverviewScreen}
        options={{
          title: t('SubscriptionScreen.overview.title'),
          headerTransparent: true,
          headerTitleAlign: headerTitleAlign(),
          headerLeft: () => {
            return (
              <BackButton
                onPress={() => {
                  navigation.navigate(RootScreens.ProfileStack);
                }}
              />
            );
          },
        }}
      />

      <Stack.Screen
        name={SubscriptionsStackScreens.SubscriptionDetail}
        component={SubscriptionDetailScreen}
        options={{
          title: t('SubscriptionScreen.detail.title'),
          headerTransparent: true,
          headerTitleAlign: headerTitleAlign(),
          headerLeft: () => {
            return (
              <BackButton
                onPress={() => {
                  navigation.navigate(
                    SubscriptionsStackScreens.SubscriptionOverview
                  );
                }}
              />
            );
          },
        }}
      />

      <Stack.Screen
        name={SubscriptionsStackScreens.SubscriptionPay}
        component={SubscriptionPayScreen}
        options={{
          title: t('SubscriptionScreen.pay.title'),
          headerTransparent: true,
          headerTitleAlign: headerTitleAlign(),
          headerLeft: (props: any) => {
            props.fallBackScreen = 'Map';
            return headerLeft(props);
          },
        }}
      />
      <Stack.Screen
        name={SubscriptionsStackScreens.SubscriptionInvoices}
        component={SubscriptionInvoices}
        options={{
          title: t('SubscriptionScreen.invoices.title'),
          headerTransparent: true,
          headerTitleAlign: headerTitleAlign(),
          headerLeft: (props: any) => {
            props.fallBackScreen =
              SubscriptionsStackScreens.SubscriptionOverview;
            return headerLeft(props);
          },
        }}
      />

      <Stack.Screen
        name={SubscriptionsStackScreens.InspectionScreen}
        component={InspectionScreen}
        options={{
          headerTransparent: true,
          title: '',
          headerTitleAlign: headerTitleAlign(),
        }}
      />
    </Stack.Navigator>
  );
};
