import {
  Elements,
  PaymentElement,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js';
import {
  Appearance,
  loadStripe,
  StripeElementLocale,
  StripeError,
} from '@stripe/stripe-js';
import i18next from 'i18next';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { View, Text } from 'react-native';
import config from '~/config/config';
import LoadingState from '~/src/components/LoadingState';
import ReparkButton from '~/src/components/ReparkButton';
import {
  createBookingRequest,
  BookOfferParams,
  payOfferWeb,
} from '~/src/features/booking/bookingService';
import { PaymentStore } from './PaymentStore';
import { PaymentFormProps } from './PaymentForm';
import rptheme from '~/rptheme';
import { checkCreditCard } from './paymentHelpers';
import { VoucherModal } from './VoucherModal';
import { GarageStore } from '../garage/GarageStore';
import Bugsnag from '@bugsnag/js';
import { PaymentMethodPicker } from './components/paymentMethodPicker/PaymentMethodPicker';

export const appearance: Appearance = {
  theme: 'stripe',
  labels: 'floating',
  variables: {
    borderRadius: `${rptheme.roundness}px`,
    colorDanger: rptheme.colors.error,
    colorPrimary: rptheme.colors.primary,
    colorSuccess: rptheme.colors.success,
    colorBackground: 'white',
    colorTextSecondary: rptheme.colors.gray,
  },
};

export const PaymentForm = (props: PaymentFormProps) => {
  const [paymentIntent] = PaymentStore((store) => [store.paymentIntent]);
  // const stripePromise = loadStripe(config().stripe.publicKey);
  const [stripePromise] = React.useState(() =>
    loadStripe(config().stripe.publicKey)
  );

  if (!paymentIntent) return <LoadingState />;
  const locale: StripeElementLocale = i18next.language as StripeElementLocale;

  return (
    <View>
      <Elements
        key={paymentIntent.id} // We do this to remount, because we fetch a new paymentIntent if an error occurs. Should be refactored in the future...
        stripe={stripePromise}
        options={{
          clientSecret: paymentIntent?.client_secret,
          locale,
          appearance,
        }}
      >
        <CCInput {...props} />
      </Elements>
    </View>
  );
};

const CCInput = ({
  onSuccess,
  onError,
  onIsProcessingPayment,
}: {
  onSuccess: (response: any) => void;
  onError?: (e: StripeError) => void;
  onIsProcessingPayment: () => void;
}) => {
  const [paymentIntent, getForPaymentIntentAllowedPaymentMethods] =
    PaymentStore((store) => [
      store.paymentIntent,
      store.getForPaymentIntentAllowedPaymentMethods,
    ]);

  const [selectedGarageId, offerStart, offerDuration] = GarageStore((store) => [
    store.selectedGarageId,
    store.offerStart,
    store.offerDuration,
  ]);

  const [useSavedCard, setUseSavedCard] = React.useState(true);
  const [isLoading, setIsLoading] = React.useState(false);
  const [hidePaymentMethods, setHidePaymentMethods] = React.useState(false);
  const { t } = useTranslation();
  const stripe = useStripe();
  const elements = useElements();

  const handleSubmit = async () => {
    setIsLoading(true);
    onIsProcessingPayment();
    try {
      if (paymentIntent.internal) {
        const params: BookOfferParams = {
          offerId: paymentIntent.metadata.offerId,
          start: offerStart,
          duration: offerDuration,
          licencePlate: paymentIntent.metadata.licencePlate,
          type: 'user-free-of-charge',
          voucherId: paymentIntent.metadata.voucherId,
        };
        const response = await createBookingRequest(params);
        onSuccess(response);
        setIsLoading(false);
      } else {
        const { stripeResponse } = await payOfferWeb({
          stripe,
          elements,
          paymentIntent,
          useSavedCard,
          paymentMethods: getForPaymentIntentAllowedPaymentMethods(),
        });
        await onSuccess(stripeResponse);
        setIsLoading(false);
      }
    } catch (e: any) {
      //e is an error with a stringified JSON message from Stripe...
      Bugsnag.notify(e);

      setIsLoading(false);
      onError?.(JSON.parse(e.message));
    }
  };

  React.useEffect(() => {
    setHidePaymentMethods(paymentIntent.amount === 0);
  }, [paymentIntent]);

  if (!selectedGarageId) return null;

  return (
    <>
      {hidePaymentMethods ? (
        <Text>{t('PaymentForm.youParkForFree')}</Text>
      ) : (
        <View>
          {getForPaymentIntentAllowedPaymentMethods().filter(
            (paymentMethod) => !checkCreditCard(paymentMethod).isExpired
          ).length && useSavedCard ? (
            <PaymentMethodPicker />
          ) : (
            <PaymentElement />
          )}
          {getForPaymentIntentAllowedPaymentMethods().length ? (
            <ReparkButton
              onPress={() => {
                setUseSavedCard(!useSavedCard);
              }}
              mode="text"
            >
              {useSavedCard
                ? t('PaymentForm.buttonUseNewPaymentMethod')
                : t('PaymentForm.buttonUseSavedPaymentMethod')}
            </ReparkButton>
          ) : null}
        </View>
      )}

      {!paymentIntent.metadata.voucherId ? (
        <VoucherModal />
      ) : (
        <ReparkButton color={rptheme.colors.accent} mode="text" icon="check">
          {t('PaymentForm.voucherApplied')}
        </ReparkButton>
      )}

      <ReparkButton
        loading={isLoading}
        disabled={isLoading}
        onPress={() => handleSubmit()}
      >
        {t('PaymentForm.pay')}
      </ReparkButton>
    </>
  );
};
