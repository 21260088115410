import React from 'react';
import { StyleSheet, View, Text, Image } from 'react-native';
import rptheme from '~/rptheme';
import ReparkButton from './ReparkButton';

type EmptyStateProps = {
  title: string;
  text?: string;
  image?: string;
  CTA?: {
    label: string;
    icon: string;
    onPress: Function;
  };
};

export const EmptyState = (props: EmptyStateProps) => {
  const { title, text, image, CTA } = props;

  return (
    <View style={styles.container} testID={'emptyState'}>
      {image && (
        <Image source={rptheme.graphics.calendar} style={styles.image} />
      )}
      <Text style={styles.title}>{title}</Text>
      {text && <Text style={styles.text}>{text}</Text>}

      {CTA && (
        <ReparkButton
          testID="CTA"
          icon={CTA.icon}
          onPress={() => CTA.onPress()}
          style={styles.emptyListCTA}
        >
          {CTA?.label}
        </ReparkButton>
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    marginVertical: 40,
  },
  title: {
    alignSelf: 'center',
    textAlign: 'center',
    fontSize: rptheme.fontSizeXLarge,
    fontFamily: rptheme.fonts.regular.fontFamily,
    fontWeight: rptheme.fonts.regular.fontWeight,
    color: rptheme.colors.primary,
  },
  text: {
    alignSelf: 'center',
    textAlign: 'center',
  },
  emptyListCTA: {
    marginTop: 30,
  },
  image: {
    height: 120,
    resizeMode: 'contain',
    marginBottom: 30,
  },
});
