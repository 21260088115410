import { Modalize } from 'react-native-modalize';
import create from 'zustand';
import {
  replaceBrowserBackAction,
  resetBrowserBack,
} from '~/src/utils/helpers';

type BottomSheetStore = {
  show: Boolean;
  sheetProps: React.ComponentProps<typeof Modalize>;
  sheetContent: JSX.Element | null;
  position: 'top' | 'initial';

  openSheet: (
    content: JSX.Element,
    props?: React.ComponentProps<typeof Modalize>
  ) => void;
  setSheetContent: (
    content: JSX.Element,
    props?: React.ComponentProps<typeof Modalize>
  ) => void;
  setSheetProps: (props: any) => void;
  setSheetPosition: (position: 'top' | 'initial') => void;
  closeSheet: () => void;
};

const initialBottomSheetProps: React.ComponentProps<typeof Modalize> = {
  // onPositionChange: (position) => get().setSheetPosition(position);
};

export const BottomSheetStore = create<BottomSheetStore>((set, get) => ({
  show: false,
  sheetProps: initialBottomSheetProps,
  sheetContent: null,
  position: 'initial',

  openSheet: (content, props) => {
    get().setSheetContent(content, props);
    if (!get().show) {
      set({ show: true });
    }

    // TODO: This is not solved optimally, because it spams the history
    // But cleaning up the history with back() leads to unwanted navigations
    // in some cases. Maybe there is a better solution out there.
    replaceBrowserBackAction(get().closeSheet);
  },

  setSheetContent: (content, props = initialBottomSheetProps) => {
    set({
      sheetContent: content,
      sheetProps: props,
    });
  },

  setSheetProps: (props) => {
    set({ sheetProps: props });
  },

  setSheetPosition: async (position) => {
    set({ position: position });
  },

  closeSheet: async () => {
    set({
      show: false,
      sheetContent: null,
      sheetProps: initialBottomSheetProps,
      position: 'initial',
    });
    resetBrowserBack();
  },
}));
