import { Platform } from 'react-native';
import { NavProp } from '.';

export function headerTitleAlign(): any {
  if (Platform.OS === 'web') {
    return 'center';
  }
  return undefined;
}

export const getLastRouteFromNavProps = (props: NavProp | undefined): any => {
  if (!props) {
    return undefined;
  }
  const routes = props.getState().routes;
  if (!routes || routes.length === 0) {
    return undefined;
  }
  return routes[routes.length - 1];
};
