import React from 'react';
import { StyleSheet } from 'react-native';
import { Picker } from '@react-native-picker/picker';
import rptheme from '~/rptheme';

type ReparkPickerProps = {
  items: Array<any>;
  labelKey: string;
  valueKey?: string;
  selectedValue: any;
  onValueChange: Function;
};

export const ReparkPicker = (props: ReparkPickerProps) => {
  const {
    items,
    labelKey,
    valueKey = 'id',
    selectedValue,
    onValueChange,
  } = props;

  return (
    <Picker
      onValueChange={async (item: any) => {
        onValueChange?.(item);
      }}
      selectedValue={selectedValue}
      style={styles.picker}
    >
      {items.map((item, index) => {
        return (
          <Picker.Item
            label={item[labelKey]}
            value={item[valueKey]}
            key={`item-${index}`}
            style={styles.item}
          />
        );
      })}
    </Picker>
  );
};

const styles = StyleSheet.create({
  picker: {
    height: 43,
    borderRadius: 15,
    backgroundColor: 'white',
    paddingHorizontal: 5,
    borderWidth: 0,
    ...rptheme.shadow,
    fontFamily: rptheme.fonts.regular.fontFamily,
    justifyContent: 'center',
  },
  item: {
    fontFamily: rptheme.fonts.regular.fontFamily,
    fontSize: rptheme.fontSizeMedium,
  },
});
