import create from 'zustand';
import { feathersClient } from '~/src/services/feathers';

import dayjs, { Dayjs } from 'dayjs';
import duration from 'dayjs/plugin/duration';
import { AuthStore } from '../authentication/AuthStore';
import { VehicleStore } from '../vehicles/VehicleStore';
import { isActiveSubscription } from './helpers';
import { PaymentMethod } from '@stripe/stripe-js';
import {
  fetchSubscriptionInvoices,
  fetchSubscriptions,
} from './subscriptionService';
import { SubscriptionInvoice } from './types';

dayjs.extend(duration);

type SubscriptionStore = {
  isFetchingSubscriptions: boolean;
  subscriptions: Array<any>;

  selectedStartDate: Dayjs;
  selectedParkingSpotId: null | string;
  selectedPriceId: null | string;

  selectParkingSpotId: (parkingSpotId: string) => void;
  selectPriceId: (priceId: string) => void;

  selectedPaymentMethod: PaymentMethod | null;
  selectPaymentMethod: (paymentMethod: PaymentMethod) => void;

  createSubscription: () => Promise<any>;
  setStartDate: (date: Dayjs) => void;

  activeSubscriptions: () => Array<any>;
  inactiveSubscriptions: () => Array<any>;

  fetchSubscriptions: () => Promise<void>;
  fetchSubscription: (id: string) => Promise<any>;

  subscriptionInvoices: Array<SubscriptionInvoice>;
  fetchSubscriptionInvoices: (subscriptionId: string) => Promise<any>;
  isFetchingSubscriptionInvoices: boolean;

  addEventlisteners: () => void;
  removeEventlisteners: () => void;
  resetStore: () => void;
};

export const SubscriptionStore = create<SubscriptionStore>((set, get) => ({
  isFetchingSubscriptions: false,
  subscriptions: [],

  selectedStartDate: dayjs().startOf('day'),

  selectedParkingSpotId: null,
  selectedPriceId: null,
  selectedPaymentMethod: null,
  isFetchingSubscriptionInvoices: false,
  subscriptionInvoices: [],

  selectParkingSpotId: (parkingSpotId: string) => {
    set({ selectedParkingSpotId: parkingSpotId });
  },

  selectPaymentMethod: (paymentMethod: PaymentMethod) => {
    set({ selectedPaymentMethod: paymentMethod });
  },

  selectPriceId: (priceId: string) => {
    set({ selectedPriceId: priceId });
  },

  setStartDate: (date: Dayjs) => {
    set({ selectedStartDate: date });
  },

  createSubscription: async () => {
    const user = AuthStore.getState().user;
    const vehicle = VehicleStore.getState().selectedVehicle;

    const subscription = await feathersClient.service('subscriptions').create({
      licencePlate: vehicle?.licencePlate,
      startDate: get().selectedStartDate,
      items: [
        {
          priceId: get().selectedPriceId,
          parkingSpotId: get().selectedParkingSpotId,
        },
      ],
      userId: user?.id,
      paymentMethodId: get().selectedPaymentMethod?.id,
    });

    return subscription;
  },

  fetchSubscriptions: async () => {
    set({ isFetchingSubscriptions: true });

    const user = AuthStore.getState().user;
    const subscriptions = await fetchSubscriptions({
      userId: user?.id,
    });

    set({ subscriptions: subscriptions.data, isFetchingSubscriptions: false });
  },

  fetchSubscription: async (id: string) => {
    set({ isFetchingSubscriptions: true });
    const subscription = await feathersClient.service('subscriptions').get(id);
    set({ isFetchingSubscriptions: false });
    return subscription;
  },

  activeSubscriptions: () => {
    return get().subscriptions.filter(
      (subscription) =>
        isActiveSubscription(subscription) ||
        subscription.status === 'incomplete' ||
        subscription.status === 'not_started'
    );
  },

  inactiveSubscriptions: () => {
    //Should return all subscriptions, that are not in get().activeSubscriptions()
    return get().subscriptions.filter(
      (subscription) => !get().activeSubscriptions().includes(subscription)
    );
  },

  fetchSubscriptionInvoices: async (subscriptionId: string) => {
    set({ isFetchingSubscriptionInvoices: true });
    const response = await fetchSubscriptionInvoices(subscriptionId);
    if (response) {
      set({ subscriptionInvoices: response.data });
    }
    set({ isFetchingSubscriptionInvoices: false });
  },

  addEventlisteners: () => {
    console.log('Adding Subscription Eventlisteners');

    feathersClient
      .service('subscriptions')
      .on('created', (subscription: any) => {
        get().fetchSubscriptions();
      });

    feathersClient.service('subscriptions').on('removed', () => {
      get().fetchSubscriptions;
    });

    feathersClient.service('subscriptions').on('patched', () => {
      get().fetchSubscriptions();
    });
  },

  removeEventlisteners: () => {
    console.log('Removing Subscription Eventlisteners');

    feathersClient.service('subscriptions').removeAllListeners('created');
    feathersClient.service('subscriptions').removeAllListeners('removed');
    feathersClient.service('subscriptions').removeAllListeners('patched');
  },

  resetStore: () => {
    set({
      isFetchingSubscriptions: false,
      subscriptions: [],
    });
  },
}));
