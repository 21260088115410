import React from 'react';
import { NavProp } from '../../navigation';
import { View } from 'react-native';
import { AuthStore } from '../../authentication/AuthStore';
import { zodResolver } from '@hookform/resolvers/zod';
import { t } from 'i18next';
import { Controller, useForm } from 'react-hook-form';
import { createSubscriptionDataZodSchema } from '~/src/rules/inputValidationRules';
import ReparkTextInput from '~/src/components/ReparkTextInput';
import ReparkButton from '~/src/components/ReparkButton';
import { TextInput } from 'react-native-paper';
import { ContinueNextButtons } from './ContinueNextButtons';

type CustomerDataFormProps = {
  navigation?: NavProp;
  onSuccess: () => void;
  onBack: () => void;
};

export const CustomerDataForm = (props: CustomerDataFormProps) => {
  const { onSuccess, onBack } = props;
  const [user, editUser] = AuthStore((store) => [
    store.user,
    store.editUser,
    store.resendEmailVerification,
  ]);
  const [isLoading, setIsLoading] = React.useState(false);
  const [showCompany, setShowCompany] = React.useState(false);

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<{
    firstName: string;
    lastName: string;
    email: string;
    phoneNumber: string;
    userCompanyName: string;
    vatId: string;
  }>({
    resolver: zodResolver(createSubscriptionDataZodSchema(t)),
    defaultValues: {
      firstName: user.firstName ? user.firstName : undefined,
      lastName: user.lastName ? user.lastName : undefined,
      email: user.email ? user.email : undefined,
      phoneNumber: user.phoneNumber,
      userCompanyName: user.userCompanyName ? user.userCompanyName : undefined,
      vatId: user.vatId ? user.vatId : undefined,
    },
  });

  const onSubmit = async (data: any) => {
    setIsLoading(true);
    const response = await editUser(data);
    setIsLoading(false);
    onSuccess();
    console.log(response);
  };

  React.useEffect(() => {
    if (user.userCompanyName || user.vatId) {
      setShowCompany(true);
    }
  }, []);

  return (
    <View>
      <Controller
        name="firstName"
        control={control}
        render={({ field: { onChange } }) => (
          <ReparkTextInput
            testID={'firstNameInput'}
            disabled={isLoading}
            value={user.firstName || ''}
            onChangeText={(text: string) => onChange(text)}
            maxLength={40}
            textContentType="givenName"
            keyboardType="default"
            autoComplete="given-name"
            autoCorrect={false}
            spellCheck={false}
            autoFocus={true}
            zoderror={errors.firstName}
            label={t('AuthTexts.firstNamePlaceHolder')}
            icon={'account-outline'}
          />
        )}
      />

      <Controller
        name="lastName"
        control={control}
        render={({ field: { onChange } }) => (
          <ReparkTextInput
            testID={'lastNameInput'}
            disabled={isLoading}
            value={user.lastName || ''}
            onChangeText={(text: string) => onChange(text)}
            maxLength={40}
            textContentType="familyName"
            keyboardType="default"
            autoComplete="family-name"
            autoCorrect={false}
            spellCheck={false}
            autoFocus={true}
            zoderror={errors.lastName}
            label={t('AuthTexts.lastNamePlaceHolder')}
            icon={'account-outline'}
          />
        )}
      />

      <Controller
        name="email"
        control={control}
        render={({ field: { onChange } }) => (
          <ReparkTextInput
            testID={'emailInput'}
            disabled={isLoading}
            value={user.email || ''}
            onChangeText={(text: string) => onChange(text)}
            maxLength={40}
            textContentType="emailAddress"
            keyboardType="email-address"
            autoComplete="email"
            autoCorrect={false}
            spellCheck={false}
            autoFocus={true}
            zoderror={errors.email}
            label={t('AuthTexts.emailPlaceholder')}
            icon={'email'}
            right={
              user.emailVerified && (
                <TextInput.Icon
                  name={'check'}
                  disabled
                  size={10}
                  testID={'emailVerifiedCheck'}
                />
              )
            }
          />
        )}
      />

      {showCompany && (
        <>
          <Controller
            name="userCompanyName"
            control={control}
            render={({ field: { onChange } }) => (
              <ReparkTextInput
                testID={'companyNameTestID'}
                disabled={isLoading}
                value={user.userCompanyName || ''}
                onChangeText={(text: string) => onChange(text)}
                maxLength={60}
                textContentType="none"
                keyboardType="default"
                autoComplete="off"
                autoCorrect={false}
                spellCheck={false}
                autoFocus={true}
                zoderror={errors.userCompanyName}
                label={t('AuthTexts.companyName')}
              />
            )}
          />
          <Controller
            name="vatId"
            control={control}
            render={({ field: { onChange } }) => (
              <ReparkTextInput
                testID={'vatInput'}
                disabled={isLoading}
                value={user.vatId || ''}
                onChangeText={(text: string) => onChange(text)}
                maxLength={40}
                textContentType="none"
                keyboardType="default"
                autoComplete="off"
                autoCorrect={false}
                spellCheck={false}
                autoFocus={false}
                zoderror={errors.vatId}
                label={t('AuthTexts.vatIdPlaceholder')}
              />
            )}
          />
        </>
      )}

      {!showCompany && (
        <ReparkButton
          mode="outlined"
          icon={'add'}
          disabled={isLoading}
          onPress={() => setShowCompany(true)}
        >
          {t('Subscription.steps.owner.addCompanyButtonLabel')}
        </ReparkButton>
      )}

      <ContinueNextButtons
        onBack={onBack}
        onContinue={handleSubmit(onSubmit)}
        nextDisabled={isLoading}
      />
    </View>
  );
};
