import React from 'react';
import { t } from 'i18next';
import { StyleSheet, View, Text } from 'react-native';
import { Paragraph, Subheading, Title, Card } from 'react-native-paper';
import rptheme from '~/rptheme';
import ReparkButton from '~/src/components/ReparkButton';
import dayjs from 'dayjs';
import { ReparkIcon } from '~/src/components/ReparkIcon';
import { GarageStore } from '../garage/GarageStore';
import { formatDate } from '~/src/utils/helpers';
import { NavProp } from '../navigation';

export type BookingDuplicateDetailsProps = {
  duplicate: any | undefined;
  continueCheckout: () => void;
  navigation: NavProp;
};

export const BookingDuplicateDetails = (
  props: BookingDuplicateDetailsProps
) => {
  const { navigation, duplicate, continueCheckout } = props;

  const [getGarage] = GarageStore((store) => [store.getGarage]);

  const constructTitle = (booking: any) => {
    const garage = getGarage(booking.garageId);

    return garage ? garage.name || garage.addressString : '';
  };

  const formatBookingDateRange = (booking: any, t: Function) => {
    const startDate = formatDate(
      dayjs(booking.start).tz('Europe/Vienna'),
      t,
      false
    );
    if (booking.duration <= 1440) {
      return startDate;
    }

    const endDate = formatDate(
      dayjs(booking.start)
        .tz('Europe/Vienna')
        .add(booking.duration, 'minutes')
        .subtract(1, 'second'),
      t,
      false
    );

    return `${startDate} - ${endDate}`;
  };

  const handleGoToBooking = () => {
    navigation.replace('BookingStack', {
      screen: 'BookingDetail',
      params: {
        id: duplicate.id,
      },
    });
  };

  return (
    <>
      {duplicate && (
        <View
          style={styles.container}
          testID="BookingDuplicateDetailsTestContainer"
        >
          <View style={styles.content}>
            <Subheading style={styles.heading}>
              {t('BookingDetail.duplicatebooking')}
            </Subheading>
            <Text style={styles.explanation}>
              {t('BookingDetail.duplicatebookingexplanation')}
            </Text>
            <Card
              style={styles.card}
              onPress={handleGoToBooking}
              testID="BookingDuplicateDetailsTestCard"
            >
              <View style={styles.bookingOverview}>
                <Title style={styles.title}>{constructTitle(duplicate)}</Title>
                <View style={styles.bookingDetail}>
                  <ReparkIcon
                    style={styles.icon}
                    name="calendar"
                    color={rptheme.colors.primary}
                  />
                  <Paragraph>{formatBookingDateRange(duplicate, t)}</Paragraph>
                </View>
                <View style={styles.bookingDetail}>
                  <ReparkIcon
                    style={styles.icon}
                    name="car"
                    color={rptheme.colors.primary}
                  ></ReparkIcon>
                  <View
                    style={styles.bookingDetail}
                    testID="BookingDuplicateDetailsTestLicensePlate"
                  >
                    <Paragraph>{duplicate.licencePlate}</Paragraph>
                  </View>
                </View>
                <Paragraph
                  style={styles.createdAt}
                  testID="BookingDuplicateDetailsTestCreated"
                >
                  {t('BookingDetail.bookedinformation', {
                    date: dayjs(duplicate.createdAt).format('DD.MM.YYYY'),
                    time: dayjs(duplicate.createdAt).format('HH:mm'),
                  })}
                </Paragraph>
                <View style={styles.goTo}>
                  <Text style={styles.goToText}>
                    {t('offer.goToBookingButton')}
                  </Text>
                  <ReparkIcon
                    name="chevron-right"
                    color={rptheme.colors.primary}
                  />
                </View>
              </View>
            </Card>
            <View style={styles.buttonGroup}>
              <ReparkButton
                testID="BookingDuplicateDetailsTestContinue"
                onPress={continueCheckout}
              >
                {t('General.continue')}
              </ReparkButton>
            </View>
          </View>
        </View>
      )}
    </>
  );
};

const styles = StyleSheet.create({
  container: {
    alignSelf: 'center',
  },
  content: {
    backgroundColor: 'white',
    paddingBottom: 10,
    borderRadius: rptheme.roundness,
    padding: 10,
    ...rptheme.shadow,
  },
  heading: {
    color: rptheme.colors.primary,
    marginTop: 10,
    marginBottom: 5,
    fontSize: 18,
    textAlign: 'center',
  },
  explanation: {
    textAlign: 'center',
    maxWidth: 250,
    fontSize: 12,
    marginBottom: 15,
  },
  title: {
    fontSize: 14,
    maxWidth: 250,
    textAlign: 'center',
  },
  card: {
    marginBottom: 20,
    backgroundColor: rptheme.colors.appBackground,
    shadowColor: rptheme.shadow.shadowColor,
    shadowOpacity: rptheme.shadow.shadowOpacity,
    shadowRadius: rptheme.shadow.shadowRadius,
    elevation: rptheme.shadow.elevation,
    textShadowOffset: rptheme.shadow.shadowOffset,
  },
  bookingOverview: {
    flexDirection: 'column',
    alignItems: 'center',
    flexWrap: 'wrap',
  },
  bookingDetail: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  createdAt: {
    maxWidth: 250,
    flexWrap: 'wrap',
    textAlign: 'center',
  },
  icon: {
    marginRight: 5,
  },
  buttonGroup: {
    width: 180,
    alignSelf: 'center',
  },
  goTo: {
    alignSelf: 'flex-end',
    flexDirection: 'row',
    alignItems: 'center',
    margin: 10,
  },
  goToText: {
    color: rptheme.colors.primary,
    marginRight: 10,
  },
});
