import { PaymentMethod } from '@stripe/stripe-js';
import ReparkListItem from '~/src/components/ReparkListItem';
import React from 'react';
import { List, Paragraph } from 'react-native-paper';
import Icon from '@mdi/react';
import { mdiBank } from '@mdi/js';
import rptheme from '~/rptheme';
import ReparkIconButton from '~/src/components/ReparkIconButton';
import { BottomSheetStore } from '../../bottom-sheet/BottomSheetStore';
import ConfirmCancelSheet from '~/src/components/ConfirmCancelSheet';
import { useTranslation } from 'react-i18next';
import { StyleSheet } from 'react-native';
import { createSEPADescription } from '../paymentHelpers';

type CardItemProps = {
  sepa_debit: PaymentMethod;
  onDeletePaymentMethod?: (paymentMethodId: string) => void;
};

export const SEPAItem = (props: CardItemProps) => {
  const { sepa_debit, onDeletePaymentMethod } = props;
  const [openSheet] = BottomSheetStore((store) => [store.openSheet]);
  const { t } = useTranslation();

  const cardData = {
    title: 'SEPA',
    description: createSEPADescription(sepa_debit),
    testID: sepa_debit.id,
    left: (props: any) => (
      <List.Icon
        {...props}
        style={{ display: 'flex' }}
        icon={() => (
          <Icon path={mdiBank} size={1} color={rptheme.colors.primary} />
        )}
      />
    ),
  };

  return (
    <>
      <ReparkListItem
        {...cardData}
        right={() => (
          <List.Icon
            icon={() => {
              if (!onDeletePaymentMethod) return null;
              return (
                <ReparkIconButton
                  testID="deletePaymentMethodButton"
                  onPress={async () => {
                    openSheet(
                      <ConfirmCancelSheet
                        title={t('PaymentMethodsScreen.confirmDeleteTitle')}
                        onConfirm={() =>
                          onDeletePaymentMethod &&
                          onDeletePaymentMethod(sepa_debit.id)
                        }
                        t={t}
                      >
                        <Paragraph style={styles.deleteText}>
                          {cardData.title} - {cardData.description}
                        </Paragraph>
                      </ConfirmCancelSheet>,
                      { adjustToContentHeight: true }
                    );
                  }}
                  icon={'delete'}
                  size={20}
                  color={rptheme.colors.error}
                ></ReparkIconButton>
              );
            }}
          />
        )}
      />
    </>
  );
};

const styles = StyleSheet.create({
  deleteText: {
    textAlign: 'center',
  },
});
