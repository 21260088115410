import * as React from 'react';
import { Pressable } from 'react-native';
import { AuthStore } from '../authentication/AuthStore';
import UserAvatar from './Avatar';

interface AvatarFABProps {
  onPress: () => void;
}

export default function AvatarFAB({ onPress }: AvatarFABProps) {
  const user = AuthStore((store) => store.user);
  return (
    <Pressable onPress={onPress}>
      <UserAvatar user={user} size={56} />
    </Pressable>
  );
}
