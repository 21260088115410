import { GeneralError } from '@feathersjs/errors';
import { PaymentMethod, Stripe, StripeElements } from '@stripe/stripe-js';
import { Dayjs } from 'dayjs';
import { feathersClient } from '../../services/feathers';
import { fetchPaymentIntentRequest } from '../payment/paymentService';

export type BookOfferParams = {
  offerId: string;
  start: Dayjs;
  duration: number;
  licencePlate: string;
  paymentIntentId?: string;
  type?: string;
  voucherId?: string;
};

export const fetchBookingsRequest = async (query: any) => {
  const response = await feathersClient.service('bookings').find({
    query: {
      ...query,
      $sort: {
        canceled: 1,
        start: -1,
      },
      $limit: 10000,
    },
  });
  return response.data;
};

export const fetchSingleBookingRequest = async (id: string) => {
  try {
    const response = await feathersClient.service('bookings').get(id);
    return response;
  } catch (e: any) {
    console.log(e);
    return { success: false, code: e.response?.status };
  }
};

export const patchBookingRequest = async (bookingData: any) => {
  const response = await feathersClient
    .service('bookings')
    .patch(bookingData.id, bookingData);

  return response;
};

export const cancelBookingRequest = async (id: string) => {
  const response = await feathersClient
    .service('bookings')
    .patch(id, { canceled: true });
  return response;
};

export const createBookingRequest = async (params: BookOfferParams) => {
  const response = await feathersClient.service('bookings').create(params);
  return response;
};

export type PayOfferWebProps = {
  stripe: Stripe | null;
  elements: StripeElements | null;
  paymentIntent: any;
  useSavedCard: boolean;
  return_url?: string;
  paymentMethods: PaymentMethod[];
};

export const payOfferWeb = async ({
  stripe,
  elements,
  paymentIntent,
  useSavedCard = false,
  return_url = 'https://www.repark.at',
  paymentMethods,
}: PayOfferWebProps) => {
  if (!stripe || !elements) {
    throw new GeneralError('Stripe and/or Elements missing');
  }

  let stripeResponse;
  const usedPaymentMethod = paymentMethods.find(
    (paymentMethod) => paymentMethod.id === paymentIntent.payment_method
  );

  if (useSavedCard && paymentIntent?.payment_method && usedPaymentMethod) {
    switch (usedPaymentMethod.type) {
      case 'card':
        stripeResponse = await stripe?.confirmCardPayment(
          paymentIntent?.client_secret,
          {
            payment_method: paymentIntent?.payment_method,
          }
        );
        break;
      case 'sepa_debit':
        stripeResponse = await stripe.confirmSepaDebitPayment(
          paymentIntent?.client_secret,
          {
            payment_method: paymentIntent?.payment_method,
          }
        );
        break;
    }
  } else {
    stripeResponse = await stripe.confirmPayment({
      elements,
      confirmParams: {
        return_url, // TODO: We need to set a return_url, but it should not be used wiht card payments alone. But we may need a "Success"-Page to redirect users.
      },
      redirect: 'if_required', // It would be required, e.g. you're paying with SOFORT etc, where a user first has to leave our app and then come back
    });
  }

  if (stripeResponse?.error) {
    throw new Error(JSON.stringify(stripeResponse.error));
  }

  return { stripeResponse };
};

export type BookOfferWebProps = {
  offerId: any;
  paymentIntent: any;
  start: Dayjs;
  duration: number;
  licencePlate: string;
};

export const bookOfferWeb = async ({
  offerId,
  paymentIntent,
  start,
  duration,
  licencePlate,
}: BookOfferWebProps) => {
  const booking = await createBookingRequest({
    paymentIntentId: paymentIntent.id,
    offerId,
    start,
    duration,
    licencePlate,
  });
  return { booking };
};

// TODO: This function is untested
export const bookOfferNative = async (
  offerId: any,
  start: Dayjs,
  duration: number,
  licencePlate: string,
  confirmPayment: Function,
  onError?: Function
) => {
  let bookingResponse;
  let stripeResponse;
  const paymentIntent = await fetchPaymentIntentRequest(offerId);

  try {
    bookingResponse = await createBookingRequest({
      paymentIntentId: paymentIntent.id,
      offerId,
      start,
      duration,
      licencePlate,
    });
  } catch (e) {
    onError?.();
  }

  try {
    stripeResponse = await confirmPayment(paymentIntent.secret, {
      type: 'Card',
    });

    console.log(stripeResponse);
  } catch (e) {
    onError?.();
  }

  return { bookingResponse, stripeResponse };
};

export const fetchStripeFee = async (bookingId: string) => {
  const response = await feathersClient
    .service('stripe')
    .get(bookingId, { query: { action: 'getStripeFee' } });
  return response;
};

export const requestInvoicesRequest = async (bookingId: string) => {
  const response = await feathersClient
    .service('bookings')
    .get(bookingId, { query: { action: 'requestInvoice' } });

  return response;
};
