import { Platform } from 'react-native';

const computeServerURL = (url: any) => {
  if (process.env.NODE_ENV === 'development' && Platform.OS === 'web') {
    const host = window.location.href.split('//')[1].split(':')[0];
    const port = url.split(':')[2];
    return `http://${host}:${port}`;
  }
  return url;
};

export default function config() {
  return {
    server: computeServerURL(process.env.REPARK_SERVER_URL),
    mode: process.env.NODE_ENV,
    firebase: {
      apiKey: process.env.FIREBASE_API_KEY,
      authDomain: process.env.FIREBASE_AUTH_DOMAIN,
      projectId: process.env.FIREBASE_PROJECT_ID,
      appId: process.env.FIREBASE_APP_ID,
      measurementId: process.env.FIREBASE_MEASUREMENT_ID,
    },
    firebaseDev: {
      authEmulator: computeServerURL(process.env.FIREBASE_AUTH_EMULATOR_URL),
      useEmulator: process.env.FIREBASE_USE_EMULATOR !== 'false',
    },
    googleMaps: {
      apiKey: process.env.GOOGLE_MAPS_API_KEY,
    },
    locize: {
      projectId: process.env.LOCIZE_PROJECT_ID,
      apiKey: process.env.LOCIZE_API_KEY,
      defaultLanguage: { de: { nativeName: 'Deutsch' } },
    },
    webPages: {
      gdpr: process.env.LEGAL_GDPR_URL,
      imprint: process.env.LEGAL_IMPRINT_URL,
      feedback: process.env.FEEDBACK_FORM_URL,
      support: process.env.SUPPORT_FORM_URL,
      phoneSupport: process.env.PHONE_SUPPORT_URL,
      terms: process.env.LEGAL_TERMS_URL,
      homepage: process.env.HOMEPAGE_URL,
      jobs: process.env.JOBS_URL,
      rentalConditions: process.env.LEGAL_RENTAL_CONDITIONS_URL,
      faq: process.env.FAQ_URL,
      howItWorks: process.env.HOMEPAGE_URL,
      linkedin: process.env.LINKEDIN_URL,
      facebook: process.env.FACEBOOK_URL,
      instagram: process.env.INSTAGRAM_URL,
      wifi_calling: process.env.WIFI_CALLING,
      shareWithFriend: process.env.SHARE_URL,
    },
    repark: {
      maxVehicles: 3,
      vehicleHeightWarning: 190,
    },
    stripe: {
      publicKey: process.env.STRIPE_PUBLIC_KEY || '',
      isMocked: process.env.STRIPE_USE_MOCK,
    },
    meta: {
      meta_pixel_id: process.env.META_PIXEL_ID,
    },
    bugsnag: {
      apiKey: process.env.BUGSNAG_API_KEY || '',
    },
  };
}
