import React from 'react';
import { View, Text, StyleSheet } from 'react-native';
import { Title } from 'react-native-paper';
import config from '~/config/config';
import rptheme from '~/rptheme';
import ReparkButton from '~/src/components/ReparkButton';
import { AuthStore } from '../../authentication/AuthStore';
import { NavProp, RootScreens } from '../../navigation';

export const PaymentFailedNote = ({
  navigation,
  t,
}: {
  navigation: NavProp;
  t: Function;
}) => {
  const user = AuthStore((store) => store.user);

  const support = {
    icon: 'email',
    label: t('fabaction.support'),
    color: rptheme.colors.primary,
    onPress: () => {
      navigation.navigate(RootScreens.WebViewScreen, {
        url: `${config().webPages.support}?email=${user?.email}&phoneNumber=${
          user?.phoneNumber
        }`,
      });
    },
  };

  return (
    <View style={styles.container}>
      <Title style={styles.title}>{t('PaymentFailedNote.title')}</Title>
      <Text style={styles.text}>{t('PaymentFailedNote.lastParkingSpot')}</Text>
      <Text style={styles.text}>{t('PaymentFailedNote.refundInfo')}</Text>
      <ReparkButton icon={support.icon} onPress={support.onPress}>
        {support.label}
      </ReparkButton>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    marginVertical: 40,
  },
  title: {
    alignSelf: 'center',
    textAlign: 'center',
    fontSize: rptheme.fontSizeXLarge,
    fontFamily: rptheme.fonts.regular.fontFamily,
    fontWeight: rptheme.fonts.regular.fontWeight,
    color: rptheme.colors.primary,
  },
  text: {
    alignSelf: 'center',
    textAlign: 'center',
  },
});
