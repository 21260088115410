import { DefaultTheme, configureFonts } from 'react-native-paper';
import { Platform } from 'react-native';
import MarkerDefault from './assets/images/map-marker.svg';
import MarkerActive from './assets/images/map-marker-active.svg';
import MarkerSpecialOffer from './assets/images/map-marker-special-offer.svg';
import MarkerComingSoon from './assets/images/map-marker-comingSoon.svg';
import MarkerActiveNoOffer from './assets/images/map-marker-active-no-offer.svg';
import MarkerNoOffer from './assets/images/map-marker-no-offer.svg';
import CalendarGraphic from './assets/images/calendar.svg';
import MailOnboardingGraphic from './assets/images/onboarding/mail_onboarding.svg';
import VehicleOnboardingGraphic from './assets/images/onboarding/vehicle_onboarding.svg';
import NameOnboardingGraphic from './assets/images/onboarding/name_onboarding.png';
import WelcomeOnboardingGraphic from './assets/images/onboarding/welcome_onboarding.png';
import FeedbackOnboardingGraphic from './assets/images/onboarding/feedback_onboarding.svg';
import NearbyGraphic from './assets/images/nearby.png';
import LeisureGraphic from './assets/images/freizeitaktivitaet.svg';
import EventGraphic from './assets/images/veranstaltung.svg';
import FriendsAndFamilyGraphic from './assets/images/freunde_und_familie.svg';
import WorkAndJobGraphic from './assets/images/arbeit_und_berufliches.svg';
import TravelGraphic from './assets/images/urlaub_und_geschaeftsreise.svg';
import SubscriptionSuccessGraphic from './assets/images/subscriptionSuccess.svg';
import HomeGraphic from './assets/images/wohnen.svg';
import DigitalShareGraphic from './assets/images/digital_share.png';

// Theme options: https://callstack.github.io/react-native-paper/theming.html

const _fontConfig = {
  regular: {
    fontFamily: 'Poppins_400Regular',
    fontWeight: 'normal',
  },
  medium: {
    fontFamily: 'Poppins_600SemiBold',
    fontWeight: 'normal',
  },
  light: {
    fontFamily: 'Poppins_400Regular',
    fontWeight: 'normal',
  },
  thin: {
    fontFamily: 'Poppins_400Regular',
    fontWeight: 'normal',
  },
};

const fontConfig = {
  web: _fontConfig,
  ios: _fontConfig,
  android: _fontConfig,
};

export default {
  ...DefaultTheme,
  roundness: 15,
  colors: {
    ...DefaultTheme.colors,
    primary: '#006AFF',
    secondary: '#D1E7FB',
    accent: '#17BA17',
    accentFade: '#5DE1B2',
    success: '#17BA17',
    softHighlight: '#DDFBDD',
    info: '#006AFF',
    error: '#D12A2A',
    appBackground: '#F9F9F9',
    background: '#FFFFFF',
    gray: '#959595',
    overlay: 'rgba(255, 255, 255, 0.8)',
  },
  fonts: configureFonts(fontConfig),
  fontSizeSmall: 12,
  fontSizeMedium: 14,
  fontSizeLarge: 16,
  fontSizeXLarge: 18,
  fontSizeXXLarge: 20,
  screenSmall: 480,
  screnMedium: 768,
  screenLarge: 1024,
  screenXLarge: 1920,
  shadow: {
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 3.84,

    elevation: 5,
  },
  fab: {
    verticalMargin: Platform.OS === 'web' ? 20 : 50,
    horizontalMargin: 20,
    bottomMargin:
      Platform.OS === 'web' ? 20 : Platform.OS === 'android' ? 10 : 50,
  },
  backIcon: Platform.OS === 'ios' ? 'chevron-left' : 'arrow-left',
  map: {
    marker: {
      default: MarkerDefault,
      active: MarkerActive,
      activeNoOffer: MarkerActiveNoOffer,
      comingSoon: MarkerComingSoon,
      noOffer: MarkerNoOffer,
      specialOffer: MarkerSpecialOffer,
      labelFontSize: 13,
      labelColor: 'white',
    },
  },
  graphics: {
    calendar: CalendarGraphic,
    mailOnboarding: MailOnboardingGraphic,
    vehicleOnboarding: VehicleOnboardingGraphic,
    nameOnboarding: NameOnboardingGraphic,
    welcomeOnboarding: WelcomeOnboardingGraphic,
    feedbackOnboarding: FeedbackOnboardingGraphic,
    nearby: NearbyGraphic,
    feedbackLeisure: LeisureGraphic,
    feedbackEvent: EventGraphic,
    feedbackJobAndWork: WorkAndJobGraphic,
    feedbackFriendsAndFamily: FriendsAndFamilyGraphic,
    feedbackTravel: TravelGraphic,
    feedbackHome: HomeGraphic,
    digitalShare: DigitalShareGraphic,
    subscriptionSuccess: SubscriptionSuccessGraphic,
  },
};
