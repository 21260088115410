import React from 'react';
import BottomSheetFrame from '../bottom-sheet/BottomSheetFrame';
import { AuthStore } from '../authentication/AuthStore';
import { Paragraph } from 'react-native-paper';
import { View } from 'react-native';
import ReparkButton from '~/src/components/ReparkButton';
import { NavProp } from '../navigation';
import { BottomSheetStore } from '../bottom-sheet/BottomSheetStore';
import { useTranslation } from 'react-i18next';
import { requestInvoicesRequest } from './bookingService';
import { Controller, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { createEmailZodSchema } from '~/src/rules/inputValidationRules';
import ReparkTextInput from '~/src/components/ReparkTextInput';
import { SnackbarStore } from '../snackbar/SnackbarStore';
import { idIs, idToType } from '~/src/utils/ids';
import { IdType } from '~/src/utils/types';
import { fetchSubscriptionInvoice } from '../subscriptions/subscriptionService';

type RequestInvoicesProps = {
  contract: {
    id: string;
  };
  navigation: NavProp;
};

export const RequestInvoicesSheet = (props: RequestInvoicesProps) => {
  const { contract } = props;
  const { t } = useTranslation();
  const [fireSnack] = SnackbarStore((store) => [store.fireSnack]);

  const [user, editUser, resendEmailVerification] = AuthStore((store) => [
    store.user,
    store.editUser,
    store.resendEmailVerification,
  ]);

  const [isLoading, setIsLoading] = React.useState(false);
  const [closeSheet] = BottomSheetStore((store) => [store.closeSheet]);

  const [verificationSent, setVerificationSent] = React.useState(false);
  const [invoicesSent, setInvoicesSent] = React.useState(false);

  const handleRequestInvoices = () => {
    try {
      setIsLoading(true);
      if (idIs(contract.id, IdType.Booking)) {
        requestInvoicesRequest(contract.id);
      } else if (idIs(contract.id, IdType.SubscriptionInvoice)) {
        fetchSubscriptionInvoice(contract.id, 'send');
      } else {
        throw new Error("Unable to handle 'contract'");
      }
      setInvoicesSent(true);
      closeSheet();

      fireSnack({
        title: t('requestInvoices.successTitle'),
        message: t('requestInvoices.successMessage'),
        type: 'success',
      });
    } catch (e) {
      console.error(e);
    } finally {
      setIsLoading(false);
    }
  };

  const {
    control,
    handleSubmit,
    setError,
    formState: { errors },
  } = useForm<{
    firstName: string;
    lastName: string;
    email: string;
    phoneNumber: string;
    userCompanyName: string;
    vatId: string;
  }>({
    resolver: zodResolver(createEmailZodSchema(t)),
    defaultValues: {
      email: user.email ? user.email : undefined,
    },
  });

  const submit = handleSubmit(async (data) => {
    setIsLoading(true);
    const response = await editUser(data);

    if (!response.success) {
      let title, message;

      switch (response.errorCode) {
        case 409:
          title = t('ProfileScreen.editUserFailed.emailAlreadyTakenTitle');
          message = t('ProfileScreen.editUserFailed.emailAlreadyTakenMessage');
          setError('email', {
            message: t('ProfileScreen.editUserFailed.emailAlreadyTakenTitle'),
          });
          break;
        default:
          title = t('General.errors.GenericErrorTitle');
          message = t('General.errors.GenericErrorMessage');
      }

      fireSnack({
        title,
        message,
        type: 'error',
      });
    }
    setIsLoading(false);
    setVerificationSent(true);
  });

  const resendVerification = async () => {
    setIsLoading(true);
    console.log('resending');
    try {
      await resendEmailVerification();
      fireSnack({
        title: t('EmailVerification.successTitle'),
        message: t('EmailVerification.successMessage'),
        type: 'success',
      });
    } catch (e: any) {
      let title, message, type;

      if (e.response.status === 429) {
        title = t('EmailVerification.tooManyRequestsTitle');
        message = t('EmailVerification.tooManyRequestsMessage');
        type = 'info' as any;
      } else {
        title = t('General.errors.GenericErrorTitle');
        message = t('General.errors.GenericErrorMessage');
        type = 'error';
      }

      fireSnack({
        title,
        message,
        type,
      });
    }

    setIsLoading(false);
    setVerificationSent(true);
  };

  if (!user.email) {
    return (
      <BottomSheetFrame title={t('requestInvoices.title')}>
        <View>
          <Paragraph>{t('requestInvoices.noEmailNote')}</Paragraph>
          <Controller
            name="email"
            control={control}
            render={({ field: { onChange } }) => (
              <ReparkTextInput
                testID={'emailInput'}
                disabled={isLoading}
                value={user.email || ''}
                onChangeText={(text: string) => onChange(text)}
                maxLength={40}
                textContentType="emailAddress"
                keyboardType="email-address"
                autoComplete="email"
                autoCorrect={false}
                spellCheck={false}
                autoFocus={true}
                zoderror={errors.email}
                label={t('AuthTexts.emailPlaceholder')}
                icon={'email'}
              />
            )}
          />

          <ReparkButton
            onPress={submit}
            loading={isLoading}
            disabled={isLoading}
          >
            {t('General.save')}
          </ReparkButton>
        </View>
      </BottomSheetFrame>
    );
  } else if (!user.emailVerified) {
    return (
      <BottomSheetFrame title={t('requestInvoices.title')}>
        <View>
          <Paragraph>{t('requestInvoices.notVerifiedNote')}</Paragraph>
          {verificationSent && (
            <Paragraph style={{ fontWeight: 'bold' }}>
              {t('requestInvoices.verificationSent')}
            </Paragraph>
          )}
          <ReparkButton
            onPress={() => {
              setIsLoading(true);
              resendVerification();
              setIsLoading(false);
            }}
          >
            {t('requestInvoices.resendVerificationEmail')}
          </ReparkButton>
        </View>
      </BottomSheetFrame>
    );
  } else {
    return (
      <BottomSheetFrame title={t('requestInvoices.title')}>
        <View>
          <Paragraph>
            {t('requestInvoices.text', { email: user.email })}
          </Paragraph>
          {invoicesSent ? (
            <Paragraph>Rechnungen sent</Paragraph>
          ) : (
            <ReparkButton
              loading={isLoading}
              disabled={isLoading}
              onPress={handleRequestInvoices}
            >
              {t('requestInvoices.request')}
            </ReparkButton>
          )}
        </View>
      </BottomSheetFrame>
    );
  }
};
