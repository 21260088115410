import * as React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { StyleSheet, View } from 'react-native';
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { t } from 'i18next';

import rptheme from '~/rptheme';
import ReparkListItem from '~/src/components/ReparkListItem';
import ReparkTextInput from '~/src/components/ReparkTextInput';
import ReparkPhoneInput from '~/src/components/ReparkPhoneInput';
import ReparkIconButton from '~/src/components/ReparkIconButton';
import { get, patch } from '~/src/services/rest';
import {
  createEmailValidation,
  createNameValidation,
  createPhoneNumberValidation,
} from '~/src/rules/inputValidationRules';
import { splitName } from '~/src/utils/helpers';
import { SnackbarStore } from '../features/snackbar/SnackbarStore';
import { CancelConfirm } from './CancelConfirm';

export enum Service {
  users,
  garages,
}

export enum Property {
  name,
  phoneNumber,
  email,
}

type EditableItemValuesType = {
  item: string;
};

const icons = {
  [Property.name]: 'account-outline',
  [Property.email]: 'email',
  [Property.phoneNumber]: 'phone',
};

// See: https://reactnative.dev/docs/textinput#autocomplete-android
const autoCompletes = {
  [Property.name]: 'name',
  [Property.email]: 'email',
  [Property.phoneNumber]: 'tel',
};

const validationSchemas = {
  [Property.name]: createNameValidation,
  [Property.email]: createEmailValidation,
  [Property.phoneNumber]: createPhoneNumberValidation,
};

export default function EditableItem(props: {
  service: Service;
  property: Property;
  id: string;
  onSuccess?: Function;
  onError?: Function;
  icon?: string;
  color?: string;
  isEditable?: boolean;
  onInfoI?: Function;
}) {
  const {
    control,
    formState: { errors },
    handleSubmit,
  } = useForm<EditableItemValuesType>({
    resolver: zodResolver(
      z.object({
        item: validationSchemas[props.property](t),
      })
    ),
  });
  // const snackbarService = React.useContext(SnackbarService);
  const fireSnack = SnackbarStore((store) => store.fireSnack);

  const [isEditing, setEditing] = React.useState(false);
  const [changeDetected, setChangeDetected] = React.useState(false);
  const [value, setValue] = React.useState('');
  const property = Property[props.property];
  const service = Service[props.service];
  const icon = props.icon || icons[props.property];
  const autoComplete = autoCompletes[props.property];
  const isEditable = props.isEditable ?? true;
  const iconSize = 20;

  React.useEffect(() => {
    const setCurrentValue = async () => {
      const entry: any = await get(service, props.id);

      if (service === 'users' && property === 'name') {
        if (entry.firstName && entry.lastName) {
          setValue(`${entry.firstName} ${entry.lastName}`);
        }
        return;
      }
      setValue(entry[property]);
    };
    setCurrentValue();
  }, []);

  const save = async (itemValue: any) => {
    const data = {
      [property]: itemValue,
    };

    if (service === 'users' && property === 'name') {
      const { firstName, lastName } = splitName(itemValue);
      delete data.name;
      data.firstName = firstName;
      data.lastName = lastName;
    }

    const result = await patch(service, props.id, data);
    return result?.data;
  };

  const onChangeText = (onChange: Function) => {
    return (text: string) => {
      if (
        props.property === Property.phoneNumber &&
        new RegExp(/^\+[0-9]{1,4} ?$/).test(text)
      ) {
        // No phonenumber was given
        text = '';
      }

      if (text !== value) {
        setChangeDetected(true);
      } else {
        setChangeDetected(false);
      }
      onChange(text);
    };
  };

  const inputView = (onBlur: Function, onChange: Function) => {
    const inputProps = {
      testID: `${property}Input`,
      value: value,
      onBlur: () => onBlur(),
      onChangeText: onChangeText(onChange),
      zoderror: errors.item,
      showError: false,
      autoComplete,
    };
    return props.property === Property.phoneNumber ? (
      <ReparkPhoneInput {...inputProps} />
    ) : (
      <ReparkTextInput {...inputProps} />
    );
  };

  const editButton = (
    <ReparkIconButton
      testID="editIconButton"
      icon={'pencil'}
      color={'grey'}
      onPress={() => {
        setEditing(true);
      }}
      size={iconSize}
      style={styles.rightButton}
    />
  );

  const infoI = props.onInfoI ? (
    <ReparkIconButton
      icon="info"
      color={rptheme.colors.primary}
      onPress={() => {
        if (props.onInfoI) props.onInfoI();
      }}
      size={iconSize}
      style={styles.rightButton}
    />
  ) : null;

  const readingView = (
    <>
      <ReparkListItem
        testID={`${property}Item`}
        title={value}
        icon={icon}
        style={styles.listItem}
        right={() => (isEditable ? editButton : infoI)}
      />
    </>
  );

  const editingView = (
    <>
      <View style={styles.listItemEdit}>
        <Controller
          name="item"
          control={control}
          defaultValue={value}
          render={({ field: { onChange, onBlur } }) =>
            inputView(onBlur, onChange)
          }
        ></Controller>
      </View>
      <View style={styles.saveCancelView}>
        <CancelConfirm
          onConfirm={handleSubmit(
            async (formData) => {
              setEditing(false);
              const newValue = formData.item;
              const previousValue = value;
              if (changeDetected) {
                try {
                  setValue(newValue);
                  const data = await save(newValue);
                  props.onSuccess && props.onSuccess(data);
                } catch (e: any) {
                  setValue(previousValue);
                  console.log(e);
                  props.onError
                    ? props.onError(e)
                    : fireSnack({
                        title: t('General.errors.GenericErrorTitle'),
                        message: e.message,
                        type: 'error',
                      });
                }
              }
            },
            async () => {
              if (errors.item?.message) {
                fireSnack({
                  message: errors.item.message,
                  type: 'error',
                });
              }
            }
          )}
          onCancel={() => setEditing(false)}
          style={styles.rightButton}
        />
      </View>
    </>
  );

  return (
    <View style={styles.row}>{isEditing ? editingView : readingView}</View>
  );
}

const styles = StyleSheet.create({
  listItem: {
    flex: 1,
    justifyContent: 'center',
  },
  listItemEdit: { flex: 1, paddingLeft: 16 },
  saveCancelView: { flexDirection: 'row' },
  row: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: 5,
    marginTop: 2,
    height: 50,
  },
  errorText: {
    color: rptheme.colors.error,
    marginTop: 5,
    textAlign: 'center',
  },
  rightButton: {
    marginTop: 12,
  },
});
