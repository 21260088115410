import React from 'react';
import { TouchableOpacity, StyleSheet } from 'react-native';
import { Portal } from 'react-native-paper';
import rptheme from '~/rptheme';
import LoadingState from './LoadingState';

type ModalProps = {
  isLoading: boolean;
  useReparkLogo?: boolean;
};

export const ReparkLoaderWithOverlay = (props: ModalProps) => {
  const { isLoading: isOpen, useReparkLogo } = props;

  return (
    <>
      {isOpen && (
        <Portal>
          <TouchableOpacity style={styles.overlay} activeOpacity={1}>
            <LoadingState noLogo={!useReparkLogo} />
          </TouchableOpacity>
        </Portal>
      )}
    </>
  );
};

const styles = StyleSheet.create({
  overlay: {
    position: 'absolute',
    backgroundColor: rptheme.colors.overlay,
    height: '100%',
    width: '100%',
    justifyContent: 'center',
    alignContent: 'center',
    paddingBottom: rptheme.fab.verticalMargin + 50,
  },
  logoFrame: {
    position: 'absolute',
    top: '50%',
    alignSelf: 'center',
  },
});
