import React from 'react';
import { View, StyleSheet, Text } from 'react-native';
import { Card, Title } from 'react-native-paper';
import { CenteredAppView } from '~/src/components/CenteredAppView';
import { NavProp, RootScreens } from '../navigation';
import { Auth } from '../authentication/Auth';
import { AuthStore } from '../authentication/AuthStore';
import { SnackbarStore } from '../snackbar/SnackbarStore';
import { VehicleStore } from '../vehicles/VehicleStore';
import { SubscriptionStore } from './SubscriptionStore';
import { ListItem } from '~/src/components/ListItem';
import { SubscriptionProgressBar } from './components/SubscriptionProgressBar';
import { SubscriptionsStackScreens } from './SubscriptionStackNavigation';
import { capitalize, formatFloor } from '~/src/utils/helpers';
import { feathersClient } from '~/src/services/feathers';
import { ContinueNextButtons } from './components/ContinueNextButtons';
import { findPriceByPriceIdInParkingSpot } from './helpers';
import { Trans, useTranslation } from 'react-i18next';
import { GarageType } from '../garage/types';
import rptheme from '~/rptheme';
import { Link } from '@react-navigation/native';
import config from '~/config/config';
import LoadingState from '~/src/components/LoadingState';

type DataConfirmScreenScreenProps = {
  navigation: NavProp;
  route: any;
};

export const DataConfirmScreen = (props: DataConfirmScreenScreenProps) => {
  const { t } = useTranslation();
  const { navigation } = props;
  const [loggedIn] = AuthStore((store) => [store.loggedIn]);

  const [
    selectedPriceId,
    selectedStartDate,
    createSubscription,
    selectedParkingSpotId,
    selectedPaymentMethod,
  ] = SubscriptionStore((store) => [
    store.selectedPriceId,
    store.selectedStartDate,
    store.createSubscription,
    store.selectedParkingSpotId,
    store.selectedPaymentMethod,
  ]);

  const [garage, setGarage] = React.useState<GarageType | null>(null);

  const [fireSnack] = SnackbarStore((store) => [store.fireSnack]);
  const [selectedVehicle] = VehicleStore((store) => [store.selectedVehicle]);
  const [user] = AuthStore((store) => [store.user]);
  const [isLoading, setIsLoading] = React.useState(false);

  const subscribe = async () => {
    try {
      setIsLoading(true);
      const subscription = await createSubscription();
      navigation.replace(SubscriptionsStackScreens.SubscriptionDetail, {
        id: subscription.id,
        isNew: true,
      });
    } catch (e: any) {
      let title, message;
      switch (e.name) {
        case 'CannotHaveMultipleSubscriptions':
          title = 'Abonnement bereits vorhanden';
          message =
            'Du hast bereits ein Abonement. Bitte kündige dieses zuerst.';
          break;

        default:
          title = t('General.errors.GenericErrorTitle');
          message = t('General.errors.GenericErrorMessage');
          break;
      }

      fireSnack({
        title,
        message,
        type: 'error',
      });
    } finally {
      setIsLoading(false);
    }
  };

  const fetchGarage = async () => {
    try {
      return await feathersClient
        .service('garages')
        .get(props.route.params.garageId);
    } catch (e) {
      console.error(e);
      navigation.navigate(RootScreens.MapScreen);
    }
  };

  React.useEffect(() => {
    if (!selectedPriceId || selectedVehicle === null) {
      navigation.replace(RootScreens.SubscriptionStack, {
        screen: SubscriptionsStackScreens.ChooseParkingSpot,
        params: {
          garageId: props.route.params.garageId,
        },
      });
    } else {
      fetchGarage().then((res) => {
        setGarage(res);
      });
    }
  }, []);

  const parkingSpot = garage?.parkingSpots.find(
    (parkingSpot) => parkingSpot.id === selectedParkingSpotId
  );

  if (!parkingSpot || !selectedPriceId) return null;

  const price = findPriceByPriceIdInParkingSpot(
    parkingSpot as any,
    selectedPriceId
  );
  if (!price) return null;

  const listItems = [
    {
      showCondition: true,
      label: t('SubscriptionCheckout.garageNameLabel'),
      value: garage?.name,
    },

    {
      showCondition: true,
      label: t('SubscriptionCheckout.addressLabel'),
      value: garage?.addressString,
    },
    {
      showCondition: true,
      label: t('SubscriptionCheckout.vendorLabel'),
      value: garage?.company.name,
    },
    {
      showCondition: true,
      label: t('SubscriptionCheckout.parkingSpotLabel'),
      value: `${parkingSpot?.name}, ${formatFloor(parkingSpot?.floor)}`,
    },
    {
      showCondition: true,
      label: t('SubscriptionCheckout.startDateLabel'),
      value: selectedStartDate?.format('DD.MM.YYYY'),
    },
    {
      showCondition: true,
      label: t('SubscriptionCheckout.licencePlateLabel'),
      value: selectedVehicle?.licencePlate,
    },
    {
      showCondition: true,
      label: t('SubscriptionCheckout.tenantLabel'),
      value: `${user.firstName} ${user.lastName}`,
    },
    {
      showCondition: true,
      label: t('SubscriptionCheckout.emailLabel'),
      value: user.email,
    },
    {
      showCondition: true,
      label: t('SubscriptionCheckout.phoneNumberLabel'),
      value: user.phoneNumber,
    },
    {
      showCondition: Boolean(user.userCompanyName),
      label: t('SubscriptionCheckout.companyLabel'),
      value: user.userCompanyName,
    },
    {
      showCondition: Boolean(user.vatId),
      label: t('SubscriptionCheckout.vatIdLabel'),
      value: user.vatId,
    },
  ];

  const priceListItems = [
    {
      showCondition: true,
      label: 'Intervall',
      value: capitalize(
        t(`subscription.billed.X${price.interval}ly`, {
          count: price.interval_count,
        })
      ),
    },
    {
      showCondition: true,
      label: 'Preis',
      value: `${price?.unit_amount && price?.unit_amount / 100}€`,
    },
    {
      showCondition: true,
      label: 'Zahlungsmethode',
      value: `${capitalize(selectedPaymentMethod?.type || '')}`,
    },
  ];

  if (!loggedIn)
    return (
      <CenteredAppView>
        <Auth goBackFallBack="Map" navigation={navigation} redirect={false} />
      </CenteredAppView>
    );

  if (isLoading) return <LoadingState />;

  return (
    <CenteredAppView>
      <SubscriptionProgressBar progress={0.9} navigation={navigation} />

      <View style={styles.container}>
        <Title>{t('Subscription.steps.confirm.title')}</Title>
        <Text>{t('Subscription.steps.confirm.text')}</Text>

        <Card style={{ padding: 15, marginVertical: 15 }}>
          {listItems.map((item, index) => {
            return (
              <View key={index} style={{ marginVertical: 3 }}>
                {item.showCondition && (
                  <ListItem label={item.label} value={item.value} />
                )}
              </View>
            );
          })}
        </Card>

        <Card style={{ padding: 15, marginVertical: 15 }}>
          {priceListItems.map((item, index) => {
            return (
              <View key={index} style={{ marginVertical: 3 }}>
                {item.showCondition && (
                  <ListItem label={item.label} value={item.value} />
                )}
              </View>
            );
          })}
        </Card>

        <View>
          <Text style={styles.legalText}>
            <Trans>
              <Trans>
                Es gelten die{' '}
                <Link
                  to={{
                    screen: 'WebView',
                    params: {
                      url: config().webPages.rentalConditions,
                    },
                  }}
                  style={{ textDecorationLine: 'underline' }}
                >
                  Mietbedingungen
                </Link>{' '}
                und{' '}
                <Link
                  to={{
                    screen: 'WebView',
                    params: {
                      url: config().webPages.gdpr,
                    },
                  }}
                  style={{ textDecorationLine: 'underline' }}
                >
                  Datenschutzerklärung
                </Link>
                . Das Abonnement kann bis zu 24 Stunden vor Ende des gewählten
                Intervalls ordentlich gekündigt werden. Mit Ablauf des gewählten
                Intervalls verlängert sich das Abonnement automatisch um die
                ausgewählte Dauer.
              </Trans>
            </Trans>
          </Text>
        </View>

        <ContinueNextButtons
          onBack={() => navigation.goBack()}
          onContinue={subscribe}
          nextDisabled={false}
          nextLabel={t('Subscription.steps.confirm.subscribeButtonLabel')}
        />
      </View>
    </CenteredAppView>
  );
};

const styles = StyleSheet.create({
  container: { marginTop: 20, marginHorizontal: 20 },
  legalText: {
    fontSize: 14,
    color: rptheme.colors.gray,
    marginBottom: 15,
  },
});
