import * as Linking from 'expo-linking';

const prefix = Linking.createURL('/');

const CompanyLinking = {
  path: 'companies',
  screens: {
    CompanyOverview: {
      path: '',
    },
    CompanyDetail: {
      path: ':id',
    },
  },
};

const ProfileLinking = {
  path: 'profile',
  screens: {
    Profile: {
      path: '',
    },
    Settings: {
      path: 'settings',
    },
    PersonalDataScreen: {
      path: 'personal-data',
    },
    ShowVehiclesScreen: {
      path: 'vehicles',
    },
    EditVehicleScreen: {
      path: 'vehicles/:vehicleId',
    },
    AddVehicleScreen: {
      path: 'vehicles/add',
    },
    ShowPaymentMethodsScreen: {
      path: 'payment-methods',
    },
    CompanyStack: CompanyLinking,
  },
};

const SubscriptionLinking = {
  path: 'subscriptions',
  screens: {
    SubscriptionOverview: {
      path: '',
    },
    SubscriptionDetail: {
      path: ':id',
    },
    SubscriptionPay: {
      path: ':id/pay',
    },
    ChooseParkingSpot: {
      path: 'subscribe/:garageId',
    },
    DataInput: {
      path: 'subscribe/:garageId/data',
    },
    DataConfirm: {
      path: 'subscribe/:garageId/confirm',
    },
    SubscriptionInvoices: {
      path: 'invoices/:subscriptionId',
    },
    InspectionScreen: {
      path: 'subscribe/:garageId/inspection',
    },
  },
};

export const linking = {
  prefixes: [prefix, 'repark://'],
  config: {
    screens: {
      Map: {
        path: '',
      },
      GarageOpener: {
        path: 'my-garages',
      },
      ProfileStack: ProfileLinking,
      BookingStack: {
        path: 'bookings',
        screens: {
          BookingOverview: {
            path: '',
          },
          BookingDetail: {
            path: ':id',
          },
        },
      },
      AboutStack: {
        path: 'about',
        screens: {
          About: {
            path: '',
          },
          AboutWebView: {
            path: 'detail',
          },
        },
      },
      SubscriptionStack: SubscriptionLinking,
      DebugStack: {
        path: 'debug',
        screens: {
          DebugOverview: {
            path: '',
          },
          DebugLocationScreen: {
            path: 'location',
          },
          DebugClientScreen: {
            path: 'client',
          },
          DebugSubscriptions: {
            path: 'subscriptions',
          },
        },
      },
      Login: {
        path: '/login',
      },
      ClaimAccess: {
        path: '/claim',
      },
      WebView: {
        path: '/web',
      },
      Checkout: {
        path: '/checkout',
      },
      404: '*',
      Onboarding: {
        path: '/welcome',
      },
      VerifyEmailAddressScreen: {
        path: '/verify/:otc',
      },
      Nearby: {
        path: '/nearby',
      },
    },
  },
};
