import * as React from 'react';
import { StyleSheet, View, KeyboardAvoidingView, Platform } from 'react-native';
import { Paragraph, Title } from 'react-native-paper';
import rptheme from '~/rptheme';
import ClaimAccessForm from './ClaimAccessForm';
import { getParkingSpotsToClaim } from '~/src/services/parkingSpotService';
import { GarageStore } from '../garage/GarageStore';
import { Auth } from '../authentication/Auth';
import { AuthStore } from '../authentication/AuthStore';
import ClaimAccessFallback from './ClaimAccessFallback';
import { t } from 'i18next';
import { RootScreens } from '../navigation';

export default function ClaimAccessScreen({ navigation, route }: any) {
  const getGarage = GarageStore((store) => store.getGarage);
  const loggedIn = AuthStore((store) => store.loggedIn);
  const garageIdToClaim = route.params?.id || null;
  const [spots, setSpots] = React.useState([]);

  React.useEffect(() => {
    const doAsync = async () => {
      const response: any = await getParkingSpotsToClaim(garageIdToClaim);
      setSpots(response);
    };
    if (garageIdToClaim) doAsync();
  }, []);

  if (!loggedIn) return <Auth goBackFallBack="Map" navigation={navigation} />;
  if (!garageIdToClaim || !getGarage(garageIdToClaim))
    return <ClaimAccessFallback />;

  return (
    <KeyboardAvoidingView
      style={styles.container}
      behavior={Platform.OS === 'ios' ? 'padding' : undefined}
    >
      <View style={styles.textContainer}>
        <Title>{t('ClaimAccessScreen.title')}</Title>
        <Paragraph>in {getGarage(garageIdToClaim).addressString}</Paragraph>
      </View>
      <ClaimAccessForm
        parkingSpots={spots}
        navigation={navigation}
        onSuccess={() => navigation.navigate(RootScreens.GarageOpenerScreen)}
      />
    </KeyboardAvoidingView>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'flex-start',
    padding: 25,
    maxWidth: rptheme.screenSmall,
    alignSelf: 'center',
    width: '100%',
  },
  textContainer: {
    marginBottom: 10,
  },
});
