import React from 'react';
import { View, Text, StyleSheet } from 'react-native';
import rptheme from '~/rptheme';

export type OpenGarageAlternativeInfoProps = {
  accessType: 'open-entrance' | 'manual-opening';
  t: Function;
};

export const OpenGarageAlternativeInfo = (
  props: OpenGarageAlternativeInfoProps
) => {
  const { accessType, t } = props;

  let title, description;

  React.useEffect(() => {
    console.log('render alternatve info');
  }, []);

  switch (accessType) {
    case 'open-entrance':
      title = t('BookingDetailScreen.OpenEntranceTitle');
      description = t('BookingDetailScreen.OpenEntranceDescription');
      break;
    case 'manual-opening':
      title = t('BookingDetailScreen.ManualOpeningTitle');
      description = t('BookingDetailScreen.ManualOpeningDescription');
      break;
  }

  return (
    <View testID={`${accessType}-Info`} style={styles.container}>
      <Text style={styles.title}>{title}</Text>
      <Text style={styles.description}>{description}</Text>
    </View>
  );
};

const styles = StyleSheet.create({
  container: { marginBottom: 25, alignItems: 'center' },
  title: {
    fontSize: 16,
    color: rptheme.colors.primary,
    fontWeight: 'bold',
    textAlign: 'center',
  },
  description: { textAlign: 'center' },
});
