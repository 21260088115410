import * as React from 'react';
import { createStackNavigator } from '@react-navigation/stack';
import { useTranslation } from 'react-i18next';
import HeaderLeft from '~/src/components/HeaderLeft';
import ProfileScreen from '~/src/features/user-profile/ProfileScreen';
import SettingsScreen from '~/src/features/user-profile/SettingsScreen';
import { headerTitleAlign } from '~/src/features/navigation/helpers';
import { PersonalDataScreen } from './personal-data/PersonalDataScreen';
import { VehiclesOverviewScreen } from '../vehicles/VehiclesOverviewScreen';
import { VehicleEditScreen } from '../vehicles/VehicleEditScreen';
import rptheme from '~/rptheme';
import ReparkIconButton from '~/src/components/ReparkIconButton';
import { VehicleStore } from '../vehicles/VehicleStore';
import { PaymentMethodsScreen } from '../payment/PaymentMethodsScreen';
import { AuthStore } from '../authentication/AuthStore';
import { RootScreens } from '../navigation';
import CompanyStackNavigation from '../companies/CompanyStackNavigation';

const Stack = createStackNavigator();

export enum ProfileStackScreenNames {
  ProfileScreen = 'Profile',
  PersonalDataScreen = 'PersonalDataScreen',
  ShowVehiclesScreen = 'ShowVehiclesScreen',
  EditVehicleScreen = 'EditVehicleScreen',
  AddVehicleScreen = 'AddVehicleScreen',
  ShowPaymentMethodsScreen = 'ShowPaymentMethodsScreen',
  SettingsScreen = 'Settings',
  CompanyStack = 'CompanyStack',
}

export default function ProfileStackNavigation(props: any) {
  const { navigation, initialRouteName } = props;
  const headerLeft = HeaderLeft.bind(navigation);
  const [vehicles, maxVehicles] = VehicleStore((store) => [
    store.vehicles,
    store.maxVehicles,
  ]);
  const loggedIn = AuthStore((store) => store.loggedIn);
  const { t } = useTranslation();
  return (
    <Stack.Navigator
      initialRouteName={
        initialRouteName || ProfileStackScreenNames.ProfileScreen
      }
      screenOptions={{
        headerTitleAlign: headerTitleAlign(),
        headerShown: true,
        headerTransparent: true,
        headerBackTitleVisible: false,
      }}
    >
      <Stack.Screen
        name={ProfileStackScreenNames.ProfileScreen}
        component={ProfileScreen}
        options={{
          title: '',
          headerLeft: (props: any) => {
            props.fallBackScreen = RootScreens.MapScreen;
            return headerLeft(props);
          },
          headerRight: () => <></>,
        }}
      ></Stack.Screen>

      {loggedIn && (
        <>
          <Stack.Screen
            name={ProfileStackScreenNames.PersonalDataScreen}
            options={{
              title: t('PersonalDataScreen.title'),
              headerLeft: (props: any) => {
                props.fallBackScreen = ProfileStackScreenNames.ProfileScreen;
                return headerLeft(props);
              },
            }}
          >
            {(props) => <PersonalDataScreen {...props} />}
          </Stack.Screen>

          <Stack.Screen
            name={ProfileStackScreenNames.ShowVehiclesScreen}
            component={VehiclesOverviewScreen}
            options={{
              title: t('ProfileScreen.Vehicles'),
              headerLeft: (props: any) => {
                props.fallBackScreen = ProfileStackScreenNames.ProfileScreen;
                return headerLeft(props);
              },
              headerRight: () => {
                return (
                  <ReparkIconButton
                    testID={'addVehicle'}
                    disabled={vehicles.length >= maxVehicles}
                    icon={'add'}
                    color={rptheme.colors.primary}
                    size={25}
                    onPress={() => {
                      navigation.navigate(
                        ProfileStackScreenNames.AddVehicleScreen
                      );
                    }}
                  />
                );
              },
            }}
          ></Stack.Screen>

          <Stack.Screen
            name={ProfileStackScreenNames.EditVehicleScreen}
            options={{
              title: t('EditVehicleScreen.title'),
              headerLeft: (props: any) => {
                props.fallBackScreen =
                  ProfileStackScreenNames.ShowVehiclesScreen;
                return headerLeft(props);
              },
            }}
          >
            {(props) => <VehicleEditScreen {...props} />}
          </Stack.Screen>

          <Stack.Screen
            name={ProfileStackScreenNames.AddVehicleScreen}
            options={{
              title: t('AddVehiclesScreen.title'),
              headerLeft: (props: any) => {
                props.fallBackScreen =
                  ProfileStackScreenNames.ShowVehiclesScreen;
                return headerLeft(props);
              },
            }}
          >
            {(props) => {
              return <VehicleEditScreen {...props} />;
            }}
          </Stack.Screen>

          <Stack.Screen
            name={ProfileStackScreenNames.ShowPaymentMethodsScreen}
            component={PaymentMethodsScreen}
            options={{
              title: t('PaymentMethodsScreen.title'),
              headerLeft: (props: any) => {
                props.fallBackScreen = ProfileStackScreenNames.ProfileScreen;
                return headerLeft(props);
              },
            }}
          ></Stack.Screen>

          <Stack.Screen
            name={ProfileStackScreenNames.SettingsScreen}
            component={SettingsScreen}
            options={{
              title: t('ProfileScreen.SettingsScreen.screenTitle'),
              headerLeft: (props: any) => {
                props.fallBackScreen = ProfileStackScreenNames.ProfileScreen;
                return headerLeft(props);
              },
            }}
          />

          <Stack.Screen
            name={ProfileStackScreenNames.CompanyStack}
            component={CompanyStackNavigation}
            options={{
              title: '',
              headerLeft: (props: any) => {
                props.fallBackScreen = ProfileStackScreenNames.ProfileScreen;
                return headerLeft(props);
              },
            }}
          />
        </>
      )}
    </Stack.Navigator>
  );
}
