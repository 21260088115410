import React from 'react';
import { StyleSheet, View } from 'react-native';
import { Modal } from '../../modal/Modal';
import { Title, Text, Paragraph, Divider } from 'react-native-paper';
import rptheme from '~/rptheme';
import ReparkButton from '~/src/components/ReparkButton';
import { formatDimensions } from '~/src/utils/helpers';
import { ReparkIcon } from '~/src/components/ReparkIcon';
import { t } from 'i18next';
import { garageContainsLiftingPlatform } from '../../garage/helpers';
import { GarageType } from '../../garage/types';

type GarageCheckoutInfoModalProps = {
  garage: GarageType;
};

export const GarageCheckoutInfoModal = (
  props: GarageCheckoutInfoModalProps
) => {
  const { garage } = props;

  const [isOpen, setIsOpen] = React.useState(false);

  const garages = [
    // 'GRG-378d5a91-8eb9-464d-bb5d-087d22d5597c', // Some Garage on Bennis Dev
    'GRG-1de83a3a-f0c7-4f1d-a6d7-4b72b3ead638', // U6 Center Test on Staging
    'GRG-2675f245-aaa2-46a2-b0f2-e55033d2dc85', // Gonzagasse / Schottenring on Production
    'GRG-f39a2df1-b06f-423a-aaf4-0245618bdc6b', // Michelbeuern on Production
    'GRG-c8694bec-0f8b-45b7-a78b-d48e57c56a0b', // Diefenbachgasse on Production
    'GRG-3c64905e-eeb6-4a63-8a68-4a49044d453f', // Jägerstraße on Production
    'GRG-95872d35-43f9-4a33-8bb3-d61d31c9c586', // Anna-Boschek-Platz on Production
  ];

  React.useEffect(() => {
    if (garages.includes(garage.id) || garageContainsLiftingPlatform(garage)) {
      setIsOpen(true);
    }
  }, []);

  const items = [
    {
      showCondition: Boolean(garage.dimensions?.height),
      icon: 'height',
      text: t('GarageCheckoutInfoModal.heightInfo', {
        height: formatDimensions(garage.dimensions?.height),
      }),
    },
    {
      showCondition: true,
      icon: 'logo',
      text: t('GarageCheckoutInfoModal.parkingSpots'),
    },
  ];

  return (
    <Modal isOpen={isOpen} onClickOutside={() => {}}>
      <View style={styles.container}>
        <View style={styles.content}>
          <Text style={styles.centeredText}>
            {t('GarageCheckoutInfoModal.title')}
          </Text>
          <Title style={styles.centeredText}>{garage.name}</Title>
          <Paragraph>{t('GarageCheckoutInfoModal.intro')}</Paragraph>

          <View style={{ marginTop: 10 }}>
            <Divider />

            {items
              .filter((item) => item.showCondition)
              .map((item) => (
                <View key={item.icon}>
                  <View style={styles.itemContainer}>
                    <View style={styles.iconContainer}>
                      <ReparkIcon
                        style={styles.icon}
                        size={25}
                        name={item.icon}
                      />
                    </View>
                    <View style={{ flex: 1 }}>
                      <Paragraph>{item.text}</Paragraph>
                    </View>
                  </View>
                  <Divider />
                </View>
              ))}
          </View>

          <Paragraph style={{ marginVertical: 10 }}>
            {t('GarageCheckoutInfoModal.outro')}
          </Paragraph>

          <ReparkButton onPress={() => setIsOpen(false)}>
            {t('GarageCheckoutInfoModal.accept')}
          </ReparkButton>
        </View>
      </View>
    </Modal>
  );
};

const styles = StyleSheet.create({
  container: {
    alignSelf: 'center',
    maxWidth: 500,
  },
  content: {
    margin: 20,
    backgroundColor: 'white',
    paddingBottom: 10,
    borderRadius: rptheme.roundness,
    padding: 10,
    ...rptheme.shadow,
    position: 'relative',
  },
  centeredText: {
    textAlign: 'center',
  },
  icon: { alignSelf: 'center' },
  itemContainer: {
    display: 'flex',
    flexDirection: 'row',
    marginVertical: 10,
  },
  iconContainer: {
    justifyContent: 'center',
    marginRight: 10,
    minWidth: 25,
  },
});
