// Calendar Picker from here: https://github.com/stephy/CalendarPicker
// It's the only one I found, that works cross-platform.

import React from 'react';
import { useTranslation } from 'react-i18next';
import { StyleSheet, View } from 'react-native';
import { Subheading } from 'react-native-paper';
import rptheme from '~/rptheme';
import ReparkButton from '~/src/components/ReparkButton';
import { Modal } from '../modal/Modal';
import { VehicleStore } from './VehicleStore';
import { NavProp } from '../navigation';
import { LicencePlateForm } from './LincencePlateInput';

type VehiclePickerProps = {
  navigation?: NavProp;
};

export const VehiclePicker = ({ navigation }: VehiclePickerProps) => {
  const { t } = useTranslation();
  const [modalIsOpen, setModalIsOpen] = React.useState(false);
  const [vehicles, selectVehicle, selectedVehicle] = VehicleStore((store) => [
    store.vehicles,
    store.selectVehicle,
    store.selectedVehicle,
  ]);

  return (
    <>
      <Modal isOpen={modalIsOpen} onClickOutside={() => {}}>
        <View style={styles.container}>
          <View style={styles.content}>
            <Subheading>{t('VehiclePicker.title')}</Subheading>
            {vehicles.map((vehicle) => (
              <ReparkButton
                icon={'car'}
                key={vehicle.id}
                onPress={() => {
                  selectVehicle(vehicle.id);
                  setModalIsOpen(false);
                }}
              >
                {vehicle.licencePlate}
              </ReparkButton>
            ))}

            <Subheading>{t('VehiclePicker.addNew')}</Subheading>

            <LicencePlateForm
              onSubmit={(vehicle) => {
                selectVehicle(vehicle.id);
                setModalIsOpen(false);
              }}
            />
          </View>
        </View>
      </Modal>
      {selectedVehicle && (
        <ReparkButton
          mode="outlined"
          onPress={() => setModalIsOpen(!modalIsOpen)}
          icon={'car'}
        >
          {selectedVehicle.licencePlate}
        </ReparkButton>
      )}
    </>
  );
};

const styles = StyleSheet.create({
  container: {
    maxWidth: rptheme.screenSmall,
    alignSelf: 'center',
  },
  content: {
    backgroundColor: 'white',
    paddingBottom: 30,
    borderRadius: rptheme.roundness,
    marginVertical: 50,
    marginHorizontal: 10,
    padding: 20,
    ...rptheme.shadow,
  },
});
