import React from 'react';
import { StyleSheet, Image, View, ScrollView } from 'react-native';
import { Title, Text } from 'react-native-paper';
import { OnboardingStepProps } from './OnboardingScreen';
import { AuthStore } from '../authentication/AuthStore';
import {
  ReparkCheckBoxStates,
  useCheckboxGroup,
} from '~/src/hooks/useCheckboxGroup';
import {
  ReparkCheckboxGroup,
  ReparkCheckboxItem,
} from '~/src/components/ReparkCheckboxGroup';
import rptheme from '~/rptheme';
import { ReparkTextInputSimple } from '~/src/components/ReparkTextInputSimple';
import { Controller, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { createOnboardingZodSchema } from '~/src/rules/inputValidationRules';
import { t } from 'i18next';
import ReparkButton from '~/src/components/ReparkButton';
import { SnackbarStore } from '../snackbar/SnackbarStore';

const initialStates: ReparkCheckBoxStates = {
  tv: false,
  flyer: false,
  referred: false,
  employer: false,
  adOnGarage: false,
  otherSites: false,
  socialMedia: false,
  google: false,
  googleMaps: false,
  other: false,
};

export const OnboardingFeedbackStep = (props: OnboardingStepProps) => {
  const { onNextButtonPress, buttonType } = props;
  const [user, editUser] = AuthStore((store) => [store.user, store.editUser]);
  const [otherInitValue, setOtherInitValue] = React.useState<any>(undefined);
  const inputRef = React.createRef<any>();
  const imageSource = rptheme.graphics.feedbackOnboarding;
  const {
    checkBoxStates,
    toggle,
    get,
    setAll,
    getTrueKeys,
    someChecked,
    isSomeChecked,
  } = useCheckboxGroup(initialStates);
  const [openOther, setOpenOther] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const [disableButton, setDisableButton] = React.useState(false);

  const fireSnack = SnackbarStore((store) => store.fireSnack);
  const {
    control,
    handleSubmit,
    formState: { errors, isDirty },
  } = useForm<{ feedback: string }>({
    resolver: zodResolver(createOnboardingZodSchema(t).feedback),
    defaultValues: { feedback: '' },
  });

  const applyUserData = () => {
    let k: string;
    for (k of Object.keys(initialStates)) {
      initialStates[k] = false;
    }
    if (!user.feedback || user.feedback === '') {
      return;
    }
    const trueKeys = user.feedback.split(',');
    if (trueKeys.length === 0) {
      return;
    }
    trueKeys.forEach((key: string) => {
      if (key.startsWith('other')) {
        const rx = new RegExp(/other\[(.*)\]/);
        const result = rx.exec(key);
        if (result) {
          setOtherInitValue(result[1]);
        }
        initialStates.other = true;
        setOpenOther(true);
      } else {
        initialStates[key] = true;
      }
    });
    setAll(initialStates);
    isSomeChecked(initialStates);
  };

  React.useEffect(() => {
    applyUserData();
  }, [user]);

  React.useEffect(() => {
    if (someChecked) {
      const truekeys = getTrueKeys();
      if (truekeys.length === 1 && truekeys[0] === 'other') {
        setDisableButton(!isDirty);
      } else {
        setDisableButton(!someChecked);
      }
    } else {
      setDisableButton(!someChecked);
    }
  }, [someChecked, isDirty]);

  const submit = handleSubmit(async (data) => {
    setIsLoading(true);
    const trueKeys: string[] = getTrueKeys();
    const otherIndex = trueKeys.indexOf('other');
    if (otherIndex > -1 && (data.feedback || data.feedback.length > 0)) {
      trueKeys[otherIndex] = `other[${data.feedback}]`;
    }
    const feedbackData = {
      feedback: trueKeys.length === 0 ? undefined : trueKeys.toString(),
    };
    const response = await editUser(feedbackData);
    if (!response.success) {
      const title = t('General.errors.GenericErrorTitle');
      const message = t('General.errors.GenericErrorMessage');
      fireSnack({
        title,
        message,
        type: 'error',
      });
      setIsLoading(false);
      return;
    }
    setIsLoading(false);

    onNextButtonPress();
  });

  const checkBoxGroupItems: ReparkCheckboxItem[] = [
    {
      key: 'adOnGarage',
      label: t('General.adOnGarage'),
      onPress: () => {
        toggle('adOnGarage');
      },
    },
    {
      key: 'flyer',
      label: t('General.flyer'),
      onPress: () => {
        toggle('flyer');
      },
    },
    {
      key: 'employer',
      label: t('General.employer'),
      onPress: () => {
        toggle('employer');
      },
    },
    {
      key: 'referred',
      label: t('General.referred'),
      onPress: () => {
        toggle('referred');
      },
    },
    {
      key: 'otherSites',
      label: t('General.otherSites'),
      onPress: () => {
        toggle('otherSites');
      },
    },
    {
      key: 'socialMedia',
      label: t('General.socialMedia'),
      onPress: () => {
        toggle('socialMedia');
      },
    },
    {
      key: 'google',
      label: t('General.google'),
      onPress: () => {
        toggle('google');
      },
    },
    {
      key: 'googleMaps',
      label: t('General.googleMaps'),
      onPress: () => {
        toggle('googleMaps');
      },
    },
    {
      key: 'other',
      label: t('General.other'),
      onPress: () => {
        const current = get('other');
        if (current === true) {
          setOtherInitValue(undefined);
        }
        setOpenOther(!current);
        toggle('other');
      },
    },
  ];

  React.useEffect(() => {
    if (openOther) {
      if (inputRef && inputRef.current) {
        inputRef.current.focus();
      }
    }
  }, [openOther]);
  return (
    <>
      <ScrollView>
        <Title testID={'title'}>
          {t('OnboardingScreen.FeedbackStep.title')}
        </Title>
        <Text testID="text" style={styles.text}>
          {t('OnboardingScreen.FeedbackStep.text')}
        </Text>
        <ReparkCheckboxGroup
          position="after"
          items={checkBoxGroupItems}
          states={checkBoxStates}
          size="medium"
        />
        {openOther && (
          <View style={styles.inputContainer}>
            <Controller
              name="feedback"
              control={control}
              render={({ field: { onChange } }) => (
                <ReparkTextInputSimple
                  ref={inputRef}
                  testID="otherInput"
                  onChangeText={(text: string) => onChange(text)}
                  autoComplete={false}
                  maxLength={32}
                  zoderror={errors.feedback}
                  autoCorrect={false}
                  spellCheck={false}
                  value={otherInitValue}
                  placeholder="..."
                />
              )}
            />
          </View>
        )}
        <Image testID="image" source={imageSource} style={styles.image} />
      </ScrollView>
      <ReparkButton
        loading={isLoading}
        disabled={isLoading || disableButton}
        testID="nextButton"
        onPress={submit}
        style={styles.nextButton}
      >
        {t(`General.${buttonType}`)}
      </ReparkButton>
    </>
  );
};

const styles = StyleSheet.create({
  text: {
    fontSize: 16,
    paddingBottom: 10,
  },
  image: {
    height: 190,
    resizeMode: 'contain',
    paddingTop: 10,
    paddingBottom: 10,
  },
  subtext: {
    fontSize: 14,
    paddingBottom: 8,
  },
  inputContainer: {
    alignSelf: 'flex-end',
    width: '83%',
  },
  nextButton: {
    position: 'absolute',
    bottom: 0,
    width: '100%',
  },
});
