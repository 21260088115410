import React from 'react';
import { StyleSheet, View } from 'react-native';
import { Title, Text } from 'react-native-paper';
import { useTranslation } from 'react-i18next';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';
import rptheme from '~/rptheme';
import { AuthStore } from '../authentication/AuthStore';
import { BottomSheetStore } from '../bottom-sheet/BottomSheetStore';
import ReparkListItem from '~/src/components/ReparkListItem';
import { DeleteAccountSheet } from './DeleteAccountSheet';
import ConfirmCancelSheet from '~/src/components/ConfirmCancelSheet';
import { SnackbarStore } from '../snackbar/SnackbarStore';
import { NavProp } from '../navigation';
import { ReparkCheckbox } from '~/src/components/ReparkCheckbox';
import { UserSettingsType } from '../authentication/authTypes';
import { ReparkLoaderWithOverlay } from '~/src/components/ReparkLoaderWithOverlay';

type SettingsScreenProps = {
  navigation: NavProp;
};

const checkAllowQuestions = (settings: UserSettingsType | null) => {
  if (
    !settings ||
    settings.allowQuestions === undefined ||
    settings.allowQuestions === null
  ) {
    return false;
  } else {
    return settings.allowQuestions;
  }
};

export default function SettingsScreen({ navigation }: SettingsScreenProps) {
  const { t } = useTranslation();
  const [loggedIn, settings, saveUserSettings] = AuthStore((store) => [
    store.loggedIn,
    store.settings,
    store.saveUserSettings,
  ]);

  const openSheet = BottomSheetStore((store) => store.openSheet);
  const [signOut, canDeleteAccount] = AuthStore((store) => [
    store.signOut,
    store.canDeleteAccount,
  ]);
  const fireSnack = SnackbarStore((store) => store.fireSnack);
  const closeSheet = BottomSheetStore((store) => store.closeSheet);
  const [isLoading, setIsLoading] = React.useState(false);

  const logout = async () => {
    openSheet(
      <ConfirmCancelSheet
        t={t}
        title={t('ProfileScreen.SignOutConfirmationText')}
        confirmLabel={t('AuthTexts.signOutButtonLabel')}
        onConfirm={async () => {
          if (await signOut()) {
            closeSheet();
            navigation.replace('Map');
          } else {
            fireSnack({
              title: t('General.errors.GenericErrorTitle'),
              message: t('General.errors.GenericErrorMessage'),
              type: 'error',
            });
          }
        }}
      />,
      { adjustToContentHeight: true }
    );
  };

  const listItems = [
    {
      showCondition: true,
      testID: 'allowQuestionsItem',
      title: (
        <ReparkCheckbox
          label={t('ProfileScreen.SettingsScreen.allowQuestions')}
          checked={checkAllowQuestions(settings)}
          onPress={async () => {
            setIsLoading(true);
            const response = await saveUserSettings({
              language: settings ? settings.language : 'de',
              allowQuestions: settings ? !settings?.allowQuestions : true,
            });
            setIsLoading(false);
            if (!response.success) {
              fireSnack({
                title: t('ProfileScreen.SettingsScreen.updateErrorTitle'),
                message: t('ProfileScreen.SettingsScreen.updateErrorMessage'),
                type: 'error',
              });
            }
          }}
          width={'90%'}
        />
      ),
      icon: 'bubbles',
    },
    {
      showCondition: true,
      testID: 'logOutListItem',
      onPress: logout,
      title: t('AuthTexts.signOutButtonLabel'),
      icon: 'logout',
    },
    // {
    //   showCondition: true,
    //   title: t('SettingsScreen.restartOnboardingLinkLabel'),
    //   icon: 'repark',
    //   onPress: () => navigation.navigate('Onboarding'),
    // },
  ];

  return (
    <>
      <KeyboardAwareScrollView
        style={styles.container}
        contentContainerStyle={styles.scrollContainer}
        extraHeight={100}
        extraScrollHeight={100}
        keyboardOpeningTime={600}
      >
        <ReparkLoaderWithOverlay isLoading={isLoading} />
        {loggedIn ? (
          <View style={styles.innerContainer}>
            <View>
              {listItems.map((item, key) => (
                <ReparkListItem
                  key={key}
                  testID={item.testID}
                  title={item.title}
                  icon={item.icon}
                  onPress={item.onPress}
                />
              ))}
            </View>
            <View>
              <Title style={styles.dangerZoneTitle}>
                {t('SettingsScreen.dangerZone')}
              </Title>

              {!canDeleteAccount() && (
                <View style={styles.cannotDeleteTextContainer}>
                  <Text>{t('SettingsScreen.cannotDeleteText')}</Text>
                </View>
              )}

              <ReparkListItem
                disabled={!canDeleteAccount()}
                testID={'deleteAccountButton'}
                title={t('General.deleteAccount')}
                icon={'warning'}
                iconColor={rptheme.colors.error}
                onPress={async () => {
                  openSheet(<DeleteAccountSheet navigation={navigation} />, {
                    adjustToContentHeight: true,
                  });
                }}
              />
            </View>
          </View>
        ) : (
          <Text>{t('General.loginToAccessPage')}</Text>
        )}
      </KeyboardAwareScrollView>
    </>
  );
}
const styles = StyleSheet.create({
  container: {
    marginTop: 100,
    maxWidth: 450,
    alignSelf: 'center',
    width: '100%',
  },
  innerContainer: { height: '100%', justifyContent: 'space-between' },
  scrollContainer: {
    flex: 1,
  },
  dangerZoneTitle: {
    color: rptheme.colors.error,
    margin: 20,
  },
  cannotDeleteTextContainer: {
    marginHorizontal: 20,
  },
});
