import axios from 'axios';
import config from '~/config/config';
import { feathersClient } from '../../services/feathers';

export const fetchVehiclesRequest = async (query?: any) => {
  const response = await feathersClient.service('vehicles').find({
    query: {
      ...query,
    },
  });
  return response.data;
};

export const createVehicleRequest = async (
  userId: string,
  vehicleData: any
) => {
  return await axios.post(`${config().server}/vehicles`, {
    userId: userId,
    licencePlate: vehicleData.licencePlate,
    countryOfOrigin: vehicleData.countryOfOrigin,
  });
};

export const patchVehicleRequest = async (vehicleData: any) => {
  return await axios.patch(`${config().server}/vehicles/${vehicleData.id}`, {
    licencePlate: vehicleData.licencePlate,
    countryOfOrigin: vehicleData.countryOfOrigin,
  });
};

export const deleteVehicleRequest = async (id: string) => {
  return await axios.delete(`${config().server}/vehicles/${id}`);
};
