import React from 'react';
import { ScrollView } from 'react-native';
import { StyleSheet } from 'react-native';
import rptheme from '~/rptheme';
import { CompanyStore } from './CompanyStore';
import LoadingState from '~/src/components/LoadingState';
import { CompanyStackScreens } from './CompanyStackNavigation';
import { ReparkList } from '~/src/components/ReparkList';

export default function CompanyOverviewScreen({ navigation }: any) {
  const [companies, fetchCompanies, isFetchingCompanies] = CompanyStore(
    (store) => [
      store.companies,
      store.fetchCompanies,
      store.isFetchingCompanies,
    ]
  );

  React.useEffect(() => {
    fetchCompanies();
  }, []);

  React.useEffect(() => {
    if (companies.length === 1) {
      navigation.navigate(CompanyStackScreens.CompanyDetailScreen, {
        id: companies[0].id,
      });
    }
  }, [isFetchingCompanies]);

  if (isFetchingCompanies) return <LoadingState />;

  return (
    <ScrollView
      contentContainerStyle={styles.container}
      keyboardDismissMode="on-drag"
      keyboardShouldPersistTaps="always"
    >
      <ReparkList
        items={companies.map((company) => {
          return {
            title: company.name,
            onPress: () =>
              navigation.navigate(CompanyStackScreens.CompanyDetailScreen, {
                id: company.id,
              }),
          };
        })}
      />
    </ScrollView>
  );
}
const styles = StyleSheet.create({
  container: {
    marginTop: 30,
    paddingTop: 30,
    flex: 1,
    maxWidth: rptheme.screenSmall,
    width: '100%',
    alignSelf: 'center',
  },
  bottomHeading: { color: 'grey', marginLeft: 25, marginTop: 30 },
});
