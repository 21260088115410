import React from 'react';
import { ScrollView, StyleSheet } from 'react-native';
import rptheme from '~/rptheme';

type CenteredAppViewProps = {
  children?: Array<JSX.Element> | JSX.Element;
};

export const CenteredAppView = (props: CenteredAppViewProps) => {
  const { children } = props;

  return <ScrollView style={styles.container}>{children}</ScrollView>;
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    maxWidth: rptheme.screenSmall,
    width: '100%',
    alignSelf: 'center',
  },
});
