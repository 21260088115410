import React from 'react';
import { Modalize } from 'react-native-modalize';
import { OfferBottom } from './OfferBottom';
import { AuthStore } from '../authentication/AuthStore';
import { GarageStore } from '../garage/GarageStore';
import { NavProp, RootScreens } from '../navigation';
import { GarageDescription } from '../garage/GarageDescription';
import { StyleSheet, View } from 'react-native';
import { OfferTop } from './OfferTop';
import analytics, { garageToGAItem } from '../analytics/analytics';
import {
  computeGarageFeatures,
  FeatureSwiper,
} from '~/src/components/FeatureSwiper';
import { ImageSwiper } from '~/src/components/ImageSwiper';
import { ReparkList } from '~/src/components/ReparkList';
import {
  canSubscribeInGarage,
  computeGarageOrientationInfo,
} from '../garage/helpers';
import { useTranslation } from 'react-i18next';
import { Title } from 'react-native-paper';
import { LinkSwiper, LinkSwiperLink } from '~/src/components/LinkSwiper';
import { TrackingStore } from '../tracking/trackingStore';
import rptheme from '~/rptheme';
import { createOfferLinks } from './helpers';
import { InfoBanner } from '~/src/components/InfoBanner';
import { mdiNewBox } from '@mdi/js';
import { BottomSheetStore } from '../bottom-sheet/BottomSheetStore';
import { SubscriptionsStackScreens } from '../subscriptions/SubscriptionStackNavigation';

export const SheetHeight = () => {
  const loggedIn = AuthStore.getState().loggedIn;
  return loggedIn ? 320 : 480;
};

export const offerSheetOptions = (
  navigation: NavProp
): React.ComponentProps<typeof Modalize> => {
  return {
    overlayStyle: { backgroundColor: 'transparent' },
    closeSnapPointStraightEnabled: false,
    FloatingComponent: <OfferBottom navigation={navigation} />,
    HeaderComponent: <OfferTop />,
    snapPoint: SheetHeight(),
  };
};

export const OfferSheet = ({ navigation }: { navigation: NavProp }) => {
  const { t } = useTranslation();
  const [selectedGarageId, selectedDate, getGarage] = GarageStore((store) => [
    store.selectedGarageId,
    store.offerStart,
    store.getGarage,
  ]);

  const [user] = AuthStore((store) => [store.user]);
  const [trackOfferView] = TrackingStore((store) => [store.trackOfferView]);
  const [closeSheet] = BottomSheetStore((store) => [store.closeSheet]);

  if (!selectedGarageId) return <></>;

  React.useEffect(() => {
    analytics.track('view_item', {
      value: getGarage(selectedGarageId).rate / 100,
      currency: 'EUR',
      items: [garageToGAItem(getGarage(selectedGarageId))],
    });
    navigation.setParams({ id: getGarage(selectedGarageId).id });
    //repark tracking here
    trackOfferView(user, getGarage(selectedGarageId)).then();
    return () => {
      navigation.setParams({ id: undefined });
    };
  }, []);

  const garageOrientationInfos = computeGarageOrientationInfo(
    getGarage(selectedGarageId),
    t
  );

  const links = createOfferLinks({
    navigation,
    user,
    garage: getGarage(selectedGarageId),
  });

  return (
    <View style={styles.container}>
      <View style={{ ...rptheme.shadow, marginBottom: 15 }}>
        <FeatureSwiper
          features={computeGarageFeatures(
            getGarage(selectedGarageId),
            selectedDate
          )}
        />
      </View>

      {canSubscribeInGarage(getGarage(selectedGarageId)) && (
        <View style={styles.textContainer}>
          <InfoBanner
            icon={mdiNewBox}
            title={t('garage.subscriptionAd.title')}
            text={t('garage.subscriptionAd.text')}
            actions={[
              {
                label: t('garage.subscriptionAd.buttonLabel'),
                onPress: () => {
                  closeSheet();
                  navigation.navigate(RootScreens.SubscriptionStack, {
                    screen: SubscriptionsStackScreens.ChooseParkingSpot,
                    params: {
                      garageId: selectedGarageId,
                    },
                  });
                },
              },
            ]}
          />
        </View>
      )}
      <ImageSwiper images={getGarage(selectedGarageId).images} />
      <View style={styles.textContainer}>
        <GarageDescription garage={getGarage(selectedGarageId)} />
      </View>

      <View style={styles.textContainer}>
        <Title style={{ fontSize: 16 }}>{t('garage.orientation.title')}</Title>
      </View>
      <ReparkList
        items={garageOrientationInfos}
        titleStyle={styles.orientationItemStyle}
      />

      {getGarage(selectedGarageId)?.links?.filter(
        (link: LinkSwiperLink) =>
          link.showInOffer && link.image && link.title && link.description
      ).length > 0 && (
        <>
          <View style={styles.textContainer}>
            <Title style={{ fontSize: 16 }}>
              {t('garage.linkSectionTitle')}
            </Title>
          </View>
          <LinkSwiper links={getGarage(selectedGarageId).links} />
        </>
      )}

      <View style={styles.textContainer}>
        <Title style={{ fontSize: 16 }}>{t('general.moreLinks')}</Title>
      </View>
      <ReparkList
        items={links}
        titleStyle={styles.orientationItemStyle}
        dense={true}
      />
    </View>
  );
};

const styles = StyleSheet.create({
  container: { marginBottom: 150 },
  textContainer: { margin: 10 },
  orientationItemStyle: { fontSize: 14 },
});
