import { feathersClient } from '~/src/services/feathers';
import * as authFirebase from '~/src/features/authentication/services/authFirebase';
import {
  SignInValuesType,
  ValidateOTCRequestType,
  EditProfileType,
} from '~/src/features/authentication/authTypes';

import config from '~/config/config';
import axios from 'axios';
import { envIsDev } from '~/src/utils/helpers';
import Bugsnag from '@bugsnag/js';

export const logout = async () => {
  try {
    await feathersClient.logout();
  } catch (e: any) {
    console.log(e);
    return false;
  }
  return true;
};

export const authenticateLocal = async (signInData: SignInValuesType) => {
  console.log('Authenticating local');
  const response = await feathersClient.authenticate({
    email: signInData.email,
    password: signInData.password,
    strategy: 'local',
  });

  // feathers stores the token by itself
  // (see configuration in ~/services/feathers.tsx)

  return response;
};

export const refreshIdToken = async (refreshToken: string) => {
  console.log('Refresh token');
  const body = {
    grant_type: 'refresh_token',
    refresh_token: refreshToken,
  };

  const refreshUrl = envIsDev()
    ? `${
        config().firebaseDev.authEmulator
      }/securetoken.googleapis.com/v1/token?key=${config().firebase.apiKey}`
    : `https://securetoken.googleapis.com/v1/token?key=${
        config().firebase.apiKey
      }`;
  const response = await axios.post(refreshUrl, body);
  if (response.status === 200) {
    const accessToken = response.data.access_token;
    return accessToken;
  }
  throw new Error('Could not refresh access token');
};

export const reauthenticate = async (authState: any) => {
  console.log('Reauthenticating with JWT');
  let newAuthState;

  try {
    // feathers checks for the token in store by itself
    // (see configuration in ~/services/feathers.tsx)
    const response = await feathersClient.reAuthenticate();
    if (response?.accessToken) {
      authState.accessToken = response.accessToken;
    }
    newAuthState = {
      ...authState,
      ...{ accessToken: response.accessToken },
      ...{ user: response.user },
      ...{ rules: response.rules },
    };
  } catch (e: any) {
    Bugsnag.notify(e);
    console.log(`Could not reauthenticate JWT: ${e.message}`);
    const refreshToken = authState.refreshToken;
    if (refreshToken) {
      console.log('Refreshing id token');
      const idToken = await refreshIdToken(refreshToken);
      console.log('Received new id token from Firebase');
      const response = await authFirebase.authenticateFeathers(idToken);
      newAuthState = {
        ...authState,
        ...{
          accessToken: response?.accessToken,
          idToken,
          user: response?.user,
          rules: response?.rules,
        },
      };
    } else {
      throw new Error('Could not refresh id token. No refresh token present.');
    }
  }

  console.log('Reauthentication successful');
  return newAuthState;
};

export const sendDeleteAccountOtcRequest = async (userId: string) => {
  return await axios.post(`${config().server}/authentication`, {
    userId,
    action: 'delete',
  });
};

export const deleteAccountRequest = async (
  userId: string,
  otc: string,
  phoneNumber: string
) => {
  return await axios.delete(
    `${
      config().server
    }/users/${userId}?otc=${otc}&phoneNumber=${encodeURIComponent(phoneNumber)}`
  );
};

export const validateOTCRequest = async (otcData: ValidateOTCRequestType) => {
  return await axios.post(`${config().server}/otcs`, {
    action: 'validate',
    otc: otcData.otc,
    phoneNumber: otcData.phoneNumber,
  });
};

export const patchUserRequest = async (
  userId: string,
  editedProfileData: EditProfileType
) => {
  return await axios.patch(`${config().server}/users/${userId}`, {
    email: editedProfileData.email || null,
    phoneNumber: editedProfileData.phoneNumber || null,
    lastName: editedProfileData.lastName,
    firstName: editedProfileData.firstName,
    settings: editedProfileData.settings,
    vatId: editedProfileData.vatId,
    userCompanyName: editedProfileData.userCompanyName,
    feedback: editedProfileData.feedback,
  });
};

export const checkUserExistence = async (phoneNumber: string) => {
  return (
    await axios.post(`${config().server}/authentication`, {
      phoneNumber: phoneNumber,
      action: 'check',
    })
  ).data;
};

export const verifyEmailRequest = async (otcData: ValidateOTCRequestType) => {
  return await axios.post(`${config().server}/otcs`, {
    action: 'verifyEmail',
    otc: otcData.otc,
  });
};

export const resendEmailVerificationRequest = async (userId: string) => {
  return await axios.patch(`${config().server}/users/${userId}`, {
    action: 'resendEmailVerification',
  });
};
