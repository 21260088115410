export const languageDetector: any = {
  type: 'languageDetector',
  async: true,
  detect: (cb: (arg0: string) => any) => {
    if (!window.navigator) {
      cb('en');
      return;
    }
    cb(window.navigator.language);
  },
  init: () => {},
  cacheUserLanguage: () => {},
};
