import { Link } from '@react-navigation/native';
import { t } from 'i18next';
import React from 'react';
import { Trans } from 'react-i18next';
import { Paragraph } from 'react-native-paper';
import rptheme from '~/rptheme';
import { formatCurrency } from '~/src/utils/helpers';
import { computeCancelationCosts } from '../../helpers';
import config from '~/config/config';
import { Text, StyleSheet } from 'react-native';

type LegalTextProps = {
  paymentAmount: number;
  companyName: string;
};

export const LegalText = (props: LegalTextProps) => {
  const { paymentAmount, companyName } = props;

  return (
    <Paragraph style={style.text}>
      <Trans>
        <Text>{t('Offer.vatIncluded')}</Text>
      </Trans>{' '}
      <Text>
        <Trans companyName={companyName}>
          VermieterIn: {{ companyName }}. HalterIn: HalterIn des o.g.
          Kennzeichens.
        </Trans>{' '}
      </Text>
      <Text testID="rentalConditionsText">
        <Trans>
          Es gelten die{' '}
          <Link
            to={{
              screen: 'WebView',
              params: {
                url: config().webPages.rentalConditions,
              },
            }}
            style={{ textDecorationLine: 'underline' }}
          >
            Mietbedingungen
          </Link>{' '}
          und{' '}
          <Link
            to={{
              screen: 'WebView',
              params: {
                url: config().webPages.gdpr,
              },
            }}
            style={{ textDecorationLine: 'underline' }}
          >
            Datenschutzerklärung
          </Link>
          .{' '}
        </Trans>
      </Text>
      <Text testID="cancelPriceTeext">
        {t('Offer.cancelationRate', {
          rate: formatCurrency(computeCancelationCosts(paymentAmount)),
        })}
      </Text>
    </Paragraph>
  );
};

const style = StyleSheet.create({
  cardSelect: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  text: {
    alignSelf: 'center',
    color: rptheme.colors.gray,
    fontSize: 12,
    lineHeight: 12 * 1.45, // So its the same as the stripe text that is added
  },
});
