import React from 'react';
import { doShowReasonOfBookingModal } from '../../feedback/ReasonOfBookingHelper';
import { AuthStore } from '../../authentication/AuthStore';
import { BookingStore } from '../BookingStore';

export const useReasonOfBooking = ({ booking }: { booking: any }) => {
  const user = AuthStore((store) => store.user);
  const [autofillRoB] = BookingStore((store) => [store.autofillRoB]);

  const [openReasonOfBookingModal, setOpenReasonOfBookingModal] =
    React.useState(false);
  const [keepReasonOfBookingModalHidden, setKeepReasonOfBookingModalHidden] =
    React.useState(false);

  React.useEffect(() => {
    if (booking) {
      if (keepReasonOfBookingModalHidden) {
        console.log('Keeping reason of booking closed');
        return;
      }
      setTimeout(() => {
        const show = doShowReasonOfBookingModal(user, booking);
        console.log('doShowReasonOfBookingModal:', show);
        if (show) {
          console.log('checking if autofill reason of booking applies');
          autofillRoB(booking).then((result) => {
            console.log(
              result
                ? 'reason of booking got autofilled'
                : 'reason of booking will be asked for'
            );
            setOpenReasonOfBookingModal(!result);
          });
        } else {
          console.log(
            'user does not want to be asked for reason of booking or booking has already the answer'
          );
          setOpenReasonOfBookingModal(false);
        }
      }, 800);
    }
  }, [booking]);

  return {
    openReasonOfBookingModal,
    setOpenReasonOfBookingModal,
    keepReasonOfBookingModalHidden,
    setKeepReasonOfBookingModalHidden,
  };
};
