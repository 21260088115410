import React from 'react';
import { View, StyleSheet } from 'react-native';
import rptheme from '~/rptheme';
import { Modal } from '../../modal/Modal';
import { Title } from 'react-native-paper';
import ReparkButton from '~/src/components/ReparkButton';
import { NavProp, RootScreens } from '../../navigation';
import { useTranslation } from 'react-i18next';

type SubscriptionAbortModalProps = {
  isOpen: boolean;
  onClose: () => void;
  navigation: NavProp;
};

export const SubscriptionAbortModal = (props: SubscriptionAbortModalProps) => {
  const { isOpen, onClose, navigation } = props;
  const { t } = useTranslation();

  function handleAbort() {
    onClose();
    navigation.navigate(RootScreens.MapScreen);
  }

  return (
    <Modal isOpen={isOpen} onClickOutside={onClose}>
      <View style={styles.container}>
        <View style={styles.content}>
          <Title>{t('SubscriptionAbortModal.title')}</Title>
          <View style={styles.buttonContainer}>
            <ReparkButton
              mode="outlined"
              style={[
                styles.button,
                { borderColor: rptheme.colors.primary, marginRight: 5 },
              ]}
              onPress={handleAbort}
            >
              {t('SubscriptionAbortModal.confirmButtonLabel')}
            </ReparkButton>
            <ReparkButton style={styles.button} onPress={onClose}>
              {t('SubscriptionAbortModal.cancelButtonLabel')}
            </ReparkButton>
          </View>
        </View>
      </View>
    </Modal>
  );
};

const styles = StyleSheet.create({
  container: {
    alignSelf: 'center',
    maxWidth: rptheme.screenSmall - 50,
  },
  content: {
    margin: 20,
    backgroundColor: 'white',
    paddingBottom: 10,
    borderRadius: rptheme.roundness,
    padding: 10,
    ...rptheme.shadow,
    position: 'relative',
  },
  buttonContainer: {
    display: 'flex',
    flexDirection: 'row',
    marginTop: 10,
  },
  button: {
    flex: 1,
  },
});
