import React from 'react';
import { StyleSheet, Text, ScrollView, View } from 'react-native';
import { useTranslation } from 'react-i18next';
import { GarageStore } from '../garage/GarageStore';
import { AuthStore } from '../authentication/AuthStore';
import LoadingState from '~/src/components/LoadingState';
import FABFrame from '~/src/components/FABFrame';
import FABGroup from '~/src/components/FABGroup';
import ReparkLogo from '~/src/components/ReparkLogo';
import { FABActions } from '~/src/utils/FABActions';
import AvatarFAB from '../user-profile/AvatarFAB';
import { FAB } from 'react-native-paper';
import rptheme from '~/rptheme';
import { RootScreens } from '../navigation';
import { GarageListItem } from './GarageListItem';
import ReparkTextInput from '~/src/components/ReparkTextInput';
import { GarageType } from '../garage/types';
import { CenteredAppView } from '~/src/components/CenteredAppView';

export function MyGaragesScreen({ navigation }: any) {
  const loggedIn = AuthStore((store) => store.loggedIn);
  const user = AuthStore((store) => store.user);
  const { emailSupportAction, feedbackAction } = FABActions({
    user,
    navigation,
  });

  const [myGarages, isFetchingGarages] = GarageStore((store) => [
    store.myGarages,
    store.isFetchingGarages,
  ]);

  const { t } = useTranslation();

  const [search, setSearch] = React.useState('');

  const filteredGarages = myGarages().filter((garage: GarageType) => {
    const haystack = garage.street
      .concat(' ', garage.name)
      .concat(' ', garage.streetNumber)
      .concat(' ', garage.addressString)
      .concat(' ', garage.company.name)
      .toLowerCase();

    return haystack.includes(search.toLocaleLowerCase());
  });

  return (
    <>
      {loggedIn ? (
        <>
          <FABFrame
            topRight={
              <AvatarFAB
                onPress={() => navigation.navigate(RootScreens.ProfileStack)}
              />
            }
            bottomRight={
              <FAB
                icon={'map'}
                onPress={() => navigation.replace(RootScreens.MapScreen)}
                style={styles.mapButton}
                color={rptheme.colors.primary}
              />
            }
            bottomLeft={
              <FABGroup
                icon={'help'}
                actions={[feedbackAction, emailSupportAction]}
              />
            }
            topCenter={<ReparkLogo />}
          >
            <CenteredAppView>
              <ScrollView style={styles.container}>
                {isFetchingGarages ? (
                  <LoadingState />
                ) : (
                  <>
                    {myGarages().length > 3 && (
                      <View style={{ marginHorizontal: 10 }}>
                        <ReparkTextInput
                          testID={'licencePlateInput'}
                          onChangeText={(text: string) => {
                            setSearch(text);
                          }}
                          icon="search"
                          value={search}
                          label={t('General.search')}
                          secureTextEntry={false}
                          autoCorrect={false}
                          spellCheck={false}
                          autoComplete={false}
                          keyboardType={'default'}
                          autoFocus={false}
                          zoderror={null}
                        />
                      </View>
                    )}

                    {filteredGarages.map((garage: any) => {
                      return (
                        <GarageListItem
                          key={garage.id}
                          garage={garage}
                          t={t}
                          navigation={navigation}
                        />
                      );
                    })}
                  </>
                )}
              </ScrollView>
            </CenteredAppView>
          </FABFrame>
        </>
      ) : (
        <Text>{t('General.loginToAccessPage')}</Text>
      )}
    </>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 0.75,
    marginTop: 100,
  },
  mapButton: { backgroundColor: 'white' },
});
