import * as React from 'react';
import { StyleSheet, View } from 'react-native';

import { ReparkList } from '~/src/components/ReparkList';
import { NavProp, RootScreens } from '../navigation';

type DebugOverviewProps = {
  navigation: NavProp;
};

export const DebugOverview = (props: DebugOverviewProps) => {
  const { navigation } = props;

  const debugItems = [
    {
      showCondition: true,
      testID: 'debugLocationScreenLink',
      onPress: () => navigation.navigate('DebugLocationScreen'),
      title: 'Location',
      icon: 'info',
    },
    {
      showCondition: true,
      title: 'Onboarding',
      icon: 'logo',
      onPress: () => navigation.navigate('Onboarding'),
    },
    {
      showCondition: true,
      title: 'Subscriptions',
      icon: 'logo',
      onPress: () => navigation.navigate('DebugSubscriptions'),
    },
  ];

  return (
    <View style={styles.container}>
      <ReparkList items={debugItems} />
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    marginTop: 50,
  },
});
