import { Circle, Marker } from '@react-google-maps/api';
import React from 'react';
import { computeSubwayColor } from '../helpers';
import viennaSubwayData from './viennasubwaydata.json';

type SubwayViennaStationMarkerProps = {
  station: typeof viennaSubwayData.features[0];
};

export const SubwayViennaFinalStopMarker = (
  props: SubwayViennaStationMarkerProps
) => {
  const { station } = props;

  return (
    <Marker
      key={station.id}
      position={{
        lat: parseFloat(station.geometry.coordinates[1] as any),
        lng: parseFloat(station.geometry.coordinates[0] as any),
      }}
      icon={{
        path: 'M-20,-20h40v40h-40z',
        fillColor: computeSubwayColor(station.properties.LINFO),
        fillOpacity: 1,
        scale: 1,
        strokeColor: '#ffffff',
        strokeWeight: 1,
      }}
      label={{
        text: `U${station.properties.LINFO}`,
        fontSize: '16px',
        fontWeight: 'bold',
        color: 'white',
      }}
    />
  );
};

export const SubwayViennaStopMarker = (
  props: SubwayViennaStationMarkerProps
) => {
  const { station } = props;

  return (
    <Circle
      key={station.id}
      center={{
        lat: parseFloat(station.geometry.coordinates[1] as any),
        lng: parseFloat(station.geometry.coordinates[0] as any),
      }}
      radius={5}
      options={{
        strokeColor: computeSubwayColor(station.properties.LINFO),
        fillColor: computeSubwayColor(station.properties.LINFO),
        fillOpacity: 1,
      }}
    />
  );
};
