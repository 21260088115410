import React from 'react';
import { View, ActivityIndicator } from 'react-native';
import rptheme from '~/rptheme';
import { NavProp } from '../features/navigation';
import { ProcessingReparkLogo } from './ProcessingReparkLogo';

export default function LoadingState({
  navigation,
  noLogo = false,
}: {
  navigation?: NavProp;
  noLogo?: boolean;
}) {
  return (
    <View style={{ height: '100%', justifyContent: 'center' }}>
      <ActivityIndicator size="large" color={rptheme.colors.primary} />
      {!noLogo && <ProcessingReparkLogo />}
    </View>
  );
}
