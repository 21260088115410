import React from 'react';
import { View, Text, StyleSheet, Image } from 'react-native';
import { Title } from 'react-native-paper';
import ReparkButton from '~/src/components/ReparkButton';
import { ListItemCard } from '../components/ListItemCard';
import { GarageType } from '../../garage/types';
import { useTranslation } from 'react-i18next';

type WelcomeStepProps = {
  onContinue: () => void;
  garage: GarageType;
  loading?: boolean;
};

export const WelcomeStep = (props: WelcomeStepProps) => {
  const { onContinue, garage, loading = false } = props;
  const { t } = useTranslation();

  const canDoList = [
    {
      title: t('Subscription.steps.welcome.canDoList.findSpot'),
      icon: 'parking-spot',
    },
    {
      title: t('Subscription.steps.welcome.canDoList.payOnline'),
      icon: 'pay-online',
    },
    {
      title: t('Subscription.steps.welcome.canDoList.openGate'),
      icon: 'tap',
    },
  ];

  const advantagesList = [
    {
      title: t('Subscription.steps.welcome.advantagesList.noDeposit'),
      icon: 'no-deposit',
    },
    {
      title: t('Subscription.steps.welcome.advantagesList.cancelAnytime'),
      icon: 'cancel-anytime-icon',
    },
    {
      title: t('Subscription.steps.welcome.advantagesList.support'),
      icon: 'support-person',
    },
  ];

  return (
    <View style={styles.container}>
      <Image
        style={styles.image}
        source={
          garage?.company?.logo
            ? { uri: garage.company.logo.path }
            : require('~/assets/images/repark-logo.png')
        }
      />
      <Title style={styles.title}>
        {t('Subscription.steps.welcome.title')}
      </Title>

      <ListItemCard
        title={t('Subscription.steps.welcome.canDoList.title')}
        items={canDoList}
      />

      <ListItemCard
        title={t('Subscription.steps.welcome.advantagesList.title')}
        items={advantagesList}
      />

      <View style={styles.poweredByContainer}>
        <Text>
          powered by{' '}
          <Image
            style={styles.poweredByImage}
            source={require('~/assets/images/repark-logo.png')}
          />
        </Text>
      </View>

      <ReparkButton onPress={onContinue} disabled={!garage} loading={loading}>
        {t('General.next')}
      </ReparkButton>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    margin: 15,
  },
  title: {
    textAlign: 'center',
  },
  image: { height: 100, resizeMode: 'contain', marginVertical: 15 },
  poweredByContainer: { display: 'flex', alignItems: 'center', margin: 15 },
  poweredByImage: {
    height: 25,
    width: 75,
    resizeMode: 'contain',
    marginBottom: -7,
    marginLeft: 5,
  },
});
