import { feathersClient } from '~/src/services/feathers';

export const fetchSubscriptions = async (params: any) => {
  return await feathersClient.service('subscriptions').find({
    query: {
      ...params,
    },
  });
};

export const fetchSubscriptionInvoices = async (subscriptionId: string) => {
  return await feathersClient.service('subscriptionInvoices').find({
    query: {
      subscriptionId,
    },
  });
};

export const fetchSubscriptionInvoice = async (
  invoiceId: string,
  action?: string
) => {
  return await feathersClient
    .service('subscriptionInvoices')
    .get(invoiceId, { query: { action } });
};
export const cancelSubscriptionRequest = async (id: string) => {
  return await feathersClient.service('subscriptions').patch(id, {
    canceled: true,
  });
};
