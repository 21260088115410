import * as React from 'react';
import { View, Platform } from 'react-native';
import { useForm, Controller } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';

import { useTranslation } from 'react-i18next';
import ReparkButton from '~/src/components/ReparkButton';

import i18next from 'i18next';

import { SnackbarStore } from '../../snackbar/SnackbarStore';
import { AuthStore } from '../AuthStore';
import { Recaptcha } from '../../recaptcha/Recaptcha';
import FirebaseRecaptchaVerifier from '../../recaptcha/helpers';
import { createLoginWithPhoneNumberValidation } from '~/src/rules/inputValidationRules';
import { FirebaseRecaptchaVerifierModal } from 'expo-firebase-recaptcha';
import ReparkPhoneInput from '~/src/components/ReparkPhoneInput';

export type AuthPhoneInputProps = {
  onVerificationReceived: (data: any) => {};
};

export default function AuthPhoneInput(props: AuthPhoneInputProps) {
  const { t } = useTranslation();
  const {
    control,
    handleSubmit,
    reset,
    setValue,
    getValues,
    formState: { errors },
  } = useForm<{ phoneNumber: string; token: string }>({
    resolver: zodResolver(createLoginWithPhoneNumberValidation(t)),
  });

  const [isLoading, setIsLoading] = React.useState(false);
  const [verifyCaptcha, setVerifyCaptcha] = React.useState(false);
  const recaptchaVerifier = React.useRef<FirebaseRecaptchaVerifierModal | null>(
    null
  );

  const startSignInWithPhone = AuthStore((store) => store.startSignInWithPhone);
  const fireSnack = SnackbarStore((store) => store.fireSnack);

  // reset() the form, so a possibly wrong validation error message is not visible :)
  React.useEffect(() => {
    i18next.on('languageChanged', function () {
      reset(control._formValues, { keepValues: true });
    });
    return () => {
      i18next.off('languageChanged');
    };
  }, []);

  const continueSignIn = async () => {
    const formData = getValues();
    let applicationVerifier;

    if (Platform.OS === 'web') {
      applicationVerifier = new FirebaseRecaptchaVerifier(formData.token);
    } else {
      applicationVerifier = recaptchaVerifier.current;
    }

    const response = await startSignInWithPhone({
      phoneNumber: formData.phoneNumber,
      applicationVerifier,
    });
    if (response.success) {
      props.onVerificationReceived({
        phoneNumber: formData.phoneNumber,
        verificationId: response.verificationId,
        firstName: response.firstName,
        newUser: response.newUser,
      });
    } else {
      setIsLoading(false);
      let title = t('AuthTexts.signInFailedErrorTitle');
      let message = t('AuthTexts.signInFailedTimeoutMessage');
      if (response.errorCode === 'auth/too-many-requests') {
        title = t('AuthText.tooManyRequestsTitle');
        message = t('AuthTexts.tooManyRequestsMessage');
      }
      fireSnack({
        title,
        message,
        type: 'error',
      });
    }
  };

  return (
    <View>
      <Controller
        name="phoneNumber"
        control={control}
        render={({ field: { onChange } }) => (
          <ReparkPhoneInput
            testID={'phoneInput'}
            onChangeText={(text: string) => onChange(text)}
            zoderror={errors.phoneNumber}
            showError={true}
          />
        )}
      ></Controller>

      <Recaptcha
        verify={verifyCaptcha}
        ref={Platform.OS !== 'web' ? recaptchaVerifier : null}
        onSuccess={(token: string) => {
          setValue('token', token);
          setVerifyCaptcha(false);
          continueSignIn();
        }}
      />

      <View>
        <ReparkButton
          testID={'nextStepButton'}
          loading={isLoading}
          disabled={isLoading}
          onPress={handleSubmit(async () => {
            setIsLoading(true);
            setVerifyCaptcha(true);
          })}
        >
          {t('General.nextStep')}
        </ReparkButton>
      </View>
    </View>
  );
}
