import React from 'react';
import rptheme from '~/rptheme';
import { LanguageSwitcherModal } from './LanguageSwitcherModal';
import { langEmojis } from './lang-emojis';
import { StyleSheet, Text, TouchableOpacity } from 'react-native';
import { useTranslation } from 'react-i18next';
import { extractLanguageFromLocalCode } from './helpers';

export const LanguageSwitcherFAB = () => {
  const [open, setOpen] = React.useState(false);
  const { i18n } = useTranslation();

  return (
    <>
      <TouchableOpacity
        testID="FABlanguageSwitchTestID"
        onPress={() => setOpen(!open)}
        style={styles.fab}
      >
        <Text style={{ fontSize: 27 }}>
          {
            langEmojis[
              extractLanguageFromLocalCode(
                i18n.language
              ) as keyof typeof langEmojis
            ]
          }
        </Text>
      </TouchableOpacity>
      <LanguageSwitcherModal isOpen={open} onClose={() => setOpen(false)} />
    </>
  );
};

const styles = StyleSheet.create({
  fab: {
    backgroundColor: 'white',
    borderRadius: 100,
    ...rptheme.shadow,
    aspectRatio: 1,
    justifyContent: 'center',
    width: 56,
    height: 56,
    textAlign: 'center',
  },
});
