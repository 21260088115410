import React from 'react';
import { View, StyleSheet } from 'react-native';
import { Subheading, Title } from 'react-native-paper';
import { formatCurrency } from '~/src/utils/helpers';
import { useTranslation } from 'react-i18next';

type PricePerProps = {
  price: number;
  interval?: 'day' | 'month' | 'year' | 'week' | 'hour' | 'minute';
  prefix?: string;
};

export const PricePer = (props: PricePerProps) => {
  const { t } = useTranslation();
  const { price, interval = 'day', prefix = '' } = props;

  const computeIntervalLabel = (
    interval: 'day' | 'month' | 'year' | 'week' | 'hour' | 'minute'
  ) => {
    switch (interval) {
      case 'day':
        return t('offer.pricePerDay');
      case 'month':
        return t('offer.pricePerMonth');
      default:
        return t('offer.pricePerMonth');
    }
  };

  return (
    <View style={styles.container}>
      <Title>
        {prefix} {formatCurrency(price)}
      </Title>
      <Subheading style={{ marginTop: -5, alignSelf: 'center', fontSize: 12 }}>
        {computeIntervalLabel(interval)}
      </Subheading>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    alignItems: 'flex-end',
  },
});
