import { Keyboard } from 'react-native';
import create from 'zustand';

type SnackbarStore = {
  isShown: Boolean;
  type: string;
  title: string;
  message: string;
  duration: number;
  icon: string;

  fireSnack: (params: SnackbarProps) => void;
  dismissSnack: () => void;
};

export type SnackbarProps = {
  title?: string;
  message?: string;
  type?: 'default' | 'info' | 'error' | 'success';
  duration?: number;
  icon?: string;
};

export const SnackbarStore = create<SnackbarStore>((set, get) => ({
  isShown: false,
  type: '',
  title: '',
  message: '',
  duration: 5000,
  icon: '',

  fireSnack: async (params: SnackbarProps) => {
    const { type, title, message, duration, icon } = params;
    Keyboard.dismiss();

    set({
      isShown: true,
      title,
      message,
      type,
      duration: duration || 5000,
      icon,
    });
    setTimeout(get().dismissSnack, get().duration);
  },
  dismissSnack: async () => {
    set({
      isShown: false,
      type: '',
      title: '',
      message: '',
      duration: 5000,
      icon: '',
    });
  },
}));
