import * as SecureStore from 'expo-secure-store';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { Platform } from 'react-native';

class SecureStoreWrapper {
  private secureStore;

  constructor() {
    this.secureStore = SecureStore;
  }

  async getItem(key: string) {
    console.log('Getting item', key);

    const item = await this.secureStore.getItemAsync(key);
    return item;
  }
  async setItem(key: string, value: string) {
    console.log('Setting item', key);

    return await this.secureStore.setItemAsync(key, value);
  }
  async removeItem(key: string) {
    console.log('Removing item', key);

    return await this.secureStore.deleteItemAsync(key);
  }
}

class AsyncStoreWrapper {
  private secureStore;

  constructor() {
    this.secureStore = AsyncStorage;
  }

  async getItem(key: string) {
    console.log('Getting item', key);
    const item = await this.secureStore.getItem(key);

    return item;
  }
  async setItem(key: string, value: string) {
    console.log('Setting item', key);

    return await this.secureStore.setItem(key, value);
  }
  async removeItem(key: string) {
    console.log('Removing item', key);

    return await this.secureStore.removeItem(key);
  }
}

export default function storage() {
  if (Platform.OS === 'web') {
    return new AsyncStoreWrapper();
  } else {
    return new SecureStoreWrapper();
  }
}
