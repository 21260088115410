import * as React from 'react';
import { StyleSheet, Text, TouchableHighlight, View } from 'react-native';

import { NavProp } from '../navigation';
import { Button, Divider } from 'react-native-paper';
import { feathersClient } from '~/src/services/feathers';
import { AuthStore } from '../authentication/AuthStore';
import dayjs from 'dayjs';
import { PaymentForm } from '../payment/PaymentForm';

type DebugSubscroptionProps = {
  navigation: NavProp;
};

export const DebugSubscriptions = (props: DebugSubscroptionProps) => {
  const [user] = AuthStore((store) => [store.user]);
  const [garageId, setGarageId] = React.useState<string>(
    'GRG-be4d8b3a-7906-4754-847a-8f80f53f0fb3'
  );

  const [garage, setGarage] = React.useState<any | null>(null);
  const [parkingSpot, setParkingSpot] = React.useState<any | null>(null);

  const [subscriptions, setSubscriptions] = React.useState<any[]>([]);
  const [subscription, setSubscription] = React.useState<any | null>(null);
  const [price, setPrice] = React.useState<string | null>(null);

  const createSubscription = async () => {
    if (!price) throw new Error('No prices selected');

    await feathersClient
      .service('subscriptions')
      .create({
        startDate: dayjs().startOf('month').add(1, 'month').toDate(),
        items: [{ price, parkingSpotId: parkingSpot }],
        userId: user.id,
      })
      .then((res: any) => {
        console.log(res);
        setSubscription(res);
        setPaymentIntent(res.paymentIntent);
        fetchSubscriptions();
      });
  };

  const fetchSubscriptions = async () => {
    const res = await feathersClient.service('subscriptions').find();
    console.log(res);
    setSubscriptions(res.data);
  };

  const getSubscription = async (id: string) => {
    const res = await feathersClient.service('subscriptions').get(id);
    console.log(res);
    setSubscription(res);
    setPaymentIntent(res.stripeSubscription.latest_invoice.payment_intent);
  };

  const [paymentIntent, setPaymentIntent] = React.useState<any | null>(null);

  const fetchGarage = async () => {
    const res = await feathersClient.service('garages').get(garageId);
    console.log(res);
    setGarage(res);
  };

  React.useEffect(() => {
    fetchSubscriptions();
    fetchGarage();
  }, []);

  return (
    <View style={styles.container}>
      <View style={{ marginBottom: 20, marginHorizontal: 5 }}>
        <Text style={{ marginBottom: 5 }}>
          Testing Subs for Garage ID: {garageId}
        </Text>

        <Text style={styles.text}>Garage: {garage?.name}</Text>
        <Text style={styles.text}>
          Parking Spots: {garage?.parkingSpots.length}
        </Text>
        <Text style={styles.text}>
          Parking Spots With Product:{' '}
          {garage?.parkingSpots.filter((p: any) => p.products.length).length}
        </Text>

        {garage?.parkingSpots.map((spot: any) => (
          <View key={spot.id} style={{ marginTop: 15 }}>
            <Text>
              {spot.name} - {spot.id}
            </Text>
            {spot.products.map((gProduct: any) => (
              <View key={gProduct.id}>
                <Text style={{ marginLeft: 15 }}>{gProduct.name}</Text>
                {gProduct.prices.map((price: any) => (
                  <Text
                    onPress={() => {
                      setParkingSpot(spot.id);
                      setPrice(price.id);
                    }}
                    style={{ marginLeft: 30 }}
                    key={price.id}
                  >
                    {price.id}
                  </Text>
                ))}
              </View>
            ))}
          </View>
        ))}
      </View>

      <View style={{ marginBottom: 20, marginHorizontal: 5 }}>
        <Text>{subscriptions.length} Subscription(s)</Text>
        {subscriptions.map((sub) => (
          <TouchableHighlight
            key={sub.id}
            onPress={() => getSubscription(sub.id)}
          >
            <Text>
              {sub.id} - "{sub.status}"
            </Text>
          </TouchableHighlight>
        ))}
      </View>

      <Divider />

      <Text>
        Selected: {parkingSpot} {price}{' '}
      </Text>
      <Button disabled={!price} onPress={createSubscription}>
        Create Subscription
      </Button>
      {subscription && (
        <View>
          <Text>
            Subscription created: <strong>{subscription.id}</strong>
          </Text>
          {paymentIntent && (
            <>
              <Text>
                Now confirm this PI: <strong>{paymentIntent.id}</strong>
              </Text>
              {/* <Text>{JSON.stringify(paymentIntent, null, 2)}</Text> */}
            </>
          )}
          <PaymentForm
            paymentIntent={paymentIntent}
            onIsProcessingPayment={() => {}}
            onSuccess={fetchSubscriptions}
            showSavedCards={false}
            showVoucherInput={false}
          />
        </View>
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    marginTop: 50,
  },
  text: {
    marginBottom: 10,
  },
});
