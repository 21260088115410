import { zodResolver } from '@hookform/resolvers/zod';
import AppLoading from 'expo-app-loading';
import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { View, StyleSheet, Platform } from 'react-native';
import rptheme from '~/rptheme';
import ReparkButton from '~/src/components/ReparkButton';
import { createVehicleZodSchema } from '~/src/rules/inputValidationRules';
import { BottomSheetStore } from '../bottom-sheet/BottomSheetStore';
import { NavProp } from '../navigation';
import { SnackbarStore } from '../snackbar/SnackbarStore';
import { VehicleStore } from './VehicleStore';
import { PaymentStore } from '../payment/PaymentStore';
import ConfirmCancelSheet from '~/src/components/ConfirmCancelSheet';
import { CenteredAppView } from '~/src/components/CenteredAppView';
import { debugResolverForm } from '~/src/utils/helpers';
import { getLastRouteFromNavProps } from '../navigation/helpers';
import { ReparkLicencePlateInput } from '~/src/components/ReparkLicencePlateInput';

type VehicleEditScreenProps = {
  navigation?: NavProp;
  route: any;
};

export const matchIsDirty = (
  toMatch: string | undefined,
  current: string
): boolean => {
  if (!toMatch) {
    //means it is a new lp
    return current.length > 0;
  }
  return toMatch.toUpperCase() !== current.toUpperCase();
};

export const VehicleEditScreen = (props: VehicleEditScreenProps) => {
  const { route, navigation } = props;

  const isNew = route.params?.vehicleId ? false : true;
  let paymentIntentId = route.params?.paymentIntentId;
  if (!paymentIntentId) {
    const last = getLastRouteFromNavProps(navigation?.getParent());
    if (last) {
      if (
        last.params &&
        last.params.params &&
        'paymentIntentId' in last.params.params
      ) {
        paymentIntentId = last.params.params.paymentIntentId;
      }
    }
  }

  const { t } = useTranslation();
  const fireSnack = SnackbarStore((store) => store.fireSnack);
  const openSheet = BottomSheetStore((store) => store.openSheet);
  const [
    isFetchingVehicles,
    getVehicle,
    patchVehicle,
    deleteVehicle,
    createVehicle,
  ] = VehicleStore((store) => [
    store.isFetchingVehicles,
    store.getVehicle,
    store.patchVehicle,
    store.deleteVehicle,
    store.createVehicle,
  ]);
  const [isLoading, setIsLoading] = React.useState(false);
  const [valuesIn, setValuesIn] = React.useState<any>(undefined);
  const [isDirty, setIsDirty] = React.useState(false);

  const removePaymentIntent = PaymentStore(
    (store) => store.removePaymentIntent
  );
  React.useEffect(() => {
    if (paymentIntentId) {
      const handleBeforeUnload = () => {
        removePaymentIntent(paymentIntentId).then();
      };
      window.addEventListener('beforeunload', handleBeforeUnload);
      return () => {
        window.removeEventListener('beforeunload', handleBeforeUnload);
      };
    }
  }, []);

  React.useEffect(() => {
    if (!isFetchingVehicles && route.params?.vehicleId) {
      const incomingData = {
        licencePlate: getVehicle(route.params?.vehicleId).licencePlate || '',
        countryOfOrigin:
          getVehicle(route.params?.vehicleId).countryOfOrigin || 'AT',
      };
      setValue('licencePlateData', incomingData);
      setValuesIn(incomingData);
    }
  }, [isFetchingVehicles]);

  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<{
    licencePlateData: {
      licencePlate: string;
      countryOfOrigin: string;
    };
  }>({
    resolver: async (data: any, context: any, options: any) => {
      await debugResolverForm({
        schema: createVehicleZodSchema(t),
        data: data.licencePlateData,
        context,
        options,
      });
      return zodResolver(createVehicleZodSchema(t))(
        data.licencePlateData,
        context,
        options
      );
    },
    defaultValues: {
      licencePlateData: {
        licencePlate: '',
        countryOfOrigin: 'AT',
      },
    },
  });

  const goBack = () => {
    if (navigation?.canGoBack()) {
      navigation.pop();
    }
    navigation?.replace('ShowVehiclesScreen', paymentIntentId);
  };

  const onDeleteVehicle = async () => {
    try {
      await deleteVehicle(route.params?.vehicleId);
      fireSnack({
        title: t('Vehicle.delete.successTitle'),
        message: t('Vehicle.delete.successMessage'),
        type: 'success',
      });
      goBack();
    } catch (e) {
      fireSnack({
        title: t('General.errors.GenericErrorTitle'),
        message: t('General.errors.GenericErrorMessage'),
        type: 'error',
      });
    }
  };

  React.useEffect(() => {
    if (isDirty) {
      navigation?.setOptions({
        headerLeft: () => {
          return (
            <ReparkButton
              mode="text"
              testID={'cancelButton'}
              disabled={isLoading}
              color={rptheme.colors.primary}
              onPress={goBack}
            >
              {t('General.cancel')}
            </ReparkButton>
          );
        },
        headerRight: () => {
          return (
            <ReparkButton
              mode="text"
              testID={'saveButton'}
              loading={isLoading}
              disabled={isLoading}
              color={rptheme.colors.primary}
              onPress={handleSubmit(async (data) => {
                setIsLoading(true);
                if (isNew) {
                  try {
                    await createVehicle(data, paymentIntentId !== undefined);
                    fireSnack({
                      title: t('Vehicle.create.successTitle'),
                      message: t('Vehicle.create.successMessage'),
                      type: 'success',
                    });

                    goBack();
                  } catch (e) {
                    fireSnack({
                      title: t('General.errors.GenericErrorTitle'),
                      message: t('General.errors.GenericErrorMessage'),
                      type: 'error',
                    });
                  } finally {
                    setIsLoading(false);
                  }
                } else {
                  try {
                    await patchVehicle(
                      {
                        ...getVehicle(route.params?.vehicleId),
                        ...data,
                      },
                      paymentIntentId !== undefined
                    );
                    fireSnack({
                      title: t('Vehicle.edit.successTitle'),
                      message: t('Vehicle.edit.successMessage'),
                      type: 'success',
                    });

                    goBack();
                  } catch (e) {
                    fireSnack({
                      title: t('General.errors.GenericErrorTitle'),
                      message: t('General.errors.GenericErrorMessage'),
                      type: 'error',
                    });
                  } finally {
                    setIsLoading(false);
                  }
                }
              })}
            >
              {t('General.save')}
            </ReparkButton>
          );
        },
      });
    }
  }, [isDirty]);

  if (isFetchingVehicles) return <AppLoading />;

  return (
    <CenteredAppView>
      <View style={styles.container}>
        <Controller
          name="licencePlateData"
          control={control}
          render={({ field: { onChange } }) => {
            return (
              <ReparkLicencePlateInput
                placeholder={t('Vehicle.licencePlate')}
                disabled={isLoading}
                testID={'licencePlateInput'}
                topLabel={t('Vehicle.licencePlateAndCountry')}
                onChangeValue={(licencePlateData) => {
                  setIsDirty(
                    matchIsDirty(
                      getVehicle(route.params?.vehicleId)?.licencePlate ||
                        undefined,
                      licencePlateData.licencePlate
                    )
                  );
                  onChange(licencePlateData);
                }}
                zoderror={(errors as any).licencePlate}
                valueIn={valuesIn}
              />
            );
          }}
        ></Controller>

        {!isNew && (
          <ReparkButton
            testID={'deleteButton'}
            mode={'outlined'}
            onPress={() =>
              openSheet(
                <ConfirmCancelSheet
                  title={t('EditVehicleScreen.confirmDeleteTitle')}
                  onConfirm={onDeleteVehicle}
                  t={t}
                />,
                { adjustToContentHeight: true }
              )
            }
            icon={'delete'}
            color={rptheme.colors.error}
          >
            {t('General.delete')}
          </ReparkButton>
        )}
      </View>
    </CenteredAppView>
  );
};

const styles = StyleSheet.create({
  container: {
    marginTop: 70,
    margin: 25,
  },
  countryPicker: Platform.OS === 'android' ? { marginTop: 3 } : {},
  inputContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
});
