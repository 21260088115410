import AppLoading from 'expo-app-loading';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { StyleSheet, View, Text } from 'react-native';
import { CenteredAppView } from '~/src/components/CenteredAppView';
import { EmptyState } from '~/src/components/EmptyState';
import { ReparkList } from '~/src/components/ReparkList';
import { ProfileStackScreenNames } from '../user-profile/ProfileStackNavigation';
import { VehicleStore } from './VehicleStore';
import { NavProp } from '../navigation';
import { PaymentStore } from '../payment/PaymentStore';

type VehiclesOverviewScreenProps = {
  navigation: NavProp;
  route?: { params?: { paymentIntentId: string } }; //this is included if the this
  //screen was navigated to from the checkout screen, if the user fully aborts, we want to clean up the paymentIntent
};

export const VehiclesOverviewScreen = ({
  navigation,
  route,
}: VehiclesOverviewScreenProps) => {
  const paymentIntentId = route?.params?.paymentIntentId;

  const [vehicles, maxVehicles, isFetchingVehicles] = VehicleStore((store) => [
    store.vehicles,
    store.maxVehicles,
    store.isFetchingVehicles,
    store.selectVehicle,
    store.selectedVehicle,
  ]);
  const { t } = useTranslation();
  const removePaymentIntent = PaymentStore(
    (store) => store.removePaymentIntent
  );

  const createVehiclesList = (vehicles: any[]) => {
    const vehiclesList = vehicles.map((vehicle) => {
      return {
        title: `${vehicle.licencePlate} ${
          vehicle.countryOfOrigin != null
            ? '(' + vehicle.countryOfOrigin + ')'
            : ''
        }`,
        icon: 'car',
        testID: vehicle.id,
        onPress: () => {
          navigation.navigate(ProfileStackScreenNames.EditVehicleScreen, {
            vehicleId: vehicle.id,
            paymentIntentId,
          });
        },
      };
    });

    return vehiclesList;
  };

  React.useEffect(() => {
    if (paymentIntentId) {
      const handleBeforeUnload = () => {
        removePaymentIntent(paymentIntentId).then();
      };
      window.addEventListener('beforeunload', handleBeforeUnload);
      return () => {
        window.removeEventListener('beforeunload', handleBeforeUnload);
      };
    }
  }, []);

  if (isFetchingVehicles) return <AppLoading />;

  return (
    <CenteredAppView>
      <View style={styles.container}>
        <ReparkList
          items={createVehiclesList(vehicles)}
          testId={'vehiclesList'}
        />
        {!vehicles.length && (
          <View style={{ padding: 25 }}>
            <EmptyState
              title={t('VehicleScreen.emptyListTitle')}
              text={t('VehicleScreen.emptyListText')}
              CTA={{
                label: t('VehicleScreen.emptyListCTALabel'),
                icon: 'car',
                onPress: () =>
                  navigation.navigate(
                    ProfileStackScreenNames.AddVehicleScreen,
                    { paymentIntentId }
                  ),
              }}
            />
          </View>
        )}

        {vehicles.length >= maxVehicles && (
          <Text style={styles.text} testID={'maxVehiclesInfo'}>
            {t('VehicleScreen.maxVehicleText', { count: maxVehicles })}
          </Text>
        )}
      </View>
    </CenteredAppView>
  );
};

const styles = StyleSheet.create({
  container: {
    marginTop: 70,
  },
  text: {
    margin: 25,
    textAlign: 'center',
  },
});
