import { Dayjs } from 'dayjs';
import { feathersClient } from '../../services/feathers';
import { PaymentIntent } from '@stripe/stripe-js';

export const fetchPaymentIntentRequest = async ({
  offerId,
  start,
  duration,
  licencePlate,
  bookingType,
  voucher,
  extending,
}: {
  offerId: string;
  start: Dayjs;
  duration: number;
  licencePlate: string;
  bookingType: string;
  voucher?: string;
  extending?: string;
}): Promise<PaymentIntent> => {
  const response = await feathersClient.service('stripe').get(offerId, {
    query: {
      start,
      duration,
      voucher,
      licencePlate,
      bookingType,
      extending,
    },
  });
  return response;
};

export const patchPaymentIntentRequest = async (id: string, data: any) => {
  const response = await feathersClient.service('stripe').patch(id, data, {});
  return response;
};

export const fetchPaymentMethodsRequest = async (stripeCustomerId: string) => {
  const response = await feathersClient
    .service('stripe')
    .get(stripeCustomerId, { query: { action: 'getPaymentMethods' } });
  return response;
};

export const detachPaymentMethodRequest = async (
  stripeCustomerId: string,
  paymentMethodId: string
) => {
  const response = await feathersClient
    .service('stripe')
    .get(stripeCustomerId, {
      query: { action: 'detachPaymentMethod', paymentMethodId },
    });
  return response;
};

export const removePaymentIntentRequest = async (id: string) => {
  const response = await feathersClient.service('stripe').remove(id);
  return response;
};

export const createSetupIntentRequest = async (stripeCustomerId: string) => {
  console.log('getting setup intent');
  const response = await feathersClient
    .service('stripe')
    .get(stripeCustomerId, { query: { action: 'createSetupIntent' } });
  return response;
};
