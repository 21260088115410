import create from 'zustand';
import { feathersClient } from '~/src/services/feathers';
import {
  checkCanOpenGarageRequest,
  fetchGarageRequest,
  fetchGaragesRequest,
  fetchMyAccessibleGaragesRequest,
  openGarageRequest,
} from '~/src/features/garage/garageService';
import { AuthStore } from '../authentication/AuthStore';
import {
  DAY,
  nowBetween,
  splitName,
  syncTimeZoneOffsets,
} from '~/src/utils/helpers';
import dayjs, { Dayjs } from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import { GarageType } from './types';

dayjs.extend(utc);
dayjs.extend(timezone);

// TODO: Add offer event listener
type GarageStore = {
  garages: Array<any>;
  myAccessibleGarages: Array<any>;
  isInitialGarageFetchDone: boolean;
  isFetchingGarages: boolean;
  isFetchingMyGarages: boolean;
  openGarageDistance: number;
  showOnlyStripeVerified: boolean;

  offerStart: Dayjs;
  offerDuration: number;
  maxBookingDaysInFuture: number;

  selectedGarageId: string | undefined;

  fetchGarages: (params?: any) => Promise<void>;
  fetchMyGarages: (params?: any) => Promise<void>;
  getGarage: (id: string) => GarageType;
  fetchGarage: (id: string) => Promise<any>;

  myGarages: () => Array<any>;
  setOfferSearchParams: (start: Dayjs, duration?: number) => void;
  setOfferSearchParamsNaive: (start: Dayjs, duration?: number) => void;
  calculateRate: (garage: any) => number;
  selectGarage: (id: string | undefined) => void;

  addGarage: (garage: object) => any;
  removeGarage: (garage: object) => any;
  updateGarage: (garage: object) => any;

  openGarage: (id: string, booking?: any) => any;

  canOpenGarage: (id: string, booking?: any) => Promise<boolean>;
  claimAccess: (
    parkingSpotId: string,
    userId: string,
    name?: string
  ) => Promise<any>;

  addEventlisteners: () => void;
  removeEventlisteners: () => void;
};

export const GarageStore = create<GarageStore>((set, get) => ({
  garages: [],
  myGarages: () => {
    if (get().myAccessibleGarages.length) {
      //return get().garages?.filter((garage: any) => isMyGarage(garage));
      return get().myAccessibleGarages.filter(
        (garage) => garage.accessType === 'repark-app'
      );
    }
    return [];
  },
  myAccessibleGarages: [],
  isInitialGarageFetchDone: false,
  isFetchingGarages: false,
  isFetchingMyGarages: false,
  openGarageDistance: 100,
  showOnlyStripeVerified: true,
  offerStart: dayjs().tz('Europe/Vienna').startOf('day'),
  offerDuration: 1440,
  selectedGarageId: undefined,
  maxBookingDaysInFuture: 365,

  fetchGarages: async (params: any) => {
    set({ isFetchingGarages: true });

    console.log('Fetching Garages ...');

    try {
      const response = await fetchGaragesRequest({
        start: get().offerStart,
        duration: get().offerDuration,
        status: {
          $in: ['enabled', 'comingSoon'],
        },
      });
      set({ garages: response, isFetchingGarages: false });
    } catch (e) {
      set({ isFetchingGarages: false });
      throw e;
    } finally {
      set({ isInitialGarageFetchDone: true });
    }
  },
  fetchGarage: async (id: string): Promise<any> => {
    const garage = await fetchGarageRequest(id);
    const garageIndex = get().garages.findIndex((garage) => garage.id === id);
    const garages = get().garages;
    if (garageIndex >= 0 && garage) {
      garages[garageIndex] = garage;
      set({ garages });
    } else {
      garages.push(garage);
      set({ garages });
    }
    return garage;
  },
  fetchMyGarages: async (params?: any) => {
    const userRole: string = AuthStore.getState().user?.role;
    set({ isFetchingMyGarages: true });
    console.log('Fetching my-garages...');

    try {
      let response = [];
      if (['superadmin', 'admin', 'backoffice'].includes(userRole)) {
        response = await fetchGaragesRequest();
      } else {
        response = (await fetchMyAccessibleGaragesRequest()).data;
      }

      set({
        myAccessibleGarages: response,
        isFetchingMyGarages: false,
      });
    } catch (e) {
      set({ isFetchingMyGarages: false });
      throw e;
    }
  },

  getGarage: (id: string) => {
    if (get().garages.length) {
      return get().garages?.find((garage: any) => garage.id === id);
    }
  },

  setOfferSearchParams: async (start: Dayjs | any, duration?: number) => {
    set({
      offerStart: start,
      offerDuration: duration || 1440,
    });

    get().fetchGarages();
  },
  setOfferSearchParamsNaive: (start: Dayjs, duration?: number): void => {
    set({
      offerStart: syncTimeZoneOffsets(start),
      offerDuration: duration || DAY,
    });
  },

  calculateRate: (garage) => {
    const offerDuration = get().offerDuration;
    if (garage && offerDuration) {
      const rate = garage.rate * (offerDuration / 1440);
      return rate;
    }

    throw new Error('No garage selected');
  },

  selectGarage: async (garageId: string | undefined) => {
    set({ selectedGarageId: garageId });
  },

  addGarage: (garage: any) => {
    set((store: any) => ({ garages: [...store.garages, garage] }));
  },

  removeGarage: (removedGarage: any) => {
    set((store: any) => ({
      garages: store.garages.filter((garage: any) => {
        return garage.id !== removedGarage.id;
      }),
    }));
  },

  updateGarage: (updatedGarage: any) => {
    const updatedGarages = get().garages.map((garage: any) => {
      if (garage.id === updatedGarage.id) {
        return updatedGarage;
      }
      return garage;
    });

    if (
      !get().garages.filter((garage: any) => garage.id === updatedGarage.id)
        .length
    ) {
      // In case of e.g:
      //    a garage's landlord gets changed to the authenticated users,
      //      the patched garage has to be added to store
      updatedGarages.push(updatedGarage);
    }

    set({ garages: updatedGarages });
  },

  openGarage: async (id: string, booking) => {
    return openGarageRequest(id, booking?.id);
  },

  canOpenGarage: async (id: string, booking?: any) => {
    return checkCanOpenGarageRequest(id, booking?.id);
  },

  addEventlisteners: () => {
    console.log('Adding Garage Eventlisteners');
    //TODO add event listeners for garage changes

    feathersClient.service('garages').on('created', () => {
      get().fetchGarages();
      get().fetchMyGarages();
    });

    feathersClient.service('garages').on('removed', (removedGarage: any) => {
      get().fetchGarages();
      get().fetchMyGarages();
    });

    feathersClient.service('garages').on('patched', (updatedGarage: any) => {
      get().fetchGarages();
      get().fetchMyGarages();
    });

    feathersClient
      .service('parking-spots')
      .on('patched', async (updatedParkingSpot: any) => {
        get().fetchGarages();
        get().fetchMyGarages();
      });

    feathersClient
      .service('accesses')
      .on('created', async (createdAccess: any) => {
        console.log('createdAccess', createdAccess);
        get().fetchGarages();
        get().fetchMyGarages();
      });

    feathersClient
      .service('accesses')
      .on('removed', async (removedAccess: any) => {
        console.log('removedAccess', removedAccess);
        get().fetchGarages();
        get().fetchMyGarages();
      });

    feathersClient
      .service('accesses')
      .on('patched', async (updatedAccess: any) => {
        console.log('updatedAccess', updatedAccess);

        get().fetchGarages();
        get().fetchMyGarages();
      });

    feathersClient
      .service('offers')
      .on('patched', async (updatedOffer: any) => {
        get().fetchGarages();
        get().fetchMyGarages();
      });
  },

  removeEventlisteners: () => {
    console.log('Removing Garage Eventlisteners');

    feathersClient.service('garages').removeAllListeners('created');
    feathersClient.service('garages').removeAllListeners('removed');
    feathersClient.service('garages').removeAllListeners('patched');
    feathersClient.service('parking-spots').removeAllListeners('patched');
    feathersClient.service('accesses').removeAllListeners('created');
    feathersClient.service('accesses').removeAllListeners('removed');
    feathersClient.service('accesses').removeAllListeners('patched');
  },

  claimAccess: async (parkingSpotId, userId, name) => {
    if (name) {
      const { firstName, lastName } = splitName(name);
      AuthStore.getState().editUser({ firstName, lastName });
    }

    try {
      await feathersClient.service('accesses').create({
        userId: userId,
        parkingSpotId,
      });
      return { success: true };
    } catch (e) {
      console.log(e);
      return { success: false };
    }
  },
}));

const isMyGarage = (garage: any) => {
  const userRole: string = AuthStore.getState().user?.role;
  if (['superadmin', 'admin'].includes(userRole)) return true;

  const userId = AuthStore.getState().user?.id;

  const iAmLandlord = garage.landlordId === userId;

  const iOwnParkingSpots =
    garage.parkingSpots?.filter((spot: any) => {
      return spot.landlordId === userId || spot.ownerId === userId;
    })?.length > 0;

  const iHaveAccessToParkingSpot =
    garage.parkingSpots
      ?.map((spot: any) => {
        spot.accesses = spot.accesses?.filter(
          (access: any) => access.userId === userId
        );
        return spot;
      })
      .filter((spot: any) => spot.accesses?.length)?.length > 0;

  return iAmLandlord || iOwnParkingSpots || iHaveAccessToParkingSpot;
};

const findGarageViaParkingSpot = (parkingSpotId: string) => {
  const garages = GarageStore.getState().garages;

  console.log(garages);

  const garage = garages.find((garage) =>
    garage.parkingSpots.find(
      (parkingsSpot: any) => parkingsSpot.id === parkingSpotId
    )
  );

  console.log(garage);

  return garage;
};

export const isValidAccess = (access: any): boolean => {
  const { validFrom, validUntil } = access;
  if (
    (!validFrom && !validUntil) ||
    (validFrom === null && validUntil === null)
  ) {
    return true;
  } else if (
    validFrom &&
    validUntil &&
    validFrom !== null &&
    validUntil !== null
  ) {
    return nowBetween(validFrom, validUntil);
  } else if (
    (validFrom || validFrom !== null) &&
    (!validUntil || validUntil == null)
  ) {
    return dayjs(validFrom).isBefore(dayjs());
  } else {
    return dayjs(validUntil).isAfter(dayjs());
  }
};
