import { getAuth, RecaptchaVerifier } from 'firebase/auth';
import React from 'react';
import { RecaptchaProps } from './helpers';

export const Recaptcha = (props: RecaptchaProps) => {
  const { verify, onSuccess } = props;

  React.useEffect(() => {
    if (verify) {
      new RecaptchaVerifier('recaptcha', { size: 'invisible' }, getAuth())
        .verify()
        .then((token) => {
          onSuccess(token);
        });
    }
  }, [verify]);

  return <div id="recaptcha"></div>;
};
