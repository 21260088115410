import React from 'react';
import { GarageType, MyGarageType, ParkingSpot } from '../garage/types';
import { Title, Text, Card } from 'react-native-paper';
import { StyleSheet, Image, View } from 'react-native';
import { GarageDeviceButtons } from '../garage/GarageDeviceButtons';
import ReparkButton from '~/src/components/ReparkButton';
import { NavProp, RootScreens } from '../navigation';
import { SubscriptionsStackScreens } from '../subscriptions/SubscriptionStackNavigation';
import { SubscriptionStore } from '../subscriptions/SubscriptionStore';
import { BookingStore } from '../booking/BookingStore';
import { BookingStackScreens } from '../booking/BookingStackNavigation';
import { isActiveBooking } from '../booking/helpers';

type GarageListItemProps = {
  garage?: MyGarageType;
  t: Function;
  navigation: NavProp;
};

export const GarageListItem = ({
  garage,
  navigation,
  t,
}: GarageListItemProps) => {
  const [subscriptions] = SubscriptionStore((store) => [store.subscriptions]);
  const [bookings] = BookingStore((store) => [store.bookings]);

  const subscriptionsInGarage = subscriptions.filter((sub) =>
    sub.parkingSpots?.some(
      (spot: ParkingSpot & { garage: GarageType }) =>
        spot.garage.id === garage?.id
    )
  );
  const hasSubscriptions = subscriptionsInGarage?.length > 0;
  const hasMultipleSubscriptions = subscriptionsInGarage?.length > 1;

  const bookingsInGarage = bookings.filter(
    (booking) =>
      booking.parkingSpot.garage.id === garage?.id && isActiveBooking(booking)
  );

  const hasBookings = bookingsInGarage?.length > 0;
  const hasMultipleBookings = bookingsInGarage?.length > 1;

  const handleManageSubPress = () => {
    if (hasMultipleSubscriptions) {
      navigation.navigate(RootScreens.SubscriptionStack, {
        screen: SubscriptionsStackScreens.SubscriptionOverview,
      });
    } else {
      navigation.navigate(RootScreens.SubscriptionStack, {
        screen: SubscriptionsStackScreens.SubscriptionDetail,
        params: { id: subscriptionsInGarage[0]?.id },
      });
    }
  };

  const handleManageBookingPress = () => {
    if (hasMultipleBookings) {
      navigation.navigate(RootScreens.BookingStack, {
        screen: BookingStackScreens.BookingOverviewScreen,
      });
    } else {
      navigation.navigate(RootScreens.BookingStack, {
        screen: BookingStackScreens.BookingDetailScreen,
        params: { id: bookingsInGarage[0]?.id },
      });
    }
  };

  return (
    <>
      <Card style={styles.container}>
        <View style={{ display: 'flex', flexDirection: 'row' }}>
          <View>
            <Image
              source={{
                uri: (garage?.images?.length && garage.images[0].path) || '',
              }}
              style={styles.image}
            />
          </View>
          <View>
            <Title>{garage?.name}</Title>
            <Text>{garage?.addressString}</Text>
          </View>
        </View>
        {hasSubscriptions && (
          <ReparkButton mode="text" onPress={handleManageSubPress}>
            {t('myGarages.manageSubscription', {
              count: subscriptionsInGarage?.length,
            })}
          </ReparkButton>
        )}

        {hasBookings && (
          <ReparkButton mode="text" onPress={handleManageBookingPress}>
            {t('myGarages.manageBooking', {
              count: bookingsInGarage?.length,
            })}
          </ReparkButton>
        )}
        {garage && (
          <GarageDeviceButtons garage={garage} navigation={navigation} />
        )}
      </Card>
    </>
  );
};

const styles = StyleSheet.create({
  container: {
    display: 'flex',
    flexDirection: 'column',
    padding: 20,
    margin: 10,
  },
  image: {
    height: 60,
    width: 60,
    aspectRatio: 1,
    borderRadius: 15,
    marginRight: 10,
  },
});
