import { useTranslation } from 'react-i18next';
import BottomSheetFrame from '../../bottom-sheet/BottomSheetFrame';
import { GarageType } from '../types';
import ReparkButton from '~/src/components/ReparkButton';
import React from 'react';
import { openLinkInBrowser } from '~/src/utils/helpers';
import { View } from 'react-native';
import rptheme from '~/rptheme';

type FloorPlansSheetProps = {
  floorPlans: GarageType['floorPlans'];
};

export const FloorPlansSheet = (props: FloorPlansSheetProps) => {
  const { floorPlans } = props;
  const { t } = useTranslation();

  const openFloorPlan = (path: GarageType['floorPlans'][number]['path']) => {
    openLinkInBrowser(path);
  };

  return (
    <BottomSheetFrame
      title={t('garage.floorPlanSheetTitle', { count: floorPlans.length })}
    >
      <View>
        {floorPlans.map((floorPlan, index) => (
          <ReparkButton
            key={`${floorPlan.title}-${index}`}
            mode="contained"
            color={rptheme.colors.primary}
            onPress={() => openFloorPlan(floorPlan.path)}
          >
            {floorPlan.title}
          </ReparkButton>
        ))}
      </View>
    </BottomSheetFrame>
  );
};
