import React from 'react';
import { FAB } from 'react-native-paper';
import rptheme from '~/rptheme';
import { NavProp, RootScreens } from '../navigation';
import { LocationStore } from '../location/LocationStore';
import { GarageStore } from '../garage/GarageStore';

type NearbyFABProps = {
  navigation: NavProp;
  isInGroup?: 'top' | 'bottom' | 'mid';
  getGarageId: Function;
};
export const NearbyFAB = (props: NearbyFABProps) => {
  const { isInGroup, navigation, getGarageId } = props;
  const [garages] = GarageStore((store) => [store.garages]);
  const [locationPermission, userDistanceTo] = LocationStore((store) => [
    store.locationPermission,
    store.userDistanceTo,
  ]);

  const handlePress = () => {
    const doAsync = async () => {
      const status = locationPermission?.status || 'undetermined';
      console.log(status);
      if (status === 'undetermined') {
        navigation.navigate(RootScreens.NearbyScreen);
      } else if (status !== 'denied') {
        const garagesWithDistance = await Promise.all(
          garages.map(async (garage) => {
            const distance = await userDistanceTo(garage);
            return {
              ...garage,
              distance: distance && distance.toFixed(2),
            };
          })
        );
        garagesWithDistance.sort((a, b) => a.distance - b.distance);
        getGarageId(garagesWithDistance[0].id);
      } else {
        navigation.navigate(RootScreens.NearbyScreen);
      }
    };
    doAsync();
  };

  return (
    <FAB
      icon={'nearme'}
      onPress={handlePress}
      color={rptheme.colors.primary}
      style={getStyles(isInGroup)}
    />
  );
};

const getStyles = (location?: 'top' | 'bottom' | 'mid') => {
  const backgroundColor = 'white';
  if (!location) {
    return {
      backgroundColor,
    };
  }
  switch (location) {
    case 'top':
      return {
        marginBottom: 10,
        backgroundColor,
      };
    case 'bottom':
      return {
        marginTop: 10,
        backgroundColor,
      };
    case 'mid':
      return {
        marginVeritcal: 10,
        backgroundColor,
      };
    default:
      return {
        margin: 0,
        backgroundColor,
      };
  }
};
