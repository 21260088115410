import feathers from '@feathersjs/feathers';
import auth from '@feathersjs/authentication-client';
import socketio from '@feathersjs/socketio-client';
import io from 'socket.io-client';
import config from '~/config/config';
import storage from '~/config/storage';

console.log('Initializing Feathers client');
console.log('Configuring web sockets on', config().server);

const socket = io(config().server, {
  transports: ['websocket'],
  forceNew: true,
});

export const feathersClient = feathers();
feathersClient.configure(
  socketio(socket, {
    timeout: 10000,
  })
);

feathersClient.configure(
  auth({
    storage: storage(),
  })
);
console.log('Feathers client initialized');

// const restClient = rest(Constants.manifest?.extra?.server);
// feathersClient.configure(restClient.axios(axios));
