import * as React from 'react';
import { Text, View } from 'react-native';
import { useTranslation } from 'react-i18next';
import Constants from 'expo-constants';
import storage from '~/config/storage';
import ReparkButton from '~/src/components/ReparkButton';
import { changeLanguage } from './helpers';
import { AuthStore } from '../authentication/AuthStore';
import { langEmojis } from './lang-emojis';

type LanguageSwitcherProps = {
  onLanguageChange: Function;
};

export default function LanguageSwitcher(props: LanguageSwitcherProps) {
  const { i18n } = useTranslation();
  const defaultLanguage = Constants.manifest?.extra?.locize?.defaultLanguage;
  const [languages, setLanguages] = React.useState(defaultLanguage);
  const [loggedIn] = AuthStore((store) => [store.loggedIn]);

  React.useEffect(() => {
    const getLocizeLanguages = async () => {
      const storedLanguages = await storage().getItem('locizeLanguages');
      if (storedLanguages) {
        setLanguages(JSON.parse(storedLanguages));
      }
    };
    getLocizeLanguages();
  }, []);

  if (languages) {
    return (
      <>
        <View style={{ margin: 25 }}>
          {Object.keys(languages).map((lang) => {
            return (
              <View key={lang}>
                <ReparkButton
                  onPress={() => {
                    changeLanguage({ i18n, lang, saveSettings: loggedIn });
                    props.onLanguageChange();
                  }}
                  mode={i18n.language !== lang ? 'outlined' : 'contained'}
                >
                  <Text style={{ fontSize: 20 }}>
                    {langEmojis[lang as keyof typeof langEmojis]}{' '}
                    {languages[lang].nativeName}
                  </Text>
                </ReparkButton>
              </View>
            );
          })}
        </View>
      </>
    );
  }
  return null;
}
