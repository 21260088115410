import React, { Ref } from 'react';
import { StyleSheet, View } from 'react-native';
import { zodResolver } from '@hookform/resolvers/zod';
import { createVehicleZodSchema } from '~/src/rules/inputValidationRules';
import { useForm, Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { VehicleStore } from './VehicleStore';
import ReparkButton from '~/src/components/ReparkButton';
import { debugResolverForm } from '~/src/utils/helpers';
import { ReparkLicencePlateInput } from '~/src/components/ReparkLicencePlateInput';

type LicencePlateFormProps = {
  onSubmit?: (newVehicle: { id: string }) => void;
};

export const LicencePlateForm = React.forwardRef(
  (props: LicencePlateFormProps, ref: Ref<any>) => {
    const { t } = useTranslation();
    const [createVehicle] = VehicleStore((store) => [store.createVehicle]);
    const vehicleSchema = createVehicleZodSchema(t);
    const { onSubmit } = props;

    const {
      control,
      handleSubmit,
      formState: { errors },
    } = useForm<{
      licencePlateData: {
        licencePlate: string;
        countryOfOrigin: String;
        id?: string;
      };
    }>({
      resolver: async (data: any, context: any, options: any) => {
        await debugResolverForm({
          schema: vehicleSchema,
          data: data.licencePlateData,
          context,
          options,
        });
        return zodResolver(vehicleSchema)(
          data.licencePlateData,
          context,
          options
        );
      },
      defaultValues: {
        licencePlateData: {
          id: undefined,
          licencePlate: '',
          countryOfOrigin: 'AT',
        },
      },
    });

    const submit = handleSubmit(
      async (data) => {
        const newVehicle = await createVehicle(data);
        onSubmit?.(newVehicle);
        return newVehicle;
      },
      () => {
        // throw new Error('Input invalid');
      }
    );

    React.useImperativeHandle(ref, () => ({
      submit,
    }));

    return (
      <View testID="vehicleForm" style={styles.container}>
        <View style={{ flex: 1 }}>
          <Controller
            name="licencePlateData"
            control={control}
            render={({ field: { onChange } }) => (
              <ReparkLicencePlateInput
                testID={'licencePlateInput'}
                onChangeValue={onChange}
                secureTextEntry={false}
                autoCorrect={false}
                spellCheck={false}
                keyboardType={'default'}
                autoFocus={false}
                placeholder={t('Vehicle.licencePlateAndCountry')}
                zoderror={(errors as any).licencePlate}
              />
            )}
          ></Controller>
          <ReparkButton onPress={submit}>
            {t('Offer.saveVehicleAndContinue')}
          </ReparkButton>
        </View>
      </View>
    );
  }
);

const styles = StyleSheet.create({
  container: { flexDirection: 'row', width: '100%' },
  inlineContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 16,
  },
  textInputContainer: { marginVertical: 0 },
});
