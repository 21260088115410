import * as React from 'react';
import {
  NavigationContainer,
  NavigationProp,
  useNavigationContainerRef,
} from '@react-navigation/native';

import {
  createStackNavigator,
  StackNavigationProp,
} from '@react-navigation/stack';
import { useTranslation } from 'react-i18next';
import AppLoading from 'expo-app-loading';
import ReparkGradient from '~/src/components/ReparkGradient';
import ReparkLogo from '~/src/components/ReparkLogo';
import NotFoundScreen from '~/src/features/notfound/NotFoundScreen';
import WebViewScreen from '~/src/features/webview/WebViewScreen';
import { linking } from './linking';
import { headerTitleAlign } from './helpers';
import analytics from '~/src/features/analytics/analytics';
import { gestureHandlerRootHOC } from 'react-native-gesture-handler';
import ClaimAccessScreen from '../claim-access/ClaimAccessScreen';
import MapScreen from '../map/MapScreen';
import ProfileStackNavigation from '../user-profile/ProfileStackNavigation';
import { MyGaragesScreen } from '../my-garages/MyGaragesScreen';
import { DefaultTheme } from 'react-native-paper';
import rptheme from '~/rptheme';
import HeaderLeft from '~/src/components/HeaderLeft';
import AboutStackNavigation from '../about/AboutStackNavigation';
import BookingStackNavigation from '../booking/BookingStackNavigation';
import { CheckoutScreen } from '../payment/CheckoutScreen';
import { DebugStackNavigation } from '../debug/DebugStackNavigation';
import { OnboardingScreen } from '../onboarding/OnboardingScreen';
import { AuthStore } from '../authentication/AuthStore';
import { VerifyEmailAddressScreen } from '../user-profile/VerifyEmailAddressScreen';
import { NearbyScreen } from '../nearby/NearbyScreen';
import { metaTrackPageView } from '../meta-pixel/renderThePixel.web';
import { SubscriptionStackNavigation } from '../subscriptions/SubscriptionStackNavigation';
import { useRemovePaymentIntent } from '../payment/hooks/useRemovePaymentIntent';

const RootStack = createStackNavigator();

const ReparkTheme = {
  ...DefaultTheme,
  colors: {
    ...DefaultTheme.colors,
    background: rptheme.colors.appBackground,
    card: 'white',
    border: 'white',
  },
};

export type NavProp = NavigationProp<any> & StackNavigationProp<any>;

type NavigationProviderProps = {
  children?: Array<JSX.Element> | JSX.Element;
};

export enum RootScreens {
  MapScreen = 'Map',
  GarageOpenerScreen = 'GarageOpener',
  ProfileStack = 'ProfileStack',
  NotFoundScreen = '404',
  WebViewScreen = 'WebView',
  AboutStack = 'AboutStack',
  BookingStack = 'BookingStack',
  ClaimAccessScreen = 'ClaimAccess',
  CheckOutScreen = 'Checkout',
  DebugStack = 'DebugStack',
  VerifyEmailAddressScreen = 'VerifyEmailAddressScreen',
  OnboardingScreen = 'Onboarding',
  NearbyScreen = 'Nearby',
  SubscriptionStack = 'SubscriptionStack',
}

const Navigation = gestureHandlerRootHOC(
  ({ children }: NavigationProviderProps) => {
    const navigationRef = useNavigationContainerRef();
    const routeNameRef = React.useRef<any>({});

    const { t } = useTranslation();

    const headerLeft = HeaderLeft.bind(navigationRef);

    const loggedIn = AuthStore((store) => store.loggedIn);
    const { remove, isRemoving } = useRemovePaymentIntent();

    return (
      <>
        <NavigationContainer
          linking={linking}
          ref={navigationRef}
          fallback={<AppLoading></AppLoading>}
          onReady={() => {
            if (navigationRef.isReady())
              routeNameRef.current = navigationRef.getCurrentRoute()?.name;
          }}
          onStateChange={async () => {
            const previousRouteName = routeNameRef.current;
            const currentRouteName = navigationRef.getCurrentRoute()?.name;
            const options: any = navigationRef.getCurrentOptions();
            const pageTitle = options?.title;

            if (previousRouteName !== currentRouteName) {
              analytics.track('screen_view', {
                page_location: currentRouteName,
                page_title: pageTitle,
                page_referrer: previousRouteName,
              });
              metaTrackPageView();
            }

            routeNameRef.current = currentRouteName;
          }}
          theme={ReparkTheme}
        >
          <RootStack.Navigator
            screenOptions={{
              headerTitleAlign: headerTitleAlign(),
            }}
            initialRouteName={RootScreens.MapScreen}
          >
            <RootStack.Screen
              options={({}) => ({
                title: 'Map',
                headerTitle: '',
                headerShown: false,
              })}
              name={RootScreens.MapScreen}
              component={MapScreen}
            ></RootStack.Screen>

            {loggedIn && (
              <RootStack.Screen
                options={({}) => ({
                  title: t('MyGaragesScreen.screenTitle'),
                  headerShown: false,
                })}
                name={RootScreens.GarageOpenerScreen}
                component={MyGaragesScreen}
              ></RootStack.Screen>
            )}

            <RootStack.Screen
              name={RootScreens.ProfileStack}
              options={({}) => ({
                title: t('ProfileScreen.screenTitle'),
                headerShown: false,
              })}
              component={ProfileStackNavigation}
            ></RootStack.Screen>

            <RootStack.Screen
              name={RootScreens.NotFoundScreen}
              component={NotFoundScreen}
              options={{
                title: t('NotFoundScreen.screenTitle'),
                headerShown: true,
                headerTitle: () => <ReparkLogo />,
                headerBackground: () => <ReparkGradient />,
              }}
            ></RootStack.Screen>

            <RootStack.Screen
              name={RootScreens.WebViewScreen}
              component={WebViewScreen}
              options={{
                headerShown: true,
                headerTransparent: true,
                headerTitleAlign: headerTitleAlign(),
                presentation: 'modal',
                headerBackTitleVisible: false,
                title: '',
                headerLeft: (props: any) => {
                  props.fallBackScreen = RootScreens.MapScreen;
                  return headerLeft(props);
                },
              }}
            ></RootStack.Screen>

            <RootStack.Screen
              name={RootScreens.AboutStack}
              options={({}) => ({
                title: t('AboutScreen.screenTitle'),
                headerShown: false,
              })}
              component={AboutStackNavigation}
            ></RootStack.Screen>

            {loggedIn && (
              <RootStack.Screen
                name={RootScreens.BookingStack}
                options={({}) => ({
                  headerShown: false,
                })}
                component={BookingStackNavigation}
              ></RootStack.Screen>
            )}

            <RootStack.Screen
              name={RootScreens.ClaimAccessScreen}
              options={({}) => ({
                title: 'Claim Access',
                headerShown: true,
                presentation: 'modal',
                headerTransparent: false,
                headerTitle: () => <ReparkLogo></ReparkLogo>,
                headerBackground: () => <ReparkGradient />,
                headerLeft: (props: any) => {
                  props.fallBackScreen = 'Map';
                  return headerLeft(props);
                },
              })}
              component={ClaimAccessScreen}
            ></RootStack.Screen>

            <RootStack.Screen
              name={RootScreens.VerifyEmailAddressScreen}
              options={({}) => ({
                title: 'VerifyEmailAddress',
                headerShown: true,
                headerTransparent: false,
                headerTitle: () => <ReparkLogo></ReparkLogo>,
                headerBackground: () => <ReparkGradient />,
                headerLeft: (props: any) => {
                  props.fallBackScreen = 'Map';
                  return headerLeft(props);
                },
              })}
              component={VerifyEmailAddressScreen}
            ></RootStack.Screen>

            {loggedIn && (
              <RootStack.Screen
                name={RootScreens.CheckOutScreen}
                options={({}) => ({
                  title: t('Checkout.screenTitle'),
                  headerShown: true,
                  headerTransparent: true,
                  headerLeft: (props: any) => {
                    props.fallBackScreen = RootScreens.MapScreen;
                    props.onPress = async () => {
                      await remove();
                      navigationRef.goBack();
                    };
                    props.disabled = isRemoving;
                    return headerLeft(props);
                  },
                })}
                component={CheckoutScreen}
              ></RootStack.Screen>
            )}

            {loggedIn && (
              <RootStack.Screen
                name={RootScreens.DebugStack}
                options={({}) => ({
                  title: 'Debug',
                  headerShown: false,
                  headerTransparent: false,
                  headerLeft: (props: any) => {
                    props.fallBackScreen = RootScreens.MapScreen;
                    return headerLeft(props);
                  },
                })}
                component={DebugStackNavigation}
              ></RootStack.Screen>
            )}

            {loggedIn && (
              <RootStack.Screen
                name={RootScreens.OnboardingScreen}
                options={({}) => ({
                  title: '',
                  headerShown: true,
                  headerTransparent: true,
                  headerLeft: () => <></>,
                })}
                component={OnboardingScreen}
              ></RootStack.Screen>
            )}

            <RootStack.Screen
              name={RootScreens.NearbyScreen}
              options={({}) => ({
                title: '',
                headerShown: true,
                headerTransparent: true,
                headerLeft: () => <></>,
              })}
              component={NearbyScreen}
            ></RootStack.Screen>

            <RootStack.Screen
              name={RootScreens.SubscriptionStack}
              options={({}) => ({
                title: '',
                headerShown: true,
                headerTransparent: true,
                headerLeft: () => <></>,
              })}
              component={SubscriptionStackNavigation}
            ></RootStack.Screen>
          </RootStack.Navigator>
        </NavigationContainer>
        {children}
      </>
    );
  }
);

export default Navigation;
