import { mdiInformation } from '@mdi/js';
import Icon from '@mdi/react';
import React from 'react';
import { View, Text, StyleSheet } from 'react-native';
import { Button } from 'react-native-paper';
import rptheme from '~/rptheme';

type ActionProp = {
  onPress: () => void;
  label: string;
};

type InfoBannerProps = {
  title?: string;
  text: string;
  icon?: string;
  backgroundColor?: string;
  textColor?: string;
  actions?: [ActionProp?, ActionProp?];
};

export const InfoBanner = (props: InfoBannerProps) => {
  const {
    icon = mdiInformation,
    title,
    text,
    backgroundColor = rptheme.colors.primary,
    textColor = 'white',
    actions,
  } = props;
  return (
    <View style={[styles.infoBanner, { backgroundColor }]}>
      <View>
        <View style={{ display: 'flex', flexDirection: 'row' }}>
          <View style={styles.iconContainer}>
            <Icon path={icon} size={1} color={textColor} />
          </View>
          <View style={styles.infoTextContainer}>
            <Text style={[styles.infoTitle, { color: textColor }]}>
              {title}
            </Text>
            <Text style={[styles.infoText, { color: textColor }]}>{text}</Text>
          </View>
        </View>
      </View>
      {actions && actions?.length > 0 && (
        <View
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-end',
          }}
        >
          {actions.map((action, index) => (
            <Button
              key={index}
              onPress={action?.onPress}
              color={textColor}
              labelStyle={{ color: textColor }}
            >
              {action?.label}
            </Button>
          ))}
        </View>
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  infoBanner: {
    padding: 10,
    borderRadius: 5,
    marginBottom: 10,
    display: 'flex',
    flexDirection: 'column',
  },
  iconContainer: {
    alignSelf: 'center',
  },
  infoTextContainer: {
    flexShrink: 1, // THIS MAKES SURE THE TEXT WRAPS. No idea why, but it works.
    paddingLeft: 10,
  },
  infoText: {
    fontSize: 14,
    lineHeight: 20,
    flexShrink: 1, // THIS MAKES SURE THE TEXT WRAPS. No idea why, but it works.
  },
  infoTitle: {
    fontSize: 16,
    fontWeight: 'bold',
    flexShrink: 1, // THIS MAKES SURE THE TEXT WRAPS. No idea why, but it works.
    marginBottom: 3,
  },
});
