import { t } from 'i18next';
import React from 'react';
import { StyleSheet, View, Text } from 'react-native';
import { Card, Subheading } from 'react-native-paper';
import rptheme from '~/rptheme';
import ReparkButton from '~/src/components/ReparkButton';
import { stripeIsMocked } from '~/src/utils/helpers';
import analytics, { garageToGAItem } from '../analytics/analytics';
import { GarageStore } from '../garage/GarageStore';
import { NavProp, RootScreens } from '../navigation';
import { VehicleStore } from '../vehicles/VehicleStore';
import { PaymentForm } from './PaymentForm';
import { PaymentStore } from './PaymentStore';
import { PaymentFailedNote } from './components/PaymentFailedNote';
import { ProcessingModal } from './components/ProcessingModal';
import { Modal } from '../modal/Modal';
import { BookingStore } from '../booking/BookingStore';
import { BookingDuplicateDetails } from '../booking/BookingDuplicateDetails';
import { feathersClient } from '~/src/services/feathers';
import { metaTrackPurchase } from '../meta-pixel/renderThePixel.web';
import { GarageCheckoutInfoModal } from './components/GarageCheckoutInfoModal';
import { useDuplicateBookingAlertHook } from './hooks/useDuplicateBookingAlertHook';
import { BookingDetailsCard } from './components/checkout/BookingDetailsCard';
import { VehicleCard } from './components/checkout/VehicleCard';
import { LegalText } from './components/checkout/LegalText';
import Icon from '@mdi/react';
import { mdiCheck } from '@mdi/js';
import { cardStyles } from './components/checkout/styles';
import { StripeError } from '@stripe/stripe-js';
import { StripeIntentFailedModal } from './components/paymentFailed/StripeIntentFailedModal';

export type CheckoutScreenProps = {
  navigation: NavProp;
  route: {
    params: {
      offer: any;
    };
  };
};

export const CheckoutScreen = (props: CheckoutScreenProps) => {
  const { navigation } = props;

  const [stripeError, setStripeError] = React.useState<StripeError | null>(
    null
  );

  const [offerStart, offerDuration, selectedGarageId, fetchGarages, getGarage] =
    GarageStore((store) => [
      store.offerStart,
      store.offerDuration,
      store.selectedGarageId,
      store.fetchGarages,
      store.getGarage,
    ]);
  const [selectedVehicle] = VehicleStore((store) => [store.selectedVehicle]);

  const [
    fetchPaymentMethods,
    paymentIntent,
    // fetchPaymentIntent,
    // removePaymentIntent,
  ] = PaymentStore((store) => [
    store.fetchPaymentMethods,
    store.paymentIntent,
    // store.fetchPaymentIntent,
    // store.removePaymentIntent,
  ]);

  const [insertBooking] = BookingStore((store) => [store.insertBooking]);

  const { duplicateBooking, setDuplicateBooking } =
    useDuplicateBookingAlertHook({
      offerStart,
      offerDuration,
      selectedGarageId,
    });

  const [isProcessingPayment, setIsProcessingPayment] = React.useState(false);
  const [isCreatingBooking, setIsCreatingBooking] = React.useState(false);

  const isProcessing = isProcessingPayment || isCreatingBooking;

  React.useEffect(() => {
    if (!paymentIntent) {
      navigation.navigate(RootScreens.MapScreen);
    }

    analytics.track('begin_checkout');

    feathersClient
      .service('bookings')
      .on('booking.successful', (booking: any) => {
        setIsCreatingBooking(false);
        insertBooking(booking);
        navigation.replace('BookingStack', {
          screen: 'BookingDetail',
          params: {
            id: booking.id,
          },
        });
      });

    feathersClient.service('bookings').on('booking.failed', (data: any) => {
      console.log('booking failed', data);
      onError(data);
    });

    return () => {
      feathersClient
        .service('bookings')
        .removeAllListeners('booking.successful');

      feathersClient.service('bookings').removeAllListeners('booking.failed');
    };
  }, []);

  if (!paymentIntent || !selectedGarageId) return null;

  const onPaymentSuccess = async () => {
    setIsProcessingPayment(false);
    setIsCreatingBooking(true);
    try {
      fetchPaymentMethods();
      analytics.track('purchase', {
        transaction_id: paymentIntent.id,
        value: paymentIntent.amount / 100,
        currency: 'EUR',
        items: [garageToGAItem(getGarage(selectedGarageId))],
      });
      metaTrackPurchase(
        paymentIntent.id,
        paymentIntent.amount / 100,
        getGarage(selectedGarageId)
      );
    } catch (e: any) {
      throw e;
    }
  };

  const onError = async (error: StripeError) => {
    setIsProcessingPayment(false);
    setIsCreatingBooking(false);
    setStripeError(error);
    await fetchGarages();

    // removePaymentIntent(paymentIntent.id);
    // if (getGarage(selectedGarageId).parkingSpots?.[0].offers?.length) {
    //   await fetchPaymentIntent({
    //     offerId: getGarage(selectedGarageId).parkingSpots?.[0].offers?.[0].id,
    //     start: offerStart,
    //     duration: offerDuration,
    //     licencePlate: selectedVehicle.licencePlate,
    //     bookingType: paymentIntent.bookingType,
    //   });
    // }
  };

  return (
    <View style={styles.container} testID="CheckoutScreenContainerTestId">
      <Modal isOpen={duplicateBooking !== undefined} onClickOutside={() => {}}>
        <BookingDuplicateDetails
          {...props}
          duplicate={duplicateBooking}
          continueCheckout={() => setDuplicateBooking(undefined)}
        />
      </Modal>

      <GarageCheckoutInfoModal garage={getGarage(selectedGarageId)} />

      <ProcessingModal
        visible={isProcessing}
        isCreatingBooking={isCreatingBooking}
        isProcessingPayment={isProcessingPayment}
      />

      <StripeIntentFailedModal
        error={stripeError}
        onClose={() => setStripeError(null)}
      />

      <BookingDetailsCard
        garage={getGarage(selectedGarageId)}
        offerStart={offerStart}
        offerDuration={offerDuration}
        paymentIntent={paymentIntent}
      />

      <VehicleCard
        navigation={navigation}
        garage={getGarage(selectedGarageId)}
      />

      {selectedVehicle &&
      getGarage(selectedGarageId).parkingSpots.length !== 0 ? (
        <Card style={cardStyles.card}>
          <Subheading style={cardStyles.heading}>
            {t('Checkout.paymentInfo')}
          </Subheading>
          {stripeIsMocked() && false ? (
            <ReparkButton onPress={onPaymentSuccess}>
              Book (Development)
            </ReparkButton>
          ) : (
            <>
              <PaymentForm
                onIsProcessingPayment={() => setIsProcessingPayment(true)}
                onSuccess={onPaymentSuccess}
                onError={(e: any) => onError(e)}
              />

              <Text style={styles.cancelCheckMarkText}>
                <Icon path={mdiCheck} size={1} color={rptheme.colors.accent} />
                {t('Checkout.cancelationPossibleUntilStart')}
              </Text>

              <LegalText
                paymentAmount={paymentIntent.amount}
                companyName={
                  paymentIntent.metadata?.companyName ||
                  getGarage(selectedGarageId)?.company?.name
                }
              />
            </>
          )}
        </Card>
      ) : (
        <>
          <PaymentFailedNote navigation={navigation} t={t} />
        </>
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    marginTop: 30,
    flex: 1,
    padding: 20,
    maxWidth: rptheme.screenSmall,
    width: '100%',
    alignSelf: 'center',
  },
  cancelCheckMarkText: {
    textAlign: 'center',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: rptheme.colors.accent,
    marginBottom: 10,
  },
});
