import { useTranslation } from 'react-i18next';
import config from '~/config/config';
import rptheme from '~/rptheme';
import { NavProp, RootScreens } from '../features/navigation';

type FABActionsProps = {
  navigation: NavProp;
  user: any;
};

export const FABActions = (props: FABActionsProps) => {
  const { user, navigation } = props;
  const { t } = useTranslation();

  const feedbackAction = {
    icon: 'feedback',
    label: t('fabaction.feedback'),
    color: rptheme.colors.primary,
    onPress: () => {
      navigation.navigate(RootScreens.WebViewScreen, {
        url: `${config().webPages.feedback}?email=${
          user?.email
        }&phoneNumber=${encodeURIComponent(user?.phoneNumber)}`,
      });
    },
  };

  const emailSupportAction = {
    icon: 'email',
    label: t('fabaction.support'),
    color: rptheme.colors.primary,
    onPress: () => {
      navigation.navigate(RootScreens.WebViewScreen, {
        url: `${config().webPages.support}?email=${
          user?.email
        }&phoneNumber=${encodeURIComponent(user?.phoneNumber)}`,
      });
    },
  };

  const phoneSupportAction = {
    icon: 'phone-support',
    label: t('fabaction.phoneSupport'),
    color: rptheme.colors.primary,
    onPress: () => {
      navigation.navigate(RootScreens.WebViewScreen, {
        url: config().webPages.phoneSupport,
      });
    },
  };

  const FAQAction = {
    icon: 'question',
    label: t('fabaction.faq'),
    color: rptheme.colors.primary,
    onPress: () => {
      navigation.navigate(RootScreens.WebViewScreen, {
        url: config().webPages.faq,
      });
    },
  };

  return {
    feedbackAction,
    emailSupportAction,
    phoneSupportAction,
    FAQAction,
  };
};
