import { t } from 'i18next';
import React from 'react';
import { Platform, StyleSheet, View } from 'react-native';
import CountryPicker, {
  Country,
  CountryCode,
} from 'react-native-country-picker-modal';
import { Paragraph, Subheading, TextInput } from 'react-native-paper';
import { Icon } from 'react-native-paper/lib/typescript/components/Avatar/Avatar';
import rptheme from '~/rptheme';
import { ReparkIcon } from './ReparkIcon';

type ReparkLicencePlateInputProps = Omit<
  React.ComponentProps<typeof TextInput>,
  'autoComplete'
> & {
  topLabel?: string;
  onChangeValue: (licencePlateData: {
    licencePlate: string;
    countryOfOrigin: string;
  }) => void;
  zoderror?: any;
  showError?: boolean;
  valueIn?: { licencePlate: string; countryOfOrigin: string };
} & Partial<React.ComponentProps<typeof CountryPicker>>;

const defaultLicencePlate = {
  licencePlate: '',
  countryOfOrigin: 'AT',
};

export const ReparkLicencePlateInput = (
  props: ReparkLicencePlateInputProps
) => {
  const {
    onChangeValue,
    topLabel,
    valueIn = defaultLicencePlate,
    zoderror,
  } = props;
  const [licencePlateData, setLicencePlateData] = React.useState<any>(valueIn);

  React.useEffect(() => {
    setLicencePlateData(valueIn);
  }, [valueIn]);

  const onSelect = (country: Country) => {
    setLicencePlateData({
      ...licencePlateData,
      ['countryOfOrigin']: country.cca2 as string,
    });
  };

  const onTextChange = (text: string) => {
    setLicencePlateData({ ...licencePlateData, ['licencePlate']: text });
  };

  React.useEffect(() => {
    onChangeValue(licencePlateData);
  }, [licencePlateData]);

  return (
    <View style={styles.container}>
      {topLabel && (
        <Subheading style={styles.subheading}>{topLabel}</Subheading>
      )}
      <TextInput
        theme={{ roundness: rptheme.roundness }}
        style={styles.outerTextInput}
        mode="outlined"
        autoComplete="tel"
        dense
        outlineColor={zoderror ? rptheme.colors.error : rptheme.colors.primary}
        activeOutlineColor={
          zoderror ? rptheme.colors.error : rptheme.colors.primary
        }
        {...props}
        render={(props: any) => (
          <View style={styles.innerInputContainer}>
            <View style={styles.pickerContainer}>
              <CountryPicker
                {...{
                  withEmoji: false,
                  countryCode:
                    (licencePlateData?.countryOfOrigin as CountryCode) || 'AT',
                  withFlag: true,
                  preferredCountries: [
                    'AT',
                    'DE',
                    'HU',
                    'SK',
                    'SI',
                    'CH',
                    'CZ',
                    'IT',
                    'HR',
                    'UA',
                  ],
                  withModal: true,
                  withAlphaFilter: false,
                  onSelect,
                }}
                containerButtonStyle={styles.countryPicker}
              />
              <ReparkIcon
                name="chevron-right"
                style={[
                  styles.chevron,
                  {
                    transform: [{ rotate: '90deg' }],
                  },
                ]}
              />
            </View>
            <TextInput
              {...props}
              maxLength={10}
              mode="flat"
              dense
              outlineColor="transparent"
              underlineColor="transparent"
              activeUnderlineColor="transparent"
              activeOutlineColor="transparent"
              onChangeText={onTextChange}
              style={styles.innerTextInput}
              value={licencePlateData.licencePlate}
            />
          </View>
        )}
      />
      <Paragraph style={styles.countryNotice}>
        {t('LicencePlate.countryNotice')}
      </Paragraph>
      {zoderror && (
        <Paragraph style={styles.errorText}>{zoderror.message}</Paragraph>
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    marginVertical: 10,
  },
  subheading: {
    color: rptheme.colors.primary,
  },
  outerTextInput: {
    paddingHorizontal: 16,
  },
  innerInputContainer: {
    display: 'flex',
    flexDirection: 'row',
  },
  pickerContainer: {
    flexDirection: 'row',
  },
  chevron: {
    width: 10,
    alignSelf: 'center',
    marginTop: 3,
  },
  countryPicker: Platform.OS === 'android' ? { marginTop: 3 } : {},
  innerTextInput: {
    backgroundColor: 'transparent',
    width: '80%',
    marginTop: Platform.OS === 'web' ? 0 : -1,
  },
  countryNotice: {
    paddingTop: 5,
    color: rptheme.colors.gray,
    paddingHorizontal: 10,
  },
  errorText: {
    color: rptheme.colors.error,
    marginTop: 5,
    textAlign: 'center',
  },
});
