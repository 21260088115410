import * as React from 'react';
import { Dimensions, Platform, StyleSheet, View } from 'react-native';
import { FAB } from 'react-native-paper';
import { Map } from '~/src/features/map/Map';
import { BottomSheetStore } from '../bottom-sheet/BottomSheetStore';
import { OfferSheet, offerSheetOptions } from '../offer/OfferSheet';
import { LocationStore } from '~/src/features/location/LocationStore';
import { ReparkMarker } from './Marker';
import FABFrame from '~/src/components/FABFrame';
import { GarageStore } from '../garage/GarageStore';
import { AuthStore } from '../authentication/AuthStore';
import { DatePicker } from '~/src/components/DatePicker';
import { NavProp, RootScreens } from '../navigation';
import ReparkButton from '~/src/components/ReparkButton';
import { useTranslation } from 'react-i18next';
import AvatarFAB from '../user-profile/AvatarFAB';
import FABGroup from '~/src/components/FABGroup';
import { FABActions } from '~/src/utils/FABActions';
import rptheme from '~/rptheme';
import { DebugFAB } from '../debug/DebugFAB';
import { BookingButton } from '../booking/components/BookingButton';
import { latitudeForBottomSheet } from './helpers';
import { NearbyFAB } from '../nearby/NearbyFAB';
import { TrackingStore } from '../tracking/trackingStore';
import { LanguageSwitcherFAB } from '../language-switcher/LanguageSwitcherFAB';

export type MapScreenProps = {
  navigation: NavProp;
  route?: any;
};

type Region = {
  longitude: number;
  latitude: number;
  longitudeDelta: number;
  latitudeDelta: number;
  altitude: number;
  zoom: number;
};

export type MapProps = {
  region: Region;
  userLocation?: any;
  children?: Array<JSX.Element> | JSX.Element | false;
  setRegion?: any;
};

export default React.memo(function MapScreen({
  navigation,
  route,
}: MapScreenProps) {
  const { t } = useTranslation();

  const mapRef = React.useRef<any>();
  const [nearest, setNearest] = React.useState();
  const [openSheet, bottomSheetShow, sheetPosition, sheetProps] =
    BottomSheetStore((store) => [
      store.openSheet,
      store.show,
      store.position,
      store.sheetProps,
    ]);
  const [
    location,
    updateLocationsDataRequest,
    setLocationSearchParams,
    hasBeenSearched,
  ] = LocationStore((store) => [
    store.location,
    store.updateLocationsDataRequest,
    store.setLocationSearchParams,
    store.hasBeenSearched,
  ]);
  const [loggedIn, user, setRedirectAfterLogin] = AuthStore((store) => [
    store.loggedIn,
    store.user,
    store.setRedirectAfterLogin,
  ]);
  const { emailSupportAction, feedbackAction, FAQAction } = FABActions({
    navigation,
    user,
  });

  const [
    garages,
    getGarage,
    isFetchingGarages,
    selectedGarageId,
    selectGarage,
    myGarages,
    isInitialGarageFetchDone,
  ] = GarageStore((store) => [
    store.garages,
    store.getGarage,
    store.isFetchingGarages,
    store.selectedGarageId,
    store.selectGarage,
    store.myGarages,
    store.isInitialGarageFetchDone,
  ]);

  const [currentOfferView, stopOfferViewTracking, trackOfferViewClose] =
    TrackingStore((store) => [
      store.currentOfferView,
      store.stopOfferViewTracking,
      store.trackOfferViewClose,
    ]);
  const [userLocation, setUserLocation] = React.useState<any>();

  const [region, setRegion] = React.useState({
    longitude: 16.373127,
    latitude: 48.208492,
    longitudeDelta: 0.1,
    latitudeDelta: 0.1,
    altitude: 80000,
    zoom: 11,
  });

  React.useEffect(() => {
    setUserLocation(location);
  }, [location]);

  React.useEffect(() => {
    if (
      route?.params?.id &&
      isInitialGarageFetchDone &&
      getGarage(route.params?.id)
    ) {
      showGarage({ id: route.params.id });
      if (route.params?.refId && loggedIn && user?.id) {
        updateLocationsDataRequest(route.params.refId, user.id).then();
        setLocationSearchParams(route.params);
      }
    }
  }, [isInitialGarageFetchDone]);

  React.useEffect(() => {
    if (hasBeenSearched) {
      return;
    }

    if (nearest && nearest !== '' && isInitialGarageFetchDone) {
      if (getGarage(nearest)) {
        showGarage({ id: nearest });
        return;
      }
    }
    setNearest(undefined);
  }, [nearest]);

  React.useEffect(() => {
    const garageId = getGarage(route?.params?.id)?.id;
    setNearest(undefined);
    if (!loggedIn && garageId) setRedirectAfterLogin(garageId);
    if (!route?.params?.id) {
      navigation.setParams({
        distance: undefined,
        refId: undefined,
        name: undefined,
        coords: undefined,
      });
      setLocationSearchParams(null);
    }
  }, [route?.params?.id]);

  React.useEffect(() => {
    if (selectedGarageId && bottomSheetShow) {
      if (Platform.OS !== 'web') {
        mapRef.current.animateCamera({
          center: {
            longitude: getGarage(selectedGarageId).coordinates.longitude,
            latitude:
              getGarage(selectedGarageId).coordinates.latitude -
              Dimensions.get('screen').height * 0.0000184729, // This should be adapted to center map in upper third
            // TODO: Implement correct latitude shift when bottom sheet is open (see web version)
          },
          altitude: 12000,
        });
      } else {
        const longitude = parseFloat(
          getGarage(selectedGarageId).coordinates.longitude
        );
        const latitude = latitudeForBottomSheet({
          latitude: getGarage(selectedGarageId).coordinates.latitude,
          sheetProps,
          region,
        });

        setRegion({
          ...region,
          ...{
            longitude,
            latitude,
          },
        });
      }
    }
    if (!selectedGarageId || !bottomSheetShow) {
      if (currentOfferView !== null) {
        trackOfferViewClose().then(() => stopOfferViewTracking());
      }
    }
  }, [selectedGarageId, bottomSheetShow]);

  const showGarage = (garage: any) => {
    selectGarage(garage.id);
    openSheet(
      <OfferSheet navigation={navigation} />,
      offerSheetOptions(navigation)
    );
  };

  const bottomLeft = (
    <FABGroup
      icon={'help'}
      actions={[feedbackAction, emailSupportAction, FAQAction]}
    />
  );

  return (
    <View
      style={{
        height:
          Platform.OS === 'web' && sheetPosition === 'top' ? '100vh' : '100%', // So that the fixed BookOfferButton stays down on iOS...
      }}
    >
      <FABFrame
        topRight={
          !bottomSheetShow ? (
            <AvatarFAB
              onPress={() => navigation.navigate(RootScreens.ProfileStack)}
            />
          ) : (
            <></>
          )
        }
        bottomCenter={
          !bottomSheetShow ? (
            loggedIn ? (
              <View style={styles.centerButtons}>
                <DatePicker isLoading={isFetchingGarages} mode={'outlined'} />
              </View>
            ) : (
              <ReparkButton
                testID="goToLoginButton"
                onPress={() => {
                  navigation.navigate(RootScreens.ProfileStack);
                }}
              >
                {t('AuthTexts.signInButtonLabel')}
              </ReparkButton>
            )
          ) : (
            <></>
          )
        }
        bottomLeft={bottomLeft}
        topCenter={
          !bottomSheetShow && loggedIn ? (
            <View style={styles.centerButtons}>
              <BookingButton navigation={navigation} />
            </View>
          ) : (
            <></>
          )
        }
        bottomRight={
          <>
            {loggedIn ? (
              <>
                {myGarages().length > 0 && (
                  <FAB
                    icon={'garage'}
                    onPress={() =>
                      navigation.replace(RootScreens.GarageOpenerScreen)
                    }
                    color={rptheme.colors.primary}
                    style={styles.fab}
                  />
                )}
              </>
            ) : (
              <LanguageSwitcherFAB />
            )}
          </>
        }
        topLeft={
          <View>
            <NearbyFAB
              navigation={navigation}
              isInGroup={'top'}
              getGarageId={setNearest}
            />
            <DebugFAB navigation={navigation} />
          </View>
        }
      >
        <Map
          ref={mapRef}
          region={region}
          userLocation={userLocation}
          setRegion={setRegion}
        >
          <>
            {garages.map((garage: any, idx: any) => {
              return (
                <ReparkMarker
                  isActive={bottomSheetShow && garage.id === selectedGarageId}
                  key={idx}
                  item={garage}
                  onPress={() => {
                    showGarage(garage);
                  }}
                  rate={garage.rate}
                />
              );
            })}
          </>
        </Map>
      </FABFrame>
    </View>
  );
});

const styles = StyleSheet.create({
  centerButtons: { marginTop: 8 },
  fab: { backgroundColor: 'white' },
});
