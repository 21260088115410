import React from 'react';
import { NavProp } from '../../navigation';
import { OpenGarageAlternativeInfo } from '../../garage/OpenGarageAlternativeInfo';
import { useTranslation } from 'react-i18next';
import OpenGarageButton from '../../garage/OpenGarageButton';
import { BottomSheetStore } from '../../bottom-sheet/BottomSheetStore';
import {
  SuccessSheet,
  successSheetOptions,
} from '../../garage/sheets/SuccessSheet';
import { ErrorSheet, errorSheetOptions } from '../../garage/sheets/ErrorSheet';

type BottomCenterProps = {
  garage: any;
  booking: any;
  navigation: NavProp;
};

export const BottomCenter = (props: BottomCenterProps) => {
  const { garage, booking, navigation } = props;
  const { t } = useTranslation();

  const [openSheet] = BottomSheetStore((store) => [store.openSheet]);

  return (
    <>
      {garage.accessType !== 'repark-app' ? (
        <OpenGarageAlternativeInfo
          accessType={garage.accessType}
          t={t}
        ></OpenGarageAlternativeInfo>
      ) : (
        <OpenGarageButton
          id={garage.id}
          bookingId={booking.id}
          t={t}
          openSuccess={(response: any) => {
            openSheet(
              <SuccessSheet {...response} navigation={navigation} />,
              successSheetOptions
            );
          }}
          openError={(response: any) => {
            console.log('Opening failed');
            openSheet(
              <ErrorSheet {...response} navigation={navigation} />,
              errorSheetOptions
            );
          }}
          booking={booking}
        />
      )}
    </>
  );
};
