import React from 'react';
import { Title } from 'react-native-paper';
import { Text } from 'react-native';
import { GarageStore } from '../garage/GarageStore';
import Select from '~/src/components/Select';
import { Calendar } from '~/src/components/calendar/Calendar';
import { Dayjs } from 'dayjs';
import ReparkButton from '~/src/components/ReparkButton';
import { createBookingRequest } from '../booking/bookingService';
import ReparkTextInput from '~/src/components/ReparkTextInput';
import { useTranslation } from 'react-i18next';
import { SnackbarStore } from '../snackbar/SnackbarStore';

type CreateBookingAsCompanyFormProps = {
  companyId: string;
};

export const CreateBookingAsCompanyForm = (
  props: CreateBookingAsCompanyFormProps
) => {
  const { companyId } = props;
  const { t } = useTranslation();
  const [garages, setOfferSearchParams, offerStart, offerDuration, getGarage] =
    GarageStore((store) => [
      store.garages.filter((garage: any) => garage.companyId === companyId),
      store.setOfferSearchParams,
      store.offerStart,
      store.offerDuration,
      store.getGarage,
    ]);

  const [fireSnack] = SnackbarStore((store) => [store.fireSnack]);

  const [selectedGarageId, setSelectedGarageId] = React.useState<any>();
  const [licencePlate, setLicencePlate] = React.useState<string>('');

  const [isCreatingBooking, setIsCreatingBooking] =
    React.useState<boolean>(false);

  React.useEffect(() => {
    if (garages.length === 1) {
      setSelectedGarageId(garages[0].id);
    }
  }, []);

  const offers =
    getGarage(selectedGarageId)?.parkingSpots?.flatMap(
      ({ offers }: any) => offers
    ) || [];

  const createBooking = async () => {
    setIsCreatingBooking(true);
    try {
      await createBookingRequest({
        offerId: offers[0].id,
        start: offerStart,
        duration: offerDuration,
        licencePlate,
        paymentIntentId: undefined,
      });

      setLicencePlate('');

      fireSnack({
        title: 'Buchung erstellt.',
        message: 'Buchung  wurde erfolgreich erstellt.',
        type: 'success',
      });
    } catch (e) {
      console.log(e);
      fireSnack({
        title: 'Buchung fehlgeschlagen.',
        message: 'Ein Fehler ist aufgetreten.',
        type: 'error',
      });
    } finally {
      setIsCreatingBooking(false);
    }
  };

  const buttonIsDisabled = offers.length === 0 || licencePlate.length < 3;

  return (
    <>
      <Title>Buchung erstellen</Title>

      {garages.length === 1 ? (
        <Text>Garage: {garages[0].addressString}</Text>
      ) : (
        <Select
          items={garages} // But why? Because `Select` uses property `id` as key...
          displayKey={'addressString'}
          onSelectItem={(garage) => setSelectedGarageId(garage?.id)}
        />
      )}
      <Calendar
        testID="calendar"
        selectedDate={offerStart}
        selectedDuration={offerDuration}
        onDateSelected={(date: Dayjs, duration = 1440) => {
          setOfferSearchParams(
            date.tz('Europe/Vienna').startOf('day'),
            duration
          );
        }}
        onClose={() => {}}
        maxBookingDaysInFuture={90}
      />

      <Text style={{ marginTop: -30, marginBottom: 30 }}>
        {offers.length} Plätze verfügbar.
      </Text>

      <ReparkTextInput
        testID={'licencePlateInput'}
        onChangeText={(text: string) => {
          setLicencePlate(
            text.replace(/[^a-zA-Z0-9ÖöÄäÜü]/g, '').toUpperCase()
          );
        }}
        value={licencePlate}
        maxLength={10}
        secureTextEntry={false}
        autoCorrect={false}
        spellCheck={false}
        autoComplete={false}
        keyboardType={'default'}
        autoFocus={false}
        label={t('Vehicle.licencePlate')}
        zoderror={null}
        icon={'car'}
      />
      <ReparkButton
        disabled={buttonIsDisabled || isCreatingBooking}
        loading={isCreatingBooking}
        onPress={() => createBooking()}
      >
        Buchung Erstellen
      </ReparkButton>
    </>
  );
};
