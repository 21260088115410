import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';
import React from 'react';
import config from '~/config/config';
import { version } from '~/package.json';
import { getEnv } from '~/src/utils/helpers';

export const initializeBugSnag = () => {
  Bugsnag.start({
    apiKey: config().bugsnag.apiKey || '',
    plugins: [new BugsnagPluginReact()],
    appVersion: version,
    enabledReleaseStages: ['production', 'staging'],
    releaseStage: getEnv(),
  });

  const ErrorBoundary = Bugsnag.getPlugin('react')?.createErrorBoundary(React);

  return { Bugsnag, ErrorBoundary };
};
