import React from 'react';
import { ScrollView, View } from 'react-native';
import { StyleSheet } from 'react-native';
import { Subheading } from 'react-native-paper';
import Profile from '~/src/features/user-profile/Profile';
import { useTranslation } from 'react-i18next';
import { AuthStore } from '../authentication/AuthStore';
import rptheme from '~/rptheme';
import { Auth } from '../authentication/Auth';
import { ReparkList } from '~/src/components/ReparkList';
import { RootScreens } from '../navigation';
import { ProfileStackScreenNames } from './ProfileStackNavigation';
import FABFrame from '~/src/components/FABFrame';
import { LanguageSwitcherFAB } from '../language-switcher/LanguageSwitcherFAB';

export default function ProfileScreen({ navigation }: any) {
  const { t } = useTranslation();
  const loggedIn = AuthStore((store) => store.loggedIn);

  const Bottom = () => {
    const listItems = [
      {
        showCondition: true,
        testID: 'aboutScreenLink',
        onPress: () => navigation.navigate(RootScreens.AboutStack),
        title: t('AboutScreen.screenTitle'),
        icon: 'info',
      },
      {
        showCondition: loggedIn,
        testID: 'settingsScreenLink',
        onPress: () =>
          navigation.navigate(ProfileStackScreenNames.SettingsScreen),
        title: t('ProfileScreen.settingsButtonLabel'),
        icon: 'settings',
      },
    ];

    return (
      <>
        <Subheading style={styles.bottomHeading}>
          {t('ProfileScreen.MoreSettings')}
        </Subheading>
        <ReparkList items={listItems} />
        <View style={{ height: 50 }}></View>
      </>
    );
  };

  return (
    <FABFrame topRight={<LanguageSwitcherFAB />}>
      <ScrollView
        contentContainerStyle={styles.container}
        keyboardDismissMode="on-drag"
        keyboardShouldPersistTaps="always"
      >
        {loggedIn ? (
          <>
            <Profile navigation={navigation}></Profile>
          </>
        ) : (
          <Auth goBackFallBack="Map" navigation={navigation} />
        )}
        <Bottom />
      </ScrollView>
    </FABFrame>
  );
}
const styles = StyleSheet.create({
  container: {
    marginTop: 30,
    flex: 1,
    maxWidth: rptheme.screenSmall,
    width: '100%',
    alignSelf: 'center',
  },
  bottomHeading: { color: 'grey', marginLeft: 25, marginTop: 30 },
});
