import React from 'react';
import { View, StyleSheet } from 'react-native';
import { Title } from 'react-native-paper';
import { CenteredAppView } from '~/src/components/CenteredAppView';
import { SubscriptionStore } from './SubscriptionStore';
import { NavProp, RootScreens } from '../navigation';
import { AuthStore } from '../authentication/AuthStore';
import { Auth } from '../authentication/Auth';
import { LicencePlateForm } from '../vehicles/LincencePlateInput';
import { VehiclePicker } from '../vehicles/VehiclePicker';
import { VehicleStore } from '../vehicles/VehicleStore';
import { CustomerDataForm } from './components/CustomerDataForm';
import { SubscriptionProgressBar } from './components/SubscriptionProgressBar';
import { SubscriptionsStackScreens } from './SubscriptionStackNavigation';
import { ContinueNextButtons } from './components/ContinueNextButtons';
import ReparkButton from '~/src/components/ReparkButton';
import { BottomSheetStore } from '../bottom-sheet/BottomSheetStore';
import { AddPaymentMethodSheet } from '../payment/addPaymentMethod/AddPaymentMethodSheet';
import { PaymentStore } from '../payment/PaymentStore';
import { CalendarOnly } from '~/src/components/calendar/CalendarOnly';
import { InfoBanner } from '../../components/InfoBanner';
import { findPriceByPriceIdInParkingSpot } from './helpers';
import { useTranslation } from 'react-i18next';
import { feathersClient } from '~/src/services/feathers';
import { GarageType } from '../garage/types';
import { PaymentMethodSelectList } from '../payment/components/paymentMethodPicker/PaymentMethodSelectList';

type DataInputScreenScreenProps = {
  navigation: NavProp;
  route: any;
};

export const DataInputScreen = (props: DataInputScreenScreenProps) => {
  enum dataInputSteps {
    startDate = 1,
    vehicle = 2,
    owner = 3,
    paymentMethod = 4,
  }
  const { t } = useTranslation();

  const { navigation } = props;
  const [loggedIn] = AuthStore((store) => [store.loggedIn]);
  const [step, setStep] = React.useState(dataInputSteps.startDate);
  const [openSheet] = BottomSheetStore((store) => [store.openSheet]);
  const [garage, setGarage] = React.useState<GarageType | null>(null);

  let progress;
  switch (step) {
    case dataInputSteps.startDate:
      progress = 0.25;
      break;
    case dataInputSteps.vehicle:
      progress = 0.5;
      break;
    case dataInputSteps.owner:
      progress = 0.7;
      break;
    case dataInputSteps.paymentMethod:
      progress = 0.8;
      break;
    default:
      progress = step / Object.keys(dataInputSteps).length / 2;
  }

  const [
    selectedPriceId,
    selectedPaymentMethod,
    selectPaymentMethod,
    setStartDate,
    selectedStartDate,
    selectedParkingSpotId,
  ] = SubscriptionStore((store) => [
    store.selectedPriceId,
    store.selectedPaymentMethod,
    store.selectPaymentMethod,
    store.setStartDate,
    store.selectedStartDate,
    store.selectedParkingSpotId,
  ]);

  const [selectedVehicle] = VehicleStore((store) => [store.selectedVehicle]);

  const [paymentMethods] = PaymentStore((store) => [store.paymentMethods]);

  const fetchGarage = async () => {
    try {
      return await feathersClient
        .service('garages')
        .get(props.route.params.garageId);
    } catch (e) {
      console.error(e);
      navigation.navigate(RootScreens.MapScreen);
    }
  };

  React.useEffect(() => {
    if (!selectedPriceId) {
      navigation.push(RootScreens.SubscriptionStack, {
        screen: SubscriptionsStackScreens.ChooseParkingSpot,
        params: {
          garageId: props.route.params.garageId,
        },
      });
    } else {
      fetchGarage().then((res) => {
        setGarage(res);
      });
    }
  }, []);

  if (!loggedIn)
    return (
      <CenteredAppView>
        <Auth goBackFallBack="Map" navigation={navigation} redirect={false} />
      </CenteredAppView>
    );

  const InfoBannerText = () => {
    if (!selectedParkingSpotId || !selectedPriceId) return '';

    const parkingSpot = garage?.parkingSpots.find(
      (parkingSpot) => parkingSpot.id === selectedParkingSpotId
    );

    if (!parkingSpot) return;
    const price = findPriceByPriceIdInParkingSpot(parkingSpot, selectedPriceId);

    if (price?.interval === 'month' && price?.interval_count === 1) {
      return t('SubscriptionDataInput.DateInfo.Monthly');
    }

    if (price?.interval === 'year' && price?.interval_count === 1) {
      return t('SubscriptionDataInput.DateInfo.Yearly');
    }

    return t(`SubscriptionDataInput.DateInfo.X${price?.interval}ly`, {
      intervalCount: price?.interval_count,
    });
  };

  return (
    <CenteredAppView>
      <SubscriptionProgressBar progress={progress} navigation={navigation} />
      <View style={styles.container}>
        {step === dataInputSteps.startDate && (
          <View>
            <Title style={{ marginBottom: 5 }}>
              {t('Subscription.steps.startdate.title')}
            </Title>

            {InfoBannerText() && <InfoBanner text={InfoBannerText() || ''} />}

            <CalendarOnly
              mode="day"
              selectedDate={selectedStartDate}
              onDateSelected={setStartDate}
              maxBookingDaysInFuture={30}
              selectedDuration={1440}
            />
            <ContinueNextButtons
              onBack={() => navigation.goBack()}
              onContinue={() => setStep(dataInputSteps.vehicle)}
              nextDisabled={!selectedPriceId}
            />
          </View>
        )}

        {step === dataInputSteps.vehicle && (
          <View>
            <Title>{t('Subscription.steps.chooseVehicle.title')}</Title>
            {!selectedVehicle ? (
              <>
                <LicencePlateForm />
              </>
            ) : (
              <>
                <VehiclePicker navigation={navigation} />
                <ContinueNextButtons
                  onBack={() => setStep(dataInputSteps.startDate)}
                  onContinue={() => setStep(dataInputSteps.owner)}
                  nextDisabled={!selectedPriceId}
                />
              </>
            )}
          </View>
        )}

        {step === dataInputSteps.owner && (
          <View>
            <Title>{t('Subscription.steps.owner.title')}</Title>
            <CustomerDataForm
              navigation={navigation}
              onSuccess={() => setStep(dataInputSteps.paymentMethod)}
              onBack={() => setStep(dataInputSteps.vehicle)}
            />
          </View>
        )}

        {step === dataInputSteps.paymentMethod && (
          <View>
            <Title>{t('Subscription.steps.payment.title')}</Title>

            <PaymentMethodSelectList
              paymentMethods={paymentMethods}
              selectedPaymentMethod={selectedPaymentMethod}
              onSelectPaymentMethod={selectPaymentMethod}
            />

            <ReparkButton
              onPress={() =>
                openSheet(<AddPaymentMethodSheet navigation={navigation} />, {
                  adjustToContentHeight: true,
                })
              }
              mode="outlined"
              icon="add"
            >
              {t('Subscription.steps.payment.addNewButtonlabel')}
            </ReparkButton>
            <ContinueNextButtons
              onBack={() => setStep(dataInputSteps.owner)}
              onContinue={() =>
                navigation.navigate(SubscriptionsStackScreens.DataConfirm, {
                  garageId: props.route.params.garageId,
                })
              }
              nextDisabled={!selectedPaymentMethod}
            />
          </View>
        )}
      </View>
    </CenteredAppView>
  );
};

const styles = StyleSheet.create({
  container: { marginTop: 20, marginHorizontal: 20 },
});
